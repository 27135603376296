import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { AnimationComponent } from './animation.component';

const sharedComponents = [AnimationComponent];

@NgModule({
  declarations: [...sharedComponents],
  exports: [...sharedComponents],
  imports: [
    CommonModule,
  ],
})
export class AnimationsModule { }
