/* eslint-disable eqeqeq */
import { AddressInformation } from './address-information.model';
import { Address } from './address.model';

export class DeliveryAddress {
  fullName: string;
  address: Address;
  company?: string;

  constructor(model?: Partial<DeliveryAddress>) {
    Object.assign(this, model);
  }

  static compare(address1?: AddressInformation | DeliveryAddress, address2?: AddressInformation | DeliveryAddress) {
    const add1 = address1 as AddressInformation;
    const add2 = address2 as AddressInformation;

    // NOTE: We are doing '==' instead of '===' to do a loose comparison between null == undefined
    return add1?.fullName == add2?.fullName
      && add1?.company == add2?.company
      && add1?.address?.streetAddress1 == add2?.address?.streetAddress1
      && add1?.address?.streetAddress2 == add2?.address?.streetAddress2
      && add1?.address?.city == add2?.address?.city
      && add1?.address?.state == add2?.address?.state
      && add1?.address?.postalCode == add2?.address?.postalCode
      && add1?.phoneNumber?.number == add2?.phoneNumber?.number;
  }
}
