import { ValidatorFn, AbstractControl } from '@angular/forms';
import { CustomValidator } from './custom.validator';

/**
 * Returns validation errors if the formControl consists of only spaces
 */
export const noWhitespaceValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value == null) { return null; } // Let requiredValidator handle this
    const isEmpty = (CustomValidator.controlValueAsString(control).length === 0);
    return isEmpty ? { whitespace: true } : null;
  };
};
