import { Deserializable } from './deserializable.model';
import { OutputMimeType } from './output-mime-type';
import { TemplateInstanceMetaData } from './template-meta-data.model';

export class TemplateInstance implements Deserializable<TemplateInstance> {
  title: string;
  description: string;
  code: string;
  category: string;
  productCode: string;
  sku: string;
  thumbnailURL: string;
  templateURL: string;
  mimeType: OutputMimeType;
  metaData: TemplateInstanceMetaData;
  globalLuxury: boolean; // the template can be used for global luxury listings
  coldwellBanker: boolean; // the template can be used for regular listings

  templateInfo?: any;
  templateUseCount: number;
  previewPdfUrl: string;
  pageSize: string;

  constructor(data?: any) {
    this.deserialize(data || {});
  }

  deserialize(input: any): TemplateInstance {
    Object.assign(this, input);
    this.pageSize = (this.templateInfo) ? `${this.templateInfo.pageWidth} x ${this.templateInfo.pageHeight}` : null;
    return this;
  }
}
