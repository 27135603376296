import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppService } from './app.service';
import { AllOrders } from '../models';

@Injectable({
  providedIn: 'root',
})

export class WhatsNewService {
  private readonly env = AppService.get('environment');

  constructor(private httpClient: HttpClient) { }

  getAllRelease$(yearMonth: string): Observable<any> {
    return this.httpClient.get(`https://listing-concierge-artifacts.s3.us-west-2.amazonaws.com/whats-new/${this.env === 'local' ? 'dev' : this.env}/${yearMonth}whats-new.json`)
      .pipe(
        catchError((error) => {
          console.error(error);
          return of(null);
        }),
      );
  }

  getReleaseDetails$(url): Observable<any> {
    return this.httpClient.get(url);
  }
}
