import { Injectable } from '@angular/core';
import {
  map, tap, retryWhen, take, delay,
} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { OktaAuthenticationProvider } from './auth-providers/okta-authentication.provider';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root',
})
export class CannyService {
  readonly url = AppService.get('cannySSOURL');

  constructor(
    private oktaService: OktaAuthenticationProvider,
    private httpClient: HttpClient,
  ) {
  }

  /** Retrieves the Canny SSO from localStorage or from persist */
  getCannySSO$(): Observable<any> {
    const cachedCanny = localStorage.getItem('canny-sso');
    if (cachedCanny) {
      return of(cachedCanny);
    }
    // TODO: Doesn't the token.interceptor already do this?
    const accessToken = this.oktaService.getToken();
    const url = AppService.get('cannySSOURL');

    return this.doGet(url, accessToken);
  }

  doGet(url: string, accessToken: any): Observable<any> {
    const headers = { Authorization: `Bearer ${accessToken}` };

    const options = { headers };
    const maxAttempts = 3;

    return this.httpClient.get(url, options).pipe(
      map((res) => <any>res),
      tap((keysRes) => localStorage.setItem('canny-sso', keysRes.cannySSOToken)),
      retryWhen((errors) => {
        // try up to 3 times, waiting 1 second between each attempt
        return errors.pipe(
          tap((error) => console.log('[CannyService][getCannySSO]: Failure performing Canny SSO ', error)),
          delay(1000),
          take(maxAttempts),
        );
      }),
    );
  }
}
