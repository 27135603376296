import { Deserializable } from './deserializable.model';

export class ProductBodyLayoutModel implements Deserializable<ProductBodyLayoutModel> {
  readonly layoutName: string;
  readonly layoutContent?: string;
  readonly layoutContentFile?: string;
  readonly maxChars?: number;

  constructor(input?: Partial<ProductBodyLayoutModel>) {
    this.deserialize(input);
  }

  deserialize(input: any): ProductBodyLayoutModel {
    return Object.assign(this, input);
  }
}
