<div class="rich-text-container" (lcClickOutside)="onClickedOutside()">
  <div class="lc-rich-text html-enabled border" [class.border-primary]="textFits && !isEmoji">
    <div [class.border-warn]="!textFits || isEmoji" >
      <lc-editor-control
        [format]="format"
        [richTextEnabled]="richTextEnabled"
        [toolbarEnabled]="toolbarEnabled"
        [setBorder]="isRichTextActions"
        [minimumHeight]="minimumHeight"
        [isHeader]="isHeader"
        [isContact]="isContact"
        [formControl]="formControl"
        (change)="onTextChange($event)"
        [errorState]="hasErrors"
        [disabled]="readonly"
        [statistics]="richTextStats"
        (selectionChange)="onBlur()"
        (undoAiGeneration)="onUndo()"
        [undoDisabled]="undoDisabled"
        [showUndo]="['102'].includes(productCode) || (!['107', '112', '105',].includes(productCode) && bodyIndex === 0)"
      ></lc-editor-control>
    </div>
    <div *ngIf="isRichTextActions" class="fx-column border-top bg-card w-100 px-1 pb-1 bg-white">
      <!-- Error messages-->
      <div class="mat-caption line-height-0">
        <mat-error *ngIf="!textFits" class="text-smaller">Text doesn't fit</mat-error>
        <mat-error *ngIf="isEmoji && textFits" class="text-smaller">Emojis are not allowed in the text</mat-error>
         <!-- Placeholder to always reserve this space so that it does not fluctuate in size -->
        <span class="text-smaller">&nbsp;</span>
      </div>

      <div class="editor-actions-btn fx-row y-center" [ngClass]="(aiFeatureIsEnabled$ | async) ? 'x-space-between' : 'x-end'">
        <div *ngIf="aiFeatureIsEnabled$ | async">
          <p *ngIf="propertyDescriptionService.getAiMaxRequests(ai) as aiMaxRequests" style="margin: 0; font-size: x-small;">
            AI Usage: {{ propertyDescriptionService.getAiRequests(ai) }} / {{ aiMaxRequests ?? 0 }}
          </p>
          <p *ngIf="!['107', '112', '105'].includes(productCode) && bodyIndex === 0" class="mat-hint" style="margin: 0; font-size: x-small;">
            AI generated content may contain errors. Verify for accuracy.
          </p>
        </div>
        <div class="fx-row fx-gap-xs">
          <button
            id="btn-cancel"
            mat-raised-button
            (click)="onCancel()"
          >Cancel
          </button>
          <button
            id="btn-save"
            mat-raised-button
            [color]="((aiFeatureIsEnabled$ | async) === false || bodyIndex !== 0 || productCode === ProductCode.CLIENT_REPORT || productCode === ProductCode.JUST_SOLD_POSTCARD) && 'primary'"
            [disabled]="disableSave || !textFits || isEmoji"
            (click)="onSave()"
          >Save
          </button>
          <button
            *ngIf="(aiFeatureIsEnabled$ | async) && bodyIndex === 0 && productCode !== ProductCode.CLIENT_REPORT &&  productCode !== ProductCode.JUST_SOLD_POSTCARD"
            id="btn-generate"
            mat-raised-button
            color="primary"
            (click)="onGenerate()"
          >Regenerate
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
