import * as ActionTypes from './workflow.actions';
import { FlowState, FlowStep } from '../../models/flow-state.model';

export const initialWorkflow = null;

function updateStepState(data: FlowState, action) {
  const currentStep: FlowStep = data?.currentStep;
  if (action.data.stepCompleted) {
    currentStep.completed = true;
  }
  if (action.data.hasChanges) {
    // Mark any and all dependencies as not starte
    data.afterChanges(currentStep);
  }
  if (action.data.parentCompleted) {
    currentStep.parent.completed = action.data.parentCompleted;
  }
  currentStep.setStarted(true);
  if (action.data.stateData) {
    currentStep.stateData = action.data.stateData;
  }
}

/**
 * This function will create a new state object from an action. See ngrx.io for the architecture
 * of reducers.
 *
 * @param data the current state
 * @param action the action
 * @return FlowState the new flow state
 */
export function workflowReducer(data = initialWorkflow, action: any): FlowState {
  const actionsThatAllowStateChange = [ActionTypes.WorkflowActionInitialize.type, ActionTypes.WorkflowActionUpdate.type];
  if (!actionsThatAllowStateChange.includes(action.type) && action.data && action.data.state) {
    throw new Error('Only WorkflowActionInitialize action may include data.state');
  }

  switch (action.type) {
    case ActionTypes.WorkflowActionInitialize.type:
    case ActionTypes.WorkflowActionUpdate.type:
      return action.data.state;
    case ActionTypes.WorkflowActionTerminate.type:
      return null;
    case ActionTypes.WorkflowActionNext.type:
      updateStepState(data, action);
      data.nextStep(data.currentStep);
      return new FlowState({ ...data });
    case ActionTypes.WorkflowActionPrev.type:
      updateStepState(data, action);
      data.prevStep(data.currentStep);
      return new FlowState({ ...data });
    case ActionTypes.WorkflowActionEdit.type:
      data.setStep(action.data.id);
      updateStepState(data, action);
      if (data.currentStep.hasSubsteps()) {
        data.nextStep(data.currentStep);
      }
      return new FlowState({ ...data });
    case ActionTypes.WorkflowActionSetStep.type:
      if (!data) {
        console.log('Workflow is not initialized');
        return data;
      }
      if (data.currentStep?.id === action.data.id) {
        return data;
      }
      data.setStep(action.data.id);
      return new FlowState({ ...data });

    default:
      return data;
  }
}
