import { Deserializable } from './deserializable.model';
import { MultiMediaItem, MultiMediaType } from './multi-media-item.model';

export class Media implements Deserializable<Media> {
  multimedia : MultiMediaItem[] = [];

  constructor(input?: any) {
    this.deserialize(input || {});
  }

  deserialize(input: any): Media {
    if (!input) { return this; }

    Object.assign(this, input);
    if (this.multimedia.length) {
      this.multimedia = this.multimedia.map((mmi) => new MultiMediaItem(mmi));
    }
    return this;
  }

  byType(type : MultiMediaType) : MultiMediaItem[] {
    return this.multimedia.filter((m) => m.type === type);
  }
}
