<!-- Date Range Input -->
<mat-form-field appearance="outline" *ngIf="enableRangeSelection">
  <mat-label>{{ label }}</mat-label>
  <mat-date-range-input [rangePicker]="picker" [min]="minDate" [max]="maxDate" >
    <input matStartDate [formControl]="startDateControl" [readonly]="readonly">
    <input matEndDate [formControl]="endDateControl" [readonly]="readonly">
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>

<!-- Single Date Input -->
<mat-form-field *ngIf="!enableRangeSelection" appearance="outline">
  <mat-label [id]="idPrefix + '-label'" >{{label}}</mat-label>
  <input matInput [id]="idPrefix + '-input'" [matDatepicker]="picker" [placeholder]="placeholder" [formControl]="formControl"  [errorStateMatcher]="matcher"
         [readonly]="readonly"
         [max]="maxDate"
         [min]="minDate">
  <mat-datepicker-toggle matPrefix [for]="picker" [disabled]="readonly || disabled"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  <mat-hint [id]="idPrefix + '-hint'">{{hint}}</mat-hint>
  <mat-error [id]="idPrefix + '-error'" *ngIf="formControl && formControl.invalid" [innerHTML]="errors"></mat-error>
</mat-form-field>
