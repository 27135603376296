<!-- TODO: This form group is the same as billing and shipping component.html-->
<form [formGroup]="formGroup" class="fx-column">
  <lc-textbox label="Full Name" idPrefix="full-name" class="mt-3" [formControl]="formGroup.get('fullName')" placeholder="Your Full Name"></lc-textbox>

  <!-- Address Section -->
  <lc-address-details-form [formGroup]="formGroup.get('address')"></lc-address-details-form>

  <lc-textbox idPrefix="company" label="Company (Optional)" [formControl]="formGroup.get('company')" placeholder="Company"></lc-textbox>

  <div lcClass.gt-sm="fx-row fx-gap-md" lcClass.lt-md="fx-column">
    <ng-container [formGroup]="formGroup.get('phoneNumber')">
      <lc-phone-number class="fx-1-1" idPrefix="phone" label="Phone" [formControl]="formGroup.get('phoneNumber').get('number')"></lc-phone-number>
    </ng-container>
    <lc-textbox class="fx-1-1" idPrefix="email" label="Email (Optional)" [formControl]="formGroup.get('email')"></lc-textbox>
  </div>
</form>

