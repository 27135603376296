<lc-base-dialog [title]="data?.title || 'PDF View'" (actionClicked)="onClose()">

  <ng-template #content>
    <div class="pdf-container">
      <iframe *ngIf="safeUrl" [src]="safeUrl"></iframe>
    </div>
  </ng-template>
</lc-base-dialog>


