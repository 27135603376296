import {
  FormGroup, FormArray, AbstractControl, ValidatorFn,
} from '@angular/forms';
import { LCFormArray } from './lc-form-array';

export class BaseForm<TModel = any> extends FormGroup {
  get id(): string {
    const control = this.get('_id');
    return control ? control.value : null;
  }

  isEdit: boolean;
  private _isNew: boolean;

  set isNew(val) { this._isNew = val; }
  get isNew(): boolean {
    if (this._isNew !== undefined) { return this._isNew; }

    const control = this.get('_id');
    if (control) {
      // If it has an _id field, check new by verifying it is not null
      return control.value == null;
    }

    // Otherwiser, assume from the originalValue
    return !this._originalValue;
  }

  protected _originalValue: Partial<TModel>;
  get originalValue(): Partial<TModel> { return this._originalValue; }

  getValue(options?: { removeNulls?: boolean, onlyDirty?: boolean }): any {
    let value = {};
    Object.keys(this.controls).forEach((key) => {
      const control = this.controls[key];
      if (options?.onlyDirty && !control.dirty) {
        return;
      }
      if (control instanceof BaseForm || (control as any).getDirty) {
        value[key] = (control as any).getValue(options);
      } else {
        value[key] = control.value;
      }
    });

    if (options?.removeNulls) {
      value = this.clean(value);
    }
    return value;
  }

  /**
   * @deprecated use .getValue(...) instead
   * @param options
   * @returns
   */
  getDirty(options?: { removeNulls?: boolean }): any {
    const dirtyOptions = { onlyDirty: true, ...options };
    return this.getValue(dirtyOptions);
  }

  setEnabled(isEnabled) {
    if (isEnabled) {
      this.enable();
    } else {
      this.disable();
    }
  }

  private clean(value: any) {
    return JSON.parse(JSON.stringify(value, (key, val) => {
      return (val === null ? undefined : val);
    }));
  }

  markAllAsDirty(options?: { invalidOnly?: boolean }) {
    BaseForm.markAllAsDirty(this, options);
  }

  markAllAsPristine() {
    BaseForm.performOnAllControls(this, (control) => {
      control.markAsUntouched();
      control.markAsPristine();
      control.updateValueAndValidity();
    });
  }

  markControlAsDirty(control: AbstractControl) {
    control.markAsDirty();
    control.markAsTouched();
    control.updateValueAndValidity();
  }

  getControl(propertyName: keyof TModel): AbstractControl {
    return this.get(propertyName.toString());
  }

  setOriginalValue(value?: Partial<TModel>) {
    this._originalValue = value;
  }

  reset(value?: any, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this._originalValue = value ?? this.originalValue;

    // Reset form arrays
    Object.keys(this.controls)
      .map((key) => ({
        control: this.controls[key],
        originalValue: this.originalValue ? this.originalValue[key] : undefined,
      }))
      .filter(({ control }) => control instanceof FormArray)
      .forEach(({ control, originalValue }) => {
        if (control instanceof LCFormArray) {
          control.reset(originalValue);
        } else if (control instanceof FormArray) {
          control.controls.forEach((arrayControl, index) => {
            if (arrayControl instanceof BaseForm && arrayControl.isNew) {
              control.removeAt(index);
            } else {
              const value = originalValue instanceof Array
                ? (originalValue || [])[index]
                : undefined;
              arrayControl.reset(value);
            }
          });
        }
      });

    super.reset(value || this.originalValue, options);
    this.markAllAsPristine();
  }

  static validateIf(runValidator: (val?: any) => boolean, validator: ValidatorFn): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const val = (control.value ?? '');
      return runValidator(val) ? validator(control) : null;
    };
  }

  static performOnAllControls(form: AbstractControl | FormGroup, action: (control: AbstractControl) => any) {
    // Perform action on current form
    action(form);

    // Enumerate all controls and perform action
    const controls = form instanceof FormGroup ? form.controls : [];
    Object.keys(controls).forEach((key) => {
      const control = controls[key];

      if (control instanceof FormArray) {
        // Element is a FormArray. Enumerate all controls and perform action
        for (const arrayControl of control.controls) {
          if (arrayControl instanceof BaseForm) {
            BaseForm.performOnAllControls(arrayControl, action);
          } else {
            action(arrayControl);
          }
        }
      } else if (control instanceof BaseForm) {
        BaseForm.performOnAllControls(control, action);
      } else {
        action(control);
      }
    });
  }

  static markAllAsDirty(control: AbstractControl | FormGroup, options?: { invalidOnly?: boolean }) {
    BaseForm.performOnAllControls(control, (c) => {
      if (options?.invalidOnly && c.valid) { return; } // Don't do anything
      c.markAsDirty();
      c.markAsTouched();
      c.updateValueAndValidity();
    });
  }

  static removeNulls(obj: any) {
    const isArray = obj instanceof Array;
    for (const k in obj) {
      if (obj[k] === null) isArray ? obj.splice(+k, 1) : delete obj[k];
      else if (typeof obj[k] === 'object') BaseForm.removeNulls(obj[k]);
    }
    return obj;
  }
}
