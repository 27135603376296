import {
  Component, OnInit, Input, EventEmitter, Output, ViewChild, ChangeDetectorRef, AfterViewInit, ChangeDetectionStrategy,
} from '@angular/core';
import { PhotoDownloadService, ThumbnailSizes, ToasterService } from '@lc/core';
import {
  CheckboxModule, LoadingEvent, PresignedPhotoDirective, SpinnerModule, UICoreModule,
} from '@lc/ui';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RouterModule } from '@angular/router';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { PhotoViewModel } from '../../photo-view-model';

/**
 * The PhotoComponent is used to display the photos in the photo-list as well as the product-photos templates.
 * It has the display for the photo as well as all of the overlay options
 */
@Component({
  selector: 'lc-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatSliderModule, MatBadgeModule,
    MatIconModule, MatButtonModule, DragDropModule, RouterModule, MatTooltipModule, MatTableModule,
    MatProgressSpinnerModule, CheckboxModule, UICoreModule, SpinnerModule],
})
export class PhotoComponent implements OnInit, AfterViewInit {
  @Input()
    viewModel: PhotoViewModel;

  @Input()
    controlPrefix: string;

  @Input()
    totalCount: number;

  @Input()
    deleteStyle: string;

  @Input()
    title: String;

  @Input()
    roundImg: boolean = true;

  @Input()
    thumbnailSize: number = ThumbnailSizes.MEDIUM_THUMBNAIL_WIDTH;

  @Output()
  readonly delete = new EventEmitter<PhotoViewModel>();

  @Output()
  readonly edit = new EventEmitter<PhotoViewModel>();

  @Output()
  readonly select = new EventEmitter<PhotoViewModel>();

  @Output()
  readonly toggleFavorites = new EventEmitter<PhotoViewModel>();

  @Output()
  readonly reorder = new EventEmitter<{ viewModel: PhotoViewModel, order: number }>();

  @Output()
  readonly portraitDisabled = new EventEmitter<PhotoViewModel>();

  @Output()
  readonly hide = new EventEmitter<PhotoViewModel>();

  @ViewChild(PresignedPhotoDirective)
    presignedDirective: PresignedPhotoDirective;

  @Input() iconsPersist: boolean = false;
  isFocused: boolean = false;
  fallbackThumbnailSize = ThumbnailSizes.LARGE_THUMBNAIL_WIDTH;
  showHover = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private photoDownloadService: PhotoDownloadService,
    private toaster: ToasterService,
  ) { }

  ngOnInit() {}

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  onDelete(viewModel: PhotoViewModel) {
    if (!viewModel.canDelete) { return; }
    this.delete.emit(viewModel);
  }

  onEdit(viewModel: PhotoViewModel) {
    if (!viewModel.canEdit) { return; }
    this.edit.emit(viewModel);
  }

  async onDownload(viewModel: PhotoViewModel) {
    if (!viewModel.canDownload) {
      return;
    }

    viewModel.loading = LoadingEvent.loading('Downloading...');

    await this.photoDownloadService.downloadPhoto(viewModel.photo)
      .catch((error) => {
        console.error('[PhotoComponent]: Error downloading photo', { error });
        this.toaster.showError('Error downloading photo. Please try again.');
      })
      .finally(() => viewModel.loading = LoadingEvent.none);
  }

  onSelected(viewModel: PhotoViewModel) {
    if (!viewModel.canSelect) { return; }
    this.select.next(viewModel);
  }

  onKeyEnter(event) {
    event.target.blur();
  }

  onReorder(viewModel: PhotoViewModel, order: number) {
    viewModel.isEditNumber = false;

    if (!viewModel.canReorder) { return; }
    if (viewModel.photo.order === +order) { return; } // Nothing change, don't emit
    this.reorder.next({ viewModel, order: +order });
  }

  onToggleFavorite(viewModel: PhotoViewModel) {
    if (!viewModel.canFavorite) { return; }
    this.toggleFavorites.next(viewModel);
  }

  onEditOrder(viewModel, isEdit) {
    if (!viewModel.canReorder) { return; }
    viewModel.isEditNumber = isEdit;
  }

  onHideClicked(event: any, viewModel: PhotoViewModel) {
    event.stopPropagation();

    if (!viewModel.canHide) { return; }
    viewModel.photo.hidden = !viewModel.photo.hidden;
    this.hide.next(viewModel);
  }

  /**
   * Executes with the img loads into view. Necessary to obtain the height/width of the images
   * since we do not have metadata to read from.
   * @param viewModel The display ViewModel for the photo
   * @param event The loaded event
   */
  onImageLoaded(viewModel: PhotoViewModel, event) {
    if (viewModel.disablePortrait && event?.target?.naturalHeight && event?.target?.naturalWidth) {
      const isPortrait = viewModel.photo?.isPortrait()
        // This is legacy code. Left here for backward compatability
        || event?.target?.naturalHeight > event?.target?.naturalWidth;

      if (isPortrait) {
        // If portrait mode and portrait is disabled, disable the drag and add a tooltip
        viewModel.disabled = true;
        viewModel.tooltip = 'Cannot use a portrait photo on a video product';
        this.portraitDisabled.emit(viewModel);
      }
    }
  }
}
