import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AllOrders } from '../models';
import { AppService } from './app.service';
import { PromptDialogService } from './prompt-dialog.service';

export enum OrderFilterTypes {
  mine = 'mine',
  notMine = 'notMine',
}

type OrderFilterStrings = keyof typeof OrderFilterTypes;

export interface OrderRequestParams {
  [key: string]: any,
  search?: string,
  sortColumn?: string,
  sortDir?: string,
  page?: number,
  pageSize?: number
  flagged?: any,
  requestType?: string,
  sortByRelevance?: boolean
}

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(
    private httpClient: HttpClient,
    private readonly promptService: PromptDialogService,
  ) {
  }

  getOrders(requestType: OrderFilterStrings, params?: OrderRequestParams): Observable<{ orders: AllOrders[], totalCount: number }> {
    const baseUrl = AppService.get('persistBaseURL');
    const allParams: OrderRequestParams = params || {};
    // if they have selected both flagged and unflagged, don't query on it
    // that is the same as not query on flagged at all.
    if (allParams.flagged?.length !== 1) {
      delete allParams.flagged;
    }
    allParams.requestType = requestType;
    const httpParams = new HttpParams({ fromObject: allParams });
    httpParams.append('requestType', requestType.toString());
    return this.httpClient.get<AllOrders[]>(`${baseUrl.replace('v1', 'v2')}orders/all`, { params: httpParams, observe: 'response' })
      .pipe(
        map((response) => {
          const orders = response.body.map((order) => new AllOrders(order));
          const totalCount = response.headers.get('total-count');
          return { orders, totalCount: +totalCount };
        }),
        catchError((response) => {
          // where can I find enum or constants for http status codes?
          const userMessage = (response.status === 507)
            ? 'Too many orders were found. Try narrowing your search'
            : 'There was an unforeseen error, try searching again';
          this.promptService.openPrompt('Error', userMessage, 'Ok');
          return of(null);
        }),
      );
  }
}
