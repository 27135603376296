// tslint:disable: rxjs-no-sharereplay
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { Option, OptionType } from '../models/option.model';
import { ApiService } from './api.service';

@Injectable()
export class OptionsService {
  private optionsCache: Observable<Option[]>;

  constructor(private apiService: ApiService) { }

  /**
   * Returns all available  options  Caches the options once per page-refresh
   * by utilizing shareReplay
   */
  getOptions(type?: OptionType): Observable<Option[]> {
    this.optionsCache ??= this.apiService.get('options').pipe(shareReplay(1));

    return this.optionsCache.pipe(
      map((options) => {
        return (options || [])
          .filter((options) => options != null)
          .filter((opt) => !type || opt.type === type)
          .sort((a, b) => (a.order - b.order));
      }),
    );
  }
}
