<mat-checkbox
  [id]="idPrefix + '-form-field'"
  [checked]="checked"
  [formControl]="formControl"
  [class.no-margin]="!(hint || showErrors)"
  [class.mat-checkbox-disabled]="readonly"
  [disableRipple]="readonly"
  (click)="readonly ? $event.preventDefault() : null"
  color="primary"
  (change)="onChanged($event)"
>
  <ng-content></ng-content>
  <mat-label [id]="idPrefix + '-label'">{{label}}</mat-label>
</mat-checkbox>

<div *ngIf="hint || showErrors" style="margin-left:24px; font-size: 75%; margin-top: -5px;">
  <span
    [ngClass]="(showErrors && errors) ? 'mat-error' : 'mat-hint'"
    [innerHTML]="(showErrors ? errors : null) || hint || '&nbsp;'"
  ></span>
</div>
