import { Deserializable } from './deserializable.model';

/**
 * Model for a complete flow of ui steps.
 */
export class FlowState implements Deserializable<FlowState> {
  /**
   * the name of this flow, used internally
   */
  readonly name: string;

  /**
   * the version of the flow state, in case the flow steps for a flow change
   * the version of any flows that remain uncompleted can be used to determine the
   * changes need to update to the new flow steps
   */
  readonly version: number;

  /**
   * The current step in the flow state
   */
  currentStep: FlowStep;

  /**
   * The steps in a flow, may contain nested sub-steps.
   */
  steps: FlowStep[];

  private readonly flattenedSteps: FlowStep[];

  static convert(steps: Object[]): FlowStep[] {
    const converted: FlowStep[] = [];
    if (!steps || !steps.length) {
      return converted;
    }
    steps.forEach((step) => {
      const cs = new FlowStep();
      cs.deserialize(step);
      converted.push(cs);
      cs.steps = FlowState.convert(cs.steps);
    });
    return converted;
  }
  static flatten(steps: FlowStep[], flattened: FlowStep[], parent: FlowStep): FlowStep[] {
    if (steps && steps.length) {
      for (let i = 0; i < steps.length; i++) {
        flattened.push(steps[i]);
        steps[i].parent = parent;
        FlowState.flatten(steps[i].steps, flattened, steps[i]);
      }
    }
    return flattened;
  }

  constructor(input: any) {
    if (input) {
      this.deserialize(input);
      this.steps = FlowState.convert(this.steps);
      const flattened = [];
      this.flattenedSteps = FlowState.flatten(this.steps, flattened, null);
      // when constructing from data structure such as new FlowState({...data}), the currentStep and corresponding step
      // on flattenedSteps are not same object in memory. This will set them to be the same by assigning current step
      // to the same object in the flattenedstep array.
      if (this.currentStep) {
        this.currentStep = this.find(this.currentStep.id);
        this.setLastCompletedStep();
      }
    }
  }

  deserialize(input: any): FlowState {
    Object.assign(this, input);
    return this;
  }

  start(step?: FlowStep): FlowStep {
    const first = step ? this.find(step.id) : this.flattenedSteps[0];
    if (!first) {
      throw new Error('Invalid Step');
    }

    this.currentStep = first.isNavigable() ? first : this.nextNavigableStep(first);
    return this.currentStep;
  }

  hasPreviousUnfinishedSteps(step: FlowStep) {
    const indexOfCurrent = this.indexOf(step);
    const indexOfFirstIncomplete = this.flattenedSteps.findIndex((s: FlowStep) => !s.completed);
    return indexOfFirstIncomplete > -1 && indexOfFirstIncomplete < indexOfCurrent;
  }

  nextNavigableStep(step: FlowStep): FlowStep {
    let nextStep = this.nextStep(step);

    const internalStep = this.find(nextStep.id);
    if (!internalStep.isNavigable()) {
      nextStep = this.nextNavigableStep(nextStep);
    }
    return nextStep;
  }

  nextStep(step: FlowStep): FlowStep {
    if (!step) {
      throw new Error('Invalid Step');
    }
    const index = this.indexOf(step);
    if (index === -1) {
      throw new Error('Invalid Step');
    }

    this.currentStep = index < this.flattenedSteps.length - 1 ? this.flattenedSteps[index + 1] : null;
    if (this.currentStep && !this.currentStep.hasSubsteps()) {
      this.currentStep.started = true;
    }
    return this.currentStep;
  }

  afterChanges(step: FlowStep) {
    this.resetDependantSteps(this.steps, step);
  }

  private resetDependantSteps(steps: FlowStep[], changedStep: FlowStep) {
    (steps || []).forEach((step) => {
      if ((step.dependsOn || []).some((dependency) => dependency.id === changedStep.id)) {
        step.completed = false;
        if (step.parent) {
          step.parent.completed = false;
        }
      }
      this.resetDependantSteps(step?.steps, changedStep);
    });
  }

  prevStep(step: FlowStep): FlowStep {
    const index = this.indexOf(step);
    if (index === -1) {
      throw new Error('Invalid Step');
    }

    this.currentStep = index > 0 ? this.flattenedSteps[index - 1] : null;
    return this.currentStep;
  }

  setStep(id: string): FlowStep {
    if (id === null) {
      this.start();
      return undefined;
    }
    const step = this.find(id);
    if (!step) {
      throw new Error(`Invalid Step for id = ${id}`);
    }
    this.currentStep = step;

    // Set both the parent and child step as started
    const firstChildStep = (this.currentStep.steps || [])[0];
    firstChildStep?.setStarted(true);
    this.currentStep.setStarted(true);

    return this.currentStep;
  }

  /**
   * This will mark a step complete and all previous steps complete in the flow
   */
  setLastCompletedStep(): void {
    this.steps.forEach((parentStep) => {
      parentStep.completed = (parentStep.steps || []).every((step) => step.completed);
      parentStep.setStarted((parentStep.steps || []).some((step) => step.started));
    });
  }

  getActiveSteps(): FlowStep[] {
    if (this.steps) {
      return this.steps.filter((step) => step.active);
    }
    return this.steps;
  }

  find(id: string): FlowStep {
    return this.findFirst((s) => s.id === id);
  }

  serializeState(): FlowStateData {
    const currentState = this.flattenedSteps.map((step) => {
      const state: any = {
        id: step.id,
        active: step.active,
        started: step.started,
        completed: step.completed,
      };

      if (step.stateData) {
        state.stateData = step.stateData;
      }
      return state;
    });
    return {
      currentStepId: this.currentStep?.id || null,
      state: currentState,
    };
  }

  deserializeState(state?: FlowStateData): FlowState {
    if (state) {
      state.state.forEach((aStep) => {
        const flowStep = this.find(aStep.id);
        // TODO - error handing if step not found?
        if (flowStep) {
          Object.assign(flowStep, aStep);
        }
      });
    }

    // Set current step on the first that meets one of the following conditions:
    // 1.) We find an incomplete step
    // 2.) We land on the currentStepId
    const currentStepId = state ? state.currentStepId : null;
    const step = this.findFirst((flowStep: FlowStep) => !flowStep.completed || flowStep.id === currentStepId);
    this.start(step);
    return this;
  }

  private filter(callback): FlowStep[] {
    return this.flattenedSteps.filter(callback);
  }

  private findFirst(callback): FlowStep {
    const foundSteps = this.filter(callback);

    return (foundSteps.length) ? foundSteps[0] : null;
  }

  private indexOf(step: FlowStep): number {
    if (this.flattenedSteps) {
      for (let i = 0; i < this.flattenedSteps.length; i++) {
        if (this.flattenedSteps[i].id === step.id) {
          return i;
        }
      }
    }
    return -1;
  }
}

export class FlowStep implements Deserializable<FlowStep> {
  /**
   * id of the parentStep, must be unique within the flow.
   */
  readonly id;

  readonly displayName: string;

  readonly description: string;

  /**
   * Is the step active (enabled) in the current flow
   */
  active = true;

  /**
   * The Angular router link pattern fot the component that will present the UI
   * for this parentStep
   */
  routerLinkUri: string;

  /**
   * The Angular router link pattern fot the component that will present the UI
   * for this parentStep
   */
  url: string;

  /**
   * marks the step as completed
   */
  completed = false;

  /**
   * Is the step started
   */
  started = false;

  /**
   * Any sub steps needed to complete this parentStep
   */
  steps: FlowStep[];

  /**
   * Parent node or null if no parent. Set on flatten of the tree
   */
  parent: FlowStep;

  icon: string;

  /**
   * Unbound, application-specific data attached to step. This is typically defined in the workflow JSON representation
   * and is used by the workflow steps. @see OrderListingFlowService definitions.
   */
  lcData: any;
  dcData: any;

  /**
   * Statedata is used to store any work in progress information for a step. This could be incomplete
   * data entry or the state of the widget (for example scroll location) on a screen or maybe selected elements in
   * a grid
   */
  stateData: any;
  dependsOn?: FlowStep[];

  constructor(id?: string, displayName?: string, routerLinkUri?: string) {
    this.id = id;
    this.displayName = displayName;
    this.routerLinkUri = routerLinkUri;
  }

  deserialize(input: any): FlowStep {
    Object.assign(this, input);

    return this;
  }

  isNavigable(): boolean {
    return this.active && (this.routerLinkUri !== undefined && this.routerLinkUri.length > 0);
  }

  /**
   * There is logic that assumes if you set started on an step and it is a parent
   * step then we need to check children steps if they have started
   *
   * @param started
   */
  setStarted(started: boolean): void {
    if (started && this.parent) {
      this.parent.setStarted(started);
    }
    this.started = started;
  }

  hasSubsteps(): boolean {
    return this.steps !== undefined && this.steps.length > 0;
  }
}

export interface FlowStateData {
  currentStepId: string;
  state: any[];
}
