/**
 *
 */
export enum ProductRejectReasonType {
  COPY = 'Copy needs more work',
  IMAGES = 'Use different images',
  DETAILS = 'Property details are missing or incorrect',
  DESIGN = 'Use a different design',
  NEEDS_REVIEW = 'I made changes that need Coordinator review',
  OTHER = 'Other',
}
