import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';

// Components
import { ResizeComponent } from './resize.component';

const sharedComponents = [ResizeComponent];

@NgModule({
  declarations: [...sharedComponents],
  exports: [...sharedComponents],
  imports: [
    CommonModule, DragDropModule,
  ],
})
export class ResizeModule { }
