<lc-base-dialog id="notes-dialog" [primaryAction]="primaryAction" [otherActions]="[cancelAction]" (close)="closeDialog()">

  <!-- Modal Header -->
  <ng-template #title>
    <div>
      <h4 id="notes-title" class="m-0">{{data?.title}}</h4>
      <h5 id="notes-subtitle" class="m-0">{{data?.subtitle}}</h5>
    </div>
  </ng-template>

  <!--Modal Body-->
  <ng-template #content>
    <div id="notes-container" class="container border px-3 mb-3">
      <div class="fx-row fx-gap-sm note" *ngFor="let note of notes$ | async">
        <mat-icon class="material-icons-outlined mt-xs">description</mat-icon>
        <div class="fx-column fx-gap-0 note-container">
          <span class="d-block text-faded note-author">{{note.author}} {{note.audit?.createdAt | date:'short'}}</span>
          <span class="d-block note-text">{{note.text}}</span>
        </div>
      </div>
    </div>

    <form [formGroup]="form">
      <lc-textarea idPrefix="note" label="Add a Note" [formControl]="form.get('text')"></lc-textarea>
    </form>
  </ng-template>
</lc-base-dialog>

