export enum TimezoneList {
  centraltime = 'Central Time',
  easterntime = 'Eastern Time',
  pacifictime = 'Pacific Time',
  mountaintime = 'Mountain Time',
  hawaiitime = 'Hawaii Time',
  alaskatime = 'Alaska Time',
}

export enum TimeZoneIanaNames {
  centraltime = 'America/Chicago',
  easterntime = 'America/New_York',
  pacifictime = 'America/Los_Angeles',
  mountaintime = 'America/Denver',
  hawaiitime = 'Pacific/Honolulu',
  alaskatime = 'America/Anchorage',
}

export const TimezoneDisplays = Object.keys(TimezoneList).map((name) => ({
  display: TimezoneList[name],
  value: TimezoneList[name],
  timezoneName: TimeZoneIanaNames[name],
}
));
