import { Injectable } from '@angular/core';
import {
  delay, map, retryWhen, take,
} from 'rxjs/operators';
import { ListingPhoto } from '../models/listing-photo.model';
import { PhotoService } from './photo.service';
import { PhotoApiService } from './api.service';

/**
 * Service used to initiate downloads of photos.
 */
@Injectable({
  providedIn: 'root',
})
export class PhotoDownloadService {
  constructor(
    private photoService: PhotoService,
    private photoApiService: PhotoApiService,
  ) {
  }

  /**
   * Calls for the correct image for the provided ListingPhoto and downloads the image in the browser
   * @param photo The ListingPhoto to download
   */
  async downloadPhoto(photo: ListingPhoto) {
    return await this.photoService.getImage(photo.uri).toPromise()
      .then((file) => this.initiateDownload(file))
      .catch((error) => console.error(`Error downloading photo ${photo.uri}: ${error.message}`));
  }

  public downloadUsingCorsProxy(photo: ListingPhoto, errorHandler) {
    this.photoService.getFileUsingCorsProxy(photo.uri).toPromise()
      .then((file) => this.initiateDownload(file))
      .catch((err) => {
        console.error(`Error downloading using cors proxy: ${err.message}`);
        errorHandler(err);
      });
  }

  public downloadProfilePhoto(uri: string) {
    // const randomFileName = (uri || '').split('/').pop();
    const currentTimeInMilliseconds = Date.now();
    const urlWithTime = `${uri}?ts=${currentTimeInMilliseconds}`;
    this.photoService.getImage(urlWithTime).toPromise()
      .then((file) => this.initiateDownload(file))
      .catch((error) => console.error(`Error downloading profile photo ${uri}: ${error.message}`));
  }

  /**
   * Creates a temporary link on the page to download the provided file
   * and initiates the download.
   * @param file The file to download
   */
  private initiateDownload(file: File) {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(file);
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
  }

  /**
   *  Download Zip file as a blob and saves as file
   *  Retries multiple times until URL is available
   * @param zipFileURL
   * @param resolution
   */
  public downloadZipFile(zipFileURL: string, resolution: string) {
    const randomFileName = `${new Date().getTime()}_${resolution}.zip`;
    return this.photoApiService.getBlobFromS3URL$(zipFileURL, undefined, { supressLogErrors: true }).pipe(
      map((response) => {
        if (response.status === 200 && response.headers.get('Content-Type') === 'application/json') {
          throw new Error('PDF Not Ready');
        } else {
          return this.photoApiService.downloadFile(response.body, randomFileName);
        }
      }),
      retryWhen((errors) => errors.pipe(delay(1000), take(20))),
    ).toPromise();
  }
  /**
   * Request with download URLs. Service responds wth a S3 URL
   * @param urls
   * @param resolution
   */
  public getZipURL(urls: string[], resolution: string) {
    if (urls.length === 0) { return; }
    const downloadURLParams = {
      imageArray: urls,
      resolution,
    };
    return this.photoApiService.post('images/download', downloadURLParams);
  }
}
