import { Injectable } from '@angular/core';
import { from, of } from 'rxjs';
import {
  catchError, delay, map, retryWhen, take, tap,
} from 'rxjs/operators';
import { core, usAutocompletePro } from 'smartystreets-javascript-sdk';
import { AppService } from './app.service';

const RETRY_MAX_ATTEMPTS = 3;
const RETRY_DELAY = 1000;
/**
 * Smart Street api call for getting the address suggestions based on streetAddress1
 */
@Injectable({
  providedIn: 'root',
})

export class SmartyStreetService {
  private readonly streetNumberRegex = /^\d+$/;

  // There does not seem to be a Client type exported from Smarty API
  private smartyClient: any;

  constructor() {
    this.init(AppService.get('smartStreetKey'));
  }

  private init(key) {
    if (!key) return;

    // Client is re-usable, can be set up once:
    //
    // https://github.com/smarty/smartystreets-javascript-sdk/blob/master/examples/us_autocomplete_pro.js
    //
    // '86422360070416896'; // Website Key
    const credentials = new core.SharedCredentials(key);
    const clientBuilder = new core.ClientBuilder(credentials).withLicenses(['us-autocomplete-pro-cloud']);
    this.smartyClient = clientBuilder.buildUsAutocompleteProClient();
  }

  /**
   * Return true if the search term is eligible to be passed to Smarty API
   * for address search.
   *
   * We limit searches so we do not make too many API calls:
   *
   * <ol>
   *   <li>We don't search until we get past the street number</li>
   *   <li>The second word in the string has to have more than 2 characters</li>
   *   <li>Words after the second need only 1 character.
   * </ol>
   *
   * Valid Searches:
   *  <ul>
   *    <li>577 E B</li>
   *    <li>577 E Base</li>
   *    <li>577 E Baseline R</li>
   *    <li>577 E Baseline Rd</li>
   *    <li>577 E Baseline Rd</li>
   *    <li>The</li>
   *  </ul>
   *  <ul>
   *    <li>577</li>
   *    <li>577 E</li>
   *    <li>577 E Ba</li>
   *  </ul>
   *
   *  see unit tests for more examples
   *
   * @param term
   */
  isSearchable(term: string) {
    if (!term) {
      return false;
    }
    // split the term into words
    const words = term.split(' ')
      .map((word) => word.trim())
      .filter((word) => word); // removes empty strings.

    if (words?.length === 0) {
      return false;
    }

    const firstIsNumber = words[0].search(this.streetNumberRegex) !== -1;
    // We will not authoriuze a search when we onlyh have the stree number
    if (firstIsNumber && words.length === 1) {
      return false;
    }
    // here we know we have more than one word so let's look at the last word
    //
    const lastWord = words[words.length - 1];

    // Since we are looking at the last word if there are more than 2 words
    // the last word might be an abbreviation like 'St' or 'Ct' (Court)
    // So we relax the limit after the second word so that we will search if the
    // last word has 1 character. If there are only 2 words we stick with 3
    // characters.
    const minimumWordLength = (words.length > 2) ? 0 : 2;
    // We only authorise the search when the last word has more than 2
    // characters. So we avoid searching for '1200 E.'
    return (lastWord.length > minimumWordLength);
  }

  /**
   * Lookup address suggestions using the lookup term via the Smarty API SDK.
   *
   * A maximum of 3 attempts are made to make the API call, failures are logged.
   *
   * If all 3 attempts fail then an error is reported in the console and an
   * empty array of suggestions is returned.
   *
   * @param term
   */
  async getAddressSuggestions(term: string): Promise<any> {
    if (!this.smartyClient) {
      console.error('[smarty-street.service] - Cannot perform predictive text search on address.  Smarty Client is `undefined`.');
      return Promise.resolve();
    }

    return from(this.smartyClient.send(new usAutocompletePro.Lookup(term))).pipe(
      map((lookupResult) => this.buildSuggestions(lookupResult || {})),
      retryWhen((errors) => {
        // try up to 3 times, waiting 1 second between each attempt
        return errors.pipe(
          tap((error) => console.log('Failure performing Smarty Address lookup', error)),
          delay(RETRY_DELAY),
          take(RETRY_MAX_ATTEMPTS),
        );
      }),
      catchError((error) => {
        console.error('Failed to do address lookup after %d attempts for term:', RETRY_MAX_ATTEMPTS, term, error);
        return of([]);
      }),

    ).toPromise();
  }

  /**
   *
   *        return this.httpClient.get(route, { headers: headers }).pipe(
   *           map(res => (<any>res)),
   *           // tslint:disable-next-line: rxjs-no-unsafe-scope
   *           tap(keysRes => {localStorage.setItem('canny-sso', keysRes.cannySSOToken)
   *           })
   *         );
   *       }
   * @private
   */

  buildSuggestions(lookupResponse) {
    const suggestions = lookupResponse?.result || [];
    let whiteSpace;

    return suggestions.map((suggestion) => {
      whiteSpace = '';
      if (suggestion.secondary) {
        if (suggestion.entries > 1) {
          suggestion.secondary += ` (${suggestion.entries} entries)`;
        }
        whiteSpace = ' ';
      }
      return {
        streetAddress1: suggestion.streetLine,
        streetAddress2: suggestion.secondary,
        city: suggestion.city,
        state: suggestion.state,
        postalCode: suggestion.zipcode,
        text: `${suggestion.streetLine}${whiteSpace}${suggestion.secondary} ${suggestion.city} ${suggestion.state} ${suggestion.zipcode}`,
      };
    });
  }
}
