import {
  FormControl, ControlValueAccessor, Validator, FormGroupDirective, NgForm, AbstractControl,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import {
  Input, Output, Directive, EventEmitter,
} from '@angular/core';
import { ErrorFormatter } from '@lc/core';
import { ErrorStateMatcher } from '@angular/material/core';

/** Error when invalid control is dirty or touched. */
export class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl, form: FormGroupDirective | NgForm): boolean {
    return !!(control && control.invalid && (control.dirty || (form && form.submitted && form.dirty)));
  }
}
@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class InputField implements ControlValueAccessor, Validator {
  public static inputCount: number = 0;

  public errors = '';
  public hasErrors: boolean;
  protected subscription: any;

  @Input()
  public formControl: FormControl = new FormControl();

  public _value: any | any[] = null;

  @Input()
    // eslint-disable-next-line no-plusplus
    idPrefix = `field-${InputField.inputCount++}`;

  @Input()
  public get value() {
    return this._value;
  }

  public set value(value) {
    if (this._value !== value) {
      this._value = value;
      this.executeOnChanged();
      this.executeOnTouched();
    }
  }
  matcher = new CustomErrorStateMatcher();

  @Input()
  public disabled: boolean;

  @Output()
  readonly change = new EventEmitter<any>();

  readonly errorsChanged = new EventEmitter<string>();

  public onChange = (_: any) => { };
  public onTouch = (_: any) => { };
  public onValidatorChange = (_: any) => {
    console.log('validator changed', _);
  };

  constructor(private sanitizer: DomSanitizer) { }

  public writeValue(value: any): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public registerOnValidatorChange(fn: () => void) {
    this.onValidatorChange = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (this.formControl.disabled === isDisabled) { return; }
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  protected executeOnTouched() {
    this.onTouch(this.value);

    this.setErrors(this.formControl);
  }

  protected executeOnChanged() {
    this.onChange(this.value);
    this.change.emit(this.value);
  }

  public validate(formControl: FormControl) {
    this.initializeForm(formControl);
    return null;
  }

  protected initializeForm(formControl: FormControl) {
    if (!this.subscription) {
      this.formControl = formControl;
      this.subscription = formControl.valueChanges.subscribe(() => {
        this.setErrors(formControl);
        this.value = formControl.value;
      }, (error) => { throw new Error(error); });
      formControl.statusChanges.subscribe(() => this.setErrors(formControl), (error) => { throw new Error(error); });
      this.setErrors(formControl);
      this.afterFormInitialized();
    }
  }

  protected async afterFormInitialized() {
    /** Open for extension */
  }

  protected setErrors(formControl: AbstractControl) {
    this.errors = this.getDisplayErrors(formControl);
    this.hasErrors = this.errors !== '';
  }

  /**
   * Get's the error messages to be displayed to the UI when the formControl status and value changes
   * @param formControl The form control to validate
   */
  protected getDisplayErrors(formControl: AbstractControl) {
    return ErrorFormatter.getErrors(formControl, this.sanitizer);
  }
}
