import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import {
  HttpClient, HttpEventType, HttpRequest, HttpResponse,
} from '@angular/common/http';
import {
  catchError, filter, map, tap,
} from 'rxjs/operators';
import { ApiService, PhotoApiService } from './api.service';
import { UploadPhoto } from '../models/upload-photo.model';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  private readonly resource = 'presign';
  userId: string;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private photosApiService: PhotoApiService,
    private readonly userService: UserService,
  ) {
    this.userId = this.userService.getUserId();
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  public getPresignedUrl<T = any>(type: string, contentType?: string): Observable<T> {
    let url = `${this.resource}/${type}`;
    if (contentType) {
      url += `?contentType=${contentType}`;
    }
    return this.apiService.get(url).pipe(
      map((result) => result),
    );
  }

  public upload(url, data) {
    return this.http.put(url, data).pipe(catchError(this.formatErrors));
  }

  public uploadWithProgress(url: string, data: any, progress: Subject<number>) {
    const req = new HttpRequest('PUT', url, data, { reportProgress: true });
    return this.http.request(req).pipe(
      tap((event) => {
        // On the UploadProgress event, update the progress status
        if (progress && event.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round((100 * event.loaded) / event.total);
          progress?.next(percentDone);
        }
      }),
      // Filter to only the HttpResponse
      filter((event) => event instanceof HttpResponse),
      tap(() => progress?.complete()),
      catchError(this.formatErrors),
    );
  }

  public uploadCropData(cropData) {
    return this.photosApiService.post('upload/cropData', cropData);
  }

  public getPresignedUrlForAll(fileArray) {
    return this.photosApiService.post('presign/all', fileArray);
  }

  public getPresignedUrlForProfile(fileArray) {
    const endpoint = this.userId ? `presign/profile/${this.userId}` : 'presign/profile';
    return this.photosApiService.post(endpoint, fileArray);
  }

  public postImageUploadAndGetThumbnails(fileObject) {
    return this.photosApiService.post<UploadPhoto[]>('upload/thumbnails', fileObject).pipe(
      map((thumbnails) => thumbnails.map((thumb) => new UploadPhoto(thumb))),
    );
  }
}
