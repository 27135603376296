import {
  AfterViewInit, Directive, ElementRef, HostListener, Input, Renderer2,
} from '@angular/core';

@Directive({
  selector: '[lcCardDescriptionAnimation]',
  exportAs: 'descriptionAnimation',
})
export class CardSlideAnimationDirective implements AfterViewInit {
  descEl: any;
  descIsShown: boolean = false;

  @Input() disableOnHover: boolean;
  @Input() description: string;
  @Input() toggleRef: ElementRef;
  @Input() descriptionStyle?: any;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit() {
    if (!this.description) { return; }
    // Create the element that will contain the description
    this.descEl = this.renderer.createElement('div');
    this.css(this.descEl, {
      position: 'absolute',
      top: '100%',
      height: '70%',
      width: '100%',
      display: 'flex',
      'align-items': 'center',
      'justify-content': 'center',
      padding: '12px',
      'border-radius': '50% 50% / 10px 10px 0 0',
      'box-sizing': 'border-box',
      transition: 'all .2s linear',
      color: 'white',
      'font-weight': 'bold',
      'background-color': '#000000bf',
      overflow: 'hidden',
      // Allow the `descriptionStyle` input to override and/or create style attributes
      ...(this.descriptionStyle ?? null),
    });
    const description = this.renderer.createText(this.description);
    this.renderer.appendChild(this.descEl, description);
    this.renderer.appendChild(this.el.nativeElement, this.descEl);
  }

  @HostListener('mouseenter', ['$event'])
  onMouseEnter(): void {
    // If no child with the #descriptionToggle template ID is provided via the `toggleRef` parameter and a
    // description exists, then show the discription automatically when the mouse enters the host element.
    if (!this.toggleRef && this.descEl && !this.disableOnHover) {
      this.slideUp();
      this.descIsShown = true;
    }
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(): void {
    if (this.descIsShown) {
      this.slideDown();
      this.descIsShown = false;
    }
  }

  onClick(event: PointerEvent): void {
    event.stopImmediatePropagation();

    if (!this.descIsShown) {
      this.slideUp();
    } else {
      this.slideDown();
    }
  }

  private slideUp(): void {
    this.renderer.setStyle(this.descEl, 'top', '30%');
    this.descIsShown = true;
  }

  private slideDown(): void {
    this.renderer.setStyle(this.descEl, 'top', '100%');
    this.descIsShown = false;
  }

  private css(element: ElementRef, style: any) {
    for (const property in style) {
      if (Object.prototype.hasOwnProperty.call(style, property) && property) {
        this.renderer.setStyle(element, property, style[property]);
      }
    }
  }
}
