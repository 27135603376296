import { Deserializable } from './deserializable.model';

// Currently, Corefact only uses 2 product SKUs for Direct Mail
export const directMailProductTypes = ['PCL', 'TF85'] as const;
export type DirectMailProductType = typeof directMailProductTypes[number];

export class DirectMailRateRequest {
  readonly sku!: DirectMailProductType;
  readonly quantity!: number;

  constructor(model?: DirectMailRateRequest) {
    Object.assign(this, model);
  }
}

export const directMailCarriers = ['USPS', ''] as const;
export type DirectMailCarrier = typeof directMailCarriers[number];

export const directMailServices = {
  Standard: 'Standard',
  'First-Class': 'First Class',
  'Print-Only': 'Print Only',
} as const;

export type DirectMailService = keyof typeof directMailServices;
export type DirectMailServiceDisplay = typeof directMailServices[keyof typeof directMailServices];

export type DirectMailRateResponse = DirectMailOption[];

export class DirectMailOption {
  carrier!: DirectMailCarrier;
  service!: DirectMailService;
  estimatedDeliveryDate!: Date;
  price!: number;
}

export class DirectMailRate extends DirectMailOption implements Deserializable<DirectMailRate> {
  dateLabel: string;
  serviceLabel: string;
  display: string;

  constructor(model: DirectMailOption) {
    super();
    this.deserialize(model);
  }

  deserialize(model: DirectMailOption): DirectMailRate {
    Object.assign(this, model);
    this.createDateLabel();
    this.serviceLabel = directMailServices[this.service];
    this.display = `${this.serviceLabel} - $${this.price}`;
    return this as DirectMailRate;
  }

  createDateLabel() {
    if (!this.estimatedDeliveryDate) { return ''; }

    try {
      // parse the date label from Date of delivery
      const date = new Date(this.estimatedDeliveryDate);

      const options: Intl.DateTimeFormatOptions[] = [
        { weekday: 'long' },
        { day: 'numeric' },
        { hour: 'numeric' },
        { month: 'long' },
        { year: 'numeric' },
        { timeZoneName: 'long' },
      ];

      const getFormattedDateObject = (date: Date, options: Intl.DateTimeFormatOptions[]): any => {
        const formattedDateStringObject = {};

        const callback = (option: Intl.DateTimeFormatOptions) => {
          const result = date.toLocaleDateString('en', option);

          for (const key of Object.keys(option)) {
            formattedDateStringObject[key] = result;
          }
        };

        options.forEach(callback);
        return formattedDateStringObject;
      };

      const dateObject = getFormattedDateObject(date, options);

      const { weekday, day, month } = dateObject;

      this.dateLabel = `${weekday}, ${month} ${day}`;
    } catch (error) {
      console.error(error);
      return '';
    }
  }
}
