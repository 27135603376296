import { Deserializable } from './deserializable.model';
import { Audit } from './audit.model';

export enum NotificationStatus {
  ACTIVE = 'ACTIVE',
  SNOOZED = 'SNOOZED',
  COMPLETED = 'COMPLETED',
}

export enum NotificationType {
  ASSIGNED_TO_ORDER = 'ASSIGNED_TO_ORDER',
  ASSIGNED_TO_TEAM = 'ASSIGNED_TO_TEAM',
  REQUESTED_MARKETING_COPY = 'REQUESTED_MARKETING_COPY',
  REQUESTED_PHOTOS = 'REQUESTED_PHOTOS',
  ORDER_CONFIRMATION = 'ORDER_CONFIRMATION',
  DEADLINE_EXPIRED = 'DEADLINE_EXPIRED',
  READY_FOR_PROOFING = 'READY_FOR_PROOFING',
  NOTIFICATION = 'NOTIFICATION',
  PRO_PHOTOS_RECEIVED = 'PRO_PHOTOS_RECEIVED',
  PRODUCT_SHIPPED = 'PRODUCT_SHIPPED',
  PRODUCT_READY_FOR_PROOFING = 'PRODUCT_READY_FOR_PROOFING',
  LCC_PROOF_REJECTED_APP_NOTIFICATION = 'LCC_PROOF_REJECTED_APP_NOTIFICATION',
  LCC_PROOF_APPROVED_APP_NOTIFICATION = 'LCC_PROOF_APPROVED_APP_NOTIFICATION',
  PRODUCT_READY_FOR_REVIEW = 'PRODUCT_READY_FOR_REVIEW',
  MISSING_PROPERTY_PHOTOS = 'MISSING_PROPERTY_PHOTOS',
  MISSING_PROPERTY_DESCRIPTION = 'MISSING_PROPERTY_DESCRIPTION',
  MLS_ID_ADDED = 'MLS_ID_ADDED',
  LISTING_STATUS_SOLD = 'LISTING_STATUS_SOLD',
  YOUTUBE_AD_READY_FOR_REVIEW = 'YOUTUBE_AD_READY_FOR_REVIEW',
  VENDOR_REJECTED_ORDER = 'VENDOR_REJECTED_ORDER',
}

export class Notification implements Deserializable<Notification> {
  _id?: string;

  /** User this notification pertains to */
  userId?: string;

  /** Marketing order this notification pertains to */
  marketingOrderId?: string;

  /** Title of the notification */
  subject: string;

  /** Description of the notification to be displayed to the user */
  text: string;

  /** Signifies the type that the notification is. Used for routing to the proper screen */
  notificationType: NotificationType;

  /** Status of the notification: active, snoozed, completed */
  status: NotificationStatus = NotificationStatus.ACTIVE;

  /** Date when the notification should be displayed again */
  nextNotificationAt?: Date;

  /** Date the notification was generated. Used as the secondary sort order of the notifications in the UI */
  audit?: Audit;

  /** Data used to reconstruct route and any additional data required for this notification (i.e. - orderId, packageCode) */
  otherData?: any; // {orderId, packageCode, etc...}

  // NOTE: The following properties are not yet on the API
  /** Used as the primary sort order of the notifications in the UI */
  priority?: number;

  /** Signifies if notification has been read */
  isRead?: boolean;

  /** Product code(s) this notification corresponds to */
  productCodes: Array<string> = [];

  constructor(input?: any) {
    this.deserialize(input);
  }

  deserialize(input: any): Notification {
    Object.assign(this, input);
    if (input && input.audit) {
      this.audit = new Audit(input.audit);
    }

    return this;
  }
}
