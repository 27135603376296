// tslint:disable: rxjs-no-unsafe-scope
import { Injectable } from '@angular/core';
import {
  HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpEventType,
} from '@angular/common/http';
import { Observable, timer, throwError } from 'rxjs';
import {
  tap, catchError, finalize, retryWhen, delay, take, mergeMap,
} from 'rxjs/operators';

import { HeadersEnum } from '../models/headers.enum';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let error: HttpErrorResponse;
    let lastResponse: HttpEvent<any>;
    const suppressErrors = request.headers.get(HeadersEnum.SuppressLogErrors) !== null;
    return next.handle(request).pipe(
      tap((response: HttpEvent<any>) => {
        lastResponse = response;
        if (response.type === HttpEventType.Response) {
          // console.log('Http Requests: successful response', response);
        }
      }),
      catchError((err: any) => {
        // Log errors to dataDog and rethrow
        error = err;
        if (!suppressErrors) {
          // Output error to console, only if we did not explicitly specified not to in the header
          console.warn(`Http Requests: request failed while executing the ${request.method} request '${request.urlWithParams}'`, this.getRequestDetails(request), error);
        }
        return throwError(err);
      }),
      retryWhen(this.getRetryStrategy()),
      finalize(() => {
        if (lastResponse.type === HttpEventType.Sent && !error) {
          if (['DELETE', 'POST', 'PUT', 'PATCH'].includes(request.method)) {
            // We want to log cancelled requests that update the data as warnings
            console.warn(`Http Requests: The ${request.method} request '${request.urlWithParams}' was cancelled.`, this.getRequestDetails(request));
          } else {
            // We want to log other cancelled requests as info
            console.log(`Http Requests: The ${request.method} request '${request.urlWithParams}' was cancelled.`, this.getRequestDetails(request));
          }
        }
      }),
    );
  }

  private getRequestDetails(request: HttpRequest<any>) {
    return request;
  }

  /**
   * https://www.javatpoint.com/rxjs-retrywhen-error-handling-operator
   */
  private getRetryStrategy(maxAttempts = 4, retryIntervalScale = 1000) {
    return (attempts) => {
      return attempts.pipe(
        take(maxAttempts),
        mergeMap((error: HttpErrorResponse, index: number) => {
          // we have already failed the original attempt,
          // here we are re-trying and maxAttempts includes that first
          // attempt;
          const retryAttempts = index + 1;
          if (retryAttempts < maxAttempts && error.status === 0) {
            // exponential (or close) back off, the more times we try
            // the longer we wait before trying again
            console.warn(`[ErrorInterceptor]: Retrying Api request for. Attempt number ${retryAttempts}`, error);
            const retryInterval = 2 ** retryAttempts * retryIntervalScale;
            return timer(retryInterval);
          }
          // we ran out of attempts, throw here
          return throwError(error);
        }),
      );
    };
  }
}
