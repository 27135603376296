import { Injectable } from '@angular/core';

export interface CookieOptions {
  path?: string;
  domain?: string;
  expires?: number;
  secure?: boolean;
  sameSite?: 'Lax' | 'Strict' | 'None';
}

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  setCookie(name: string, value: string, options: CookieOptions = {}): void {
    let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

    if (options.expires >= 0) cookieString += `; expires=${options.expires}`;
    if (options.path) cookieString += `; path=${options.path}`;
    if (options.secure) cookieString += '; Secure';
    if (options.sameSite) cookieString += `; SameSite=${options.sameSite}`;

    document.cookie = cookieString;
  }

  getCookie(name: string): string | undefined {
    const nameEQ = `${encodeURIComponent(name)}=`;
    const cookies = document.cookie.split(';').map((c) => c.trim());
    const cookie = cookies.find((c) => c.startsWith(nameEQ));
    if (!cookie) return;

    return decodeURIComponent(cookie.substring(nameEQ.length));
  }
}
