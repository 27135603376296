import { Deserializable } from './deserializable.model';

export class LayoutDetail implements Deserializable<LayoutDetail> {
  layoutName: string;
  layoutContent: string;
  maxChars: number;

  constructor(input?: Partial<LayoutDetail>) {
    this.deserialize(input);
  }

  deserialize(input: Partial<LayoutDetail>): LayoutDetail {
    if (!input) { return this; }
    Object.assign(this, input);
    return this;
  }
}
