import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { STATES_BY_ABBREVIATION, USStates } from '@lc/core';

@Component({
  selector: 'lc-address-information-form',
  templateUrl: './address-information-form.component.html',
  styleUrls: ['./address-information-form.component.scss'],
})
export class AddressInformationFormComponent {
  readonly states: USStates[] = STATES_BY_ABBREVIATION;
  @Input() formGroup: FormGroup;
}
