// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';

// Tab Components
import { NavigationTabsComponent } from './components/navigation-tabs/navigation-tabs.component';

const angularModules = [CommonModule, RouterModule, MatTabsModule];
const externalComponents = [NavigationTabsComponent];

@NgModule({
  declarations: [...externalComponents],
  exports: [...externalComponents],
  imports: [
    ...angularModules,
  ],
})
export class UITabsModule { }
