import { ElementRef } from '@angular/core';

export function shallowEqual(object1: any, object2: any): boolean {
  const keys = Object.keys(object1);
  for (const key of keys) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
}

/**
 * Retrieve the native HTNL element from the Angular elementRef.
 *
 * If the native element cannot be found, output an error to the console
 * containing the errMsg and if throwError is true throw an error with errMsg
 * as the error message property.
 *
 * Use this to track failures ot get a nativeElement, usually because the
 * angular element is null or undefined.
 *
 * @param elementRef
 * @param errMsg
 * @param throwError
 */
export function getNativeElement(elementRef?: ElementRef, errMsg = 'ERROR', throwError = true) {
  let msg: string;
  let nativeElement: any;

  if (!elementRef) {
    msg = `${errMsg} elementRef is null or undefined`;
  } else {
    nativeElement = elementRef?.nativeElement;

    if (!nativeElement) {
      msg = `${errMsg} elementRef does not contain nativeElement`;
    }
  }

  if (msg) {
    console.debug(msg);
  }

  return nativeElement;
}
