import {
  Directive, EventEmitter, HostListener, ElementRef, Output, Input,
} from '@angular/core';

@Directive({
  selector: '[lcClickOutside]',
})
export class ClickOutsideDirective {
  isInitialized: boolean;
  constructor(private elementRef: ElementRef) {
    // Delay 200 milliseconds before intercepting click events
    setTimeout(() => (this.isInitialized = true), 200);
  }

  @Input() eventType: 'mousedown' | 'click' = 'mousedown';
  @Output('lcClickOutside')
  readonly clickOutside = new EventEmitter<MouseEvent>();

  @HostListener('document:mousedown', ['$event', '$event.target'])
  onMouseDown(event: any, targetElement: HTMLElement): void {
    if (this.eventType !== 'mousedown') return;

    this.executeEvent(event, targetElement);
  }

  /** Listen for the click event on the host element. */
  @HostListener('document:click', ['$event', '$event.target'])
  onClick(event: any, targetElement: HTMLElement): void {
    if (this.eventType !== 'click') return;

    this.executeEvent(event, targetElement);
  }

  private executeEvent(event: any, targetElement: HTMLElement) {
    if (!targetElement || !this.isInitialized) return;

    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.clickOutside.emit(event);
    }
  }
}
