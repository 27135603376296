import { HttpClient, HttpBackend } from '@angular/common/http';
import { combineLatest, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export type Applications = 'admin' | 'agent' | 'coordinator' | 'photo' | 'user' | 'designer';

enum ProductSuiteNames {
  LISTING_CONCIERGE = 'Listing Concierge',
  DESIGN_CONCIERGE = 'Design Concierge',
}
export type ProductSuiteName = ProductSuiteNames.LISTING_CONCIERGE | ProductSuiteNames.DESIGN_CONCIERGE;

export class Browser {
  name: string = null;
  version: string = null;
  url: string = null;
  logo: string = null;
  os: string[] = null;
}

/**
 * Private class that is not exposed. Must get values from the AppService.
 * All variables are initialized with default values so that te Object.keys(...)
 * locates all valid variables
 */
class AppConfig {
  app: Applications = null;
  productName: ProductSuiteName = null;
  applicationKey: string = null;
  applicationName: string = null;
  environment: string = null;

  persistBaseURL: string = null;
  notifyBaseURL: string = null;
  photosBaseURL: string = null;
  printerBaseURL: string = null;
  batchProcessBaseURL: string = null;
  pdfRenderBaseURL: string = null;

  oktaAuthEnabled: boolean = true;
  oktaClientId: string = null;
  oktaTenantUrl: string = null;
  oktaIssuer: string = null;
  oktaRedirectUri: string = null;

  stripePublishKey: string = null;
  sessionTimeout: number = 5;
  userAgents: Browser[] = [];
  sessionEndWarningInMins: number = 5;
  maxLogoutRetries: number = 3;
  logoutRetryDelay: number = 5000;
  launchDarklyClientID: string = null;
  brandedLoginPageIDP: string = null;
  bhgBrandedLoginPageIDP: string = null;
  eraBrandedLoginPageIDP: string = null;
  cannyBoardToken: string = null;
  smartStreetKey: string = null;
  cannySSOURL: string = null;
  whatsNewURL: string = null;
  productSuiteName: string = null;
  serviceNowMcdUrl: string = null;
  serviceNowBrandingKey: string = null;
  serviceNowSkipLoadHistory: number = 1;
  /**
   *
   * @param configs Configurations in priority order ([0] = Top Priority)
   */
  constructor(configs: Array<any>) {
    configs = configs || [];
    Object.keys(this).forEach((key) => {
      // Grab the first value in priority order
      const value = configs.map((config) => config[key])
        .filter((val) => val != null)[0];

      this[key] = value;
    });
  }
}

export class AppService {
  /**
   * App Configuration that is readonly and not exposed. Must be accessed via the
   * AppService static properties to prevent modifications
   */
  private static config: AppConfig;

  // Custom property accessors
  static get app(): string { return this.get<Applications>('app'); }

  static get productSuiteName(): ProductSuiteName { return this.get<ProductSuiteName>('productSuiteName'); }

  static get isAdminApp(): boolean { return this.app === 'admin'; }
  static get isAgentApp(): boolean { return this.app === 'agent' || this.app === 'user'; }
  static get isLCAgentApp(): boolean { return this.app === 'agent'; }
  static get isLCCoordinatorApp(): boolean { return this.app === 'coordinator'; }
  static get isCoordinatorApp(): boolean { return this.app === 'coordinator' || this.app === 'designer'; }
  static get isPhotoApp(): boolean { return this.app === 'photo'; }
  static get isDCUserApp(): boolean { return this.app === 'user'; }
  static get isDCDesignerApp(): boolean { return this.app === 'designer'; }
  static get isDCApp(): boolean { return this.app === 'user' || this.app === 'dcuser' || this.app === 'designer'; }

  static get isListingConciergeProduct(): boolean { return this.productSuiteName === ProductSuiteNames.LISTING_CONCIERGE; }
  static get isDesignConciergeProduct(): boolean { return this.productSuiteName === ProductSuiteNames.DESIGN_CONCIERGE; }

  static get<TValueType = any>(propertyName: keyof AppConfig): TValueType {
    if (this.config == null) {
      throw Error('App has not been initialized. Make sure to run the APP_INITIALIZER');
    }
    const value: any = this.config[propertyName];
    return value;
  }

  static getByString<TValueType = any>(propertyName: string): TValueType {
    if (this.config == null) {
      throw Error('App has not been initialized. Make sure to run the APP_INITIALIZER');
    }
    const value: any = this.config[propertyName];
    return value;
  }

  /**
   * If the appConfig has not been loaded, load the config
   */
  public static initialize(backend: HttpBackend): Promise<void> {
    if (this.config != null) {
      throw Error('AppService can only be initialized once. Use static properties instead!');
    }

    // If the AppService has not been initialized, load up the configuration values and set the AppConfig
    // properties accordingly
    const http = new HttpClient(backend);

    // System wide configuration
    const baseConfig$ = http.get('./assets/configs/config.json').pipe(catchError((err) => {
      console.log('Error loading base configuration! Using empty configuration');
      return of({});
    }));

    // App Wide configuration
    const appConfig$ = http.get('./assets/configs/app-config.json').pipe(catchError((err) => {
      console.log('Error loading app configuration! Using empty configuration');
      return of({});
    }));

    return combineLatest([baseConfig$, appConfig$]).pipe(
      map(([baseConfig, appConfig]) => this.initializeAppConfig(baseConfig, appConfig)),
    ).toPromise();
  }

  /**
  * Initializing the AppConfig using the prioritized configurations in this order:
   * 1.) Custom App Config
   * 2.) App Config
   * 3.) Custom Base Config
   * 4.) Base Config
   */
  private static initializeAppConfig(baseConfig: any, appConfig: any) {
    const customBaseConfig = this.loadEnvironmentConfig(baseConfig);
    const customAppConfig = this.loadEnvironmentConfig(appConfig);

    const priorityConfigs = [customAppConfig, appConfig, customBaseConfig, baseConfig];
    this.config = new AppConfig(priorityConfigs);
  }

  /**
   * Loads developer specific configuration
   */
  private static loadEnvironmentConfig(jsonObject: any) {
    let customConfig;
    // Read the custom configuration variable
    const { configuration } = jsonObject;
    if (configuration && jsonObject[configuration] != null) {
      customConfig = jsonObject[configuration];
    }
    return customConfig || {};
  }
}
