import { User } from '../../models/user.model';
import * as UserActions from './user.actions';

export const initialState: User = null;

export const userReducer = (state = initialState, action:any): User => {
  switch (action.type) {
    // set state to loaded user
    case UserActions.LoadLoggedInUser.type: {
      return action.user;
    }
    case UserActions.UserProfileUpdated.type: {
      if (state.profiles.find((p) => action.payload._id === p._id)) {
        state.profile = action.payload;
        const ind = state.profiles.findIndex((obj) => state.profile._id === obj._id);
        state.profiles[ind] = state.profile;
      }
      return new User(state);
    }
    default: {
      return state;
    }
  }
};
