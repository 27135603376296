// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

// Components
import { AvatarComponent } from './avatar.component';
import { AvatarDirective } from './avatar.directive';

const angularModules = [CommonModule, MatIconModule];
const externalComponents = [AvatarComponent];
const internalComponents = [
  // We currently are not exporting this directive as I want developers to use
  // the shared component for the event where we need to make the avatar more complex (i.e. - adding a star icon, etc.).
  // The directive can be used as a model for how to create similar directives
  AvatarDirective,
];

@NgModule({
  declarations: [
    ...internalComponents,
    ...externalComponents,
  ],
  imports: [
    ...angularModules,
  ],
  exports: [
    ...externalComponents,
  ],
})
export class AvatarModule { }
