import { Deserializable } from './deserializable.model';

export class ProductDescription implements Deserializable<ProductDescription> {
  orderId: string;
  productCode: string;
  marketingCopyHeadline: string[];
  marketingCopyBody: string[];

  constructor(data?: any) {
    this.deserialize(data || {});
  }

  deserialize(input: any): ProductDescription {
    Object.assign(this, input);
    return this;
  }
}
