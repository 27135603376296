import { Pipe, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ErrorFormatter } from '@lc/core';

@Pipe({
  name: 'error',
  standalone: true,
})
export class ErrorPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(value: FormControl): unknown {
    return ErrorFormatter.getErrors(value, this.sanitizer);
  }
}
