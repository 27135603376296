import { Deserializable } from './deserializable.model';
import { Profile } from './profile.model';
import { AddressType } from './address.type';
import { UserRoles } from './user-roles.enum';

class PreferenceCategory {
  category: string;
  preferences: any;

  constructor(preferenceCategory) {
    if (preferenceCategory) {
      this.category = preferenceCategory.category;
      this.preferences = preferenceCategory.preferences;
    }
  }
}

export class User implements Deserializable<User> {
  _id: string;
  oktaId: string;
  username: string;
  email: string;
  dateJoined: string;
  firstName: string;
  lastName: string;
  personMasterId: string;
  profile: Profile;
  profiles: Profile[];
  preferences: PreferenceCategory[];
  userPermissions: string[];
  lastLoginDate: Date;
  previousLoginDate: Date;
  _assumedUser: string;
  roles: UserRoles[];
  showDialog: boolean;
  canBeDelegatedBy: object[];
  canDelegate: object[];
  storeCustomerId?: string;

  constructor(input?: any) {
    if (input) {
      this.deserialize(input);
    }
  }

  deserialize(input: any): User {
    Object.assign(this, input);

    if (input.profile) {
      this.profile = new Profile().deserialize(input.profile);
    }

    this.preferences = [];
    if (input.preferences) {
      input.preferences.forEach((category) => {
        this.preferences.push(new PreferenceCategory(category));
      });
    }
    if (input.lastLoginDate) {
      this.lastLoginDate = new Date(input.lastLoginDate);
    }
    if (input.previousLoginDate) {
      this.previousLoginDate = new Date(input.previousLoginDate);
    }

    return this;
  }

  getFirstName(): string {
    if (this.profile && this.profile.preferredFirstName) {
      return this.profile.preferredFirstName;
    }

    return this.firstName;
  }

  getLastName(): string {
    if (this.profile && this.profile.preferredLastName) {
      return this.profile.preferredLastName;
    }
    return this.lastName;
  }

  getPhotoUrl(): string {
    if (this.profile && this.profile.photoUrl) {
      return this.profile.photoUrl;
    }

    return '';
  }

  getProfilePhotoUrl(): string {
    if (this.profile && this.profile.profilePhotoUrl) {
      return this.profile.profilePhotoUrl;
    }

    return '';
  }

  getAddress(type: AddressType) {
    if (!this.profile || !this.profile.contactInfo) { return null; }

    const getAddressType = this.profile.contactInfo.filter((item) => item.type === 'card');
    const contact = getAddressType.find((contactInfo) => contactInfo.address.type === type);

    return (contact) ? contact.address : null;
  }
}
