import { ValidatorFn, AbstractControl } from '@angular/forms';

export type urlValidatorOptions = {
  enforceHttps?: boolean
};

export const urlValidator = (options?: urlValidatorOptions): ValidatorFn => {
  const enforceHttps = options?.enforceHttps ?? false;

  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }

    // coerce value into a string to prevent errors
    let value = `${control?.value}`;

    // create a version of the value which contains no white space
    const valueWithNoWhitespace = value.replace(/\s/g, '');

    // if the current value contains white spaces then set the value to the version that
    // doesn't include them.
    if (valueWithNoWhitespace !== value) {
      control.setValue(valueWithNoWhitespace);
      value = control.value;
    }

    // no need to validate an empty string
    if (value === '') {
      return null;
    }

    // Use Regex101 to test expressions: https://regex101.com/
    // This pattern not does require the http protocol, which goes against most uriValidators.
    // Need to maintain this regex across services if necessary.
    const urlPattern = /^(https?:\/\/)?(www\.)?(([a-zA-Z0-9]+[-a-zA-Z0-9]*[a-zA-Z0-9]+\.)+)+[a-zA-Z0-9]{2,}(\/[^\s]*)?$/i;
    const isValid = urlPattern.test(value);

    if (isValid && enforceHttps) {
      return value.startsWith('https') ? null : { custom: 'The URL must begin with <strong>https<strong>' };
    }

    return isValid ? null : { url: true };
  };
};
