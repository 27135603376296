import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';

export class NavigationMenuItem {
  readonly id: string;
  isSelected?: boolean;
  isExpanded?: boolean;
  isDisabled?: boolean;

  readonly exactMatch: boolean;
  constructor(
    readonly label: string,
    readonly path: string,
    readonly svgPath?: string,
    readonly children?: NavigationMenuItem[],
    options?: { exactMatch?: boolean },
  ) {
    this.id = `${label.replace(' &', '').replace(/ /g, '-').toLowerCase()}-link`;
    this.exactMatch = options?.exactMatch;
  }
}

@Component({
  selector: 'lc-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss'],
})
export class NavigationMenuComponent implements OnInit {
  @Input() links: NavigationMenuItem[];
  @Input() isExpanded: boolean = true;

  @Output() readonly expandedChanged = new EventEmitter<NavigationMenuItem>();

  constructor() {}

  ngOnInit(): void {}

  /**
   * Executes when the user manually clicks the expand button.
   * @param event The mouse event
   * @param tab The tab to toggle
   */
  onToggleExpand(event: MouseEvent, tab: NavigationMenuItem) {
    event.preventDefault();
    event.stopImmediatePropagation();
    tab.isExpanded = !tab.isExpanded;
    this.expandedChanged.next(tab);
  }

  onActiveLinkChanged(isActive: boolean, item: NavigationMenuItem, parent?: NavigationMenuItem) {
    item.isSelected = isActive;
    if (parent) {
      parent.isSelected = isActive;
    }

    if (isActive) {
      if (item.children) {
        item.isExpanded = true;
      }
      if (parent) {
        parent.isExpanded = true;
        parent.isSelected = true;
      }
    }
  }
}
