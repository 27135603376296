// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule, MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule, MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

// Third Party Modules
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { QuillModule } from 'ngx-quill';

// Internal Modules
import { FormService } from '@lc/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { UICoreModule } from '../core';
import { AutoCompleteModule } from './autocomplete/autocomplete.module';
import { TextareaModule } from './textarea/textarea.module';
import { TextboxModule } from './textbox/textbox.module';
import { MultiselectModule } from './multiselect/multiselect.module';
import { CardSelectModule } from './card-select/card-select.module';
import { RadioGroupModule } from './radio-group/radio-group.module';
import { DropdownModule } from './dropdown/dropdown.module';
import { ChipsModule } from './chips/chips.module';
import { DatepickerModule } from './date-picker/datepicker.module';
import { CheckboxModule } from './checkbox/checkbox.module';
import { RichTextModule } from './rich-text/rich-text.module';
import { PhoneNumberModule } from './phone-number/phone-number.module';

// Internal Components
import { CardOptionsComponent } from './card-options/card-options.component';
import { TimePickerComponent } from './time-picker/time-picker.component';

const angularModules = [
  CommonModule, FormsModule, ReactiveFormsModule,
  MatButtonModule, MatChipsModule, MatCheckboxModule, MatDatepickerModule,
  MatFormFieldModule, MatIconModule, MatInputModule, MatSelectModule, MatProgressBarModule,
  MatProgressSpinnerModule, MatTooltipModule,
];

const exportedAngularModules = [
  FormsModule,
  ReactiveFormsModule,
  MatInputModule,
];

const thirdPartyModules = [
  NgxMaskDirective,
  QuillModule,
];

const internalModules = [
  AutoCompleteModule,
  TextareaModule,
  TextboxModule,
  MultiselectModule,
  CardSelectModule,
  RadioGroupModule,
  DropdownModule,
  ChipsModule,
  CheckboxModule,
  DatepickerModule,
  RichTextModule,
  UICoreModule,
  PhoneNumberModule,
];
const exportComponents = [
  CardOptionsComponent,
  TimePickerComponent,
];

/**
 * @deprecated Use individual modules instead
 */
@NgModule({
  declarations: [...exportComponents],
  exports: [
    ...exportComponents,
    ...exportedAngularModules,
    ...internalModules,
  ],
  imports: [
    ...angularModules,
    ...thirdPartyModules,
    ...internalModules,
  ],
  providers: [
    provideNgxMask(),
    FormService,
  ],
})
export class InputsModule {
  private static isConfigured: boolean;
  constructor() {
    InputsModule.configureValidators();
  }

  private static configureValidators() {
    if (InputsModule.isConfigured) { return; }

    /**
     * Fix for the MatInput required asterisk. (see https://github.com/angular/components/issues/2574#issuecomment-486656158)
     */
    const requiredImplementation = {
      get(): boolean {
        if (this._required) {
          return this._required;
        }

        // The required attribute is set
        // when the control return an error from validation with an empty value
        if (this.ngControl?.control?.validator) {
          const emptyValueControl = { ...this.ngControl.control };
          (emptyValueControl as any).value = null;
          return ('required' in (this.ngControl.control.validator(emptyValueControl) || {}));
        }
        return false;
      },
      set(value: boolean) {
        this._required = coerceBooleanProperty(value);
      },
    };
    Object.defineProperty(MatInput.prototype, 'required', requiredImplementation);
    Object.defineProperty(MatSelect.prototype, 'required', requiredImplementation);
    InputsModule.isConfigured = true;
  }
}
