import { ValidatorFn, AbstractControl } from '@angular/forms';

export function inArrayValidator(values: any[]) : ValidatorFn {
  return (control: AbstractControl) => {
    const selectedValues = control.value instanceof Array ? control.value : [control.value];
    if (control.value == null) { return null; } // Let the .required() validator handle this case
    const isEveryIncluded = selectedValues.every((value) => values.includes(value || ''));
    return isEveryIncluded ? null : { custom: 'Selected value is not valid' };
  };
}
