import { ValidatorFn, AbstractControl } from '@angular/forms';

export function emojiValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }
    const emojiPattern = /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
    const hasEmoji = emojiPattern.test(control.value);
    return hasEmoji ? { emojiError: 'Emojis are not allowed in the text' } : null;
  };
}
