/**
 * For Brands
 *
 * CBA
 * CBR
 * ALL
 *
 */
export enum BrandType {
  CBA = 'CBR',
  CBR = 'CBA',
  ALL = 'ALL',
}

export type Brands = `${BrandType}`;

export const AllBrands: Brands[] = Object.values(BrandType).map((value) => value);
