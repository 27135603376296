import {
  Component, Input, forwardRef, Output, EventEmitter, ContentChild, TemplateRef, OnInit,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { StringFormControl } from '@lc/core';
import { InputField } from '../input-field';
import { Mask } from '../input-masks';

@Component({
  selector: 'lc-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
  ],
})
export class AutocompleteComponent extends InputField {
  @Input()
    autoCompleteOptions: any[];

  @Output()
  readonly optionValue = new EventEmitter();

  @Input()
    isAutocomplete: boolean;

  @Input()
    placeholderText: string;

  isLoading = false;

  @Input()
    label: string;

  @Input()
    placeholder = '';

  @Input()
    readonly = false;

  @Input()
    required = false;

  @Input()
    inputMask: Mask;

  @Input()
    hint: string;

  @Input()
    suffix: string;

  @Input()
    maskDropSpecialCharacters = true; // # choose if mask will drop special character in the model, or not, default value is true

  @Output()
  readonly blur = new EventEmitter<void>();

  @ContentChild('label')
    labelTemplate: TemplateRef<any>;

  @ContentChild('hint')
    hintTemplate: TemplateRef<any>;

  @ContentChild('suffix')
    suffixTemplate: TemplateRef<any>;

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  public onBlur() {
    if (this.formControl && this.formControl instanceof StringFormControl) {
      this.formControl.onLostFocus();
    }

    this.blur.emit();
    this.executeOnTouched();
  }

  updateSelection(event) {
    this.optionValue.emit(event);
  }
}
