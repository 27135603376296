import { Component, Input, OnInit } from '@angular/core';
import { Address, ToasterService } from '@lc/core';

@Component({
  selector: 'lc-address-menu-item',
  templateUrl: './address-menu-item.component.html',
  styleUrls: ['./address-menu-item.component.scss'],
})
export class AddressMenuItemComponent implements OnInit {
  @Input() address: Address;
  @Input() showClipboard: boolean;
  @Input() isExpanded: boolean = true;
  @Input() hideIcon: boolean = false;
  @Input() editLink: string | any[];
  @Input() replaceUrl: boolean;

  constructor(
    private toasterService: ToasterService,
  ) {
  }

  ngOnInit(): void {
  }

  /**
   * Returns a toaster to the UI on copy to clipboard.
   */
  copied(event) {
    if (event.isSuccess) {
      this.toasterService.showInfo('Address copied to board!');
    } else {
      this.toasterService.showError('Failed to copy the address');
    }
  }
}
