import { Coupon } from './coupon.model';
import { Deserializable } from './deserializable.model';
import { PaymentRefund } from './payment-refund.model';
import { AddonPayment } from './addon-payment.model';
import { Payment } from './payment.model';
import { TaxDetails } from './tax-details.model';

/**
 * The Pricing model stores all the information regarding the fields used for
 * the pricing calculation
 */
export class Pricing implements Deserializable<Pricing> {
  /** The total package cost (base package price + opt in/out changes) */
  packageTotal: number;

  /** The total cost of all product addons, including vendor overrides */
  addonTotal: number;

  /** The total tax of the addonTotal */
  addonTaxTotal: number;

  /** The total cost of discounts applied (i.e. - coupons) */
  discountTotal: number;

  /** The subTotal before taxes for this package (packageTotal + addonTotal - discountTotal) */
  subTotal: number;

  /** The amount due at the time of checkout (subTotal + addonTaxTotal) */
  total: number;

  /** The payment collected from stripe */
  payment: Payment;

  /** CouponIds of the applied coupons. Potentially the full object if the object was populated on the API */
  coupons: string[];

  /** The refunds that have been applied to this order */
  refunds: PaymentRefund[];

  /** The addon payments that have been applied to this order */
  addonPayments: AddonPayment[];

  /** The taxDetails returned from stripe */
  taxDetails: TaxDetails[];

  constructor(input?: any) {
    if (input) {
      this.deserialize(input);
      this.payment = new Payment(input.payment);
      this.coupons = (input.coupons || []).map((coupon) => new Coupon(coupon));
      this.refunds = (input.refunds || []).map((refund) => new PaymentRefund(refund));
      this.taxDetails = (input.taxDetails || []).map((details) => new TaxDetails(details));
    }
  }

  deserialize(input: any): Pricing {
    return Object.assign(this, input);
  }
}
