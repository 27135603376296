import { FormControl, Validators } from '@angular/forms';
import { BaseForm } from './base.form';
import {
  TeamPhotoOptions, TeamNameOptions, TeamPhoneOptions, TeamEmailOptions, TeamWebsiteOptions, TeamLicenseOptions, TeamLogoOptions,
} from '../models';

export class TeamContactPreferencesForm extends BaseForm {
  constructor(value?: any) {
    super({
      teamPhotoOption: new FormControl(TeamPhotoOptions.teamOnly, [Validators.required]),
      teamNameOption: new FormControl(TeamNameOptions.teamOnly, [Validators.required]),
      teamPhoneOption: new FormControl(TeamPhoneOptions.teamOnly, [Validators.required]),
      teamEmailOption: new FormControl(TeamEmailOptions.teamOnly, [Validators.required]),
      teamWebsiteOption: new FormControl(TeamWebsiteOptions.teamOnly, [Validators.required]),
      teamLicenseOption: new FormControl(TeamLicenseOptions.leadOnly, [Validators.required]),
      teamLogoOption: new FormControl(TeamLogoOptions.teamOnly, [Validators.required]),
    });

    if (value) {
      this.patchValue(value);
    }
  }
}
