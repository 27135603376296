import {
  AbstractControl, FormControl, FormGroup, ValidatorFn, Validators,
} from '@angular/forms';
import { BaseForm } from './base.form';
import { urlValidator } from '../validators/url.validator';
import { SocialMedia } from '../models';

export class SocialMediaForm extends BaseForm {
  public get socialMedia(): string { return this.get('socialMedia').value; }

  constructor(value?: SocialMedia, options?: { validateType?: boolean, typeRequired?: boolean }) {
    const init = value || new SocialMedia();
    super({
      type: new FormControl(init.type, options?.typeRequired ? [Validators.required] : []),
      link: new FormControl(null, [Validators.required, urlValidator()]),
    });

    if (options && options.validateType === true) {
      this.get('type').setValidators([this.validateType(this)]);
      this.get('type').updateValueAndValidity();
      this.get('text').valueChanges.subscribe(() => this.get('type').updateValueAndValidity({ emitEvent: true }), (error) => { throw new Error(error); });
    }

    if (value) {
      this.patchValue(value);
    }
  }

  validateType(group: FormGroup): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const socialMedia = group.get('text').value;
      return socialMedia && control.value == null ? { required: true } : null;
    };
  }
}
