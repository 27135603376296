import { Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { HeaderConstants } from '../constants/header.constants';
import { AddKeys, LoadNotifications } from '../state-mgmt';
import { RouterService } from './router.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  private lastUrlWithoutParams: string;

  /** The constructor will initialize the things we need for the routing to take place */
  constructor(private store: Store<any>, readonly routerService: RouterService) { }

  initializeKeys(appName: string, productName: string) {
    const initKeys = {};
    initKeys[HeaderConstants.APPNAME] = appName;
    initKeys[HeaderConstants.PRODUCTNAME] = productName;
    this.store.dispatch(AddKeys({ payload: initKeys }));
  }

  /**
   * Fires an event to load updated notifications.
   * Only executes if url has changed and we are not in an unauthenticated page
   * @param navEnd
   */
  refreshNotifications(url: NavigationEnd | string, otherIds?: string[]) {
    const urlAfterRedirects = url instanceof NavigationEnd ? url.urlAfterRedirects : url;
    const urlWithoutParams = this.getBaseUrl(urlAfterRedirects);
    const shouldRefresh = this.shouldRefresh(urlWithoutParams);

    if (shouldRefresh) {
      this.lastUrlWithoutParams = urlWithoutParams;
      this.store.dispatch(LoadNotifications({ otherIds }));
    }
  }

  getBaseUrl(url: string) {
    const urlWithoutParams = (url || '').split('#')[0].split('?')[0];
    return urlWithoutParams;
  }

  shouldRefresh(urlWithoutParams: string) {
    const ignoredUrls = ['/logout', '/inactivity-logout', '/login/callback', '/okta/login'];
    return urlWithoutParams !== this.lastUrlWithoutParams && !ignoredUrls.includes(urlWithoutParams);
  }
}
