import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { Observable } from 'rxjs';
import { PromptDialogService } from '../services';
import { UIConstants } from '../constants';

export interface ComponentCanDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

export const unsavedChangesGuard: CanDeactivateFn<ComponentCanDeactivate> = (
  component: ComponentCanDeactivate,
): Observable<boolean> | Promise<boolean> | boolean => {
  const promptDialogService = inject(PromptDialogService); // Inject the service

  if (component.canDeactivate && component.canDeactivate()) {
    return true;
  }

  return promptDialogService.openPrompt(
    'Confirm Navigation',
    'Are you sure you want to leave this page? If you do, any unsaved changes will be lost.',
    UIConstants.YES,
    [UIConstants.NO],
  ).then((result) => result?.text === UIConstants.YES);
};
