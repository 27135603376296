import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OktaAuthenticationProvider } from './auth-providers/okta-authentication.provider';
import { UserService } from './user.service';
import { AppService } from './app.service';

@Injectable()
export class AuthenticationService {
  public currentQueryParams: {};

  constructor(
    private readonly userService: UserService,
    private readonly oktaAuthService: OktaAuthenticationProvider,
    public readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe(
      (params) => {
        this.currentQueryParams = params;
      },
      (error) => {
        throw new Error(error);
      },
    );
  }

  redirectToLogin() {
    this.oktaAuthService.redirectToLogin();
  }

  redirectPostLogin() {
    const postLoginRedirectTo: string = localStorage.getItem('post-login-redirect');
    if (postLoginRedirectTo && postLoginRedirectTo.length > 0) {
      this.router.navigateByUrl(postLoginRedirectTo);
    } else {
      this.router.navigate(['']);
    }

    localStorage.removeItem('post-login-redirect');
  }

  redirectToLogout() {
    this.router.navigate(['logout']);
  }

  async logout() {
    await this.oktaAuthService.logout();
    localStorage.clear();
  }

  /* istanbul ignore next */
  getToken(): string {
    return this.oktaAuthService.getToken();
  }

  getImpersonatedUserOktaId() {
    return this.userService.getImpersonatedUserOktaId();
  }

  isAuthenticated(): boolean {
    if (AppService.get('environment') === 'integration') {
      return true;
    }
    return this.oktaAuthService.isAuthenticated() && this.userService.getCurrentUser() !== null;
  }

  async isAuthenticated$(): Promise<boolean> {
    if (this.isAuthenticated()) {
      return true;
    }
    const isAuthenticated = await this.oktaAuthService.isAuthenticated$();
    return isAuthenticated && this.userService.getCurrentUser() !== null;
  }
}
