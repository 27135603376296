import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, isDevMode } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { User } from '../models';
import { LOGGEDINUSER } from '../state-mgmt';

declare let Five9SocialWidget: any;
const WINDOW = () => window as any;

export class IRSCMenuItem {
  name: string;
  icon: string;
  callback: () => void;
}

@Injectable({ providedIn: 'root' })
export class SpeedDialService {
  constructor(@Inject(DOCUMENT) private doc: any, private store: Store<any>) {}

  setSpeedDial(options: { five9Config: any, feedbackFn: (window: any) => void, isProduction: boolean }) {
    const rscClient = WINDOW().__RSC__;
    const menuItems: IRSCMenuItem[] = [
      {
        name: 'Access Help Center Articles',
        icon: 'fal fa-question-circle',
        callback: () => window.open('https://www.mycbdesk.com/help-center', '_blank'),
      },
      {
        name: 'Chat With Us',
        icon: 'fal fa-comment',
        callback: () => {
          this.enableChat(options.five9Config, options.isProduction);
          this.doc.querySelector('#five9-maximize-button')?.click();
        },
      },
      {
        name: 'Call/Request Help',
        icon: 'fal fa-ticket-alt',
        callback: () => window.open('https://anywhere.service-now.com/', '_blank'),
      },
      {
        name: 'Reset Password',
        icon: 'fal fa-key',
        callback: () => window.open('https://realogy.okta.com/enduser/settings', '_blank'),
      },
      {
        name: 'Feedback',
        icon: 'fal fa-comment-exclamation',
        callback: () => options?.feedbackFn(window),
      },
    ];
    rscClient?.setSpeedDial(menuItems);
  }

  async enableChat(options: any, isProduction: boolean) {
    // Load the current user before we can chat
    const user: User = await this.store.select(LOGGEDINUSER).pipe(take(1)).toPromise();
    if (!user) { return; }

    if (isProduction && Five9SocialWidget) {
      try {
        const getUserForChatOptions = this.getOptions(options, user, isProduction);
        Five9SocialWidget?.addWidget(getUserForChatOptions);
      } catch (error) {
        console.log('Five9SocialWidget is not loaded properly');
      }
    }
  }

  private getOptions(options: any, user: User, isProduction: boolean) {
    const profile = user?.profile;

    const optionsFields = { ...options };
    optionsFields.fields.name.value = `${profile?.preferredFirstName} ${profile?.preferredLastName}`;
    optionsFields.fields.email.value = profile?.preferredEmail;
    optionsFields.fields.UserName.value = profile?.preferredEmail;
    optionsFields.fields.OfficeName.value = profile?.office?.address?.name || '';
    optionsFields.fields.MetroName.value = profile?.office?.metro?.metroName || '';
    optionsFields.fields.OktaID.value = user.oktaId;
    optionsFields.fields.UserType.value = '';
    optionsFields.fields.Role.value = 'Agent';
    optionsFields.theme = isProduction
      ? 'https://mycbdesk.com/assets/css/five9-chat.css'
      : 'https://dev.mycbdesk.com/assets/css/five9-chat.css';
    return optionsFields;
  }
}
