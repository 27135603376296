// tslint:disable: rxjs-no-sharereplay
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject, Observable, of, Subject,
} from 'rxjs';
import {
  filter, map, shareReplay, switchMap, tap,
} from 'rxjs/operators';
import { UserSettingsForm } from '../forms';
import { User, UserSettings } from '../models';
import { TableConfig } from '../models/table-config.interface';
import { LOGGEDINUSER } from '../state-mgmt/user/user.actions';
import { ApiService } from './api.service';

/**
 * This service handles the logic required to manage the user's settings
 */
@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  /** UserSettings cached in memory */
  private readonly userSettings: { [userId: string]: Subject<UserSettings> } = {};
  private readonly userTableConfigs: { [userId: string]: BehaviorSubject<TableConfig[]> } = {};

  constructor(private readonly apiService: ApiService, private store: Store<any>) { }

  getCurrentUserSettings$(): Observable<UserSettings> {
    return this.store.select(LOGGEDINUSER).pipe(
      filter((user: User) => user != null),
      switchMap((user) => this.getUserSettings$(user._id)),
    );
  }

  /** Retrieves the user's settings for the given userId. Will only fetch once per request  */
  getUserSettings$(userId: string): Observable<UserSettings> {
    if (!this.userSettings[userId]) {
      // Initialize the Subject that can be used to force the UI to update without re-calling the API
      this.userSettings[userId] = new BehaviorSubject<UserSettings>(null);
    }

    // Return the cached observable
    return this.userSettings[userId].pipe(
      switchMap((settings) => (settings
        // If settings have already been cached, return them
        ? of(settings)
        // Otherwise, make a new API Request
        : this.apiService.get<UserSettings>(`users/${userId}/settings`).pipe(
          map((userSettings) => new UserSettings(userSettings)),
          tap((userSettings) => this.userSettings[userId].next(userSettings)),
        ))),
    );
  }

  async GetandUpdateUserSetting(userId: string) {
    const val = await this.apiService.get<UserSettings>(`users/${userId}/settings`).pipe(
      map((userSettings) => new UserSettings(userSettings)),
      tap((userSettings) => this.userSettings[userId].next(userSettings)),
    );
    await val.subscribe((settings: UserSettings) => {
      this.updateCache(userId, settings);
    }, (error) => { throw new Error(error); });
  }

  /** Updates the user's settings  */
  async updateSettings(settingsForm: UserSettingsForm): Promise<UserSettings> {
    const { value } = settingsForm;
    if (settingsForm.invalid) {
      // This shouldn't happen. If it does, log it so we see it in dataDog and can fix it
      console.warn('Attempting to update user settings with an invalid form.', value);
    }

    // Post the form to the API and update the in-memory cache
    return await this.apiService.post<UserSettings>(`users/${value.user}/settings`, value).pipe(
      map((settings) => new UserSettings(settings)),
      tap((settings) => this.updateCache(settings.userId, settings)),
    ).toPromise();
  }

  getTableConfigs$<TFilters = any>(userId: string, filters?: { tableId?: string }) {
    if (!this.userTableConfigs[userId]) {
      // Initialize the Subject that can be used to force the UI to update without re-calling the API
      this.userTableConfigs[userId] = new BehaviorSubject<TableConfig[]>(null);
    }

    const route = `users/${userId}/table-configs`;
    // Return the cached observable
    return this.userTableConfigs[userId].pipe(
      switchMap((configs) => (configs
        // If settings have already been cached, return them
        ? of(configs)
        : this.apiService.get<TableConfig<TFilters>[]>(route, filters, { version: 'v2' }).pipe(
          map((configs) => (configs || []).map((config) => new TableConfig<TFilters>(config))),
          tap((configs) => this.userTableConfigs[userId].next(configs)),
          shareReplay(1),
        ))),
    );
  }

  async createSavedFilter(userId: string, config: TableConfig) {
    const route = `users/${userId}/table-configs`;
    return await this.apiService.post<TableConfig>(route, config, { version: 'v2' }).pipe(
      map((config) => new TableConfig(config)),
      tap((config) => this.updateTableCache(userId, config)),
    ).toPromise();
  }

  async updateSavedFilter(userId: string, config: TableConfig) {
    const route = `users/${userId}/table-configs/${config._id}`;
    return await this.apiService.put<TableConfig>(route, config, { version: 'v2' }).pipe(
      map((config) => new TableConfig(config)),
      tap((config) => this.updateTableCache(userId, config)),
    ).toPromise();
  }

  async deleteSavedFilter(userId: string, filterId: string) {
    const route = `users/${userId}/table-configs/${filterId}`;
    return await this.apiService.delete<TableConfig>(route, undefined, { version: 'v2' }).pipe(
      tap(() => this.updateTableCache(userId, undefined, filterId)),
    ).toPromise();
  }

  /** Triggers an update to the current observables if they are subscribed to */
  private updateCache(userId: string, settings: UserSettings) {
    if (this.userSettings[userId]) {
      this.userSettings[userId].next(settings);
    }
  }

  /** Triggers an update to the current observables if they are subscribed to */
  private updateTableCache(userId: string, configs: TableConfig | TableConfig[], configId?: string) {
    if (this.userTableConfigs[userId]) {
      if (configs instanceof Array) {
        this.userTableConfigs[userId].next(configs);
      } else {
        const currentValue = this.userTableConfigs[userId].value;
        const existingIndex = (currentValue || []).findIndex((val) => val._id === (configs?._id || configId));
        const updatedConfigs = configs ? [configs] : [];
        currentValue.splice(existingIndex, existingIndex >= 0 ? 1 : 0, ...updatedConfigs);
        this.userTableConfigs[userId].next(currentValue);
      }
    }
  }
}
