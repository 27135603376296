import { Deserializable } from './deserializable.model';
import { PhotoAgency } from './photography-agency.model';

export class Lockbox implements Deserializable<Lockbox> {
  code: string;
  location: string;

  deserialize(input: any): Lockbox {
    Object.assign(this, input);
    return this;
  }
}

export class PhotographerAppointment implements Deserializable<PhotographerAppointment> {
  photoAgency: PhotoAgency;
  scheduledAt: Date;
  lockboxAvailable = false;
  lockbox: Lockbox;
  comments: string;
  timezone?:string;
  timezoneOffset?: string;

  constructor(data?: Partial<PhotographerAppointment>) {
    this.deserialize(data);
  }

  deserialize(input: any): PhotographerAppointment {
    if (!input) { return this; }
    Object.assign(this, input);
    return this;
  }
}
