import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AutoCompleteModule } from '../../inputs/autocomplete/autocomplete.module';
import { TextboxModule } from '../../inputs/textbox/textbox.module';
import { DropdownModule } from '../../inputs/dropdown/dropdown.module';
import { PhoneNumberModule } from '../../inputs/phone-number/phone-number.module';

// Checkout Components
import { AddressDetailsFormComponent } from './address-details-form/address-details-form.component';
import { AddressInformationFormComponent } from './address-information-form/address-information-form.component';
import { UICoreModule } from '../../core';

const angularModules = [CommonModule];
const lcModules = [
  AutoCompleteModule, TextboxModule, DropdownModule, PhoneNumberModule, UICoreModule,
];
const internalComponents = [];
const externalComponents = [AddressDetailsFormComponent, AddressInformationFormComponent];

const externalModules = [
  ...lcModules,
];

@NgModule({
  declarations: [...internalComponents, ...externalComponents],
  exports: [...externalComponents, ...externalModules],
  imports: [...angularModules, ...lcModules],
})
export class AddressFormModule {}
