// NOTE: See https://danielbarta.com/literal-iteration-typescript/
const InputTypes = {
  select: 'Dropdown',
  'multi-select': 'Multiselect',
  textarea: 'Textarea',
  textbox: 'Textbox',
  'radio-group': 'Radio Buttons',
  checkbox: 'Checkbox',
} as const;

export const InputTypeDisplays = Object.keys(InputTypes)
  .map((key) => ({ value: key, display: InputTypes[key] as string }));

export type InputType = keyof typeof InputTypes;

export class Validators {
  required?: boolean;
  min?: number;
  max?: number;
  /** The minimum length this input requires */
  minLength?: number;

  /** The maximum length this input requires */
  maxLength?: number;
}

export class FormGroup {
  key: string;
  order: number;
  header: string;
  subheader: string;

  constructor(value: Partial<FormGroup>) {
    Object.assign(this, value);
  }
}

export abstract class Input<TValue = any> {
  key: string;

  /** The type of element to use to capture the value form the user */
  inputType: InputType;

  /** The label to display with this input */
  label: string;

  /** The hint to display with the input */
  hint?: string;

  /** The default placeholder to show on the form */
  placeholder?: string;

  /** The order this input will show up in the UI */
  order?: number;

  /** The default value for this input  */
  defaultValue?: TValue;

  /** */
  validators: Validators;

  /** The group ID this input will show up in the UI */
  groupKey?: string;

  options?: InputOption[];

  constructor(value: Partial<Input<TValue>>) {
    Object.assign(this, value);
  }
}

export class InputOption<TValue = any> {
  /** The value associated to this option */
  value: TValue;

  /** The label to display with this option */
  label: string;

  /** The description related to this option */
  description?: string;

  /** Additional data that can be customized or extended as needed */
  data?: {
    /** Assets to be used to display this field */
    imageUrl?: string;

    /** Additional Properties not common to fields */
    [key: string]: any;
  };

  constructor(value: Partial<InputOption>) {
    Object.assign(this, value);
  }
}

export class Select<TValue = any> extends Input<TValue> {
  /** The options to display to the user */
  options: InputOption[];
  inputType: 'select' = 'select';

  constructor(value: Partial<Select<TValue>>) {
    super(value);
  }
}
export class Checkbox extends Input<boolean> {
  inputType: 'checkbox' = 'checkbox';

  constructor(value: Partial<Checkbox>) {
    super(value);
  }
}

export class Textbox<TValue extends string | number = string> extends Input<TValue> {
  type: 'number' | 'text' = 'text';
  inputType: 'textbox' = 'textbox';

  constructor(value: Partial<Textbox<TValue>>) {
    super(value);
  }
}

export class Textarea extends Input<string> {
  inputType: 'textarea' = 'textarea';

  constructor(value: Partial<Textarea>) {
    super(value);
  }
}

export class RadioGroup<TValue = any> extends Input<TValue> {
  options: InputOption[];
  inputType: 'radio-group' = 'radio-group';

  constructor(value: Partial<RadioGroup>) {
    super(value);
  }
}

export class MultiSelect<TValue extends Array<any> = any[]> extends Input<TValue> {
  /** The options to display to the user */
  options: InputOption[];
  inputType: 'multi-select' = 'multi-select';
  constructor(value: Partial<MultiSelect<TValue>>) {
    super(value);
  }
}

export class DynamicFormModel {
  /** The type of inputs to display on the form */
  readonly inputs: { [key: string]: Input };

  /** Allows inputs to be grouped in seperate sections/pages */
  readonly groups: FormGroup[];

  constructor(model?: Partial<DynamicFormModel>) {
    Object.assign(this, model);
    this.inputs = this.inputs || {};

    // TODO: Map to the proper input type
    // Object.keys(this.inputs || {}).forEach(key => this.inputs[key] = )
  }
}
