import { Deserializable } from '@lc/core';

export class Ai implements Deserializable<Ai> {
  userRequests: UserRequests[] = [];
  preferences: Preferences = new Preferences();

  constructor(input?: any) {
    this.deserialize(input || {});
  }

  deserialize(input: any): Ai {
    Object.assign(this, input);
    return this;
  }
}

export class UserRequests {
  role: 'agent' | 'coordinator';
  descriptionCount?: number;
  descriptionMax?: number;
  imageCount?: number;
  imageMax?: number;
}

export class Preferences {
  useAiForDescription?: boolean;
}
