<mat-form-field [id]="idPrefix + '-form-field'" appearance="outline" floatLabel="never" [class.ng-valid]="errors === ''" class="dashed-border">
  <!-- Input -->
  <div class="fx-row x-center y-center">
    <div class="upload-container">
      <lc-file-upload [class.d-none]="formControl?.value" #fileUpload
        [imageUrl]="placeholderImage" [photoStyle]="photoStyle"
        [instructions]="placeholder" [processing]="!!uploadStatus"
        (uploadResult)="uploadPhoto($event)" [allowedExtensions]="allowedExtensions" [multipleUpload]="multipleUpload"></lc-file-upload>

      <div *ngIf="formControl?.value" class="image-border fx-column x-center y-center">
        <!-- Image Display-->
        <div class="image-container fx-row x-center y-center">
          <img [src]="formControl.value" [class]="photoStyle">
        </div>

        <div *ngIf="canEdit && formControl?.value" class="menu-button-container">

          <!-- Menu Button -->
          <button  mat-flat-button [matMenuTriggerFor]="menu" aria-label="Logo Options" class="mb-2 icon-btn bg-light-card" color="default">
            <mat-icon>more_vert</mat-icon>
          </button>

          <!-- Dropdown Menu -->
          <mat-menu #menu="matMenu">
            <button mat-menu-item color="primary" (click)="onEdit()" *ngIf="editImage?.observers?.length">
              <mat-icon>edit</mat-icon>
              <span>Edit</span>
            </button>
            <button  mat-menu-item color="primary" (click)="fileUpload?.chooseFiles()">
              <mat-icon>import_export</mat-icon>
              <span>Replace</span>
            </button>
            <button  mat-menu-item color="primary" *ngIf="downloadUrl" (click)="onDownload()">
              <mat-icon>download</mat-icon>
              <span>Download</span>
            </button>
            <button mat-menu-item color="warn" (click)="onDelete()">
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>

  <!-- Hidden input used to make this a valid mat-form-field-->
  <input matInput class="d-none" [formControl]="formControl" [errorStateMatcher]="matcher">

  <!-- Hint-->
  <mat-hint [id]="idPrefix + '-hint'" class="w-100">
    {{hint}}
  </mat-hint>

  <!-- Error -->
  <mat-error class="mt-3" [id]="idPrefix + '-error'" *ngIf="formControl && formControl.invalid" [innerHTML]="errors"></mat-error>
</mat-form-field>

