import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from './user.service';
import { TeamProfile, TeamContactPreferences } from '../models';
import { AppService } from './app.service';
import { OktaAuthenticationProvider } from './auth-providers/okta-authentication.provider';

@Injectable({
  providedIn: 'root',
})
export class TeamProfileService {
  private readonly apiEndpoint = AppService.get('persistBaseURL');

  constructor(private client: HttpClient, private userService: UserService, private oktaService: OktaAuthenticationProvider) { }

  public getAgentProfileId(agentProfileId?: string): Observable<TeamProfile[]> {
    agentProfileId = agentProfileId || this.userService.getAgentProfileId();
    if (!agentProfileId) { return throwError(new Error('agentProfileId cannot be null')); }
    const accessToken = this.oktaService.getToken();
    const httpHeaders = new HttpHeaders({ Authorization: `Bearer ${accessToken}` });
    const httpParams = new HttpParams({ fromObject: { agentProfileId } });
    return this.client.get<TeamProfile[]>(`${this.apiEndpoint}team-profiles`, { headers: httpHeaders, params: httpParams });
  }

  public getAgentProfiles(agentId?: string): Observable<TeamProfile[]> {
    agentId = agentId || this.userService.getUserId();
    if (!agentId) { return throwError(new Error('agentId cannot be null')); }

    return this.client.get<TeamProfile[]>(`${this.apiEndpoint}team-profiles`, { params: { agentId } }).pipe(
      map((teamProfiles) => teamProfiles.map((profile) => new TeamProfile(profile))),
    );
  }

  public getTeamProfile(id: string): Observable<TeamProfile> {
    return this.client.get<TeamProfile>(`${this.apiEndpoint}team-profiles/${id}`).pipe(
      map((teamProfile) => new TeamProfile(teamProfile)),
    );
  }

  public post(teamProfile: TeamProfile) {
    const postClone = this.cloneTeamToSave(teamProfile);
    return this.client.post<TeamProfile>(`${this.apiEndpoint}team-profiles`, postClone).pipe(
      map((profile) => new TeamProfile(profile)),
    );
  }

  public put(teamProfile: TeamProfile) {
    const putClone = this.cloneTeamToSave(teamProfile);
    return this.client.put<TeamProfile>(`${this.apiEndpoint}team-profiles/${teamProfile._id}`, putClone).pipe(
      map((profile) => new TeamProfile(profile)),
    );
  }

  public updateContactPreferences(teamId: string, contactPrefences: TeamContactPreferences) {
    return this.client.put<TeamProfile>(`${this.apiEndpoint}team-profiles/${teamId}/contactPreferences`, contactPrefences).pipe(
      map((profile) => new TeamProfile(profile)),
    );
  }

  private cloneTeamToSave(teamProfile) {
    const postClone = { ...teamProfile };
    delete postClone._id;

    return postClone;
  }
}
