<mat-card class="p-0 lc-card fx-column" [class.h-100]="stretchHeight" [class.w-100]="stretchWidth" [class.no-box]="radioButtonTemplate" [class]="'margins-' + size">


  <!-- Card Image-->
  <div mat-card-image class="w-100 m-0" *ngIf="imageTemplate">
    <template [ngTemplateOutlet]="imageTemplate"></template>
  </div>


  <mat-card-content class="m-0 fx-1-1" [ngClass]="contentClass">
    <!-- Card Header-->
    <div *ngIf="headerTemplate || header" class="header-container border-bottom">
      <div *ngIf="header || subheader" class="fx-column">
        <span *ngIf="header" class="mat-title font-weight-bold m-0 line-height-0 card-header">{{header}}</span>
        <span *ngIf="subheader" class="mat-subheading-2 mat-hint m-0 line-height-0 card-subheader">{{subheader}}</span>
      </div>

      <template [ngTemplateOutlet]="headerTemplate"></template>
    </div>

    <!-- Card Content-->
    <div *ngIf="contentTemplate" class="content-container" [ngClass]="contentClass" [class.pb-4]="!actionsTemplate && size==='lg'" [class.pb-2]="!actionsTemplate && size=='sm'">
      <template [ngTemplateOutlet]="contentTemplate"></template>
    </div>

    <!-- Radio button Content-->
    <div *ngIf="radioButtonTemplate" class="header-container px-3">
      <template [ngTemplateOutlet]="radioButtonTemplate"></template>
    </div>
  </mat-card-content>

  <!-- Divider (conditional upon content and actions)-->
  <ng-container *ngIf="(headerTemplate || header || subheader || contentTemplate) && actionsTemplate">
    <mat-divider class="actions-divider position-relative"></mat-divider>
  </ng-container>

  <!-- Card Content-->
  <mat-card-actions class="m-0" *ngIf="actionsTemplate">
    <template [ngTemplateOutlet]="actionsTemplate"></template>
  </mat-card-actions>

  <!-- Spinner that overlays the entire card -->
  <lc-spinner *ngIf="loading?.isLoading" [spinnerText]="loading.loadingText" [showOverlay]="true"></lc-spinner>
</mat-card>
