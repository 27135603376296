import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { MarketingOrder } from '../models/marketing-order.model';
import { MultiMediaItemRequest } from '../models/multi-media-item.model';
import { UpdateOrderComplete } from '../state-mgmt';

/**
 * This service is responsible for managing the REST calls for the marketingOrder's multimedia links.
 * Once the marketing order is updated, we then update the order in state
 */
@Injectable({
  providedIn: 'root',
})
export class MarketingOrderMultimediaService {
  constructor(private apiService: ApiService, private store: Store<any>) { }

  addLink$(marketingOrderId: string, link: MultiMediaItemRequest): Observable<MarketingOrder> {
    return this.apiService.post<MarketingOrder>(`marketing-orders/${marketingOrderId}/media/multimedia`, link).pipe(
      map((order) => new MarketingOrder(order)),
      tap((order) => this.updateOrder(order)),
    );
  }

  updateLink$(marketingOrderId: string, link: MultiMediaItemRequest): Observable<MarketingOrder> {
    delete link.audit; // This should be removed once we switch to angular form-driven requests
    return this.apiService.put<MarketingOrder>(`marketing-orders/${marketingOrderId}/media/multimedia/${link._id}`, link).pipe(
      map((order) => new MarketingOrder(order)),
      tap((order) => this.updateOrder(order)),
    );
  }

  deleteLink$(marketingOrderId: string, linkId: string): Observable<MarketingOrder> {
    return this.apiService.delete<MarketingOrder>(`marketing-orders/${marketingOrderId}/media/multimedia/${linkId}`).pipe(
      map((order) => new MarketingOrder(order)),
      tap((order) => this.updateOrder(order)),
    );
  }

  deleteLinks$(marketingOrderId: string, linkIds: string[]) {
    return this.apiService.delete<MarketingOrder>(`marketing-orders/${marketingOrderId}/media/multimedia`, { linkIds }).pipe(
      map((order) => new MarketingOrder(order)),
      tap((order) => this.updateOrder(order)),
    );
  }

  // TODO: Remove this method when we get rid of ngrx
  private updateOrder(order: MarketingOrder) {
    this.store.dispatch(UpdateOrderComplete({ payload: order }));
  }
}
