import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { ProfileInfo, SharedMarketingOrder } from '../models';

@Injectable({
  providedIn: 'root',
})
export class MarketingOrderShareService {
  constructor(private apiService: ApiService) { }

  displaySharedOrders$() {
    return this.hasSharedOrders$();
  }

  hasSharedOrders$(): Observable<boolean> {
    return this.apiService.get<boolean>('marketing-orders/any-shared-with-me');
  }

  getSharedOrders$(): Observable<SharedMarketingOrder[]> {
    return this.apiService.get<SharedMarketingOrder[]>('marketing-orders/shared-with-me').pipe(
      map((sharedOrders) => sharedOrders.map((order) => new SharedMarketingOrder(order))),
    );
  }

  getSharedWithAgents$(marketingOrderId: string): Observable<ProfileInfo[]> {
    return this.apiService.get<ProfileInfo[]>(`marketing-orders/${marketingOrderId}/shared-with-agents`).pipe(
      map((agents) => agents.map((agent) => new ProfileInfo(agent))),
    );
  }

  addSharedWithAgents$(marketingOrderId: string, agentId: string, agentprofileId: string): Observable<ProfileInfo[]> {
    return this.apiService.post(`marketing-orders/${marketingOrderId}/shared-with-agents?agentId=${agentId}&agentprofileId=${agentprofileId}`).pipe(
      map((agents) => agents.map((agent) => new ProfileInfo(agent))),
    );
  }

  removeSharedWithAgents$(marketingOrderId: string, agentId: string, agentprofileId: string): Observable<ProfileInfo[]> {
    return this.apiService.delete(`marketing-orders/${marketingOrderId}/shared-with-agents/${agentId}/${agentprofileId}`).pipe(
      map((agents) => agents.map((agent) => new ProfileInfo(agent))),
    );
  }
}
