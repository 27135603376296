import { Component, OnInit, NgZone } from '@angular/core';
import '@abt-desk/rsc/lib/es2015';
import '@abt-desk/rsc/lib/es2015/footer';
import '@abt-desk/rsc/lib/es2015/header';
import '@abt-desk/rsc/lib/es2015/speed-dial';

@Component({
  selector: 'lc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public mounted = false;

  constructor(private ngZone: NgZone) { }

  ngOnInit() {
    this.ngZone.run(() => this.mounted = true);
  }
}
