import * as XLSX from 'xlsx';
import { Injectable } from '@angular/core';
import { UIConstants } from '../constants';
import { PromptDialogService } from './prompt-dialog.service';

export class ContactList {
  fileName?: string;
  messages?: UploadMessage[];
  success: boolean;
  upload?: Upload;
}

export class Upload {
  data?: string[][];
  hasHeader?: boolean;
  records?: number;
  radiusQty?: number;
}

export class UploadMessage {
  type: string;
  message: string;
  details?: string;
}

export class ContactListUploadContext {
  productTitle!: string;
  totalPrice?: number;
  quantity?: number;
  additionalQty?: number;
  additionalUnits?: number;
  totalQuantity?: number;
  isPaymentRequired?: boolean;
}

@Injectable()
export class ContactListUploadService {
  fileName: string;
  upload: Upload = {
    data: undefined,
    hasHeader: undefined,
    records: 0,
    radiusQty: 0,
  };
  success: boolean = true;
  messages: UploadMessage[] = [];
  context: ContactListUploadContext;

  private validMimeTypes: string[] = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'text/csv',
  ];

  private headerPatterns: string[] = [
    'first name',
    'last name',
    'site address',
    'site city',
    'site state',
    'site zip',
  ];

  constructor(private promptDialog: PromptDialogService) {}

  /**
   * Processes uploaded files
   */
  async processFile(files: File[], context: ContactListUploadContext): Promise<ContactList> {
    try {
      this.success = true;
      this.context = context;
      this.messages = [];
      this.validateInput(files);
      const file = files[0];
      this.fileName = file.name;
      const { data } = await this.loadData(file);
      this.upload.data = data;
      return {
        fileName: this.fileName, upload: this.upload, success: this.success, messages: this.messages,
      };
    } catch (error) {
      return { success: this.success, messages: this.messages };
    }
  }

  validateInput(files: File[]) {
    // only allow a single file to be uploaded
    if (files?.length !== 1) {
      this.messages.push({
        type: 'error',
        message: 'Please only upload one file',
      });

      this.success = false;
      throw new Error();
    }

    const file = files[0];

    // validate that the file is in csv, xls or xlsx format
    if (
      file.type
      && file.type.length > 0
      && this.validMimeTypes.indexOf(file.type) === -1
    ) {
      this.messages.push({
        type: 'error',
        message: 'The file type is incorrect. Please ensure you are uploading an Excel or CSV files only',
      });

      this.success = false;
      throw new Error();
    }
  }

  /**
   * Load data from a file into a spreadsheet and then convert to JSON
   */
  loadData(file: File): Promise<{ data: any }> {
    return new Promise((resolve, reject) => {
      const reader: FileReader = new FileReader();

      reader.onload = async (e: any) => {
        // read the workbook
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        // get the first sheet
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        // get data as json
        const jsonData = <any[][]>(XLSX.utils.sheet_to_json(ws, { header: 1 }));

        const filteredData = (jsonData || []).filter((row) => Array.isArray(row) && row.find((col) => col && col.toString().trim().length > 0));

        try {
          await this.validate(filteredData);
        } catch (error) {
          reject(error);
        }

        resolve({ data: filteredData });
      };

      reader.readAsBinaryString(file);
    });
  }

  /**
   * This validation method assumes there is data with the first element in array being the header
   *
   * @param data the two dimension array of data
   */
  async validate(data: any[][]): Promise<boolean | void> {
    // check to make see if there is a header
    const header = data[0];
    this.validateHeader(header);
    this.validateHeaderSequence(header);

    if (this.messages.length === 0) {
      this.upload.hasHeader = true;
    }

    this.upload.records = data.length - (this.upload.hasHeader ? 1 : 0);

    // this.messages.push({
    //   type: 'info',
    //   message: 'We detected a header in your upload',
    //   details: 'Header used: ' + JSON.stringify(header)
    // });

    // // push message with number of records on list
    // this.messages.push({
    //   type: 'info',
    //   message: 'There are ' + this.upload.records + ' records in mailing list.',
    // });

    // // inform user that one is sent to agent and seller
    // this.messages.push({
    //   type: 'info',
    //   message: 'One ' + this.context.productTitle.toLowerCase() + ' will be mailed to the agent and seller.'
    // });

    // // inform user that left over quantity will be sent to radius mailing around listing address
    // const addressCount = this.upload.records;
    // const piecesCount = this.getTotalQty();
    // this.upload.radiusQty = piecesCount - addressCount - 2;

    // if (this.upload.radiusQty > 0) {
    //   const msg = `Your list includes ${addressCount} addresses, your order includes a total of ${piecesCount} (which includes 1 copy to be mailed to you and 1 copy to the seller). ${this.upload.radiusQty} will be sent to addresses in a radius around the listing.`;

    //   return await this.promptDialog.openPrompt('Confirm', msg, UIConstants.YES, [UIConstants.NO + ", I'll edit the list myself"])
    //     .then(response => {
    //       (<HTMLInputElement>document.getElementById("addressFileInput")).value = "";
    //       if (response?.text !== UIConstants.YES) {
    //         this.messages = [];
    //         this.fileName = undefined;
    //         this.upload.data = undefined;
    //         throw new Error();
    //       }
    //     });
    // }

    // if (this.upload.radiusQty < 0) {
    //   const msg = `Your list has ${addressCount} addresses, however your package only includes a total of ${piecesCount} pieces (which includes 1 copy to be mailed to you and 1 copy to the seller). Do you want us to remove the extra addresses and continue?`;

    //   return await this.promptDialog.openPrompt('Confirm', msg, UIConstants.YES, [UIConstants.NO + ", I'll edit the list myself"])
    //     .then(response => {
    //       (<HTMLInputElement>document.getElementById("addressFileInput")).value = "";
    //       if (response?.text === UIConstants.YES) {
    //         this.upload.radiusQty = 0;
    //         this.upload.records = piecesCount - 2;
    //         data.length = piecesCount - (this.upload.hasHeader ? 1 : 2);
    //       } else {
    //         this.messages = [];
    //         this.fileName = undefined;
    //         this.upload.data = undefined;
    //         throw new Error();
    //       }
    //     });
    // }

    return new Promise((resolve) => { resolve(true); });
  }

  /**
   * Look for some form of matched header in the list of headers
   *
   * @param header the header row assumed to be the first element in the uploaded data
   * @return boolean true if found, false otherwise
   */
  validateHeader(header: any[]) {

  }

  validateHeaderSequence(headers: any[]) {

  }

  getTotalQty() {
    return this.context?.totalPrice > 0 && this.context?.isPaymentRequired
      ? (+(this.context?.quantity || 0)) + (+(this.context?.additionalQty || 0)) + (+(this.context?.additionalUnits || 0))
      : this.context?.totalQuantity || 0;
  }
}
