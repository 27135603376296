import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * The LoaderService is to support the spinner on and off actions .
 * The purpose of the service is to handle spinner component on and Off  on the UI.
 * This service helps to have a common place to look for the action of showing spinner and stoping spinner.
 * All show and hide of spinner actions has to go through this service
 */
@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private static readonly queue = [];

  private readonly _isLoading = new Subject<boolean>();
  readonly isLoading = this._isLoading.asObservable();

  show() {
    LoaderService.queue.push();
    this._isLoading.next(true);
  }

  hide() {
    // If there are elements left, which there should be, pop an element from the queue
    if (LoaderService.queue.length) {
      LoaderService.queue.pop();
    }

    // After the pop, determine if the queue is empty. If it is, turn off the loading indicator
    if (!LoaderService.queue.length) {
      this._isLoading.next(false);
    }
  }
}
