import { Directive, HostListener, Input } from '@angular/core';
import { PresignService, PromptDialogService } from '@lc/core';
import { take } from 'rxjs/operators';
import { PdfViewerDialog } from './pdf-viewer.dialog';

@Directive({
  selector: '[lcPdfViewer]',
})
export class PdfViewerDirective {
  private presignedUrl?: string;

  @Input('lcPdfViewer') url?: string;
  @Input() pdfTitle: string;
  @Input() pdfPresign: boolean = false;

  /** Listen for the click event on the host element. */
  @HostListener('click')
  async onClick() {
    if (!this.url) { return; } // If the url isn't provided, just return
    const url = this.pdfPresign
      ? await this.getPresignedUrl(this.url)
      : this.url;

    PdfViewerDialog.open(this.promptService, { url, title: this.pdfTitle });
  }

  constructor(
    private readonly promptService: PromptDialogService,
    private readonly presignService: PresignService,
  ) { }

  private async getPresignedUrl(url: string) {
    if (!this.presignedUrl) {
      try {
        this.presignedUrl = await this.presignService.presignUrls([url]).pipe(take(1)).toPromise()
          .then((presigned) => presigned[0]?.presignedUrl);
      } catch (error) {
        console.error('[PdvViewerDirective]: Error presigning url for pdfViewer', error);
        return url;
      }
    }
    return this.presignedUrl;
  }
}
