<mat-list-item *ngIf="companyName" class="py-2 bg-lightest">
  <div  class="w-100 fx-row fx-gap-sm y-center" [class.x-center]="!isExpanded">

    <mat-icon class="size-34 material-icons-outlined" [matTooltip]="'Company: ' + companyName" matTooltipPosition="right" [matTooltipDisabled]="isExpanded">domain</mat-icon>

    <div *ngIf="isExpanded">
      <div id="company-info" class="company-name mat-body-2 text-bold text-uppercase line-height-0">{{companyName}}</div>
      <div class="company-label mat-caption line-height-0">Company Name</div>
    </div>
  </div>
</mat-list-item>
