import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MarketingOrderNotesClient } from '../clients/marketing-order-notes.client';
import { NoteForm } from '../forms/note.form';
import { Note } from '../models/note.model';

/**
 * The NotesService is a layer between the components and the API Clients.
 * The purpose of the service is to handle business logic while interacting with the
 * clients. This eliminates the components from needing to handle business logic.
 * All business logic should be done here
 */
@Injectable({
  providedIn: 'root',
})
export class MarketingOrderNotesService {
  constructor(private readonly noteClient: MarketingOrderNotesClient) { }

  /**
   * Loads the notes belonging to a specific marketing order.
   */
  getNotesFor(orderId: string): Observable<Note[]> {
    // Note: If we want to do caching or utilize the ngrx store, do it here
    return this.noteClient.get(orderId);
  }

  /**
   * Submits a notification to the API
   * @param form
   */
  async create(orderId: string, form: NoteForm) {
    return await this.noteClient.post(orderId, form.value);
  }
}
