import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { User } from '../models';
import { AppService } from './app.service';

declare let gtag: any;

@Injectable({ providedIn: 'root' })
export class GTagService {
  constructor(@Inject(DOCUMENT) private doc: any) {
  }

  updateGTag(gtags: Map<string, string>, user: User, url: string, userType: 'Agent' | 'Coordinator' | 'Designer' | 'Photographer') {
    const environment = AppService.get('environment');
    const gTag = environment ? gtags.get(environment) : undefined;
    if (!gTag) { return; } // No GTAG key exists for this environment. Just return

    this.setGTagManager(gTag);
    this.setDataLayer(user, url, userType);
  }

  setGTagManager(gTag: string) {
    const scripts = [...(this.doc?.getElementsByTagName('script') || [])];
    const hasBeenSet = scripts.some((script) => script?.src.indexOf('https://www.googletagmanager.com/gtm.js') === 0);
    if (hasBeenSet) { return; }

    const s = this.doc.createElement('script');
    s.type = 'text/javascript';
    s.innerHTML = `(function (w, d, s, l, i) {
    w[l] = w[l] || []; w[l].push({
      'gtm.start':
        new Date().getTime(), event: 'gtm.js'
    }); var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${gTag}')`;
    const head = this.doc.getElementsByTagName('head')[0];
    head.appendChild(s);
  }

  setDataLayer(user: User, url: string, userType: 'Agent' | 'Coordinator' | 'Designer' | 'Photographer') {
    const profile = user?.profile;
    if (!profile) { return; } // If profile is not set, just return

    // Create the generic event regardless of user type
    const event: any = {
      event: 'virtualPageview',
      page_path: url,
      emailAddress: profile?.preferredEmail || 'email',
      createdAt: Math.floor(new Date().getTime() / 1000),
      brand: profile?.office?.company?.brandCode || '',
      country: 'US',
      userType,
      oktaID: user?.oktaId,
    };

    // Set additional properties for non-photographers
    if (userType !== 'Photographer') {
      // Set the audit date
      const auditCreatedDate = profile?.audit?.createdAt;
      const setRoundedValueAuditCreatedDate = Math.round(new Date(auditCreatedDate).getTime() / 1000);
      const setRoundNewDateValue = Math.round(new Date().getTime() / 1000);
      const auditCreatedDateTime = auditCreatedDate ? setRoundedValueAuditCreatedDate : setRoundNewDateValue;
      event.createdAt = auditCreatedDateTime;

      // Set the company and MDM information for non photographers only
      event.personMDMID = user?.personMasterId || '';
      event.companyMDMID = profile?.office?.company?.companyMasterId || '';
      event.companyName = profile?.office?.company?.companyName || '';
    }

    // Set additional properties for Agent only
    if (userType === 'Agent') {
      event.metroID = profile?.office?.metro?.metroNumber || '';
      event.metroName = profile?.office?.metro?.metroName || '';
      event.officeID = profile?.office?.officeCode || '';
      event.officeName = profile?.office?.address?.name || '';
    }

    // Set the data layer on the window
    (window as any).dataLayer ||= [];
    (window as any).dataLayer.push(event);
  }
}
