import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseDialogModule } from './base/base.module';

const angularModules = [CommonModule];
const externalModules = [BaseDialogModule];

/**
 * @depecated Import BaseDialogModule instead
 */
@NgModule({
  declarations: [],
  imports: [
    ...angularModules,
  ],
  exports: [...externalModules],
})
export class DialogsModule { }
