// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatDividerModule } from '@angular/material/divider';
import { MatStepperModule } from '@angular/material/stepper';

import {
  TextboxModule, DropdownModule, RichTextModule, TextareaModule, CheckboxModule, RadioGroupModule,
} from '../../inputs';
import { MultiselectModule } from '../../inputs/multiselect/multiselect.module';
import { FormButtonsModule } from '../../layout';

// Internal Component
import { DynamicFormComponent } from './dynamic-form.component';

const angularModules = [
  CommonModule,
  FormsModule, MatDividerModule, MatStepperModule,
];
const lcModules = [
  TextboxModule,
  TextareaModule,
  RichTextModule,
  DropdownModule,
  FormButtonsModule,
  CheckboxModule,
  RadioGroupModule,
  MultiselectModule,
];
const externalComponents = [DynamicFormComponent];

const externalModules = [
  ...lcModules, ReactiveFormsModule,
];

@NgModule({
  declarations: [...externalComponents],
  exports: [...externalModules, ...externalComponents],
  imports: [...angularModules, ...lcModules],
})
export class DynamicFormModule { }
