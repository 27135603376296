import { FormControl, AbstractControl } from '@angular/forms';
import { MarketingOrderStatusType, BaseForm } from '@lc/core';
import { DateOption } from './date-option';
import { StatusOption } from './status-option';

export enum FlaggedFilterValue {
  flagged = 'Flagged',
  unflagged = 'Unflagged',
}

export class OrderFilters {
  /** The search string that will be used in the address lookup */
  search: string;

  /** The area filters */
  areas: string[];

  /** The status filters */
  status: MarketingOrderStatusType[];

  /** The startDate of when the marketing order was created? */
  startDate: Date;

  /** The endDate of when the marketing order was created? */
  endDate: Date;

  /** The assignment of the marketing order */
  assignments: string;

  /** The agentId of the marketing orders  */
  agentIds: string[];

  /** The coordinatorId of the marketing orders  */
  coordinatorIds: string[];

  /**
   * can contain 'flagged' or 'unflagged'
   */
  flagged: string[];
}

export class OrderFiltersForm extends BaseForm<OrderFilters> {
  public showAssignment: boolean;
  public showFlagged: boolean;
  public userFilters: 'agent' | 'coordinator' | 'both' = 'agent';

  // The following form values are the parameters used to make the API request.
  // Get the values by calling 'form.value'
  get areas(): AbstractControl { return this.getControl('areas'); }
  get search(): AbstractControl { return this.getControl('search'); }
  get startDate(): AbstractControl { return this.getControl('startDate'); }
  get endDate(): AbstractControl { return this.getControl('endDate'); }
  get agentIds(): AbstractControl { return this.getControl('agentIds'); }
  get coordinatorIds(): AbstractControl { return this.getControl('coordinatorIds'); }

  get flaggedControl(): FormControl { return this.getControl('flagged') as FormControl; }

  get searchFormControl(): FormControl { return this.search as FormControl; }
  get assignmentsFormControl(): FormControl { return this.getControl('assignments') as FormControl; }
  // The following form values are used for UI purposes only and will not be included in the
  // form.value object
  readonly agentSearch = new FormControl();
  readonly coordinatorSearch = new FormControl();

  // The readonly display options for the date dropdown in the UI
  readonly dateOptions: DateOption[] = [
    new DateOption('Today'),
    new DateOption('This Week'),
    new DateOption('This Month'),
    new DateOption('Custom'),
  ];

  // The readonly display options for the status dropdown in the UI
  readonly statusOptions: StatusOption[];

  readonly userFilterOptions = [
    { value: 'agent', viewValue: 'Agent' },
    { value: 'lcc', viewValue: 'Coordinator' },
  ];

  // The readonly display options for the assigned to dropdown in the UI
  readonly assignmentOptions: string[] = ['ASSIGNED', 'UNASSIGNED'];

  readonly flagOptions = [FlaggedFilterValue.flagged, FlaggedFilterValue.unflagged];

  /**
   * The value of the dropdown. Not a part of this FormGroup as we want .value() to return only the filter
   * properties
   * */
  private _selectedStatuses: StatusOption[];
  private _selectedDateOption: DateOption;

  /** The selected user filter option. Defaulted to agent */
  public selectedUserFilterOption: string = this.userFilterOptions[0].value;
  public selectedCoordinatedIds: string[];

  public get selectedAgentIds(): string[] { return this.agentIds.value || []; }
  public set selectedAgentIds(agentIds: string[]) { this.agentIds.setValue(agentIds); }

  /**
   * The selectedDateOption is what controls the selected date options in the dropdown in the UI.
   */
  get selectedDateOption(): DateOption { return this._selectedDateOption; }
  set selectedDateOption(dateOption: DateOption) {
    const { startDate, endDate } = dateOption || {};
    const currentStart = this.startDate.value;
    const currentEnd = this.startDate.value;
    // eslint-disable-next-line eqeqeq
    if (startDate != currentStart) {
      this.startDate.setValue(startDate);
      this.startDate.markAsDirty();
    }
    // eslint-disable-next-line eqeqeq
    if (endDate != currentEnd) {
      this.endDate.setValue(endDate);
      this.endDate.markAsDirty();
    }
    this._selectedDateOption = dateOption;
  }

  /**
   * The selected statuses is what controls the selected options in the dropdown in the UI.
   */
  get selectedStatuses(): StatusOption[] { return this._selectedStatuses; }
  set selectedStatuses(StatusOptions: StatusOption[]) {
    const statuses = [].concat(...(StatusOptions || []).map((selected) => selected.statuses));
    const currentStatuses = (this.getControl('status').value || []) as MarketingOrderStatusType[];
    // Check if the statuses have changed, by first, comparring length. Then by enumerating to find any differences
    const hasChanged = statuses.length !== currentStatuses.length
      || statuses.some((newStatus) => currentStatuses.indexOf(newStatus) < 0);
    if (hasChanged) {
      this.getControl('status').setValue(statuses);
      this.getControl('status').markAsDirty();
    }
    this._selectedStatuses = StatusOptions;
  }

  public get selectedAreas(): string[] { return this.getControl('areas').value || []; }
  public set selectedAreas(areas: string[]) {
    const areaControl = this.getControl('areas');
    areaControl.setValue(areas);
    areaControl.markAsDirty();
  }

  constructor(options?: { statusOptions?: StatusOption[] }) {
    super({
      search: new FormControl(),
      areas: new FormControl(),
      status: new FormControl(),
      startDate: new FormControl(),
      endDate: new FormControl(),
      assignments: new FormControl(),
      agentIds: new FormControl(),
      coordinatorIds: new FormControl(),
      flagged: new FormControl(),
    });

    // Initialize the status options
    this.statusOptions = options?.statusOptions || [
      new StatusOption('New', '#E1E8ED', [MarketingOrderStatusType.SUBMITTED]),
      new StatusOption('Ready', '#54C029', [MarketingOrderStatusType.PENDING]),
      new StatusOption('In Progress', '#F4B124', [MarketingOrderStatusType.PROCESSING]),
      new StatusOption('Review', '#c33576', [MarketingOrderStatusType.COMPLETED]),
      new StatusOption('Closed', '#3989C9', [MarketingOrderStatusType.CLOSED]),
      new StatusOption('Cancelled', '#657786', [MarketingOrderStatusType.CANCELLED]),
    ];
  }

  reset() {
    super.reset();
    this.selectedDateOption = null;
  }

  setByStatus(status: string | string[]) {
    const statuses = status instanceof Array ? status : [status];
    this.selectedStatuses = this.statusOptions.filter((aStatus) => aStatus.statuses.some((optionStatus) => statuses.indexOf(optionStatus) >= 0));
  }

  patchFromQueryParams(params: any) {
    this.search.setValue(params?.search);
    this.areas.setValue(params?.areas?.split(','));

    // If the parameters exists set the status
    if (Object.keys(params).length !== 0) {
      const statusNames = params.status?.split(',') || [];
      this.selectedStatuses = this.statusOptions.filter((statusOption) => statusNames.indexOf(statusOption.label) >= 0);
    }

    if (params.date) {
      const selectedDateOption = this.dateOptions.find((option) => option.text === params.date);
      if (params.date === 'Custom') {
        selectedDateOption.startDate = this.parseDate(params.startDate);
        selectedDateOption.endDate = this.parseDate(params.endDate);
      }
      this.selectedDateOption = selectedDateOption;
    }

    if (params.agentIds) {
      this.selectedAgentIds = params.agentIds.split(',');
      this.agentIds.setValue(this.selectedAgentIds);
    }
    if (params.coordinatorIds) {
      this.selectedCoordinatedIds = params.coordinatorIds.split(',');
    }
    if (params.flagged) {
      this.flaggedControl.setValue(params.flagged?.split(','));
      this.flaggedControl.markAsPristine();
    }

    this.getControl('assignments').setValue(params.assignments?.split(','));
    this.getControl('assignments').markAsPristine();
    this.getControl('status').markAsPristine();
  }

  parseDate(dateString: string) {
    if (dateString) {
      const dateParts = dateString.split('-');
      if (dateParts.length === 3) {
        // Date Parts is in the format '2020-1-1'
        // Create date with the dateParts[0] (year), dateParts[1] - 1 (month), dateParts[2] (day)
        return new Date(+dateParts[0], +dateParts[1] - 1, +dateParts[2]);
      }
    }
    return new Date(dateString);
  }

  getQueryParams(): any {
    const params: any = {};
    if (this.search.value) {
      params.search = this.search.value;
    }
    if (this.selectedDateOption) {
      params.date = this.selectedDateOption.text;
      if (this.selectedDateOption.text === 'Custom') {
        const formatDate: (d: Date) => string = (d) => `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;

        if (this.selectedDateOption.startDate) {
          params.startDate = formatDate(this.selectedDateOption.startDate);
        }

        if (this.selectedDateOption.endDate) {
          params.endDate = formatDate(this.selectedDateOption.endDate);
        }
      }
    }

    const areas = (this.getControl('areas').value || []) as string[];
    if (areas.length > 0) {
      params.areas = areas.toString();
    }

    if ((this.selectedStatuses || []).length > 0) {
      params.status = this.selectedStatuses.map((selected) => selected.label).toString();
    }
    const assignments = this.getControl('assignments').value as string[];
    if ((assignments || []).length > 0) {
      params.assignments = assignments.toString();
    }
    const agentIds = this.agentIds?.value || [];
    if (agentIds.length > 0) {
      params.agentIds = agentIds.join(',');
    }
    const coordinatorIds = this.coordinatorIds?.value || [];
    if (agentIds.length > 0) {
      params.coordinatorIds = coordinatorIds.join(',');
    }
    this.setFlaggedQueryParamValue(params);
    return params;
  }

  /**
   * There are only 2 values, flagged and unflagged
   * if both are selected we do not need to filter, so we only add the
   * flagged parameter to query params when one is selected.
   *
   * @param params
   */
  setFlaggedQueryParamValue(params) {
    const flagged = this.flaggedControl.value || [];

    if (flagged.length) {
      params.flagged = flagged.toString();
    }
  }

  getFilters(): any {
    const params: any = {};
    const filters = this.value as OrderFilters;

    if (filters?.search) {
      params.search = filters.search;
    }

    if (filters.status?.length > 0) {
      // map the UI selection to the valid states of an order
      params.status = filters.status.toString();
    }

    if (filters.areas?.length > 0) {
      params.areas = filters.areas.toString();
    }

    if (filters.assignments?.length === 1) {
      params.assignments = filters.assignments.toString();
    }

    // only send param if > 0 selected and not all selected
    if (filters.agentIds?.length > 0) {
      params.agentIds = filters.agentIds.join(',');
    }
    // only send param if > 0 selected and not all selected
    if (filters.coordinatorIds?.length > 0) {
      params.coordinatorIds = filters.coordinatorIds.join(',');
    }

    if (filters.startDate && filters.endDate) {
      params.startDate = filters.startDate;
      params.endDate = filters.endDate;
    }

    const { flagged } = filters;
    if (flagged?.length) {
      params.flagged = flagged.toString();
    }

    return params;
  }
}
