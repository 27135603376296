import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { PhotoApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ExternalUrlService {
  constructor(private apiService: PhotoApiService) { }

  async validateUrl(url: string) {
    return await this.apiService
      .getResponse(`cors/url-validation?url=${encodeURIComponent(url)}`)
      .pipe(
        filter((event) => event instanceof HttpResponse),
        map((event: HttpResponse<any>) => event),
      )
      .toPromise();
  }
}
