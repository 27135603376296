import { Deserializable } from './deserializable.model';
import { ProductDetails } from './product-details.model';

export class ProductOffering implements Deserializable<ProductOffering> {
  productCode: string;
  description: string;
  modifiedDetails: ProductDetails;

  deserialize(input: any): ProductOffering {
    if (!input) { return this; }
    Object.assign(this, input);

    if (this.modifiedDetails) {
      this.modifiedDetails = new ProductDetails(this.modifiedDetails);
    }
    return this;
  }
}
