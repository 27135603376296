// Angular imports
import {
  Component, forwardRef, Input, OnChanges, SimpleChanges,
} from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

// Internal imports
import { InputField } from '../input-field';

export interface IRadioOption<TValue = any> {
  label: string;
  description?: string;
  value: TValue;
  isSelected: boolean;
  option: any;
}

@Component({
  selector: 'lc-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RadioGroupComponent),
      multi: true,
    },
  ],
})
export class RadioGroupComponent extends InputField implements OnChanges {
  @Input() label: string = '';
  @Input() options: IRadioOption[] = [];
  @Input() selected: any;
  @Input() layout: 'row' | 'column' = 'row';

  selectedValues: string[] = [];

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.updateRadioOptions();
    }
  }

  onSelect(option: IRadioOption) {
    option.isSelected = !option.isSelected;
    this.options?.forEach((opt) => opt.isSelected = option.isSelected && (opt === option));
    this.value = option.isSelected ? option.value : null;
  }

  /**
   * Update the selected values with the formControl/ngModel value changes.
   */
  protected executeOnChanged() {
    // Map the selected value(s) to an array of values and store as selectedValues
    const values = this.value instanceof Array ? this.value : [this.value];
    this.selectedValues = values.filter((value) => value != null);

    // Enumerate the existing cards and mark the proper ones as selected
    this.options?.forEach((option) => option.isSelected = this.selectedValues.includes(option.value));

    // Call through the base method
    super.executeOnChanged();
  }

  /**
   * When the given options change update the UI Model
   */
  private updateRadioOptions() {
    (this.options || []).forEach((option) => {
      option.isSelected = this.selectedValues.includes(option.value);
    });
  }
}
