import { createAction, props } from '@ngrx/store';
import { TeamProfile, TeamContactPreferences } from '../../models';

// export enum TeamActionTypes {
//   GetTeam = '[Team] Get Team Profile',
//   GetTeamComplete = '[Team] Get Team Profile Complete',
//   UpdateTeam = '[Team] Update Team Profile',
//   UpdateTeamComplete = '[Team] Update Team Profile Complete',
//   UpdateTeamContactPreferences = '[Team] Update Team Preferences',
// }

export const GetTeam = createAction('[Team] Get Team Profile', props<{ team:TeamProfile, teamId?: string }>());

export const GetTeamComplete = createAction('[Team] Get Team Profile Complete', props<{ team: TeamProfile }>());

export const UpdateTeam = createAction('[Team] Update Team Profile', props<{ payload: TeamProfile, orderId?: string }>());

export const UpdateTeamComplete = createAction('[Team] Update Team Profile Complete', props<{ payload: TeamProfile }>());

export const UpdateTeamContactPreferences = createAction('[Team] Update Team Preferences', props<{ payload: TeamProfile, preferences: TeamContactPreferences }>());

// export class GetTeam implements Action {
//   readonly type = TeamActionTypes.GetTeam;
//   constructor(public team?: TeamProfile, public teamId?: string) {}
// }

// export class GetTeamComplete implements Action {
//   readonly type = TeamActionTypes.GetTeamComplete;
//   constructor(public team: TeamProfile) {}
// }

// export class UpdateTeam implements Action {
//   readonly type = TeamActionTypes.UpdateTeam;
//   constructor(public payload: TeamProfile, public orderId?: string) {}
// }

// export class UpdateTeamComplete implements Action {
//   readonly type = TeamActionTypes.UpdateTeamComplete;
//   constructor(public payload: TeamProfile) {}
// }

// export class UpdateTeamContactPreferences implements Action {
//   readonly type = TeamActionTypes.UpdateTeamContactPreferences;
//   constructor(public payload: TeamProfile, public preferences: TeamContactPreferences) {}
// }

// export type TeamActions = GetTeam | GetTeamComplete | UpdateTeam | UpdateTeamContactPreferences | UpdateTeamComplete;

export const SELECTEDTEAM = 'selectedTeam';
