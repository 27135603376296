import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { VendorServiceOrder } from '../models';
import { ApiService } from './api.service';

@Injectable()
export class VideoOrderService {
  resource = 'video-orders';

  constructor(private apiService: ApiService) { }

  get(marketingOrderId: string, productCode: string): Observable<VendorServiceOrder[]> {
    const queryParams: any = { marketingOrderId };
    if (productCode) {
      queryParams.productCode = productCode;
    }

    return this.apiService.get(this.resource, queryParams).pipe(
      map((orders: VendorServiceOrder[]) => {
        const videoOrderObjs = [];
        orders.forEach((o: VendorServiceOrder) => {
          videoOrderObjs.push(new VendorServiceOrder(o));
        });
        return videoOrderObjs;
      }),
    );
  }

  /**
   * This service is NOT To be used widely in the app. It is a tactical approach to re-submitting
   * video order requests under very specific conditions. Currently it is hidden behind a feature flag
   * that will only be enabled for a single user. The server resource is also hidden behind the same feature flag.
   */
  async resubmit(marketingOrderId: string, productCode: string) {
    const path = `${this.resource}/send-request/${marketingOrderId}`;
    return this.apiService.get(path, { productCode }).toPromise();
  }
}
