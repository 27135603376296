import { Deserializable } from './deserializable.model';
import { ProductInstance } from './product-instance.model';

export class ChangeLogTemplate extends ProductInstance {
  marketingCopyHeadline: string[];
  marketingCopyBody: string[];
  photos: any;
  customContactBlock?: string;

  /**
  ANY FIELDS ADDED MUST ALSO BE ADDED TO TRACKED FIELDS IN lcms-persist/src/config/mongoose-global-middleware/hooks.js
   */
  constructor(data?: ProductInstance) {
    const obj = {
      marketingCopyHeadline: data.marketingCopyHeadline,
      marketingCopyBody: data.marketingCopyBody,
      photos: data.photos,
      customContactBlock: data.customContactBlock,
      qr: {
        display: 1,
        resourceUrl: 1,
      },
      hiddenTags: data.hiddenTags,
      selectedTemplate: {
        title: 1,
      },
    };
    super(obj);
    if (obj?.photos) {
      const key = data.getPhotoType();
      this.photos = JSON.parse(JSON.stringify(obj.photos));
      this.photos[key] = this.photos[key].map((p) => new PhotoUri(p));
    }
  }
}

export class PhotoUri implements Deserializable<PhotoUri> {
  uri: string;

  constructor(data?: any) {
    this.deserialize(data || {});
  }

  deserialize(input: any): PhotoUri {
    this.uri = input?.uri || [];
    return this;
  }
}
