import { Injectable } from '@angular/core';

/**
 * Provides methods for converting display text to HTML friendly formats for use
 * inside HTML tags.
 */
@Injectable()
export class FormattingService {
  /**
   * Takes a string of mixed case text and converts it to a string that
   * can be uses in CSS class definitions. Example:
   *
   *  Social & Web Boost => social-n-web-boost
   *
   * @param text
   */
  formatDisplayTextAsCssClass(text: string) {
    if (!text) { return; }

    return this.replaceCharacters(
      this.replaceCharacters(text.toLowerCase(), '&', 'n', true),
      ' ',
      '-',
      true,
    );
  }

  /**
   * Takes a string and replaces an instance of 'target' string with 'replacement' or all instances
   * of target based on the value of 'replaceAll'
   * @param str
   * @param target
   * @param replacement
   * @param replaceAll
   * @returns
   */
  replaceCharacters(
    str: string,
    target: string,
    replacement: string,
    replaceAll: boolean = true,
  ): string {
    const regex = replaceAll ? new RegExp(target, 'g') : new RegExp(target, 'i');
    return (str ?? '').replace(regex, replacement);
  }

  /**
   * format strings for HTML/RichText use
   * @param value
   * @returns
   */
  formatForRichText(value?: string): string {
    if (typeof value !== 'string') {
      value = '';
    }
    return this.replaceCharacters(value, '\n', '<br>', true);
  }

  /**
   * format strings for plain text (textarea) use
   * @param value
   * @returns
   */
  formatForPlainText(value: string): string {
    return this.replaceCharacters(value, '<br>', '\n', true);
  }
}
