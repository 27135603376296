/**
 * Enum containing the available photo order statuses
 */
export enum PhotoOrderStatus {
  OPEN = 'OPEN',
  SERVICING = 'SERVICING',
  SUBMITTING = 'SUBMITTING',
  CLOSED = 'CLOSED',
  CANCELLED = 'CANCELLED',
}

/**
 * The different state transitions that can occur
 */
export enum PhotoOrderTransitions {
  start = 'start',
  complete = 'complete',
  cancel = 'cancel',
}
