import { ValidatorFn, AbstractControl } from '@angular/forms';

export function emptyStringValidator(key: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === '') {
      control.setValue(null);
    }
    return null;
  };
}
