import { Audit } from './audit.model';
import { DeliveryAddress } from './delivery-address.model';
import { Deserializable } from './deserializable.model';
import { FulfillmentOption } from './fulfillment-option.model';

export const FulfillmentTypesConst = ['ship', 'radius', 'list', 'website'] as const;
export type FulfillmentType = typeof FulfillmentTypesConst[number];

// Only keeping this enum here since it is being imported and used in various areas.
export enum FulfillmentTypes {
  ADDRESS = 'ship',
  RADIUS = 'radius',
  LIST = 'list',
  WEBSITE = 'website',
}

export const PrintOrderStatuses = ['pending', 'rejected', 'submitted', 'ready', 'cancelled', 'complete', 'error'] as const;
export type PrintOrderStatus = typeof PrintOrderStatuses[number];

export class Fulfillment implements Deserializable<Fulfillment> {
  type: FulfillmentType;
  options: FulfillmentOption[];
  status: PrintOrderStatus;
  isProofReady?: boolean;

  quantity?: number;

  audit?: Audit;

  storeOrderId?: string;
  returnAddress?: DeliveryAddress;

  constructor(input?: any) {
    this.deserialize(input);
  }

  deserialize(input: any): Fulfillment {
    Object.assign(this, input);
    return this;
  }

  addOption(option: FulfillmentOption) {
    if (!this.options) { this.options = []; }

    if (option) { this.options.push(option); }
  }

  find(selector): FulfillmentOption {
    return this.options.find(selector);
  }
}
