import {
  Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ElementRef,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { FileHelper, ToasterService } from '@lc/core';
import { InputField } from '../../inputs/input-field';
import { getNativeElement } from '../../../../../products/src/lib/shared/components/util/util';

@Component({
  selector: 'lc-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent extends InputField implements OnInit {
  @ViewChild('fileInput')
    fileInput: ElementRef;

  // TODO - abstract this component by allowing a templateRef passed. This is not complete code but the start
  @Input()
    uploadTemplate: TemplateRef<any>;

  // TODO - remove the two inputs and replace with class names to identify components
  @Input()
    inputId: string; // the id of the upload input field - used for e2e

  @Input()
    btnId: string; // the id of the upload button - used for e2e

  @Input()
    label: string; // text to display as a component label

  @Input()
    imageUrl: string; // image to display. If null, then photo image will display.

  @Input()
    instructions: string; // text to display for the component

  @Input()
    allowedExtensions: any[] = []; // File validation constraints

  @Input()
    processing: boolean; // determines if the component should accept files

  @Output()
  readonly uploadResult = new EventEmitter<File[]>();

  @Input()
    multipleUpload: boolean = true; // sets the input to upload single or multiple images

  @Input()
    photoStyle: null | 'round';

  allowExt = [];

  constructor(
    sanitizer: DomSanitizer,
    private toaster: ToasterService,
  ) {
    super(sanitizer);
  }

  ngOnInit() {
    this.allowExt = this.allowedExtensions?.map((ext) => `.${ext}`);
  }

  // called for drag-n-drop
  async handleDroppedFiles(event: NgxFileDropEntry[]) {
    if (!this.processing) {
      this.uploadFiles(await this.getFilesFromUploadEvent(event));
    }
  }

  // called for file chooser input
  async handleSelectedFiles(event) {
    await this.uploadFiles(event.target.files);
  }

  async getFilesFromUploadEvent(event: NgxFileDropEntry[]) {
    const filePromises = [];
    event.forEach((droppedFile) => {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        const filePromise = new Promise((resolve) => {
          fileEntry.file(resolve);
        });
        filePromises.push(filePromise);
      }
    });
    return Promise.all(filePromises);
  }

  async uploadFiles(files: File[]) {
    const isValid = await this.validateExtension(files);
    if (!isValid) {
      this.toaster.showError('Only PNG and JPG files are allowed');
    } else {
      this.uploadResult.emit(files);
    }
  }

  public chooseFiles() {
    getNativeElement(this.fileInput, 'FileUploadComponent').click();
  }

  private async validateExtension(files: File[]) {
    if (this.allowedExtensions?.length) {
      const fileType = await FileHelper.getFileTypeFromSignature(files[0], { validation: 'strict' });
      if (!fileType || !this.allowedExtensions.includes(fileType.suffix)) {
        return false;
      }
    }

    return true;
  }
}
