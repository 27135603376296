import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiClient } from './api.client';
import { AppService } from '../services/app.service';
import { ProfileInfo } from '../models/profile-info.model';

/**
 *  Contains all of the methods required to communicate with the User Match API
 *  Should not contain methods with business logic. This is only for API communication
 */
@Injectable({
  providedIn: 'root',
})
export class UserMatchClient {
  public readonly userMatchEndpoint = `${AppService.get('persistBaseURL')}user/match`;

  constructor(private apiClient: ApiClient) {
  }

  /**
   * Queries the API and returns all user matches for a given search criteria.
   */
  get(params: { search?: string, profileIds?: string[], userIds?: string[], excludeTeamMembers?: boolean, roles?: string }): Observable<ProfileInfo[]> {
    return this.apiClient.get<ProfileInfo[]>(this.userMatchEndpoint, params)
      .pipe(map((users) => users.map((user) => new ProfileInfo(user))));
  }
}
