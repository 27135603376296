import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { ActivityAudit } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ActivityAuditService {
  resource = 'activity-audit';

  constructor(private apiService: ApiService) { }

  getActivityForMarketingOrder(marketingOrderId: string): Observable<ActivityAudit[]> {
    const url = `${this.resource}?marketingOrderId=${marketingOrderId}&sort=DESC`;
    return this.apiService.get(url);
  }
}
