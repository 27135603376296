export enum ProductCategory {
  PRINT = 'print',
  DIGITAL = 'digital',
  MULTIMEDIA = 'multimedia',
  MANUAL = 'manual',
  OTHER = 'other',
  VIDEO = 'video',
}

export const ProductCategories = [
  ProductCategory.MULTIMEDIA,
  ProductCategory.PRINT,
  ProductCategory.DIGITAL,
  ProductCategory.MANUAL,
  ProductCategory.OTHER,
  ProductCategory.VIDEO,
];
