import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

/**
 * The RouterService should be initialized upon App Initialization and will keep track of router history.
 * This service can be used in the future to help with navigation-type events
 * */
@Injectable({
  providedIn: 'root',
})
export class RouterService {
  private static readonly history = [];

  constructor(private router: Router) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(
        ({ urlAfterRedirects }: NavigationEnd) => RouterService.history.push(urlAfterRedirects),
        (error) => console.warn('Error in the RouterService subscription', error),
      );
  }

  public getHistory(): string[] {
    return RouterService.history;
  }

  public getPreviousUrl(fallback?: string): string {
    return RouterService.getPreviousUrl(fallback);
  }

  static async goBack(router: Router, fallback?: string) {
    const previousUrl = RouterService.getPreviousUrl(fallback);
    return await router.navigateByUrl(previousUrl);
  }

  static getPreviousUrl(fallback?: string): string {
    return RouterService.history[RouterService.history.length - 2] || fallback || '/';
  }
}
