import { FormControl, Validators } from '@angular/forms';
import { BaseForm } from './base.form';

export class PhotoInformationForm extends BaseForm {
  get photoUrl() { return this.get('photoURL').value; }

  constructor(value?: any) {
    super({
      photoUrl: new FormControl(null, [Validators.required]),
      videoPhotoUrl: new FormControl(),
      originalPhotoUrl: new FormControl(),
      metaData: new FormControl(),
    });

    if (value) {
      this.patchValue(value);
    }
  }
}
