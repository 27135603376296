import { Pipe, PipeTransform } from '@angular/core';
import { ConciergeObligationTypes } from '../models/concierge-obligation.types';

@Pipe({
  name: 'serviceTypeDisplay',
})
export class ServiceTypeDisplayPipe implements PipeTransform {
  /**
   * Transforms the status from the UI to a friendly display
   */
  transform(val: string, args: any) {
    if (!val) { return val; }

    switch (val) {
      case ConciergeObligationTypes.FULL:
        return 'Full-Service';
      case ConciergeObligationTypes.PARTIAL:
        return 'Partial-Service';
      case ConciergeObligationTypes.NONE:
        return 'Self-Service';
      default:
        return val;
    }
  }
}
