import {
  Component, OnInit, Input, EventEmitter, Output,
} from '@angular/core';
import { ProfileInfo } from '@lc/core';
import { MultiSelectDisplayOptions } from '../../../inputs';
import { OrderFiltersForm, OrderFilters } from '../models';

@Component({
  selector: 'lc-order-filters',
  templateUrl: './order-filters.component.html',
  styleUrls: ['./order-filters.component.scss'],
})
export class OrderFiltersComponent implements OnInit {
  readonly areaDisplayOptions = new MultiSelectDisplayOptions('Area', 'Areas', true);
  readonly statusDisplayOptions = new MultiSelectDisplayOptions('Status', 'Statuses', true);
  readonly dateDisplayOptions = new MultiSelectDisplayOptions('Dates', 'Dates', true);
  readonly assignmentDisplayOptions = new MultiSelectDisplayOptions(
    'Coordinator',
    'Coordinators',
    true,
  );

  @Input() form: OrderFiltersForm;

  @Input() areas: string[];

  @Output()
  readonly filter = new EventEmitter<OrderFilters>();

  private initialCount = 0;
  intitalFlag = false;

  constructor() {}

  ngOnInit(): void {
    this.initialCount = this.form.selectedAreas?.length || 0;
    if (this.initialCount === 0) {
      this.intitalFlag = true;
    }
  }

  onAreaClosed(event) {
    this.initialCount = this.form.selectedAreas?.length || 0;
    if (this.initialCount > 0) {
      this.intitalFlag = false;
    }
    if (!event && !this.intitalFlag) {
      if (this.form.areas.dirty) {
        this.onFilter();
        this.form.getControl('areas').markAsPristine();
      }
    }
  }

  onStatusClosed() {
    if (this.form.getControl('status').dirty) {
      this.onFilter();
      this.form.getControl('status').markAsPristine();
    }
  }

  onDateToggled(isOpened: boolean) {
    if (isOpened) { return; }
    if (this.form.getControl('startDate').dirty || this.form.getControl('endDate').dirty) {
      this.onFilter();
      this.form.getControl('startDate').markAsPristine();
      this.form.getControl('endDate').markAsPristine();
    }
  }

  onAssignmentsClosed() {
    if (this.form.getControl('assignments').dirty) {
      this.onFilter();
      this.form.getControl('assignments').markAsPristine();
    }
  }

  public onAgentsSelected(agents: ProfileInfo[]) {
    const agentIds = agents.map((agent) => agent.userId);
    this.form.coordinatorIds.setValue([]);
    this.form.agentIds.setValue(agentIds);
    this.onFilter();
  }

  public onCoordinatorsSelected(coordinators: ProfileInfo[]) {
    const coordinatorIds = coordinators.map((coordinator) => coordinator.userId);
    this.form.agentIds.setValue([]);
    this.form.coordinatorIds.setValue(coordinatorIds);
    this.onFilter();
  }

  onFilter(start?: Date, end?: Date) {
    this.form.startDate.setValue(start?.toString() ?? this.form.startDate.value?.toString());
    this.form.endDate.setValue(end?.toString() ?? this.form.endDate.value?.toString());
    this.filter.emit(this.form.value);
  }
}
