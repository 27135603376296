import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';

export class DialogAction {
  constructor(public text: string, public onClick?: () => any) {
  }
}

export abstract class PromptDialogReference {
  public abstract readonly result: Promise<any>;

  abstract close(): void;
}

export interface IDialogOptions {
  centered?: boolean;
  backdrop?: boolean;
  disableClose?: boolean;
  width?: string;
  height?: string;
  panelClass?: string;
  autoFocus?: boolean;
}

/**
 * This is the default PromptDialogService that will get registered, but must then be
 * overridden by the actual implementation. The reason is, we do not want @lc/core to
 * have to worry about the UI implementation details. Therefore, we are registering a
 * a default implementation that we can reference as an 'interface'
 */
@Injectable({ providedIn: 'root' })
export class PromptDialogService {
  constructor() { }

  async openComponent<TDialog, TData = any, TResponse = any>(dialog: ComponentType<TDialog> | TemplateRef<any>, data: TData, options?: IDialogOptions): Promise<TResponse> {
    throw new Error('Not Implemented: Make sure to register the PromptDialogService by calling SharedComponentsUiModule.forRoot() in the app.module');
  }

  openPrompt(title: string, message: string, primaryAction: DialogAction | string, otherActions?: DialogAction[] | string[], options?: IDialogOptions): Promise<DialogAction> {
    throw new Error('Not Implemented: Make sure to register the PromptDialogService by calling SharedComponentsUiModule.forRoot() in the app.module');
  }

  open<TDialog, TData = any, TResponse = any>(dialog: ComponentType<TDialog> | TemplateRef<any>, data: TData, options?: IDialogOptions): PromptDialogReference {
    throw new Error('Not Implemented: Make sure to register the PromptDialogService by calling SharedComponentsUiModule.forRoot() in the app.module');
  }
}
