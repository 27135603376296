import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Note } from '../models/note.model';
import { ApiService } from '../services/api.service';

const ROUTES = {
  get: (orderId: string) => `orders/${orderId}/notes`,
  create: (orderId: string) => `orders/${orderId}/notes`,
};

/**
 *  Contains all of the methods required to communicate with the Notes API.
 *  Should not contain methods with business logic. This is only for API communication
 */
@Injectable({
  providedIn: 'root',
})
export class MarketingOrderNotesClient {
  constructor(private client: ApiService) {
  }

  /**
   * Queries the API and returns all notes for a provided ownerId parameter
   */
  get(orderId: string): Observable<Note[]> {
    const route = ROUTES.get(orderId);
    return this.client.query$(route, { version: 'v2', model: Note });
  }

  /**
   * POSTs the notification form to the API. Ensuring proper form validation
   * @param form The notification form to post.
   */
  async post(orderId: string, body: Note): Promise<Note[]> {
    const route = ROUTES.get(orderId);
    return await this.client.create<Note[], Note>(route, body, { version: 'v2' })
      .then((notes) => notes.map((n) => new Note(n)));
  }
}
