import { Observable, of } from 'rxjs';
import { ErrorData } from '../errors/error-data';
import { GlobalErrorHandler } from '../errors/global-error-handler';
import { NotificationEventService, NotificationEvent } from '../notifications';

export abstract class BaseEffects {
  /**
   * Abstract constructor used to enfource the error handling of effects
   * @param errorHandler Error handler to use while processing errors
   */
  constructor(protected readonly errorHandler: GlobalErrorHandler, protected readonly eventService: NotificationEventService) {
  }

  processCatchError(type, payload, err, logToDatadog: boolean = true): Observable<ErrorData> {
    const errorPayload = new ErrorData(
      type,
      {
        original: payload,
        error: err,
      },
    );
    this.errorHandler.handleError(errorPayload, logToDatadog);

    if (this.eventService) {
      // We need to emit the error notification to any listeners
      const event = new NotificationEvent(type, err, payload);
      this.eventService.getEventEmitter().emit(event);
    }

    return of(errorPayload);
  }
}
