import { TeamProfile } from '../../models/team-profile.model';
import * as TeamActionTypes from './team.actions';

export const teamInitialState: TeamProfile = null;

export function teamReducer(state = teamInitialState, action: any): TeamProfile {
  switch (action.type) {
    case TeamActionTypes.GetTeamComplete.type: {
      return action.team;
    }
    case TeamActionTypes.UpdateTeam.type: {
      return action.payload;
    }
    case TeamActionTypes.UpdateTeamComplete.type: {
      console.log('UpdateTeamComplete:', action);
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
