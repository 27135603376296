import { Deserializable } from './deserializable.model';
import { PhoneNumber } from './phone-number.model';
import { TeamMember } from './team-member.model';
import { TeamContactPreferences } from './team-contact-preferences.model';
import { PhotoInformation } from './photo-information.model';
import { ContactInfo, ContactInfoFactory } from './contactinfo.model';
import { Audit } from './audit.model';
import { ImageModel } from './image.model';

export class TeamProfile implements Deserializable<TeamProfile> {
  _id: string;
  name: string;
  phone: PhoneNumber;
  email: string;
  websiteURL: string;
  ownerId: string;

  teamImage: ImageModel;

  // teamPhotoURL: string;
  teamPhoto: PhotoInformation;
  teamLogoURL: string;
  members: TeamMember[];
  contactPreferences: TeamContactPreferences;
  office: string;
  contactInfo: ContactInfo[];
  audit: Audit;

  constructor(input?: any) {
    this.deserialize(input || {});
  }

  deserialize(input: any): TeamProfile {
    Object.assign(this, input);
    if (this.contactInfo && this.contactInfo.length) {
      this.contactInfo = this.contactInfo.map((ci) => ContactInfoFactory.createContactInfo(ci));
    }
    return this;
  }
}
