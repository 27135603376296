import { Injectable } from '@angular/core';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { compareVersions } from './compare-versions';
import { AppService, Browser } from './app.service';

export enum UserAgentDeviceType {
  TABLET = 'tablet',
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}

@Injectable()
export class UserAgentService {
  private deviceInfo: DeviceInfo;

  public device: string;
  public osVersion: string;
  public compliant: boolean;

  constructor(private deviceService: DeviceDetectorService, private router: Router) {
    this.deviceInfo = deviceService.getDeviceInfo();
  }

  /**
   * Validates that the browser is compliant. If not compliant, will route the user to the browser-support page
   */
  doBrowserCheck() {
    if (!this.isCompliant()) {
      // if the browser-update message is loaded (which loads for browsers that can't run the Angular app) then hide it
      // and navigate to the LC browser support component
      const element: HTMLElement = document.getElementById('buorg') as HTMLElement;
      if (element) {
        element.hidden = true;
      }

      this.router.navigate(['/browser-support']);
    }
  }

  /**
   * Return the operating system based on the user agent string
   */
  public getOs(): string {
    return this.deviceInfo.os;
  }

  /**
   * Return the device type based on the user agent string
   */
  public getDeviceType(): string {
    if (this.device) return this.device;

    if (this.deviceService.isDesktop(this.deviceInfo.userAgent)) {
      this.device = UserAgentDeviceType.DESKTOP.toString();
      return this.device;
    }
    if (this.deviceService.isTablet(this.deviceInfo.userAgent)) {
      this.device = UserAgentDeviceType.TABLET.toString();
      return this.device;
    }
    if (this.deviceService.isMobile(this.deviceInfo.userAgent)) {
      this.device = UserAgentDeviceType.MOBILE.toString();
      return this.device;
    }
  }

  /**
   * Returns the entire user agent string
   */
  public getUserAgentString(): string {
    return this.deviceInfo.userAgent;
  }

  /**
   * Parses out the operating system version from the user agent string
   */
  public getOsVersion(): string {
    // DEVELOPER NOTE
    // the deviceDetectorService has an os_version field but it often returns 'unknown' so we're getting it ourselves.

    if (this.osVersion) return this.osVersion;

    let { device } = this.deviceInfo;
    if (device === 'Unknown' && this.deviceInfo.os === 'Mac') {
      device = 'Mac Desktop';
    }
    if (device === 'Unknown' && this.deviceInfo.os === 'Linux') {
      device = 'Linux Desktop';
    }
    if (device === 'Unknown' && this.deviceInfo.os === 'Windows') {
      device = 'Windows Desktop';
    }

    const osString = this.getOsString();

    let testString;
    let version;
    let parts;
    let os;
    switch (device) {
      case 'iPad':
      case 'iPhone':
        testString = 'iPhone OS ';
        version = osString.substring(osString.indexOf(testString) + testString.length, osString.indexOf('like Mac OS X'));
        break;
      case 'Mac Desktop':
        testString = 'Mac OS X ';
        version = osString.substr(osString.indexOf('Mac OS X ') + testString.length);
        break;
      case 'Linux Desktop':
        version = 'unknown';
        break;
      case 'Samsung':
        parts = osString.split(';');
        os = parts.find((part) => part.indexOf('Android') >= 0);
        testString = 'Android ';
        version = os.substr(os.indexOf(testString) + testString.length);
        break;
      case 'Windows Desktop':
        testString = 'Windows NT ';
        version = osString.substring(osString.indexOf(testString) + testString.length, osString.indexOf('\;'));
        break;
      default:
        version = 'unknown';
        break;
    }

    while (version.indexOf('_') >= 0) {
      version = version.replace('_', '.');
    }

    this.osVersion = version;
    return this.osVersion;
  }

  /**
   * Returns the name of the detected browser
   */
  public getBrowser(): string {
    const browserName = this.deviceInfo.browser;

    // fix for LC-2686.  the DeviceDetectorService returns 'MS-Edge' currently for Edge browsers.  We're converting it
    // to the more common 'Edge' value that people know
    if (browserName === 'MS-Edge') {
      return 'Edge';
    }
    return this.deviceInfo.browser;
  }

  /**
   * Returns the version of the detected browser
   */
  public getBrowserVersion(): string {
    return this.deviceInfo.browser_version;
  }

  /**
   * Returns a list of the supported browsers with versions and download URLs
   */
  public getSupportedBrowsers(os: string): Browser[] {
    return AppService?.get('userAgents')?.filter((browser) => browser?.os?.includes(os));
  }

  /**
   * Determines if the user-agent accessing the application is compliant with the supported versions.  It interrogates in order:
   * 1. browser manufacturer
   * 2. browser manufacturer version
   */
  public isCompliant(): boolean {
    if (this.compliant) return this.compliant;

    const compliantUAs = AppService.get('userAgents');

    // Retrieve specifics from the deviceInfo about the user agent
    const uaBrowser: string = this.getBrowser().toLowerCase();
    const uaBrowserVersion: string = this.getBrowserVersion();

    const compliantBrowser: Browser = compliantUAs?.find((ua) => ua.name.toLowerCase() === uaBrowser);
    if (!compliantBrowser) return false; // if the browser isn't supported return false;

    return compareVersions(uaBrowserVersion, compliantBrowser.version) >= 0;
  }

  private getOsString(): string {
    const userAgentString = this.deviceInfo.userAgent;

    const start = userAgentString.indexOf('(') + 1;
    const end = userAgentString.indexOf(')');
    return userAgentString.substr(start, end - start);
  }
}
