import { Injectable } from '@angular/core';
import { map, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { MarketingOrder, ProductInstance, ProductQR } from '../models';
import { ApiService } from './api.service';
import { UpdateCurrentProductState } from '../state-mgmt';

const ROUTES = {
  existing: (orderId: string, productIndex: number) => `orders/${orderId}/products/${productIndex}/qr`,
};

@Injectable({ providedIn: 'root' })
export class ProductQRService {
  constructor(private store: Store<any>, private apiService: ApiService) { }

  async updateProductQR(order: MarketingOrder, product: ProductInstance, qr: Partial<ProductQR>) {
    const productIndex = order.getProductIndex(product);
    const route = ROUTES.existing(order._id, productIndex);
    const updatedQr = await this.apiService.patch<ProductQR>(route, qr, { version: 'v2', model: ProductQR });
    product.qr = updatedQr;
    this.store.dispatch(UpdateCurrentProductState({ orderId: order._id, product }));
    return updatedQr;
  }

  async getProductQR(order: MarketingOrder, product: ProductInstance) {
    const productIndex = order.getProductIndex(product);
    const route = ROUTES.existing(order._id, productIndex);
    return await this.apiService.get<ProductQR>(route, undefined, { version: 'v2' }).pipe(
      map((res) => new ProductQR(res)),
      tap((qr) => {
        product.qr = qr;
        this.store.dispatch(UpdateCurrentProductState({ orderId: order._id, product }));
      }),
      take(1),
    ).toPromise();
  }
}
