<div class="modal-header">
  <button class="close-button" type="button" mat-icon-button (click)="onCancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="modal-body">
  <div mat-dialog-content class="text-center">
    <img class="image-container" *ngIf="welcomeDialogImage$ | async as welDialogImg" [src]="welDialogImg"/>
  </div>
</div>
