export type Extension = { signs: string[]; mime: string };

/**
 * Most common file extensions/signatures as documented here:
 * https://gist.github.com/qti3e/6341245314bf3513abb080677cd1c93b
 */
export const FILE_EXTENSIONS: { [extension: string]: Extension } = {
  // NOTE: Define the most common ones first.
  doc: {
    signs: [
      '0,0D444F43',
      '0,CF11E0A1B11AE100',
      '0,D0CF11E0A1B11AE1',
      '0,DBA52D00',
      '512,ECA5C100',
    ],
    mime: 'application/msword',
  },
  img: {
    signs: [
      '0,000100005374616E64617264204A6574204442',
      '0,504943540008',
      '0,514649FB',
      '0,53434D49',
      '0,7E742C015070024D52010000000800000001000031000000310000004301FF0001000800010000007e742c01',
      '0,EB3C902A',
    ],
    mime: 'application/octet-stream',
  },
  pdf: {
    signs: ['0,25504446'],
    mime: 'application/pdf',
  },
  ppt: {
    signs: [
      '512,006E1EF0',
      '512,0F00E803',
      '512,A0461DF0',
      '0,D0CF11E0A1B11AE1',
      '512,FDFFFFFF04',
    ],
    mime: 'application/vnd.ms-powerpoint',
  },
  pptx: {
    signs: ['0,504B030414000600'],
    mime: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  },
  xlsx: {
    signs: ['0,504B030414000600'],
    mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  docx: {
    signs: ['0,504B030414000600'],
    mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  },
  zip: {
    signs: [
      '0,504B0304',
      '0,504B030414000100630000000000',
      '0,504B0708',
      '30,504B4C495445',
      '526,504B537058',
      '29152,57696E5A6970',
    ],
    mime: 'application/zip',
  },
  m4a: {
    signs: [
      '0,00000020667479704D344120',
      '4,667479704D344120',
    ],
    mime: 'audio/mp4',
  },
  mp3: {
    signs: [
      '0,494433',
      '0,FFFB',
    ],
    mime: 'audio/mpeg',
  },
  gif: {
    signs: ['0,474946383961'],
    mime: 'image/gif',
  },

  jpg: {
    signs: ['0,FFD8'],
    mime: 'image/jpeg',
  },
  jpeg: {
    signs: ['0,FFD8'],
    mime: 'image/jpeg',
  },
  jpe: {
    signs: ['0,FFD8'],
    mime: 'image/jpeg',
  },
  jfif: {
    signs: ['0,FFD8'],
    mime: 'image/jpeg',
  },
  png: {
    signs: ['0,89504E47'],
    mime: 'image/png',
  },
  tiff: {
    signs: [
      '0,492049',
      '0,49492A00',
      '0,4D4D002A',
      '0,4D4D002B',
    ],
    mime: 'image/tiff',
  },
  mp4: {
    signs: [
      '0,000000146674797069736F6D',
      '0,000000186674797033677035',
      '0,0000001C667479704D534E56012900464D534E566D703432',
      '4,6674797033677035',
      '4,667479704D534E56',
      '4,6674797069736F6D',
    ],
    mime: 'video/mp4',
  },
  m4v: {
    signs: [
      '0,00000018667479706D703432',
      '0,00000020667479704D345620',
      '4,667479706D703432',
    ],
    mime: 'video/mp4',
  },
  mpeg: {
    signs: [
      '0,00000100',
      '0,FFD8',
    ],
    mime: 'video/mpeg',
  },
  mpg: {
    signs: [
      '0,00000100',
      '0,000001BA',
      '0,FFD8',
    ],
    mime: 'video/mpeg',
  },
  mov: {
    signs: [
      '0,00',
      '0,000000146674797071742020',
      '4,6674797071742020',
      '4,6D6F6F76',
    ],
    mime: 'video/quicktime',
  },
  ai: {
    signs: ['0,25504446'],
    mime: 'application/postscript',
  },
  rtf: {
    signs: ['0,7B5C72746631'],
    mime: 'application/rtf',
  },
  ico: {
    signs: ['0,00000100'],
    mime: 'image/vnd.microsoft.icon',
  },
  log: {
    signs: ['0,2A2A2A2020496E7374616C6C6174696F6E205374617274656420'],
    mime: 'text/plain',
  },
  tar: {
    signs: ['257,7573746172'],
    mime: 'application/x-tar',
  },
  bmp: {
    signs: ['0,424D'],
    mime: 'image/bmp',
  },
  wmf: {
    signs: [
      '0,010009000003',
      '0,D7CDC69A',
    ],
    mime: 'image/wmf',
  },
  eml: {
    signs: [
      '0,46726F6D3A20',
      '0,52657475726E2D506174683A20',
      '0,582D',
    ],
    mime: 'message/rfc822',
  },
  emf: {
    signs: ['0,01000000'],
    mime: 'image/emf',
  },
  pps: {
    signs: ['0,D0CF11E0A1B11AE1'],
    mime: 'application/vnd.ms-powerpoint',
  },
  p10: {
    signs: ['0,64000000'],
    mime: 'application/pkcs10',
  },
  pls: {
    signs: ['0,5B706C61796C6973745D'],
    mime: 'application/pls+xml',
  },
  eps: {
    signs: [
      '0,252150532D41646F62652D332E3020455053462D332030',
      '0,C5D0D3C6',
    ],
    mime: 'application/postscript',
  },
  ttf: {
    signs: ['0,0001000000'],
    mime: 'application/font-sfnt',
  },
  gz: {
    signs: ['0,1F8B08'],
    mime: 'application/gzip',
  },
  tgz: {
    signs: ['0,1F8B08'],
    mime: 'application/gzip',
  },
  psd: {
    signs: ['0,38425053'],
    mime: 'image/vnd.adobe.photoshop',
  },
  dwg: {
    signs: ['0,41433130'],
    mime: 'image/vnd.dwg',
  },
};
