import { Notification } from '../../models/notification.model';
import * as NotificationActionTypes from './notification.actions';

const initialState = [];

export function notificationReducer(state = initialState, action: any): Notification[] {
  switch (action.type) {
    case NotificationActionTypes.NotificationsUpdated.type:
      return action.notifications;
  }
  return state;
}
