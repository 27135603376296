import { Deserializable } from './deserializable.model';
import { PhoneNumber } from './phone-number.model';
import { Address } from './address.model';
import { AddressType } from './address.type';

// TODO make it a BusinessCard? Now that we are normailizing to ContactInfo representation
export class AddressInformation implements Deserializable<AddressInformation> {
  type?: AddressType;
  fullName: string;
  address: Address;
  company?: string;
  phoneNumber?: PhoneNumber;
  email?: string;
  constructor(input?: Partial<AddressInformation>) {
    this.deserialize(input || {});
  }

  deserialize(input: Partial<AddressInformation>): AddressInformation {
    Object.assign(this, input);

    if (this.address) {
      this.address = new Address(this.address);
    }
    if (this.phoneNumber) {
      this.phoneNumber = new PhoneNumber(this.phoneNumber);
    }
    return this;
  }
}
