import { ValidatorFn, AbstractControl } from '@angular/forms';

/**
 * The Email validator is different than Angular's Validators.Email because it
 * requires a domain extension (i.e. - '.com')
 */
export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }

    // TODO - LC-4510 - https://nrt-trial.atlassian.net/browse/LC-4510:
    // This should be using the 'constants.regex.emailRegex' from  @lc/common, however, due to how we are exporting our common module,
    // we cannot import it without also adding dependencies to many backend npm packages.
    const emailRegExp = /^(?:[a-z0-9!#$%&'*+\/=?^_{|}-]+(?:\.[a-z0-9!#$%&'*+\/=?^_{|}-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
    const isAccepted = emailRegExp.test(control.value);
    return isAccepted ? null : { email: true };
  };
}
