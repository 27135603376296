import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  catchError, filter, map, switchMap,
} from 'rxjs/operators';
import { BaseEffects } from '../base.effects';
import { ErrorData, GlobalErrorHandler } from '../../errors';
import { PhotographerService } from '../../services/photographer.service';
import { PhotoAgency } from '../../models';
import * as PhotographyAgencyActionTypes from './photography-agency.actions';
import { NotificationEventService } from '../../notifications';

@Injectable()
export class PhotographyAgencyEffects extends BaseEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private photographerService: PhotographerService,
    eventService: NotificationEventService,
    errorHandler: GlobalErrorHandler,
  ) {
    super(errorHandler, eventService);
  }

  getAllPhotographyAgencies: Observable<PhotoAgency[]> = createEffect(() => this.actions$.pipe(
    ofType(PhotographyAgencyActionTypes.GetAllPhotographyAgencies),
    switchMap((action) => {
      return this.photographerService.retrievePhotographers().pipe(
        catchError((err) => this.processCatchError(PhotographyAgencyActionTypes.GetAllPhotographyAgencies, action.payload, err)),
      );
    }),
    filter((order: any) => !(order instanceof ErrorData)), // Don't emit any events if it errors out
    map((photoAgencies: PhotoAgency[]) => {
      // Only dispatch event if succeeds properly. Otherwise downstream effects happen
      this.store.dispatch(PhotographyAgencyActionTypes.GetAllPhotographyAgenciesComplete({ payload: photoAgencies }));
      return photoAgencies;
    }),
  ), { dispatch: false });
}
