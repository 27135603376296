import { AbstractControl, FormArray } from '@angular/forms';
import { BaseForm } from './base.form';

export class LCFormArray<TModel, TForm extends AbstractControl = BaseForm<TModel>> extends FormArray {
  private _models: TModel[];
  public get models(): TModel[] {
    return this._models;
  }
  readonly originalValues: TModel[];

  get forms(): TForm[] {
    return (this.controls as any) as TForm[];
  }

  /** Returns whether this control is dirty or not */
  get dirty(): boolean {
    return this.controls.some((child) => child.dirty) || this.controls?.length !== this.models?.length;
  }

  /**
   * Returns the dirty fields from this control.
   * For some reason .value was not retuning the properly modified fields
   */
  getDirty() {
    if (this.dirty) {
      return this.controls.map((control) => control.value);
    }
  }

  constructor(values: TModel[], private formCreator: (value: TModel, index?: number) => AbstractControl) {
    super([]);
    this.reset(values);
  }

  elementAt(index: number): TForm {
    return this.forms[index];
  }

  addControl(value?: TModel) {
    const newForm = this.createForm(value, this.forms.length);
    if (newForm instanceof BaseForm) {
      newForm.isNew = true;
      newForm.isEdit = true;
    }
    this.push(newForm);
    return newForm;
  }

  addForm(form: BaseForm<TModel>) {
    this.push(form);
  }

  deleteControl(form: BaseForm<TModel>) {
    const indexOfControl = this.controls.indexOf(form);
    if (indexOfControl >= 0) {
      this.removeAt(indexOfControl);
    }
    this.markAsDirty();
  }

  getValue() {
    return this.value as TModel[];
  }

  setValue(values: any[], options?: { onlySelf?: boolean; emitEvent?: boolean; }) {
    const controls = (values || []).map((value, index) => this.createForm(value, index));
    this.controls.splice(0, this.controls.length, ...controls);
    super.setValue(values, options);
  }

  reset(models?: TModel[]) {
    if (models) { this._models = models; }
    this.controls = (this.models || []).map((value, index) => this.createForm(value, index));
    this.updateValueAndValidity();
  }

  markAllAsDirty() {
    this.each((control) => BaseForm.markAllAsDirty(control));
  }

  each(predicate: (control: AbstractControl) => void) {
    this.forms.forEach((control) => predicate(control));
  }

  private createForm(value: TModel, index: number) {
    const form = this.formCreator(value, index);
    form.valueChanges.subscribe(() => this.updateValueAndValidity());
    return form;
  }
}
