import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../models/user.model';
import * as UserActions from './user.actions';

@Injectable()
export class UserEffects {
  constructor(private actions$: Actions) {
  }

  doNothing: Observable<User> = createEffect(() => this.actions$.pipe(
    ofType(UserActions.LoadLoggedInUser),
    map((action) => action.user),
  ), { dispatch: false });
}
