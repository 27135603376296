/**
 * For products
 *
 * PENDING => order placed in system. When order is submitted with photo order, this is the state set
 * TODO => product ready to be created. order received by photographer (maybe logged into system to see orders)
 * CREATING => order accepted and started
 * PROOFING => Mods to order or product
 * DELIVERY => order "complete"
 * DONE => Completed workflow
 *
 */
export enum ServiceStatusType {
  PENDING = 'PENDING',
  TODO = 'TO_DO',
  CREATING = 'CREATING',
  PROOFING = 'PROOFING',
  READY = 'READY',
  DELIVERY = 'DELIVERY',
  DONE = 'DONE',
  REORDER = 'REORDER',
}

export enum ServiceActions {
  START = 'START',
  COMPLETE = 'COMPLETE',
  DELIVER = 'DELIVER',
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
  CLOSE = 'CLOSE',
  CANCEL = 'CANCEL',
  REVIEW = 'REVIEW',
  FORCECHANGE = 'FORCE',
  PARTIALREVIEW = 'PARTIALREVIEW',
}
