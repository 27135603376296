import { Deserializable } from './deserializable.model';

export class VIPOrder implements Deserializable<VIPOrder> {
  isVIPOrderByListPriceAndPackage: boolean;
  isVIPOrderByVIPProfile: boolean;

  /**
     * true: Coordinator manually marked this order as VIP
     * false: Coordinator manually marked this order as not VIP
     * undefined: Has not been manually changed at all
     */
  isVIPOrderByManualFlag?: boolean;

  constructor(input?: any) {
    this.deserialize(input || {});
  }

  deserialize(input: any): VIPOrder {
    Object.assign(this, input);
    return this;
  }
}
