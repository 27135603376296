// tslint:disable: rxjs-no-sharereplay
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class FormService {
  private formErrorDetectedSubject: BehaviorSubject<any> = new BehaviorSubject<any>({
    formId: null,
    isDisabled: false,
  });
  formErrorDetected: Observable<any> = this.formErrorDetectedSubject;

  constructor() { }

  disableFormElements(formId) {
    this.formErrorDetectedSubject.next({
      formId,
      isDisabled: true,
    });
  }

  enableAllForms() {
    this.formErrorDetectedSubject.next({
      formId: null,
      isDisabled: false,
    });
  }

  disableSaveBtn() {
    this.formErrorDetectedSubject.next({
      isDisabled: true,
    });
  }

  enableSaveBtn() {
    this.formErrorDetectedSubject.next({
      isDisabled: false,
    });
  }
}
