import { Deserializable } from './deserializable.model';
import { ProductInstance } from './product-instance.model';

export const ProductTitles = {
  photograhy: 'Professional Photography',
  brochure: 'Brochure',
};

export class PackageInstance implements Deserializable<PackageInstance> {
  title: string;
  description: string;
  code: string;
  thumbnailUri: string;
  price: number;
  position: number;
  products: ProductInstance[] = [];

  constructor(data?: any) {
    this.deserialize(data || {});
    const productObjects = [];
    this.products.forEach((prod) => {
      productObjects.push(new ProductInstance(prod));
    });
    this.products = productObjects;
  }

  deserialize(input: any): PackageInstance {
    Object.assign(this, input);

    if (input.products) {
      this.products = input.products.map((pr) => new ProductInstance(pr));
    }

    return this;
  }

  // TODO use codes instead of titles to find products
  getPhotographyProduct() {
    return this.products.find((p) => p.title === ProductTitles.photograhy);
  }

  getBrochureProduct() {
    return this.products.find((p) => p.title === ProductTitles.brochure);
  }

  getProduct(productCode: string): ProductInstance {
    return this.products.find((aProduct) => aProduct.code === productCode);
  }
}
