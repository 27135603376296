import { Deserializable } from './deserializable.model';

export class Award implements Deserializable<Award> {
  awardCode: string;
  awardDescription: string;
  awardYear: string;

  constructor(input?: any) {
    this.deserialize(input || {});
  }

  deserialize(input: any): Award {
    Object.assign(this, input);
    return this;
  }
}
