import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { SELECTEDPROFILE, GetProfile } from './profile.actions';
import { Profile } from '../../models/profile.model';

@Injectable()
export class ProfileResolve {
  readonly profile$: Observable<Profile>;

  constructor(private store: Store<any>) {
    this.profile$ = this.store.select(SELECTEDPROFILE);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const { profileId } = route.params;
    // TODO - throw error to be handled by global error handler
    if (!profileId) {
      return of(null);
    }

    this.store.dispatch(GetProfile({ payload: profileId }));
    return of(null);
  }
}
