/**
 * A log entry constructed from the logger settings and the logged message
 */
export class LogEntry {
  public date: Date;
  public level: string;
  public category: string;
  public message: string;

  constructor(level: string, category: string, message: string) {
    this.level = level;
    this.category = category;
    this.message = message;
    this.date = new Date();
  }
}
