import { Extension, FILE_EXTENSIONS } from '../constants/file-extensions.constants';

export interface FileType { mimeType: string, suffix: string }

export class FileHelper {
  private static FILE_TYPES: FileType[];
  private static FILE_SIGNATURES: (FileType & { regex: RegExp })[];

  /**
   * Used for more accuracy, not efficiency.
   * Looks up the corresponding types based on email signature.
   * We do this for scenarios where we don't want to rely on the
   * file name and type and want to use the signature for more certainty.
   * @param file the file to read the signature from
   * @returns The corresponding file type
   */
  static async getFileTypeFromSignature(file: File, options?: { validation?: 'strict' }) {
    if (!file) { return undefined; }

    return new Promise<FileType>((resolve) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        // We specifically want to load by signature first, in some cases
        const fileSignature = FileHelper.getSignature(e.target.result as ArrayBuffer);
        const matchingFileTypes = FileHelper.lookupFileTypeBySignature(fileSignature);

        if (!matchingFileTypes && options?.validation === 'strict') {
          return resolve(undefined);
        }

        if (matchingFileTypes.length === 1) {
          return resolve(matchingFileTypes[0]);
        }

        if (matchingFileTypes.length > 1) {
          const fileExtension = FileHelper.getFileExtension(file);
          const singleMatch = matchingFileTypes.find((t) => t.suffix === fileExtension);

          if (singleMatch) {
            return resolve(singleMatch);
          }
          console.warn(`FileHelper: Couldn't find a single matching File Type for file with extension ".${fileExtension}" and signature "${fileSignature}"`);
        }

        console.warn(`
          FileHelper: Could not locate file type ${file.type} from signature.
          Falling back to default fileType
        `);

        const fileType = FileHelper.getFileType(file);
        return resolve(fileType);
      };

      // Read the first 4 characters of the file
      const blob = file.slice(0, 4);
      fileReader.readAsArrayBuffer(blob);
    });
  }

  /**
   * Reads the file based on the
   * @param file
   * @returns
   */
  static getFileType(file: File): FileType {
    const suffix = FileHelper.getFileExtension(file);

    // Try to just looking it up by the file type
    const matchingFile = FileHelper.lookupFileTypeByMime(file.type, suffix);
    if (matchingFile) { return matchingFile; }

    // Otherwise just build fileType based on fileInfo
    return { mimeType: file.type, suffix: suffix || 'unknown' };
  }

  /**
   * Parses a filename and returns the file extension
   *
   * @param file File
   * @returns A string representation of the file extension
   */
  static getFileExtension(file: File): string {
    const fileNameArray = file.name?.split('.') || [];
    return fileNameArray[fileNameArray.length - 1];
  }

  /**
   * Extract a signature from the first 4 bytes of the data.
   * @param data
   * @returns {string}
   */
  private static getSignature(data: ArrayBuffer) {
    try {
      const uint = new Uint8Array(data);
      const bytes = [];

      // NOTE: 4 Bites handles most file signatures
      // See: https://stackoverflow.com/a/13727712/1529214
      for (let i = 0; i < 4; i++) {
        let str = uint[i].toString(16);
        str = (str.length === 1) ? `0${str}` : str;
        bytes.push(str);
      }

      return bytes.join('').toUpperCase();
    } catch (error) {
      console.warn('FileHelper: Could not read file signature. It may be an empty or invalid file.');
      return 'UNKNOWN SIGNATURE';
    }
  }

  static lookupFileTypeByMime(mimeType: string, extension: string): FileType {
    FileHelper.FILE_TYPES = FileHelper.FILE_TYPES || Object.keys(FILE_EXTENSIONS).map((extension) => ({
      mimeType: FILE_EXTENSIONS[extension].mime, suffix: extension,
    }));

    const matchingTypes = FileHelper.FILE_TYPES.filter((type) => !mimeType || type.mimeType === mimeType);
    const matchingSuffixes = matchingTypes.filter((type) => type.suffix === extension);

    // We prefer the matching with the suffix, however, if we cant find the suffix,
    // just use the matching mime type
    let fileTypes: { mimeType: string, suffix: string }[];

    if (matchingSuffixes?.length) {
      fileTypes = matchingSuffixes;
    } else {
      console.warn(`FileHelper: Could not find a matching MIMEType for file extension ".${extension}"`);

      fileTypes = matchingTypes;

      if (fileTypes.length > 1) {
        // If this warning is logged we will likely encounter an error later on the callstack, since we are trying to use
        // an extension that does not match the extension of the provided file.
        console.warn(`FileHelper: There are multiple FILE_EXTENSIONs that match the '${mimeType}' MIMEType. Will try assuming ".${fileTypes[0]?.suffix}" as the extension for provided file.`);
      }
    }

    return fileTypes?.length ? fileTypes[0] : undefined;
  }

  static lookupFileTypeBySignature(fileSignature: string): FileType[] | undefined {
    FileHelper.FILE_SIGNATURES = FileHelper.FILE_SIGNATURES || Object.keys(FILE_EXTENSIONS).flatMap((extensionKey, i, arr) => {
      const extension: Extension = FILE_EXTENSIONS[extensionKey];
      return extension.signs.map((sign) => {
        const parts = sign.split(',');
        const offset = +parts[0];
        const signature = parts[1];
        const regex = new RegExp(`.{${offset || 0}}?${signature}`);

        return { regex, mimeType: extension.mime, suffix: extensionKey };
      });
    });

    const fileTypes = FileHelper.FILE_SIGNATURES.filter((lookup) => lookup.regex.test(fileSignature));

    if (fileTypes.length > 1) {
      console.warn(`FileHelper: There are multiple FILE_EXTENSIONs that match the signature '${fileSignature}'`);
    }

    return fileTypes.length ? fileTypes.map((t) => { return { mimeType: t.mimeType, suffix: t.suffix }; }) : undefined;
  }
}
