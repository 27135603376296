export const options = {
  rootUrl: 'https://app.five9.com/consoles/',
  type: 'chat',
  title: 'Product Studio',
  tenant: 'RealogyHoldings',
  profiles: 'Chat - Design Concierge V2',
  showProfiles: false,
  surveyOptions: {
    showComment: true,
    requireComment: false,
  },
  fields: {
    name: {
      value: '',
      show: true,
      label: 'Name',
    },
    email: {
      value: 'john.stone@nrtllc.com',
      show: true,
      label: 'Email',
    },
    UserName: {
      value: '',
      show: false,
      label: 'UserName',
    },
    MetroName: {
      value: '',
      show: false,
      label: 'MetroName',
    },
    MetroID: {
      value: '',
      show: false,
      label: 'MetroID',
    },
    OfficeName: {
      value: '',
      show: false,
      label: 'OfficeName',
    },
    OfficeID: {
      value: '',
      show: false,
      label: 'OfficeID',
    },
    OktaID: {
      value: '',
      show: false,
      label: 'OktaID',
    },
    UserType: {
      value: '',
      show: false,
      label: 'UserType',
    },
    Role: {
      value: '',
      show: false,
      label: 'Role',
    },
  },
  playSoundOnMessage: true,
  allowCustomerToControlSoundPlay: false,
  showEmailButton: true,
  showPrintButton: false,
  allowUsabilityMenu: true,
};
