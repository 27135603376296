import { Deserializable } from './deserializable.model';

/*
 * Phone number type used for dropdown selects and labeling
 */
export enum PhoneType {
  mobile = 'mobile',
  direct = 'direct',
  office = 'office',
}

/**
 * The friendly UI display and values for the Request Types
 */
export const PhoneTypeDisplays: { display: string, value: PhoneType }[] = [
  { display: 'Mobile', value: PhoneType.mobile },
  { display: 'Direct', value: PhoneType.direct },
  { display: 'Office', value: PhoneType.office },
];

export class PhoneNumber implements Deserializable<PhoneNumber> {
  type: PhoneType;
  number: string;
  extension: string;
  priority: number;
  prefix: string;
  primary = false;

  constructor(input?: any) {
    this.deserialize(input || {});
  }

  deserialize(input: any): PhoneNumber {
    Object.assign(this, input);

    return this;
  }
}
