import { Injectable, TemplateRef } from '@angular/core';
import {
  DialogAction, PromptDialogService as CorePromptDialogService, PromptDialogReference, IDialogOptions,
} from '@lc/core';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ComponentType } from '@angular/cdk/portal';
import { lastValueFrom } from 'rxjs';
import { PromptDialogComponent, PromptDialogData } from './prompt-dialog.component';

export class MatPromptDialogReference extends PromptDialogReference {
  public readonly result: Promise<any>;

  constructor(public dialogRef: MatDialogRef<any>) {
    super();
    this.result = dialogRef.afterClosed().toPromise();
  }

  close() {
    this.dialogRef.close();
  }
}

/**
 * Concrete implementation of the CorePromptDialogService.
 * Gets registered as the core implementation when calling SharedComponentsUiModule.forRoot()
 * */
@Injectable()
export class PromptDialogService implements CorePromptDialogService {
  constructor(private modal: MatDialog) { }

  openPrompt(title: string, message: string, primaryAction: DialogAction | string, otherActions?: DialogAction[] | string[], options?: IDialogOptions): Promise<DialogAction> {
    const reference = this.getPromptReference(title, message, primaryAction, otherActions, options);
    return reference.result;
  }

  async openComponent<TDialog, TData = any, TResponse = any>(dialog: ComponentType<TDialog> | TemplateRef<any>, data: TData, options?: IDialogOptions): Promise<TResponse> {
    const reference = this.openDialog<TDialog, TData, TResponse>(dialog, data, options);
    const afterClosed$ = reference.afterClosed().pipe();
    return await lastValueFrom(afterClosed$);
  }

  open<TDialog, TData = any, TResponse = any>(dialog: ComponentType<TDialog> | TemplateRef<any>, data: TData, options?: IDialogOptions): MatPromptDialogReference {
    const ref = this.openDialog<TDialog, TData, TResponse>(dialog, data, options);
    return new MatPromptDialogReference(ref);
  }

  private getPromptReference(title: string, message: string, primaryAction: DialogAction | string, otherActions?: DialogAction[] | string[], options?: IDialogOptions): MatPromptDialogReference {
    otherActions = otherActions || [];

    // Allow the actions to just be text, but convert them to dialogAction objects
    const primary = this.toAction(primaryAction);
    const others = (<any[]>otherActions).map((action: DialogAction | string) => this.toAction(action));

    const dialogRef = this.openDialog(PromptDialogComponent, new PromptDialogData(title, message, primary, others), options);
    const component: PromptDialogComponent = dialogRef.componentInstance;

    // Initialize the dialog with the properties
    component.close.subscribe((action) => dialogRef.close(action), (error) => { throw new Error(error); });
    return new MatPromptDialogReference(dialogRef);
  }

  private openDialog<TDialog, TData = any, TResponse = any>(dialog: ComponentType<TDialog> | TemplateRef<any>, data: TData, options?: IDialogOptions): MatDialogRef<TDialog, TResponse> {
    const reference = this.modal.open<TDialog, TData, TResponse>(dialog, {
      disableClose: options?.disableClose ?? true,
      data,
      hasBackdrop: options?.backdrop ?? true,
      width: options?.width ?? '500px',
      height: options?.height,
      maxWidth: '90vw',
      maxHeight: '90vh',
      panelClass: options?.panelClass,
      autoFocus: options?.autoFocus ?? false,
    });
    return reference;
  }

  private toAction(action: DialogAction | string) {
    // Convert string actions to DialogAction if necissary
    return action instanceof DialogAction ? action : new DialogAction(action);
  }
}
