import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { PromptDialogService, LaunchDarklyService, FeatureFlags } from '../services';

@Injectable()
export class AdminAppFormGuard {
  // subtypes that are exempt from the feature flag check
  static exemptSubTypes = ['template-specifications', 'template-offerings'];

  constructor(
    private readonly router: Router,
    private featureFlagService: LaunchDarklyService,
    private promptDialog: PromptDialogService,
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return await this.performCheck(route);
  }

  async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
    return await this.performCheck(route);
  }

  async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return await this.performCheck(childRoute);
  }

  private async performCheck(route: any): Promise<boolean> {
    const subType = route.paramMap.get('subType');

    const isEnabled = await this.featureFlagService.isFeatureEnabled(FeatureFlags.SUPER_ADMIN);
    if (isEnabled) { return true; }

    const isExempt = AdminAppFormGuard.exemptSubTypes.includes(subType);
    if (isExempt) { return true; }

    await this.promptDialog.openPrompt('No Access', 'This feature is not available for the current user. Please try a different user.', 'Ok');

    this.router.navigate(['/']);

    return false;
  }
}
