import {
  Component, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChanges,
} from '@angular/core';
import {
  Dimensions, PhotoService, CroppedImage, PhotoType, UploadService, UploadStatus, ToasterService,
} from '@lc/core';
import { PhotoCropperDirective, PhotoCropSettings, AspectRatios } from '../directives/photo-cropper.directive';
import { ImageCropperDialogData } from '../dialogs';

@Component({
  selector: 'lc-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperComponent implements OnChanges {
  maxZoom: number = 10;
  minZoom: number = 0.1;
  zoom: number = 0;
  isLoaded: boolean;
  aspectRatios = AspectRatios;
  dimensions: Dimensions;

  /** The url of the image */
  @Input() photo: string;

  /** The settings used for this photo cropper */
  @Input() settings: PhotoCropSettings;

  /** Should the display be round */
  @Input() isRound: boolean;

  /** The directive used to crop the photo */
  @ViewChild(PhotoCropperDirective) directive: PhotoCropperDirective;

  /** The loaded event indicates that the cropper has been initialized */
  @Output() readonly cropperLoaded = new EventEmitter<boolean>();

  constructor(
    private photoService: PhotoService,
    private uploadService: UploadService,
    private toasterService: ToasterService,
  ) { }

  /** Indicates when the cropper is loaded  */
  onLoaded() {
    this.isLoaded = true;
    this.cropperLoaded.emit(true);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.settings) {
      this.updateAspectRatio();
    }
  }

  /**
   * Zoom out by a step of 10%. Do not let it go below 0
   */
  onZoomOut() {
    this.zoom = Math.max(this.minZoom, this.zoom - 0.1);
    this.onZoomChanged();
  }

  /**
   * Zoom in by a step of 10% with the max of 1
   */
  onZoomIn() {
    this.zoom = Math.min(this.maxZoom, this.zoom + 0.1);
    this.onZoomChanged();
  }

  /**
   * Update the cropper with the new zoom
   */
  onZoomChanged() {
    this.directive.zoomTo(this.zoom);
  }

  /**
   * Crops the image and returns the CroppedPhoto. Uploads the image to S3 if requested
   * */
  async onCrop(data?: ImageCropperDialogData, photoType?: PhotoType) {
    const crop = this.settings.findExistingCrop() || new CroppedImage({ aspectRatio: this.settings.aspectRatio });
    try {
      crop.dimensions = this.directive.getCroppedDimensions();
    } catch (err) {
      console.error('Failed to get cropped photo data from photo cropper directive.');
      this.toasterService.showError('Failed to get cropped photo data. Please refresh the page and try again.');
      return;
    }

    crop.metadata = {
      // Other metadata for other purposes
      aspectRatio: this.settings.aspectRatio,
    };

    // Check if we should upload to s3
    if (data.saveInS3 && (!crop.url || this.dimensionsChanged(this.dimensions, crop.dimensions))) {
      if (photoType === PhotoType.LISTING_PHOTO) {
        const cropRequestData = {
          originalFileURL: data.requests[0].imageUrl,
          croppedData: crop,
        };
        const cropResponseData = await this.uploadService.uploadCropData(cropRequestData).toPromise();
        crop.url = cropResponseData.croppedImageURl;
        crop.dimensions.s3Url = crop.url;
      } else if (photoType === PhotoType.PROFILE_PHOTO) {
        const originalFileName = this.photo.split('_-_').pop() || 'cropped-photo';
        const croppedFile = await this.directive.getCroppedFile(originalFileName);
        const filesToPresign = { files: [{ name: originalFileName, mimetype: croppedFile.type }] };
        const presignData = await this.uploadService.getPresignedUrlForProfile(filesToPresign).toPromise();
        const loadArray = [];
        for (const fileIter in presignData) {
          if (fileIter) {
            loadArray.push({
              file: croppedFile,
              url: presignData[fileIter],
              publicUrl: presignData[`${fileIter}_cloudfront-url`],
              name: fileIter,
            });
          }
        }
        const thisUpload = new UploadStatus(croppedFile);
        thisUpload.presignURL = loadArray[0].url;
        await this.photoService.preSignUploadWithStatus(thisUpload, 0).toPromise();
        crop.url = loadArray[0].publicUrl ?? loadArray[0].url.substring(0, loadArray[0].url.indexOf('?'));
        crop.dimensions.s3Url = crop.url;
      }
    }
    return crop;
  }

  /** Finds and selects current cropped dimensions for the current aspect ratio */
  updateAspectRatio() {
    this.dimensions = this.settings.findSelectedDimensions();
    setTimeout(() => this.directive.reload(), 200);
  }

  /** Checks if the crop dimensions have changed */
  private dimensionsChanged(oldDimensions: Dimensions, newDimensions: Dimensions) {
    return oldDimensions?.cropDimensions?.fromTopPercentage !== newDimensions?.cropDimensions?.fromTopPercentage
      || oldDimensions?.cropDimensions?.fromLeftPercentage !== newDimensions?.cropDimensions?.fromLeftPercentage
      || oldDimensions?.cropDimensions?.widthPercentage !== newDimensions?.cropDimensions?.widthPercentage
      || oldDimensions?.cropDimensions?.heightPercentage !== newDimensions?.cropDimensions?.heightPercentage;
  }
}
