import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatDividerModule } from '@angular/material/divider';
import { ScrollingModule } from '@angular/cdk/scrolling';

// Layout Modules
import { AuthGuard } from '@lc/core';
import { PageHeaderModule } from './page-header/page-header.module';

// Layout Components
import { ToolbarComponent } from './toolbar/toolbar.component';
import { NotificationsMenuComponent } from './toolbar/notifications-menu/notifications-menu.component';
import { FooterComponent } from './footer/footer.component';
import { PrivacyPolicyPageComponent } from './privacy-policy-page/privacy-policy-page.component';
import { TermsOfUsePageComponent } from './terms-of-use-page/terms-of-use-page.component';

const angularModules = [
  CommonModule, RouterModule, MatBadgeModule,
  MatDividerModule, MatToolbarModule, MatMenuModule, MatButtonModule, MatIconModule,
  MatProgressSpinnerModule, ScrollingModule,
];
const internalModules = [PageHeaderModule];
const internalComponents = [NotificationsMenuComponent];
const externalComponents = [ToolbarComponent, FooterComponent, PrivacyPolicyPageComponent, TermsOfUsePageComponent];

@NgModule({
  declarations: [...internalComponents, ...externalComponents],
  exports: [...externalComponents, ...internalModules],
  imports: [
    ...angularModules,
    ...internalModules,
    RouterModule.forChild([
      {
        path: 'privacy-policy',
        component: PrivacyPolicyPageComponent,
        canActivate: [AuthGuard],

      },
      {
        path: 'tos',
        component: TermsOfUsePageComponent,
        canActivate: [AuthGuard],
      },
    ]),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UILayoutModule { }
