import { Deserializable } from './deserializable.model';
import { Audit } from './audit.model';

export enum VendorOrderStatus {
  OPEN = 'OPEN',
  SERVICING = 'SERVICING',
  SUBMITTING = 'SUBMITTING',
  CLOSED = 'CLOSED',
  CANCELLED = 'CANCELLED',
  REJECTED = 'REJECTED',
}

export enum VendorOrderTypes {
  VIDEO = 'video',
  ADVERTISING = 'advertising',
  PRINT = 'print',
}

export enum VendorEventTypes {
  INITIAL_SEND = 'initial-send',
  ORDER_CONFIRMATION = 'order-confirmation',
  ORDER_COMPLETE = 'order-complete',
  ORDER_UPDATE = 'order-update',
  ORDER_REJECTED = 'order-rejected',
}

export interface HttpRequest {
  url?: string;
  body?: any;
  query?: any;
  method?: string;
  headers?: any;
}

export interface HttpResponse {
  code?: string;
  body?: any;
}

export class VendorOrderStatusObj {
  status: VendorOrderStatus;
  notes: string;
}

export class VendorEvent implements Deserializable<VendorEvent> {
  type: VendorEventTypes;
  description?: string;
  direction: 'inbound' | 'outbound';
  request: HttpRequest;
  response: HttpResponse;
  date: Date;

  constructor(input?: any) {
    this.deserialize(input);
  }

  deserialize(input: any): VendorEvent {
    Object.assign(this, input);

    return this;
  }
}

export class VendorServiceOrder implements Deserializable<VendorServiceOrder> {
  type: VendorOrderTypes;
  status: string | VendorOrderStatusObj;

  /** User this printJob pertains to */
  userId?: string;

  /** Marketing order this printJob pertains to */
  marketingOrderId?: string;

  /** Product code this printJob pertains to */
  productCode?: string;

  /** vendorOrderId of the printJob */
  vendorOrderId: string;

  isreorder: boolean;

  events: VendorEvent[];

  orderedAt: Date;

  audit: Audit;

  details: any;

  corefact_mailing_list: string;

  corefact_imb_url: string;

  constructor(input?: any) {
    if (!input?.status) {
      input.status = VendorOrderStatus.OPEN;
    }

    this.deserialize(input);
  }

  deserialize(input: any): VendorServiceOrder {
    Object.assign(this, input);

    return this;
  }
}
