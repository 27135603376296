import {
  Component, Input, OnChanges, SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'lc-character-count',
  templateUrl: './character-count.component.html',
  styleUrls: ['./character-count.component.scss'],
})
export class CharacterCountComponent implements OnChanges {
  @Input() characterCount: number;

  @Input() userInput: string;

  // Classnames are used to identify the styles within this components stylesheet.
  // If an different style is needed, add it to this components scss file.
  @Input() className: string;

  // String to be displayed to the user as they type.
  charactersRemainingString: string;

  // Classes to apply to give user helpful feedback
  errorClass: string = '';

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.userInput) {
      this.charactersRemainingString = `${this.userInput ? this.userInput?.length : '0'}  / ${this.characterCount}`;
      this.characterCountError();
    }
  }

  /**
   * Applies an 'error' class that will turn the background
   * color / font color red and white respectively to demonstrate
   * to the user they have met or exceeded the maximum characters allowed.
   * This will also display by how many characters they have exceeded.
   */
  characterCountError() {
    if (this.userInput && this.userInput?.length > this.characterCount) {
      this.errorClass = 'error';
      this.charactersRemainingString = `Maximum exceeded by ${Math.abs(this.characterCount - this.userInput.length)}`;
    } else {
      this.errorClass = '';
    }
  }
}
