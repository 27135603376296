export class InputOptionsService {
  constructor() { }

  filterOptions(options: any[], callback: (option?: any) => boolean) {
    if (!options) {
      return;
    }

    if (!callback || typeof callback !== 'function') {
      return options;
    }

    return options.filter((option) => callback(option));
  }
}
