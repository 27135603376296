// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Internal Component
import { PageHeaderComponent } from './page-header.component';

const angularModules = [CommonModule];

@NgModule({
  declarations: [PageHeaderComponent],
  exports: [PageHeaderComponent],
  imports: [...angularModules],
})
export class PageHeaderModule {}
