import { Deserializable } from './deserializable.model';

export type ShippingRateResponse = ShippingOption[];

export const ShippingCarriers = {
  UPS: 'UPS',
  USPS: 'United States Postal Service',
} as const;

export type ShippingCarrier = keyof typeof ShippingCarriers;
export type ShippingCarrierDisplay = typeof ShippingCarriers[ShippingCarrier];

// NOTE: See https://danielbarta.com/literal-iteration-typescript/
export const ShippingServiceDisplays = {
  NextDayAir: 'Next Day Air',
  '2ndDayAir': '2nd Day Air',
  Ground: 'Ground',
  '3DaySelect': '3 Day Select Shipping',
  NextDayAirSaver: 'Next Day Air - Saver',
  NextDayAirEarlyAM: 'Next Day Air - Early Morning',
  '2ndDayAirAM': '2nd Day Air - Morning',
} as const;

export type ShippingService = keyof typeof ShippingServiceDisplays;
export type ShippingServiceDisplay = typeof ShippingServiceDisplays[ShippingService];

export class ShippingOption {
  /** The carrier that will be used */
  readonly carrier!: ShippingCarrier;

  /** The type of shipping service that will be used */
  readonly service!: ShippingService;

  /** The estimated delivery date */
  readonly estimatedDeliveryDate!: Date;

  /** The price in USD */
  readonly price!: number;

  constructor(model?: Partial<ShippingOption>) {
    Object.assign(this, model);
  }
}

export class ShippingRate extends ShippingOption implements Deserializable<ShippingRate> {
  dateLabel: string;
  serviceLabel: string;
  display: string;

  constructor(model: ShippingOption) {
    super();
    this.deserialize(model);
  }

  deserialize(model: ShippingOption): ShippingRate {
    Object.assign(this, model);
    this.createDateLabel();
    this.serviceLabel = ShippingServiceDisplays[model.service];
    this.display = `$${this.price} - ${this.carrier} ${this.serviceLabel}`;
    return this as ShippingRate;
  }

  createDateLabel() {
    if (!this.estimatedDeliveryDate) { return ''; }

    try {
      // parse the date label from Date of delivery
      const date = new Date(this.estimatedDeliveryDate);

      const options: Intl.DateTimeFormatOptions[] = [
        { weekday: 'long' },
        { day: 'numeric' },
        { hour: 'numeric' },
        { month: 'long' },
        { year: 'numeric' },
        { timeZoneName: 'long' },
      ];

      const getFormattedDateObject = (date: Date, options: Intl.DateTimeFormatOptions[]): any => {
        const formattedDateStringObject = {};

        const callback = (option: Intl.DateTimeFormatOptions) => {
          const result = date.toLocaleDateString('en', option);

          for (const key of Object.keys(option)) {
            formattedDateStringObject[key] = result;
          }
        };

        options.forEach(callback);
        return formattedDateStringObject;
      };

      const dateObject = getFormattedDateObject(date, options);

      const { weekday, day, month } = dateObject;

      this.dateLabel = `${weekday}, ${month} ${day}`;
    } catch (error) {
      console.error(error);
      return '';
    }
  }
}
