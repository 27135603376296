import { Component, Input } from '@angular/core';

export interface ISpinner {
  isLoading: boolean;
  text?: string;
}

export class Spinner implements ISpinner {
  constructor(public isLoading: boolean, public text?: string) {

  }

  start(text?: string) {
    this.isLoading = true;
    this.text = text ?? this.text;
  }

  stop() {
    this.isLoading = false;
  }

  static none() {
    return new Spinner(false);
  }

  static loading(text?: string) {
    return new Spinner(true, text);
  }
}

@Component({
  selector: 'lc-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input() size?: number;
  @Input() showOverlay?: boolean;
  @Input() spinnerText?: string;
  @Input() fixedCenter?: boolean;
  @Input() spinner?: ISpinner;
}
