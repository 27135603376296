// Angular Modules
import {
  CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA, ModuleWithProviders,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// Third Party Modules
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ngxLoadingAnimationTypes, NgxLoadingComponent, NgxLoadingModule } from 'ngx-loading';
import { ClipboardModule } from 'ngx-clipboard';

// Material Modules
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatNativeDateModule } from '@angular/material/core';

// LC Modules
import { PromptDialogService as CorePromptDialogService } from '@lc/core';

// Internal Services
import {
  InputsModule, AgentSearchModule, DialogsModule, UICoreModule, CardsModule, HeaderModule, FormButtonsModule,
} from '@lc/ui';
import { PromptDialogService } from './dialogs/prompt-dialog/prompt-dialog.service';
import { NotesDialogService } from './dialogs/notes-dialog/notes-dialog.service';
import { PdfPreviewDialogService } from './dialogs/pdf-preview-dialog/pdf-preview-dialog.service';
import { UploadResultsDialogService } from './dialogs/upload-results-dialog/upload-results-dialog.service';
import { PhotoGalleryDialogService } from './dialogs/photo-gallery-dialog/photo-gallery-dialog.service';
import { ReasonDialogService } from './dialogs/reason-dialog/reason-dialog.service';
import { BulkAssignDialogService } from './dialogs/bulk-assign-dialog/bulk-assign-dialog.service';
import { WelcomeUiDialogService } from './dialogs/welcome-ui-dialog/welcome-ui-dialog.service';

// Internal Components
import { PromptDialogComponent } from './dialogs/prompt-dialog/prompt-dialog.component';
import { NotesDialogComponent } from './dialogs/notes-dialog/notes-dialog.component';
import { FieldVersionComponent } from './dialogs/versions/field-version.component';
import { PdfPreviewDialogComponent } from './dialogs/pdf-preview-dialog/pdf-preview.dialog.component';
import { BulkAssignDialogComponent } from './dialogs/bulk-assign-dialog/bulk-assign-dialog.component';
import { PhotoGalleryDialogComponent } from './dialogs/photo-gallery-dialog/photo-gallery-dialog.component';
import { UploadResultsDialogComponent } from './dialogs/upload-results-dialog/upload-results-dialog.component';
import { ReasonDialogComponent } from './dialogs/reason-dialog/reason-dialog.component';
import { WelcomeUiDialogComponent } from './dialogs/welcome-ui-dialog/welcome-ui-dialog.component';

import { BrowserSupportComponent } from './browser/browser-support.component';
import { PhotoComponent } from './photos/photo-list/photo/photo.component';

const angularModules = [CommonModule, FormsModule, ReactiveFormsModule, RouterModule];

const thirdPartyModules = [
  NgxFileDropModule, NgxMaskDirective, ImageCropperModule,
  NgxLoadingModule.forRoot({
    animationType: ngxLoadingAnimationTypes.circleSwish,
    backdropBackgroundColour: 'rgba(0,0,0,0.1)',
    backdropBorderRadius: '4px',
    primaryColour: '#1f69ff',
    secondaryColour: '#ffffff',
    tertiaryColour: '#ffffff',
  }),
  ClipboardModule,
];
const materialModules = [
  MatIconModule, MatSelectModule, MatChipsModule, MatAutocompleteModule, MatDialogModule, MatDividerModule,
  MatButtonModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatNativeDateModule, MatTableModule,
];
const services = [NotesDialogService, PdfPreviewDialogService,
  UploadResultsDialogService, PhotoGalleryDialogService, ReasonDialogService, BulkAssignDialogService, WelcomeUiDialogService];
const dialogComponents = [PromptDialogComponent, NotesDialogComponent, FieldVersionComponent, BulkAssignDialogComponent, PhotoGalleryDialogComponent, UploadResultsDialogComponent, ReasonDialogComponent, PdfPreviewDialogComponent, WelcomeUiDialogComponent];
const inputComponents = [];
const internalComponents = [...dialogComponents, ...inputComponents];

// WARNING: DO NOT IMPORT CORE MODULE HERE
@NgModule({
  imports: [
    ...angularModules,
    ...thirdPartyModules,
    ...materialModules,
    InputsModule,
    UICoreModule,
    CardsModule,
    HeaderModule,
    DialogsModule,
    FormButtonsModule,
    AgentSearchModule,
    PhotoComponent,
    RouterModule.forChild([
      { path: 'browser-support', component: BrowserSupportComponent },
    ]),
  ],
  declarations: [
    ...internalComponents,
    BrowserSupportComponent,
  ],
  providers: [
    ...services,
    provideNgxMask(),
  ],
  exports: [
    ...internalComponents,
    NgxLoadingComponent,
    BrowserSupportComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA,
  ],
})
export class SharedComponentsUiModule {
  static forRoot(): ModuleWithProviders<SharedComponentsUiModule> {
    return {
      ngModule: SharedComponentsUiModule,
      providers: [
        // Registers the concrete implementation of the PromptDialogService for use throughout the application.
        // Overrides the core implementation which is just an empty class, similiar to an interface
        { provide: CorePromptDialogService, useClass: PromptDialogService },
      ],
    };
  }
}
