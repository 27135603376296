import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { tap, withLatestFrom } from 'rxjs/operators';
import { ParamMap, Router } from '@angular/router';
import { ORDERSTATE } from './workflow.actions';
import * as ActionTypes from './workflow.actions';
import { FlowState } from '../../models/flow-state.model';

@Injectable()
export class WorkflowEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<FlowState>,
  ) {
  }

  navigate: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.WorkflowActionNext, ActionTypes.WorkflowActionPrev, ActionTypes.WorkflowActionEdit),
    withLatestFrom(this.store, (action: any, store) => {
      return {
        action,
        orderState: store[ORDERSTATE],
      };
    }),
    tap((payload) => {
      if (payload?.orderState?.currentStep) {
        let url = payload.orderState.currentStep.routerLinkUri;
        payload.action.params.keys.forEach((key) => {
          url = url.replace(`:${key}`, payload.action.params.get(key));
        });
        /**
      * Navigates while replacing the current state in history. To avoid Back button issues.
      */
        this.router.navigate([url], { replaceUrl: true });
      } else {
        console.log('Order Creation process steps has an issue', payload);
        this.router.navigate(['']);
      }
    }),
  ), { dispatch: false });

  /**
   * This effect is used when the initialization of a state needs to navigate to a URL
   * on the flow state model. It is used to jump back to the last place in a workflow
   * on initialization
   */

  initialize: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.WorkflowActionInitialize),
    withLatestFrom(this.store, (action:any, store) => {
      return {
        action,
        orderState: store[ORDERSTATE],
      };
    }),
    tap((payload) => {
      if (payload?.orderState?.currentStep) {
        let url = payload.orderState.currentStep.routerLinkUri;
        payload.action.params.keys.forEach((key) => {
          url = url.replace(`:${key}`, payload.action.params.get(key));
        });
        // Only replace navigation if we are coming from a step within the order-listing,
        // otherwise, preserve navigation
        const isFromOrderListing = this.router.url.indexOf('order-listing') >= 0;
        this.router.navigate([url], { replaceUrl: isFromOrderListing });
      } else {
        console.log('Order Creation process steps has an issue', payload);
        this.router.navigate(['']);
      }
    }),
  ), { dispatch: false });
}
