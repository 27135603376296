import { BaseForm, LCFormArray } from '@lc/core';
import { FormControl, FormGroup as NgFormGroup, Validators } from '@angular/forms';
import {
  Observable, map, shareReplay, startWith,
} from 'rxjs';
import {
  FormGroup, Input, DynamicFormModel, Textbox, InputTypeDisplays, InputType, InputOption,
} from './dynamic-form.model';

type ValueType = 'String' | 'Boolean' | 'Number';
export class InputOptionForm extends BaseForm<InputOption> {
  readonly valueType: FormControl<ValueType>;
  readonly valueType$: Observable<ValueType>;
  readonly valueTypes: ValueType[] = ['String', 'Boolean', 'Number'];

  constructor(value?: InputOption) {
    super({
      label: new FormControl(value?.label, [Validators.required]),
      value: new FormControl(value?.value, []),
      description: new FormControl(value?.value, []),
      data: new FormControl(value?.data, []),
    });

    const valueType = this.getValueType(value?.value);
    this.valueType = new FormControl<ValueType>(valueType);
    this.valueType$ = this.valueType.valueChanges.pipe(
      startWith(valueType),
      map((type) => {
        const control = this.getControl('value');
        if (type === 'String' && typeof value?.value !== 'string') {
          control.setValue(control.value ? `${control.value}` : null);
          control.markAsDirty();
        } else if (type === 'Number' && typeof value?.value !== 'number') {
          control.setValue(control.value ? +control.value : null);
          control.markAsDirty();
        } else if (type === 'Boolean' && typeof value?.value !== 'boolean') {
          control.setValue(!!control.value);
          control.markAsDirty();
        }
        return type;
      }),
      shareReplay(1),
    );
    this.reset(value);
  }

  reset(value?: InputOption, options?: { onlySelf?: boolean; emitEvent?: boolean; }): void {
    super.reset(value, options);
    const valueType: ValueType = this.getValueType(value?.value);
    this.valueType.setValue(valueType, { emitEvent: true });
    this.isNew = !value;
  }

  private getValueType(value: any): ValueType {
    if (typeof value === 'number') {
      return 'Number';
    } if (typeof value === 'boolean') {
      return 'Boolean';
    }
    return 'String';
  }
}

export class InputForm extends BaseForm<Input> {
  readonly inputOptions = InputTypeDisplays;
  readonly hasOptions$: Observable<boolean>;

  readonly options: LCFormArray<InputOption, InputOptionForm>;
  get selectedValue() { return this.getControl('options').value.filter((option) => option?.isSelected).map((option) => option?.value); }

  constructor(value?: Input) {
    super({
      key: new FormControl(value?.key, [Validators.required]),
      inputType: new FormControl(value?.inputType, [Validators.required]),
      label: new FormControl(value?.label, [Validators.required]),
      hint: new FormControl(value?.hint, []),
      placeholder: new FormControl(value?.placeholder, []),
      order: new FormControl(value?.order, []),
      defaultValue: new FormControl(value?.defaultValue, []),
      validators: new FormControl(value?.validators, []),
      groupKey: new FormControl(value?.groupKey, []),
      options: new LCFormArray<InputOption, InputOptionForm>(value?.options, (option) => new InputOptionForm(option)),
    });

    this.options = this.getControl('options') as LCFormArray<InputOption, InputOptionForm>;
    this.hasOptions$ = this.getControl('inputType').valueChanges.pipe(
      startWith(value?.inputType),
      map(() => {
        const inputType: InputType = this.getControl('inputType').value;
        const typesWithOptions: InputType[] = [
          'multi-select',
          'select',
          'radio-group',
        ];
        const hasOptions = typesWithOptions.includes(inputType);
        if (hasOptions) {
          this.options.setValue(this.originalValue?.options || []);
        } else {
          this.options.setValue([]);
        }
        return hasOptions;
      }),
    );

    this.reset(value);
  }
}

export class InputControl<TType = any> extends FormControl {
  readonly type = this.input.inputType;
  constructor(readonly input: Input<TType>) {
    const validators = [Validators.required]; // TODO: Build Validators
    super(input?.defaultValue, validators);
  }
}

export class DynamicFormGroup extends NgFormGroup {
  readonly sortedControls: InputControl[] = [];

  constructor(readonly group: FormGroup) {
    super({});
  }

  insertControl(control: InputControl) {
    this.sortedControls.push(control);
    super.addControl(control.input.key, control);
    control.valueChanges.subscribe(() => this.updateValueAndValidity());
  }
}

export class DynamicForm extends BaseForm<DynamicFormModel> {
  // TODO: private set, public get
  sortedControls: InputControl[];
  groups: DynamicFormGroup[];

  inputForm: NgFormGroup;

  readonly inputs: NgFormGroup;

  constructor(model?: Partial<DynamicFormModel>) {
    super({
      inputs: new NgFormGroup({}),
    });
    this.inputs = this.get('inputs') as NgFormGroup;
    this.reset(model);
  }

  reset(value?: Partial<DynamicFormModel>, options?: { onlySelf?: boolean; emitEvent?: boolean; }): void {
    this._originalValue = value;

    this.groups = [];
    this.sortedControls = [];
    const defaultGroup = new FormGroup({});

    // Reset the inputs
    this.inputs.controls = {};
    Object.values((this._originalValue?.inputs || {}))
      .forEach((input) => this.inputs.addControl(input.key, new InputForm(input)));

    // Build the Input Form used by the UI to collect user input
    this.inputForm = Object.values((this._originalValue?.inputs || {}))
      .sort((a, b) => a.order - b.order)
      .reduce((controls, input) => {
        const control = DynamicForm.inputFactory(input);

        // Add the control to the group
        const group = value.groups?.find((group) => group.key === input.groupKey) || defaultGroup;
        let existingGroup = this.groups.find((g) => g.group === group);
        if (!existingGroup) {
          existingGroup = new DynamicFormGroup(group);
          this.groups.push(existingGroup);
        }
        existingGroup.insertControl(control);

        this.sortedControls.push(control);

        controls.addControl(input.key, control);
        return controls;
      }, new NgFormGroup([]) as NgFormGroup);
  }

  addInput(input: Input) {
    // TODO: Verify key is not already in the controls
    const existing = this.inputs.controls[input?.key];
    if (existing) {
      throw new Error('Key already exists'); // Show toaster as well
    }

    const control = new InputForm(input);
    this.inputs.addControl(input.key, control);
    BaseForm.markAllAsDirty(this.inputs);
    return control;
  }

  updateInput(input: Input) {
    const existing = this.inputs.controls[input?.key];
    if (!existing) {
      // Input doesnt exist. Add it
      return this.addInput(input);
    }

    existing.reset(input);
    BaseForm.markAllAsDirty(this.inputs);
    return existing;
  }

  deleteInput(key: string) {
    const existing = this.inputs.controls[key];
    if (!existing) {
      throw new Error('Input does not exist');
    }
    this.inputs.removeControl(key);
    BaseForm.markAllAsDirty(this.inputs);
    return existing;
  }

  static inputFactory(input: Input) {
    switch (input.inputType) {
      case 'textbox':
        return new InputControl(input as Textbox);
      default: return new InputControl(input);
    }
  }
}
