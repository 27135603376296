import { DomSanitizer } from '@angular/platform-browser';
import {
  Component, ContentChild, ElementRef, forwardRef, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild,
} from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PromptDialogReference, PromptDialogService } from '@lc/core';
import { InputField } from '../input-field';

/**
 * The model used to display the options
 */
export interface ICardOption<TModel = any> {
  /** The label for the card */
  label: string;

  /** The value to set the form control to when selected */
  value: TModel;

  /** The description for this card */
  description?: string;

  /** The image url to display on the card */
  imageUrl?: string;

  /** The image url to display in full size mode */
  fullSizeImageUrl?: string;

  isSelected?: boolean;
  isDisabled?: boolean;
  isReadonly?: boolean;
}

@Component({
  selector: 'lc-card-select',
  templateUrl: './card-select.component.html',
  styleUrls: ['./card-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CardSelectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CardSelectComponent),
      multi: true,
    },
  ],
})
export class CardSelectComponent extends InputField implements OnChanges {
  @Input() options: ICardOption[] = [];
  @Input() isMultiselect: boolean;
  @Input() minOptions?: number;
  @Input() maxOptions: number;
  @Input() variant?: string = 'default';
  @Input() label: string;
  @Input() hint?: string;
  @Input() size: number = 225;

  @ContentChild('image') imageTemplate: TemplateRef<any>;
  @ContentChild('footer') footerTemplate: TemplateRef<any>;

  @ContentChild('descriptionToggle', { read: ElementRef }) toggleRef: ElementRef;
  @ViewChild('fullSizeImageTemplate') fullSizeImageTemplate: TemplateRef<any>;

  selectedValues: string[] = [];

  dialogRef: PromptDialogReference;
  constructor(sanitizer: DomSanitizer, private readonly dialogService: PromptDialogService) {
    super(sanitizer);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.updateCardOptions();
    }
  }

  onSelect(option: ICardOption) {
    if (option.isDisabled || option.isReadonly) {
      return; // Do nothing;
    }

    if (this.isMultiselect) {
      // If multiselect, ensure we do not exceed the maxOptions if specified
      if (!option.isSelected && this.maxOptions > 0 && (this.selectedValues || []).length >= this.maxOptions) {
        return; // Cannot select anymore
      }

      // Otherwise, simply toggle the selected value and update the value as an array
      option.isSelected = !option.isSelected;
      const selectedOptions = this.options.filter((opt) => opt.isSelected);
      this.value = selectedOptions.map((opt) => opt.value);
      // this.selected.emit(selectedOptions.map(opt => opt.option));
    } else {
      // If single select, simply toggle the selected value and update the value as an array
      option.isSelected = !option.isSelected;
      this.options.forEach((opt) => opt.isSelected = option.isSelected && (opt === option));
      this.value = option.isSelected ? option.value : null;
    }
  }

  onFullSize(option: ICardOption, event: MouseEvent) {
    event.stopPropagation();
    this.dialogRef = this.dialogService.open(this.fullSizeImageTemplate, option, { disableClose: false });
  }

  /**
   * Update the selected values with the formControl/ngModel value changes.
   */
  protected executeOnChanged() {
    // Map the selected value(s) to an array of values and store as selectedValues
    const values = this.value instanceof Array ? this.value : [this.value];
    this.selectedValues = values.filter((value) => value != null);

    // Enumerate the existing cards and mark the proper ones as selected
    this.options.forEach((option) => {
      option.isSelected = this.selectedValues.includes(option.value);
      option.isDisabled = !option.isSelected && this.maxOptions && (this.maxOptions > 0 && this.selectedValues?.length >= this.maxOptions);
    });
    this.options.forEach((option) => option.isSelected = this.selectedValues.includes(option.value));

    // Call through the base method
    super.executeOnChanged();
  }

  /**
   * When the given options changes, update the UI Model for the cards
   */
  private updateCardOptions() {
    (this.options || []).forEach((option) => {
      option.isSelected = this.selectedValues.includes(option.value);
      option.isDisabled = !option.isSelected && this.maxOptions && (this.maxOptions > 0 && this.selectedValues?.length >= this.maxOptions);
    });
  }
}
