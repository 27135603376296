export enum ProductCode {
  FLYER = '100',
  PROFESSIONAL_PHOTOGRAPHY = '101',
  WEBSITE = '102',
  JUST_LISTED_POSTCARD = '103',
  BROCHURE = '104',
  JUST_SOLD_POSTCARD = '105',
  SILVER_ENVELOPE_MAILER = '106',
  SOCIAL_MEDIA_POST = '107',
  TELEVISION_AND_VIDEO = '108',
  PROFESSIONAL_VIDEO = '109',
  VIDEO_SLIDESHOW = '110',
  PRINT_ADVERTISING = '111',
  CLIENT_REPORT = '112',
  YOUTUBE_ADVERTISING = '120',
  EMAIL_FLYER = '201',
  CB_MOBILE = '202',
  THE_UPDATE = '203',
  SOCIAL_BOOST = '204',
}
