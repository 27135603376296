import { Injectable } from '@angular/core';
import {
  catchError, filter, map, startWith, switchMap, take, timeout,
} from 'rxjs/operators';
import { interval, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { MarketingOrder, ProductCode } from '../models';
import { MarketingOrderService } from './marketing-order.service';
import { GetOrder } from '../state-mgmt';

/**
 * Service that uses RxJS interval to poll the server for updated content. Polling exits
 * when new content meets the comparison criteria or a max timeout is reached.
 */
@Injectable()
export class PollingService {
  constructor(
    private marketingOrderService: MarketingOrderService,
    private store$: Store<any>,
  ) {}

  /**
   * Poll for an updated website product and exit when its found or when the RxJS interval times out
   * @param marketingOrder
   * @param frequency
   * @param maxWait
   */
  public pollForWebsite(marketingOrder: MarketingOrder, frequency: number = 5000, maxWait: number = 60000) {
    // testDate is used for comparing a newly retrieved website product in a marketing order. We would rather use the
    // audit record date on the marketingOrder passed in but we can't be guaranteed that what we pass in is indeed the
    // latest version of it. It is good enough to poll for anything that has changed after right now.
    const testDate = new Date();

    return interval(frequency)
      .pipe(
        startWith(0),
        switchMap(() => this.marketingOrderService.getOrder(marketingOrder._id)),
        filter((res) => PollingService.shouldKeepPollingForWebsite(marketingOrder, res, testDate)),
        take(1),
        map((updated) => {
          console.log(`original updated date: ${marketingOrder.audit.updatedAt} and retrieved marketing-order updated date: ${updated.audit.updatedAt}`);
          this.store$.dispatch(GetOrder({ payload: updated, useCached: false }));
          return true;
        }),
        timeout(maxWait),
        catchError(() => of(false)),
        take(1),
      ).toPromise();
  }

  /**
   * Compares the original marketing order against the new marketing order to see if it meets the criteria to stop polling.
   *
   * @param originalMO
   * @param newMO
   * @param testDate
   */
  private static shouldKeepPollingForWebsite(originalMO: MarketingOrder, newMO: MarketingOrder, testDate: Date): any {
    // if the original marketing order didn't have any publishedUris then simply check that the new one does
    if (!originalMO.getProduct(ProductCode.WEBSITE)?.publishedUris?.length) {
      return newMO.getProduct(ProductCode.WEBSITE)?.publishedUris?.length > 0;
    }

    // Check for updated audit dates
    const updatedDate = new Date(newMO.getProduct(ProductCode.WEBSITE)?.audit?.updatedAt);
    return updatedDate > testDate;
  }
}
