import {
  ActivatedRoute, NavigationEnd, ParamMap, QueryParamsHandling, Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import {
  filter, map, startWith, switchMap,
} from 'rxjs/operators';

export class NavigationHelper {
  static urlChanged$(router: Router): Observable<string> {
    return router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.urlAfterRedirects),
    );
  }

  static params$<TParams = any>(router: Router, route: ActivatedRoute) {
    const urlChanged$ = NavigationHelper.urlChanged$(router);
    return urlChanged$.pipe(
      startWith(''),
      map(() => this.rootRoute(route)),
      switchMap((route) => route.paramMap.pipe(map((params) => NavigationHelper.convertToObject(params, false) as TParams))),
    );
  }

  static updateQueryParams(router: Router, currentRoute: ActivatedRoute, params: any, handling?: QueryParamsHandling) {
    router.navigate([], { relativeTo: currentRoute, queryParams: params, queryParamsHandling: handling });
  }

  static queryParams$<TParams = any>(activatedRoute: ActivatedRoute) {
    return activatedRoute.queryParamMap.pipe(
      map((params) => NavigationHelper.convertToObject(params, false) as TParams),
    );
  }
  static queryParams<TParams = any>(activatedRoute: ActivatedRoute) {
    const params = activatedRoute.snapshot.queryParamMap;
    return NavigationHelper.convertToObject(params, false) as TParams;
  }

  static convertToObject<TParams = any>(params: ParamMap, includeNull: boolean) {
    const model = params.keys.reduce((obj, key) => {
      const value = params.get(key);
      if (value || includeNull) { obj[key] = value; }
      return obj;
    }, {});
    return model as TParams;
  }

  static convertToParams(model: any, includeNull: boolean) {
    const params = Object.keys(model).reduce((obj, key) => {
      const value = model[key];
      if (value || includeNull) { obj[key] = value; }
      return obj;
    }, {});
    return params;
  }

  static rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
