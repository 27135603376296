import { Deserializable } from './deserializable.model';
import { ProductCode } from './product.code';

export type TaskStatus = 'complete' | 'incomplete';
export type TaskTarget = 'order' | 'profile' | 'product';
export type TaskType =
  | 'order-marketing-copy'
  | 'order-photos'
  | 'team-profile-photos'
  | 'agent-profile-photos'
  | 'colist-profile-photos'
  | 'listing-mls-id'
  | 'product-design-details'
  | 'order-video-url'
  | 'product-appointment'
  | 'order-status'
  | 'product-proof-review'
  | 'product-marketing-copy'
  | 'product-photos-min'
  | 'product-editing-complete';

export type AssigneeType = 'agent' | 'coordinator' | 'user' | 'system';

export class Task implements Deserializable<Task> {
  title: string;
  description: string;
  status: TaskStatus;
  target: TaskTarget;
  type: TaskType;
  assignees: AssigneeType[];

  statusHistory: {
    from?: TaskStatus,
    to?: TaskStatus,
    date: Date;
    userId?: string;
    userName?: string;
  }[];

  /** Deprecated. Use statusHistory instead */
  completion?: {
    date: Date;
    userId?: string;
    userName?: string;
  };

  creation: {
    date: Date;
    userId?: string;
    userName?: string;
  };
  isHardRequirement?: boolean;
  data?: {
    productCode?: ProductCode;
    productCodes?: ProductCode[];
    orderId?: string;
    [otherKeys: string]: any;
  };

  constructor(value?: Partial<Task>) {
    this.deserialize(value);
  }

  deserialize(value?: Partial<Task>): Task {
    Object.assign(this, value);
    return this;
  }
}
