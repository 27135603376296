/**
 * For products
 *
 * PENDING => order placed in system. When order is submitted with photo order, this is the state set
 * TODO => product ready to be created. order received by photographer (maybe logged into system to see orders)
 * CREATING => order accepted and started
 * PROOFING => Mods to order or product
 * DELIVERY => order "complete"
 * DONE => Completed workflow
 *
 */
export enum MarketingOrderStatusType {
  OPEN = 'OPEN',
  SUBMITTED = 'SUBMITTED',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  CLOSED = 'CLOSED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
}

export enum MarketingOrderTransitions {
  SUBMIT = 'submit',
  CLOSE = 'close',
  READY = 'ready',
  CANCEL = 'cancel',
  BEGIN = 'begin',
  FINALIZE = 'finalize',
}

export type MarketingOrderStatus = `${MarketingOrderStatusType}`;

export const AllMarketingOrderStatuses: MarketingOrderStatus[] = Object.values(MarketingOrderStatusType).map((value) => value);
