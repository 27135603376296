import * as SessionActionTypes from './session.actions';

export const initialState: any = null;

export function sessionReducer(state = initialState, action: any) {
  switch (action.type) {
    case SessionActionTypes.UpdateUserSession.type: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
