import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationEvent, NotificationEventService, NotificationEventTypes } from '../notifications';
import { ErrorData } from './error-data';

/**
 * GlobalErrorHandler to process errors
 */
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  static chunkFailedMessageRegex = /Loading chunk [\d]+ failed/;

  constructor(private notificationService: NotificationEventService) {
  }

  handleError(error: any, logToDatadog: boolean = true) {
    if (error === null || typeof error === 'undefined') {
      return;
    }

    if (GlobalErrorHandler.chunkFailedMessageRegex.test(error.message)) {
      this.reloadWindow();
      return;
    }

    // Log the error to the console (which will be written to datadog)
    if (logToDatadog) {
      console.error(`An unhandled exception has occured: ${error?.message}`, error);
    }
    if (error?.payload?.error === 'EMPTYID') {
      this.handleUndefinedIdError(error);
    } else if (error instanceof HttpErrorResponse) {
      this.handleHttpError(error);
    } else if (error?.payload?.error instanceof HttpErrorResponse) {
      this.handleServerError(error);
    } else if (error instanceof ErrorData) {
      this.handleErrorData(error);
    } else if (error && error.stack) {
      console.error(error.stack);
    } else {
      let errMsg = error;
      if (typeof error === 'object') {
        const cache = [];
        errMsg = JSON.stringify(error, (key, value) => {
          if (typeof value === 'object' && value !== null) {
            if (cache.indexOf(value) !== -1) {
              // Duplicate reference found, discard key
              return;
            }
            // Store value in our collection
            cache.push(value);
          }
          return value;
        });
      }
      console.error(`Error: ${errMsg}`);
      this.handleUnexpectedError(errMsg);
    }
  }

  handleHttpError(error: HttpErrorResponse) {
    // defaults to the snackbar for api error
    const name = NotificationEventTypes.APIERROR;
    const message = `Error while processing the request  -  ${error.statusText}    `;
    const event = new NotificationEvent(name, message, { payload: error });
    this.notificationService.getEventEmitter().emit(event);
  }

  handleUndefinedIdError(error: any) {
    // defaults to the snackbar for api error
    const name = NotificationEventTypes.APIERROR;
    const message = 'Something went wrong. Please refresh the page';
    const event = new NotificationEvent(name, message, { payload: error });
    this.notificationService.getEventEmitter().emit(event);
  }

  // we have different handlers based on different error objects. Final action to be determined
  handleServerError(error: any) {
    // defaults to the snackbar for api error
    const name = NotificationEventTypes.APIERROR;
    const message = `Error while processing the request  -  ${error.payload.error.statusText}    `;

    const event = new NotificationEvent(name, message, error);
    this.notificationService.getEventEmitter().emit(event);
  }

  handleErrorData(error: ErrorData) {
    this.notificationService.getEventEmitter().emit(error.getNotificationEvent());
  }

  handleUnexpectedError(error: any) {
    const event = new NotificationEvent('error', 'unexpected', error);
    this.notificationService.getEventEmitter().emit(event);
  }

  reloadWindow() {
    window.location.reload();
  }
}
