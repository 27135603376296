<ng-container *ngFor="let tab of links">
  <mat-list-item class="py-0">
    <a mat-button [id]="tab.id" class="w-100" [class.primary-color]="tab.isSelected" [class.expanded]="tab.isExpanded"
      [disabled]="tab.isDisabled" [class.disabled]="tab.isDisabled"
      [routerLink]="[tab.path]" [routerLinkActive]="'selected primary-color'" [routerLinkActiveOptions]="{exact: tab.exactMatch}"
      (isActiveChange)="onActiveLinkChanged($event, tab)"
      [matTooltip]="tab.label" matTooltipPosition="right" [matTooltipDisabled]="isExpanded">
      <div class="nav-link-content fx-row y-center fx-gap-sm" [class.x-center]="!isExpanded">
        <mat-icon>{{ tab.svgPath }}</mat-icon>
        <span *ngIf="isExpanded" class="text-left w-100 d-inline-block">{{ tab.label }}</span>

        <button *ngIf="isExpanded && tab?.children?.length" mat-icon-button disableRipple="true" (click)="onToggleExpand($event, tab);">
          <mat-icon>{{tab.isExpanded ? 'expand_less' : 'expand_more'}}</mat-icon>
        </button>
      </div>
    </a>
  </mat-list-item>

  <ng-container *ngIf="tab?.children?.length">
    <!-- NOTE: Using class.d-none instead of *ngIf because we want the (isActiveChange) to fire regardless of whether it is displayed or not -->
    <mat-list-item *ngFor="let child of tab.children" class="py-0" [class.d-none]="!(isExpanded && tab.isExpanded)">
      <a [id]="child.id" class="w-100 child-button"
        [routerLink]="[child.path]" [routerLinkActive]="'primary-color'"  [routerLinkActiveOptions]="{exact: child.exactMatch}"
        (isActiveChange)="onActiveLinkChanged($event, child, tab)" mat-button [class.disabled]="child.isDisabled" [disabled]="child.isDisabled">
        <span class="text-left w-100 d-inline-block">{{ child.label }}</span>
      </a>
    </mat-list-item>
  </ng-container>
</ng-container>
