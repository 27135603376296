import { Deserializable } from './deserializable.model';
import { ProductOption } from './product-option.model';

export class VendorOverrides implements Deserializable<VendorOverrides> {
  code: string;
  modifiedOptions: ProductOption[];
  priceAdjustment: number;
  quantity?: number;

  constructor(input?: any) {
    this.deserialize(input);
  }

  deserialize(input?: any): VendorOverrides {
    if (!input) { return this; }
    Object.assign(this, input);

    if (this.modifiedOptions) {
      this.modifiedOptions = this.modifiedOptions.map((option) => new ProductOption(option));
    }
    return this;
  }
}
