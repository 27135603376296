import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lc-company-menu-item',
  templateUrl: './company-menu-item.component.html',
  styleUrls: ['./company-menu-item.component.scss'],
})
export class CompanyMenuItemComponent implements OnInit {
  @Input() companyName: string;
  @Input() isExpanded: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }
}
