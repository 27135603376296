import { ListingPhoto } from '../models/listing-photo.model';

export class ListingPhotoFactory {
  public static photoOne: ListingPhoto = new ListingPhoto({
    uri: 'https://photos.com/photoOne.jpg',
    favorite: true,
    uploadedBy: 'john',
    thumbnails: {
      540: 'https://photos.com/photoOne-medium.jpg',
      1080: 'https://photos.com/photoOne-medium.jpg',
    },
  });

  public static allPhotos: ListingPhoto[] = [
    ListingPhotoFactory.photoOne,
  ];
}
