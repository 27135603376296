import { Deserializable } from './deserializable.model';

export class License implements Deserializable<License> {
  licenseNumber: string;
  licenseState: string;

  constructor(input?: any) {
    this.deserialize(input || {});
  }

  deserialize(input: any): License {
    Object.assign(this, input);
    return this;
  }
}
