import { Component, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export class UploadResultsDialogData {
  constructor(readonly failedUploads: string[], readonly successfulUploads?: string[], readonly isMultiple?: boolean) {}
}

@Component({
  selector: 'lc-upload-results-dialog',
  templateUrl: './upload-results-dialog.component.html',
  styleUrls: ['./upload-results-dialog.component.scss'],
})
export class UploadResultsDialogComponent {
  private readonly _close = new Subject();
  public readonly close = this._close.asObservable();

  constructor(@Inject(MAT_DIALOG_DATA) public data: UploadResultsDialogData) { }

  onClose() {
    this._close.next(undefined);
  }
}
