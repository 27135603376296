import {
  AbstractControl, FormControl, ValidatorFn, Validators,
} from '@angular/forms';
import { BaseForm } from './base.form';
import { LockboxForm } from './lockbox.form';
import { PhotographerAppointment, Lockbox } from '../models/photographer-appointment.model';
import { PhotographyAgencyForm } from './photography-agency.form';
import { CustomValidator } from '../validators';

export class PhotographerAppointmentForm extends BaseForm<PhotographerAppointment> {
  // accessors are not required, but reduce code and risk of not retrieving the proper control
  public get comments(): string { return this.get('comments').value; }
  public get lockboxAvailable(): boolean { return this.get('lockboxAvailable').value; }
  public get lockbox(): Lockbox { return this.get('lockbox').value; }
  public get photoAgency(): PhotographyAgencyForm { return this.getControl('photoAgency') as PhotographyAgencyForm; }

  constructor(value?: PhotographerAppointment, required?: boolean) {
    super({
      lockboxAvailable: new FormControl(false),
      comments: new FormControl(null),
      lockbox: new LockboxForm(value ? value.lockbox : null),
      photoAgency: new PhotographyAgencyForm(value ? value.photoAgency : null),
      scheduledAt: new FormControl(value?.scheduledAt),
      timezone: new FormControl(value?.timezone),
      timezoneOffset: new FormControl(value?.timezoneOffset),
    });

    if (required) {
      // Only require the following fields if useCustomScheduling is false
      const requiredValidator = this.requireIfNotCustomScheduling(this.photoAgency.getControl('useCustomScheduling'));
      this.getControl('scheduledAt').setValidators([requiredValidator]);
      this.getControl('timezone').setValidators([requiredValidator]);
      this.getControl('timezoneOffset').setValidators([requiredValidator]);
    }

    if (value) {
      const model = { ...value };
      delete model.lockbox;
      this.patchValue(model);
    }
  }

  /**
   * Requires the field if the useCustomSchedulingControl is set to false
   */
  private requireIfNotCustomScheduling(useCustomSchedulingControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // Control is required if the customScheduling value is false
      const isRequired = !useCustomSchedulingControl.value;
      return isRequired ? CustomValidator.required(control) : null;
    };
  }
}
