import { Component, Inject, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { BaseForm, DialogAction } from '@lc/core';
import { FormControl, Validators } from '@angular/forms';
import { Masks, MultiSelectDisplayOptions } from '@lc/ui';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Order } from '@lc/store';
import { OrderRefundReasonType } from './order-refund-reason.type';

export class ReasonDialogData {
  constructor(
    readonly readonly: boolean = false,
    readonly title: string,
    readonly message: string,
    readonly reasons: string[],
    readonly acceptAction: ReasonDialogAction,
    readonly cancelAction: ReasonDialogAction,
    readonly reason?: string,
    readonly notes?: string,
    readonly storeOrders?: Order[],
  ) {
  }
}

export class ReasonDialogAction extends DialogAction {
  constructor(
    public text: string,
    public reason?: string,
    public notes?: string,
    public amount?: number,
    public storeOrder?: string,
    public onClick?: () => any,
  ) {
    super(text, onClick);
  }
}

export class ReasonForm extends BaseForm {
  constructor(value?: any) {
    super({
      storeOrder: new FormControl(null),
      lineItems: new FormControl(null),
      reason: new FormControl(null, [Validators.required]),
      notes: new FormControl(null),
      amount: new FormControl(null),
    });

    if (value) {
      this.patchValue(value);
    }
  }
}

interface OrderOption {
  id: string;
  amount: number;
  display: string;
}

interface LineItemOption {
  id: string;
  amount: number;
  display: string;
}

@Component({
  selector: 'lc-reason-dialog',
  templateUrl: './reason-dialog.component.html',
  styleUrls: ['./reason-dialog.component.scss'],
})
export class ReasonDialogComponent implements OnDestroy {
  readonly refundMask = Masks.decimalWithWholeNumber;

  public readonly reasonForm: ReasonForm = new ReasonForm();

  private readonly _close = new Subject<ReasonDialogAction>();
  public readonly close = this._close.asObservable();

  public hideNotes = false;
  maxRefundAmount: number = 0;
  private storeOrdersSubscription: Subscription;
  readonly orderOptions: OrderOption[];
  lineItemOptions: LineItemOption[];
  lineItemOptionsDisplay = new MultiSelectDisplayOptions(null, null, true);
  private readonly formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

  constructor(@Inject(MAT_DIALOG_DATA) public data: ReasonDialogData) {
    // populate the form if values are passed in
    if (data.reason) {
      this.reasonForm.getControl('reason').setValue(data.reason);
    }
    if (data.notes) {
      this.reasonForm.getControl('notes').setValue(data.notes);
    }

    if (data.acceptAction?.text === 'Refund') {
      this.orderOptions = this.formatStoreOrders(data?.storeOrders || []);
      const storeOrderControl = this.reasonForm.getControl('storeOrder');
      storeOrderControl.setValidators([Validators.required]);
      storeOrderControl.setValue(this.orderOptions?.[0]?.id);
      this.onOrderSelected(storeOrderControl.value);
      this.hideNotes = true;
    }

    this.reasonForm.markAsPristine();
  }

  ngOnDestroy() {
    if (this.storeOrdersSubscription) {
      this.storeOrdersSubscription.unsubscribe();
    }
  }

  /**
   * Sets the validator on the notes form control if the reason is 'other'
   */
  changeReason() {
    const reason = this.reasonForm.get('reason').value;

    if (this.data.acceptAction.text === 'Refund' && reason !== OrderRefundReasonType.OTHER) {
      this.hideNotes = true;
      this.reasonForm.controls.notes.clearValidators();
      this.reasonForm.controls.notes.setValue(null);
    } else {
      this.hideNotes = false;
      this.reasonForm.controls.notes.setValidators([Validators.required]);
    }
  }

  onActionClicked(action: ReasonDialogAction) {
    if ((action || this.data.cancelAction) === this.data.cancelAction) {
      this.onClose(this.data.cancelAction);
    } else {
      this.onAcceptClicked(this.data.acceptAction);
    }
  }

  onOrderSelected(orderId: string) {
    const orderOption = this.orderOptions.find((order) => order.id === orderId);
    this.setAmountValidators(orderOption.amount);

    const storeOrder = this.data.storeOrders.find((order) => order._id === orderOption.id);
    this.lineItemOptions = storeOrder.lineItems
      ?.filter((lineItem) => lineItem.total > 0)
      .map((lineItem) => ({
        id: lineItem._id,
        amount: lineItem.total,
        display: `${this.formatter.format(lineItem.total)} – ${lineItem.title} (${lineItem.description})`,
      })) || [];
  }

  onLineItemSelected(options: LineItemOption[]) {
    const sumOfSelectedLineItemAmounts = this.lineItemOptions
      .filter((lineItem) => options.some((option) => option.id === lineItem.id))
      .reduce((accumulator, currentLineItem) => accumulator + currentLineItem.amount, 0);
    this.reasonForm.getControl('amount').setValue(sumOfSelectedLineItemAmounts);
    this.lineItemOptionsDisplay = {
      ...this.lineItemOptionsDisplay,
      multiDisplay: `${this.formatter.format(sumOfSelectedLineItemAmounts)} – Line Items`,
    };
  }

  private onAcceptClicked(action: ReasonDialogAction) {
    this.reasonForm.markAllAsDirty();
    if (!this.reasonForm.valid) {
      return;
    }

    action.reason = this.reasonForm.getControl('reason').value;
    action.notes = this.reasonForm.getControl('notes').value;
    action.amount = this.reasonForm.getControl('amount').value;
    action.storeOrder = this.reasonForm.getControl('storeOrder')?.value;
    this.onClose(action);
  }

  private onClose(action: ReasonDialogAction) {
    if (action?.onClick) {
      action.onClick();
    }

    this._close.next(action);
  }

  private setAmountValidators(maxAmount: number) {
    this.maxRefundAmount = maxAmount;
    this.reasonForm.controls.amount.setValidators([
      Validators.required,
      Validators.min(0.01),
      Validators.max(maxAmount),
    ]);
    this.reasonForm.controls.amount.updateValueAndValidity();
  }

  private formatStoreOrders(storeOrders: Order[]): OrderOption[] {
    return storeOrders
      .map((order) => {
        const total = Number(
          (order.pricing.refundTotal ? order.pricing.grandTotal - order.pricing.refundTotal : order.pricing.grandTotal)
            .toFixed(2),
        );
        return {
          id: order._id,
          amount: total,
          display: `${this.formatter.format(total)} – ${order.description}`,
        };
      })
      .reverse(); // items ordered first should be first in the list
  }
}
