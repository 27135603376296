import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlSegment, Data, Route, Router } from '@angular/router';
import { PromptDialogService } from '../services/prompt-dialog.service';
import { LaunchDarklyService } from '../services/launch-darkly.service';

@Injectable()
export class FeatureFlagGuard {
  constructor(private readonly router: Router, private authService: LaunchDarklyService, private promptDialog: PromptDialogService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return await this.performCheck(route.data);
  }

  async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
    return await this.performCheck(route.data);
  }

  async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return await this.performCheck(route.data);
  }

  private async performCheck(routeData: Data): Promise<boolean> {
    const { featureFlag } = routeData;
    if (!featureFlag) {
      console.error('Feature flag directive called, but no feature flag provided');
      return true;
    }

    const isEnabled = await this.authService.isFeatureEnabled(featureFlag);
    if (isEnabled) { return true; }

    await this.promptDialog.openPrompt('No Access', 'This feature is not available for the current user. Please try a different user.', 'Ok');
    this.router.navigate(['/']);
    return false;
  }
}
