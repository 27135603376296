import { Deserializable } from './deserializable.model';
import { PhotoThumbnails } from './photo-thumbnail.model';
import { UploadPhoto } from './upload-photo.model';

export class Dimension {
  constructor(public width: number, public height: number) { }
}

export class CropDimensions {
  fromTopPercentage: number;
  fromLeftPercentage: number;
  widthPercentage: number;
  heightPercentage: number;
}

export class Dimensions {
  templateDimensions: Dimension;
  cropDimensions: CropDimensions;
  cropData: any;
  s3Url?: string;
}

/**
 * Client-side model of a Listing photograph.
 */
export class ListingPhoto implements Deserializable<ListingPhoto> {
  _id: string;
  uri: string;
  order: number;
  favorite = false;
  hidden = false;
  uploadedBy: string;
  photographerId: string;
  thumbnails: PhotoThumbnails = {};
  dimensions: { [orderIndex: number]: Dimensions } = {};
  productIndex: number;
  height?: number;
  width?: number;
  tags?: string[];
  caption?: string;

  constructor(listingPhoto?) {
    if (listingPhoto) {
      Object.assign(this, listingPhoto);
    }
  }

  deserialize(input: any): ListingPhoto {
    Object.assign(this, input);

    return this;
  }

  isPortrait() {
    return (this.height || 0) > (this.width || 0);
  }

  /**
   * Creates ListingPhotos based on the results of a photo upload.
   * @param photos The recently uploaded photos
   * @param userId The userId to signify who uploaded these images
   * @param count The current count of the images
   * @param photographerId The photographer who uploaded the images
   */
  static createFromRawPhotos(photos: UploadPhoto[], userId: string, count: number, photographerId?: string): ListingPhoto[] {
    // Create an array of the original Listing Photos with the thumbnails attached
    const listingPhotos: ListingPhoto[] = photos
      .filter((photo) => photo.original)
      .map((original) => {
        const originalPhoto: ListingPhoto = new ListingPhoto();
        originalPhoto.uri = original.url;
        originalPhoto.order = count + 1;
        originalPhoto.uploadedBy = userId;
        originalPhoto.height = original?.imageInfo?.height;
        originalPhoto.width = original?.imageInfo?.width;

        if (photographerId) {
          originalPhoto.photographerId = photographerId;
        }

        // Enumerate all child thumbnails and attach to the original
        photos.filter((photo) => !photo.original && photo.file === original.file).forEach((thumbnail) => {
          if (originalPhoto.thumbnails === undefined) {
            originalPhoto.thumbnails = new PhotoThumbnails();
          }
          originalPhoto.thumbnails[thumbnail.width] = { uri: thumbnail.url };
        });
        count += 1; // Need to increment the count
        return originalPhoto;
      });

    return listingPhotos;
  }
}
