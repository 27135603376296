import { createAction, props } from '@ngrx/store';
import { ParamMap } from '@angular/router';
import { FlowState } from '../../models/flow-state.model';

export interface ActionData {
  state?: FlowState;
  id?: string;
  stepCompleted?: boolean;
  parentCompleted?: boolean;
  stateData?: any;
}

export const WorkflowActionNext = createAction('WFANext', props<{ data?:ActionData, params?: ParamMap }>());

export const WorkflowActionPrev = createAction('WFAPrev', props<{ data?:ActionData, params?: ParamMap }>());

export const WorkflowActionEdit = createAction('WFAEdit', props<{ data?:ActionData, params?: ParamMap }>());

export const WorkflowActionUpdate = createAction('WFAUpdate', props<{ data?: ActionData }>());

export const WorkflowActionInitialize = createAction('WFAInitialize', props<{ data?:ActionData, params?: ParamMap }>());

export const WorkflowActionTerminate = createAction('WFATerminate');

export const WorkflowActionSetStep = createAction('WFASetStep', props<{ data?:ActionData, params?: ParamMap }>());

// TODO - need to move to constants
// feature state names
export const ORDERSTATE = 'orderState';
