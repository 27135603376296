import { Deserializable } from './deserializable.model';

export class Audit implements Deserializable<Audit> {
  createdAt: Date;
  createdBy: string;
  createdByFullName: string;
  updatedAt: Date;
  updatedBy: string;
  updatedByFullName: string;

  constructor(input?: any) {
    this.deserialize(input || {});
  }

  deserialize(input: any): Audit {
    Object.assign(this, input);
    this.createdAt = input.createdAt ? new Date(input.createdAt) : undefined;
    this.updatedAt = input.updatedAt ? new Date(input.updatedAt) : undefined;
    return this;
  }
}
