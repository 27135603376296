// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatDatepickerModule } from '@angular/material/datepicker';

// Internal Component
import { DatepickerComponent } from './datepicker.component';

const angularModules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  MatInputModule,
  MatDatepickerModule,
];

@NgModule({
  declarations: [DatepickerComponent],
  exports: [DatepickerComponent],
  imports: [...angularModules],
})
export class DatepickerModule { }
