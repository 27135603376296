// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

// LC Modules
import { SpinnerModule } from '../components/spinner';
import { HeaderModule } from '../layout/header';

// Export Components
import { CardComponent } from './components/card/card.component';

const angularModules = [
  CommonModule, MatButtonModule, MatCardModule, MatDividerModule,
];
const lcModules = [SpinnerModule, HeaderModule];

const exportComponents = [CardComponent];
const externalModules = [SpinnerModule, HeaderModule];
@NgModule({
  declarations: [...exportComponents],
  exports: [...exportComponents, ...externalModules],
  imports: [
    ...angularModules,
    ...lcModules,
  ],
})
export class CardsModule { }
