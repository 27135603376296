import { Injectable } from '@angular/core';
import { NotificationEventService } from '../notifications/notification-event.service';
import { NotificationEvent, NotificationEventTypes } from '../notifications/notification-event';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  constructor(private notificationService: NotificationEventService) { }

  showInfo(info: string) {
    const event = new NotificationEvent(NotificationEventTypes.INFO, info ?? 'An update has occurred');
    this.notificationService.getEventEmitter().emit(event);
  }

  showError(error: string) {
    const event = new NotificationEvent(NotificationEventTypes.ERROR, error ?? 'An unkown error occurred.');
    this.notificationService.getEventEmitter().emit(event);
  }
}
