import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { AddKeys } from '../state-mgmt';
import { HeaderConstants } from '../constants';

@Injectable()
export class TrackingHeaderGuard {
  constructor(private store: Store<any>) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    this.updatePathHeader(state.url);
    return true;
  }

  async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.updatePathHeader(state.url);
    return true;
  }

  /**
   * Update the keys we use to pass cusotm headers in any api calls so we can
   * log the page from which thew request originated as the 'referer'
   *
   * @param url
   * @private
   */
  private updatePathHeader(url: string) {
    const payload = {};
    payload[HeaderConstants.PATHNAME] = url;
    this.store.dispatch(AddKeys({ payload }));
  }
}
