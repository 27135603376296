// tslint:disable: rxjs-no-sharereplay
import { Injectable } from '@angular/core';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import {
  map, tap, switchMap, shareReplay, take, filter,
} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {
  Observable, BehaviorSubject, of, combineLatest, from,
} from 'rxjs';
import { LDUser } from 'launchdarkly-js-client-sdk';
import { datadogRum } from '@datadog/browser-rum';
import { User } from '../models';
import { UserService } from './user.service';
import { AppService } from './app.service';

export enum FeatureFlags {
  BYPASS_PROFILE_STATUS = 'bypass-profile-status',
  BYPASS_REAL_ESTATE_ROLE = 'bypass-real-estate-role',
  LOAN_OFFICER = 'gra-loan-officer',
  VIP_PROFILE = 'vip-flag',
  PHOTO_COLOR_VALIDATION = 'photo-color-validation',
  QR_CODE_DISPLAY = 'qr-code-display',
  BUY_SIDE_COMMISSION = 'buy-side-commission',
  ADDITIONAL_QTY = 'additional-qty',
  PRODUCT_REORDER = 'product-reorder',
  DYNAMIC_WEBSITE_PRODUCT = 'dynamic-website-product',
  LCC_QUICK_ADMIN = 'lcc-quick-admin',
  SHOW_TASKS = 'show-tasks',
  SAVED_FILTERS = 'saved-filters',
  SUPER_ADMIN = 'super-admin',
  CUSTOM_YOUTUBE_AD_URL = 'custom-youtube-ad-url',
  RESUBMIT_REQUEST_PRODUCT_STATE = 'resubmit-request-product-state',
  OMNI_CHAT = 'omni-chat',
  AI_PROPERTY_DESCRIPTION = 'ai-prop-description-generation',
  AI_IMAGE_TAGGING = 'ai-photo-tag-generation',
}

@Injectable({
  providedIn: 'root',
})
export class LaunchDarklyService {
  /** The LaunchDarkly client used to retrieve the feature flags */
  private readonly client$: Observable<LaunchDarkly.LDClient>;

  /** BehaviorSubjects for each of the configured/requested feature flags. */
  private readonly featureFlags$: { [flagName: string]: BehaviorSubject<any> } = {};

  constructor(
    private userService: UserService,
    private httpClient: HttpClient,
  ) {
    // Only execute launch darkly if the user is not null
    const user$ = this.userService.currentUser.pipe(filter((user) => user != null));
    const userHmac$ = user$.pipe(switchMap(() => this.getUsersHMAC$()));
    const ldUser$ = user$.pipe(map((user) => this.convertToLDUser(user)));

    // Initializes the client from the current user and hmac
    // Waits until the client is initialized before configuring flags
    this.client$ = combineLatest([ldUser$, userHmac$]).pipe(
      switchMap(([ldUser, hmac]) => this.initializeLD$(ldUser, hmac)),
      tap((client) => this.initializeFlags(client)),
      shareReplay(1),
    );
  }

  /*
   * Returns the feature value from the LaunchDarkley client
   * @param flag The FeatureFlag value we are returning
   */
  getFeature$<TExpected = any>(feature: FeatureFlags | string, defaultValue: TExpected = null): Observable<any> {
    return this.client$.pipe(switchMap(() => this.getFeatureFlag$(feature, defaultValue).asObservable()));
  }
  /**
   * Returns the feature value from the LaunchDarkley client
   * @param flag The FeatureFlag value we are returning
   */
  async getFeature<TExpected = any>(feature: FeatureFlags | string, defaultValue: TExpected = null): Promise<TExpected> {
    await this.client$.pipe(take(1)).toPromise();
    return this.getFeatureFlag$(feature, defaultValue).value;
  }

  /**
   * Checks to see if the feature flag is enabled.
   * @param flag Whether the flag is enabled or not. default is false
   */
  isFeatureEnabled$(flag: FeatureFlags, defaultValue: any = false): Observable<boolean> {
    return this.client$.pipe(switchMap(() => this.getFeatureFlag$(flag, defaultValue).asObservable()));
  }

  /**
   * Checks to see if the feature flag is enabled.
   * @param flag Whether the flag is enabled or not. default is false
   */
  async isFeatureEnabled(flag: FeatureFlags, defaultValue: any = false): Promise<boolean> {
    await this.client$.pipe(take(1)).toPromise();
    return this.getFeatureFlag$(flag, defaultValue).value;
  }

  private getFeatureFlag$<TExpected = any>(feature: FeatureFlags | string, defaultValue: TExpected = null): BehaviorSubject<TExpected> {
    if (!this.featureFlags$[feature]) {
      this.featureFlags$[feature] = new BehaviorSubject(defaultValue);
    }
    return this.featureFlags$[feature];
  }

  /**
   * Stores the value for the given feature flag in the BehaviorSubject to be emitted
   * @param featureFlagName The feature flag name
   * @param value The value to emit
   */
  private updateFlagValue(featureFlagName: string, value: any) {
    if (this.featureFlags$[featureFlagName] == null) {
      this.featureFlags$[featureFlagName] = new BehaviorSubject<any>(value);
    } else {
      this.featureFlags$[featureFlagName].next(value);
    }
  }

  /** Initializes the LaunchDarkly client and waits until ready */
  private initializeLD$(user: LDUser, hmac: any): Observable<LaunchDarkly.LDClient> {
    const clientId = AppService.get('launchDarklyClientID');

    const client = LaunchDarkly.initialize(
      clientId,
      user,
      {
        hash: (typeof hmac === 'object') ? hmac.hmac : hmac,
        useReport: true,
        streamReconnectDelay: 3000,
        inspectors: [
          {
            type: 'flag-used',
            name: 'dd-inspector',
            method: (key: string, detail: LaunchDarkly.LDEvaluationDetail) => {
              datadogRum.addFeatureFlagEvaluation(key, detail.value);
            },
          },
        ],
      },
    );

    return from(client.waitUntilReady()).pipe(map(() => client));
  }

  /** Initializes the current flag values after initialization */
  private initializeFlags(client: LaunchDarkly.LDClient) {
    const currentFlags = client.allFlags();
    Object.keys(currentFlags).forEach((key) => {
      const value: any = currentFlags[key];
      this.updateFlagValue(key, value);
    });

    // Subscribe to flag changes
    client.on('change', (keys) => this.onFlagChanges(keys));
  }

  /** Takes the flag changes (keys) and updates the current values */
  private onFlagChanges(keys: any) {
    // console.log('Change detected', keys)
    Object.keys(keys).forEach((key) => {
      const change: { current: any, previous: any } = keys[key];
      this.updateFlagValue(key, change.current);
    });
  }

  /** Converts the current user to the LaunchDarkly user */
  private convertToLDUser(user: User): LaunchDarkly.LDUser {
    return {
      key: user._id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      custom: {
        metroNumber: user?.profile?.office?.metro?.metroNumber || '',
        metroName: user?.profile?.office?.metro?.metroName || '',
        officeName: user?.profile?.office?.address?.name || '',
        marketingArea: user?.profile?.office?.marketingArea || '',
        appName: AppService.get('app'),
        productName: AppService.get('productSuiteName'),
      },
    };
  }

  /** Retrieves the HMAC from localStorage or from persist */
  private getUsersHMAC$(): Observable<any> {
    const cachedHMAC = localStorage.getItem('ld-hmac');
    if (cachedHMAC) {
      return of(cachedHMAC);
    }

    const route = `${AppService.get('persistBaseURL')}user/launch-darkly/hmac`;
    return this.httpClient.get(route).pipe(
      map((res) => (<any>res)),
      tap((keysRes) => localStorage.setItem('ld-hmac', keysRes.hmac)),
    );
  }
}
