import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { ContentKeys, ContentValueKeys, ContentfulService } from '../contentful/contentful.service';
import { AppService } from './app.service';
import { IDialogOptions, PromptDialogService } from './prompt-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class TermsAndConditionsService {
  constructor(private contentfulService: ContentfulService, private dialogService: PromptDialogService) {

  }

  async openDialog(): Promise<boolean> {
    const title = 'Terms and conditions at checkout';
    const message = await this.getTermsAndConditions();
    const options: IDialogOptions = { width: '750px', panelClass: 'text-sm' };
    const response = await this.dialogService.openPrompt(title, message, 'Accept', ['Cancel'], options);
    return response?.text === 'Accept';
  }

  async getTermsAndConditions(): Promise<string> {
    let key = null;

    if (AppService.isDCApp) {
      key = ContentValueKeys.DC;
    } else if (AppService.isAgentApp) {
      key = ContentValueKeys.LC;
    }

    const message$ = this.contentfulService.getContent(ContentKeys.CHECKOUT_TERMS_CONDITIONS, key, '<p style="margin-bottom: 15px;">'
      + 'Orders cannot be canceled after your proofs have been released. Once you have approved each of your items, they'
      + 'will immediately go to print and the order cannot be stopped or canceled. Please be mindful of this as you review'
      + 'and approve your materials.'
      + '</p>'

      + '<p style="margin-bottom: 15px;">'
      + 'If you have ordered photography as part of your Listing Concierge package, please note that it’s only applicable'
      + 'to one property only (i.e. if you are listing a property with multiple units, you can only use your Listing'
      + 'Concierge photography for one of the units).'
      + '</p>'

      + '<p style="margin-bottom: 15px;">'
      + 'If you did not enter all of your information (including listing information, content and marketing copy), please'
      + 'return to the Listing Details section and complete the information. You may continue to make changes until your'
      + 'Listing Concierge Coordinator has accepted the order.'
      + '</p>'

      + '<p style="margin-bottom: 15px;">'
      + 'Once your Listing Concierge Coordinator has accepted the order, certain features of the Listing Concierge platform'
      + 'will be locked until they release your proofs.'
      + '</p>'

      + '<p style="margin-bottom: 15px;">'
      + 'When you receive your proofs, it is your responsibility to ensure that you thoroughly review the content,'
      + 'photographs, layout, spelling, color, print marks, etc. Coldwell Banker, as well as your Listing Concierge'
      + 'Coordinator, office staff and Branch Manager are not responsible for issues with your order that have been'
      + 'pre-approved by you or your assistant, designer, team member, etc.'
      + '</p>'

      + '<p style="margin-bottom: 15px;">'
      + 'Office staff and employees of Coldwell Banker are not permitted to approve your orders on your behalf.'
      + '</p>'

      + '<p style="margin-bottom: 15px;">'
      + 'It is highly recommended to use a larger device, such as your desktop or laptop computer, to review and approve'
      + 'your proofs. We do not recommend reviewing and approving your proofs on your phone or smaller mobile device (i.e.'
      + 'tablet or iPad). Once photography has been delivered, no refunds will be provided.</p>'

      + '<p>If I selected the Listing Concierge package that includes, in certain materials, the co-marketing of an affinity '
      + 'partner (“Partner”), e.g. Guaranteed Rate Affinity loan officer, I understand that (i) such Partner will pay the fair '
      + 'market value of such marketing (ii) the payment by Partner is for the fair marketing value and shall not constitute a '
      + 'payment for referrals and (iii) I am not obligated to refer business to such Partner.'
      + '</p>');

    return message$.pipe(take(1)).toPromise();
  }
}
