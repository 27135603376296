import { ValidatorFn, AbstractControl } from '@angular/forms';

/** These tags will be replaced for the paragraph tags as they are unsupported in the productTemplates */
const paragraphTags: string[] = ['h[1-6]'];

/** These tags will be replaced for the span tags as they are unsupported in the productTemplates */
const spanTags: string[] = ['sub', 'sup'];

/** These are the html elements that we want to be remove the html as they are unsupported in the productTemplates */
const strippedElements: string[] = [
  '<s>', '</s>', // remove strikethrough tags
  'ql-align-justify', // Remove text justification
  'class=""', // Remove empty class attributes
  'style="[^"]*"*"', // Remove all style attributes
  '<img[\\s\\S]*?>', // Remove all image elements
];

/**
 * Function to replace specific tags in html with a new tag
 * @param html The HTML to replace tags in
 * @param oldTags The old tags (i.e. 'h1' or ['h1', 'h2])
 * @param newTag The new tag to use as a replacement
 */
function replaceTag(html: string, oldTags: string | string[], newTag: string) {
  const replaceTags = oldTags instanceof Array ? oldTags : [oldTags];
  const newHtml = replaceTags.reduce((newHtmlString, oldTag) => {
    const openingTagRegex = new RegExp(`<${oldTag}`, 'g');
    const closingTagRegex = new RegExp(`</${oldTag}>`, 'g');
    return newHtmlString.replace(openingTagRegex, `<${newTag}`).replace(closingTagRegex, `</${newTag}>`);
  }, html);

  return newHtml;
}

/**
 * Removes given html elements from an html string
 * @param html The html to parse
 * @param elements The element(s) to remove
 */
function removeElements(html: string, elements: string | string[]) {
  const elementsToRemove = elements instanceof Array ? elements : [elements];
  const newHtml = elementsToRemove.reduce((newHtmlString, elementToRemove) => {
    const openingTagRegex = new RegExp(`${elementToRemove}`, 'g');
    return newHtmlString.replace(openingTagRegex, '');
  }, html);

  return newHtml;
}
/**
 * Validator that will replace html with proper html text. Does not actually invalidate the
 * input, but instead replaces the values with the proper html elements
 */
export function productHtmlValidator(allowLinks: boolean = false): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const { value } = control;
    if (!value) {
      return null; // If the value is null or empty, nothing to validate. Just return
    }

    const elementsToRemove = [...strippedElements];
    const elementsToReplaceWithSpan = [...spanTags];
    if (!allowLinks) {
      elementsToRemove.push('href="[^"]*"*"'); // Remove all href attributes)
      elementsToReplaceWithSpan.push('a');
    }

    // Replace tags that are not supported by digital or print products
    let newValue = replaceTag(value, paragraphTags, 'p');
    newValue = replaceTag(newValue, elementsToReplaceWithSpan, 'span');
    newValue = removeElements(newValue, elementsToRemove);

    if (newValue !== value) {
      // If we replaced some values, need to set the value again
      control.setValue(newValue, { emitEvent: false });
    }

    // Returning null will keep this control marked as valid, so long as other validators aren't invalid
    return null;
  };
}
