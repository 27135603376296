// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

// Header Components
import { HeaderComponent } from './header.component';

const angularModules = [CommonModule, MatIconModule];
const externalComponents = [HeaderComponent];

@NgModule({
  declarations: [
    ...externalComponents,
  ],
  exports: [
    ...externalComponents,
  ],
  imports: [
    ...angularModules,
  ],
})
export class HeaderModule { }
