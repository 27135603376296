import { Profile } from '../../models/profile.model';
import * as ProfileActionTypes from './profile.actions';

export const initialState: Profile = null;

export function profileReducer(state = initialState, action: any): Profile {
  switch (action.type) {
    case ProfileActionTypes.ClearProfile.type: {
      return null;
    }
    case ProfileActionTypes.LoadProfile.type: {
      return action.payload;
    }
    case ProfileActionTypes.UpdateProfile.type: {
      return action.payload;
    }
    case ProfileActionTypes.GetProfileComplete.type:
    case ProfileActionTypes.UpdateProfileComplete.type: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
