import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MarketingOrderQRCodes } from '../models';
import { ApiService } from './api.service';

@Injectable()
export class QRCodeService {
  constructor(private apiService: ApiService) { }

  getQRStats(orderId: string): Observable<MarketingOrderQRCodes> {
    return this.apiService.query$('qr-code/stats', { params: { orderId }, version: 'v2' }).pipe(map((res) => new MarketingOrderQRCodes(res)));
  }
}
