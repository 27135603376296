<ng-container *ngIf="data$ | async as data">
  <table mat-table [dataSource]="data?.qrCodes" id="qr-statistics-table">
    <ng-container matColumnDef="productTitle">
      <th mat-header-cell *matHeaderCellDef id="field-product-name">Product Name</th>
      <td mat-cell *matCellDef="let qrCode">{{ qrCode?.data?.productTitle }}</td>
      <td mat-footer-cell *matFooterCellDef>Total</td>
    </ng-container>
    
    <ng-container matColumnDef="numberOfScans">
      <th mat-header-cell *matHeaderCellDef id="field-number-of-scans">Number of Scans</th>
      <td mat-cell *matCellDef="let qrCode">{{ qrCode?.hits }}</td>
      <td mat-footer-cell *matFooterCellDef>{{ data?.totalHits }}</td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  </table>
</ng-container>
