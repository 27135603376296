import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { ExifParserFactory } from 'ts-exif-parser';
import { IImageInfo } from '../models/upload-photo.model';
import { FeatureFlags, LaunchDarklyService } from './launch-darkly.service';

const sRGBColorSpace = '1';

/**
 * The ImageLoaderService is responsible for taking in processing an image file,
 * performing validation and converting it to data64.
 */
@Injectable({
  providedIn: 'root',
})
export class ImageLoaderService {
  constructor(private readonly featureFlagService: LaunchDarklyService) { }

  /**
   * Takes in an image file, and performs validation. If validation passes, it will load the file
   * into an HtmlImage and return the DataURL
   * @param file
   * @param allowedExtensions
   * @param minFileSizeInMB
   */
  loadFile(file: File, allowedExtensions: string[], minFileSizeInMB?: number): Observable<IImageInfo> {
    return new Observable((observer) => {
      this.validateImageFile(file, allowedExtensions, minFileSizeInMB)
        .then((msg) => {
          if (msg) {
            observer.error(msg);
          } else {
            this.loadImage(file, observer);
          }
        });
    });
  }

  async validateImageFile(file: File, allowedExtensions: string[], minFileSizeInMB?: number): Promise<string> {
    if (!this.validateFileExtension(file, allowedExtensions)) {
      return `Invalid format (Should one of ${allowedExtensions.join(', ')})`;
    }

    if (!this.validateFileSize(file, minFileSizeInMB)) {
      return `Image too small (Should be at least ${minFileSizeInMB}MB)`;
    }

    const isValidProfile = await this.validateColorProfile(file);
    if (!isValidProfile) {
      return 'Must have sRGB color profile';
    }
    return null;
  }

  /**
   * Validates the file extension against a list of allowed extensions
   * @param file The file to validate
   * @param allowedExtensions The allowed extensions
   */
  public validateFileExtension(file: File, allowedExtensions: string[]): boolean {
    let filenameSuffix = file.name ? file.name.split('.').slice(-1)[0] : undefined;
    filenameSuffix = filenameSuffix ? filenameSuffix.toLowerCase() : undefined;
    return allowedExtensions.some((extension) => extension.replace('.', '') === filenameSuffix);
  }

  /**
   * We have problems in print PDF files with images that have a non-standard
   * color-space. Here we extract the color space from the exif data in the
   * file.
   *
   * @param file
   * @return  a truthy value that can be used as an error message or null
   *         if the image color profile is ok to use.
   */
  public async validateColorProfile(file: File): Promise<boolean> {
    const isEnabled = await this.featureFlagService.isFeatureEnabled(FeatureFlags.PHOTO_COLOR_VALIDATION, false);
    if (!isEnabled) {
      // no color validation if the flag is not enabled
      return true;
    }
    const buffer = await this.readFile(file);

    const tags = this.parseExifTags(buffer);
    // color-space appears to equal color-profile.
    // extract the color-space. If no color-space is present,
    // we will accept the image.
    const colorSpace = tags.ColorSpace;

    // If there is no color-space in Exif, or the color-space is sRGB it is
    // an acceptable image file.
    //
    // the typescript declaration for ExifData says the values are all string
    // but in developing this code ColorSpace was always a number
    // so we coerce it to a string for comparison.
    return !colorSpace || (colorSpace.toString() === sRGBColorSpace);
  }

  public async readFile(file: File): Promise<ArrayBuffer> {
    // this was the only way I couild find to read a local file in the browser
    const url = URL.createObjectURL(file);
    const response = await fetch(url);
    const buffer = await response.arrayBuffer();
    URL.revokeObjectURL(url);

    return buffer;
  }

  public parseExifTags(buffer: ArrayBuffer): any {
    const parser = ExifParserFactory.create(buffer);
    const result = parser.parse();
    // get the Exif tags but the image may not even have any Exif data
    return result.tags || {};
  }
  /**
   * Validates the file size
   * @param file The file to validate
   * @param minimumFileSizeInMB The file size in MB
   */
  public validateFileSize(file: File, minimumFileSizeInMB?: number) {
    // If there is no minimum file size, return true. It's valid
    if (minimumFileSizeInMB == null) { return true; }

    // Otherwise, convert the file size to MB and compare
    const fileSizeInMB = file.size / (1024 * 1024);
    return fileSizeInMB >= minimumFileSizeInMB;
  }

  private loadImage(file: File, observer: Subscriber<IImageInfo>) {
    // Scale here in code so we know when scaling is complete
    // Otherwise scaling can often take longer than the upload.
    // If upload/progress completes before thumbnail is visible, the display looks very odd
    const img = new Image();
    img.onload = () => this.onImageLoaded(img, observer);
    img.onerror = (e: ErrorEvent) => this.onImageError(e, file, observer);
    img.src = URL.createObjectURL(file);
  }

  private onImageError(e: ErrorEvent, file: File, observer: Subscriber<IImageInfo>) {
    // If the Image errors, we need to set an appropriate error message and have the observable error out
    const msg = `Error loading thumbnail for ${file.name}:${e.message}`;
    console.warn(msg);
    observer.error(msg);
  }

  /**
   * Load the HTML Image into a file and complete the observable with the DataURL
   * @param image
   * @param observer
   */
  private onImageLoaded(image: HTMLImageElement, observer: Subscriber<{ dataUrl: string, height: number, width: number }>) {
    // Dump the image into a canvas to retrieve the DataURL
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const { width } = image;
    const { height } = image;
    const scale = Math.min((150 / width), (150 / height));
    const iwScaled = width * scale;
    const ihScaled = height * scale;
    canvas.width = iwScaled;
    canvas.height = ihScaled;
    ctx.drawImage(image, 0, 0, iwScaled, ihScaled);

    // NOT Sure what this is doing? Do we need this?
    const thumb = new Image();
    thumb.src = canvas.toDataURL();

    URL.revokeObjectURL(image.src);

    // Return the DataURL as the response to the observable
    const dataUrl = canvas.toDataURL();
    observer.next({ dataUrl, height, width });
    observer.complete();
  }
}
