import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Internal Directives
import { BadgeDirective } from './directives/badge.directive';
import { FeatureFlagDirective } from './directives/feature-flag.directive';
import { PresignedPhotoDirective, PresignedPhotoBackgroundDirective } from './directives/presigned-photo.directive';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { ScreenSizeDirective } from './directives/screen-size.directive';
import { HoveredDirective } from './directives/hovered.directive';
import { DetectSelectionOnClickDirective } from './directives/detect-selection-on-click.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { NoBreakHyphenDirective } from './directives';
import { ResponsiveClassDirective } from './directives/responsive-class.directive';

const angularModules = [CommonModule];
const directives = [
  BadgeDirective, FeatureFlagDirective, ScreenSizeDirective,
  PresignedPhotoDirective, PresignedPhotoBackgroundDirective, DebounceClickDirective,
  DetectSelectionOnClickDirective, ClickOutsideDirective, NoBreakHyphenDirective, ResponsiveClassDirective,
];
const standaloneDirectives = [
  HoveredDirective,
];

@NgModule({
  declarations: [...directives],
  imports: [
    ...angularModules,
    ...standaloneDirectives,
  ],
  exports: [
    ...directives,
    ...standaloneDirectives,
  ],
})
export class UICoreModule { }
