import { Deserializable } from './deserializable.model';
import { ContactType } from './contact.type';
import { AddressInformation } from './address-information.model';
import { SocialMedia, MediaType } from './social-media.model';

export class CardContactInfo implements Deserializable<CardContactInfo> {
  type: ContactType;
  address: AddressInformation;
  socialMedia: SocialMedia;

  constructor(input?: any) {
    this.deserialize(input || {});
  }

  deserialize(input: any): CardContactInfo {
    Object.assign(this, input);
    if (this.address) {
      this.address = new AddressInformation(this.address);
    }

    return this;
  }

  getCardInfoSocialMedia(socialMedia) {
    return ContactInfoFactory.getContactInfoSocialMediaFactory(socialMedia);
  }
}

export type ContactInfo = CardContactInfo;

export class ContactInfoFactory {
  public static createContactInfo(data: any) {
    if (!data || !data.type) {
      throw new Error('Must supply data and a contact info type');
    }
    switch (data.type) {
      case ContactType.card: {
        return new CardContactInfo(data);
      }
      case ContactType.socialMedia: {
        return new CardContactInfo(data);
      }
      default: {
        throw new Error('only card contacts currently supported');
      }
    }
  }

  static getContactInfoSocialMediaFactory(socialmedia: any) {
    if (!socialmedia || !socialmedia.contactInfo) { return []; }

    const getSocialMediaKeys = Object.keys(socialmedia.contactInfo);
    const getSocialMediaType = getSocialMediaKeys.reduce((all, item) => {
      const contactInfo = new CardContactInfo();
      contactInfo.type = ContactType.socialMedia;

      const socialMediaObj = new SocialMedia();
      const mediaValue = socialmedia.contactInfo[item];
      socialMediaObj.type = item;
      socialMediaObj.link = mediaValue;
      contactInfo.socialMedia = mediaValue;
      if (mediaValue.link) {
        all.push(contactInfo);
      }

      return all;
    }, []);

    return getSocialMediaType;
  }
}
