<div class="fx-gap-xs header page-header border-bottom" lcClass.lt-md="fx-column" lcClass.gt-sm="fx-row x-space-between">

  <div class="fx-column fx-grow">
    <h1 *ngIf="header" class="page-title m-0">{{header}}</h1>
    <h4 *ngIf="subheader" class="mat-title m-0 page-subtitle">{{subheader}}</h4>
  </div>

  <div *ngIf="caption" class="fx-column fx-1-1-auto" lcClass.gt-sm="text-right">
    <span class="mat-caption m-0 page-caption">{{caption}}</span>
  </div>


</div>
