import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ApiService } from './api.service';
import {
  PhotoAgency, ProductInstance, ServiceActions, MarketingOrder, Fulfillment,
} from '../models';
import { UpdateCurrentOrderState, UpdateCurrentProductState } from '../state-mgmt/order/order.actions';
import { BaseForm } from '../forms';

@Injectable({
  providedIn: 'root',
})
export class MarketingOrderProductService {
  constructor(private apiService: ApiService, private store: Store<any>) { }

  /**
   * Returns the scoped agencies for the given marketing order and productCode
   * @param marketingOrderId The marketingOrder to scope agencies from
   * @param productCode The productCode used for agencyOverrides
   */
  getAgencies$(marketingOrderId: string, productCode: string): Observable<PhotoAgency[]> {
    return this.apiService.get<PhotoAgency[]>(`marketing-orders/${marketingOrderId}/products/${productCode}/vendors`).pipe(
      map((agents) => agents.map((agent) => new PhotoAgency(agent))),
    );
  }

  /**
   * Take an action on a product that may change state
   * @param id the marketing order id
   * @param action the action
   * @param product the product to act upon
   */
  async productAction(id: string, action: ServiceActions, product: ProductInstance): Promise<MarketingOrder> {
    const route = `marketing-orders/${id}/action/${action}?productCode=${product.code}`;
    return await this.apiService.put<MarketingOrder>(route, { approval: product.approval, targetState: product.status }).pipe(
      map((updatedOrder) => new MarketingOrder(updatedOrder)),
      tap((updatedOrder) => this.store.dispatch(UpdateCurrentOrderState({ payload: updatedOrder }))),
      catchError((errors) => { throw Error(this.formatErrors(errors, 'An error occurred updating the product status.')); }),
    ).toPromise();
  }

  getContactBlock$(marketingOrderId: string, product: ProductInstance): Observable<string> {
    const route = `marketing-orders/${marketingOrderId}/products/${product.code}/contact-block`;
    return this.apiService.get<string>(route).pipe(
      catchError((errors) => { throw Error(this.formatErrors(errors, 'An error occurred while retrieving the contact block')); }),
    );
  }

  async patchProduct(marketingOrderId: string, productCode: string, updates: Partial<ProductInstance>) {
    // these should not be marked dirty if they are empty
    if (!updates.marketingCopyHeadline?.length) {
      delete updates.marketingCopyHeadline;
    }
    if (!updates.marketingCopyBody?.length) {
      delete updates.marketingCopyBody;
    }

    const route = `marketing-orders/${marketingOrderId}/products/${productCode}`;
    return await this.apiService.patch<MarketingOrder>(route, updates, { model: MarketingOrder })
      .then((updatedOrder) => this.store.dispatch(UpdateCurrentOrderState({ payload: updatedOrder })))
      .catch((errors) => { throw Error(this.formatErrors(errors, 'An error occured while patching the product')); });
  }

  async updateFulfillment(orderId: string, productCode: string, form: BaseForm<Fulfillment>) {
    const patchValue = form.getValue({ onlyDirty: true });// These are only the form values that have changed;
    const route = `orders/${orderId}/products/${productCode}/fulfillments`;
    const product = await this.apiService.patch(route, patchValue, { model: ProductInstance, version: 'v2' });
    this.store.dispatch(UpdateCurrentProductState({ orderId, product }));
    return product;
  }

  async completeFulfillment(orderId: string, productCode: string, payment: { stripeTokenId?: string, acceptedTerms: boolean }) {
    const route = `orders/${orderId}/products/${productCode}/fulfillments/submit`;
    const product = await this.apiService.create(route, payment, { model: ProductInstance, version: 'v2' });
    this.store.dispatch(UpdateCurrentProductState({ orderId, product }));
    return product;
  }

  /**
   * Check patched headline and body for non-string values.
   *
   * If any non-string values are found, log the data and convert the values
   * to strings.
   *
   * @param patchValue
   */
  fixPatchValue(patchValue, orderId, productCode): any {
    const mapper = function (item, index) {
      let result: any = item;
      if (typeof item !== 'string') {
        result = (item) ? item.toString() : item;
        console.warn(
          `order: ${orderId}, product; ${productCode}, ${this.name}[${index}] was converted to a string: ${result}`,
        );
      }
      return result;
    };

    if (patchValue?.marketingCopyHeadline?.length) {
      patchValue.marketingCopyHeadline = patchValue.marketingCopyHeadline.map(mapper, { name: 'marketingCopyHeadline' });
    }
    if (patchValue?.marketingCopyBody?.length) {
      patchValue.marketingCopyBody = patchValue.marketingCopyBody.map(mapper, { name: 'marketingCopyBody' });
    }

    return patchValue;
  }

  async replaceProduct(marketingOrder: MarketingOrder, pkgCode: string, fromProductCode: string, toProductCode: string) {
    const route = `marketing-orders/${marketingOrder._id}/packages/${pkgCode}/products/replace/${fromProductCode}/${toProductCode}`;
    return await this.apiService.post<MarketingOrder>(route, marketingOrder).pipe(
      map((updatedOrder) => new MarketingOrder(updatedOrder)),
      tap((updatedOrder) => this.store.dispatch(UpdateCurrentOrderState({ payload: updatedOrder }))),
    ).toPromise();
  }

  private formatErrors(errors: any, defaultError?: string) {
    const message = errors?.error?.error?.raw?.message || errors?.error?.error?.message || defaultError;
    return message.toString();
  }
}
