import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContentGenerator } from './content-generator';
import {
  MarketingOrder, OutputMimeType, ProductInstance, Address,
} from '../models';
import { VideoOrderService } from './video-order.service';

@Injectable()
export class VideoGenerationService implements ContentGenerator {
  mimeType = OutputMimeType.json;

  downloadable = false;

  constructor(
    private http: HttpClient,
    private videoOrderService: VideoOrderService,
  ) {

  }

  generate(order: MarketingOrder, product: ProductInstance, otherData?: { consent?: boolean }): Observable<any> {
    return this.videoOrderService.get(order._id, product.code);
  }

  download(order: MarketingOrder, product: ProductInstance, imageResolution?: string): Observable<any> {
    return of(null);
  }

  downloadById(marketingOrderId: string, address: Address, product: ProductInstance | { title: string, code: string }): void {

  }
}
