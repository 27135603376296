import {
  Component, EventEmitter, Input, Output, OnChanges, SimpleChanges,
} from '@angular/core';
import { BaseForm } from '@lc/core';
import { DynamicFormModel } from './dynamic-form.model';
import { DynamicForm, DynamicFormGroup } from './dynamic-form.form';

@Component({
  selector: 'lc-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnChanges {
  @Input() model: DynamicFormModel;

  @Output() readonly saved = new EventEmitter<DynamicForm>();

  groupIndex = 0;
  group: DynamicFormGroup;
  readonly form = new DynamicForm();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model && this.model) {
      this.form.reset(this.model);
      this.groupIndex = 0;
      this.group = this.form.groups[0];
    }
  }

  onDone(group: DynamicFormGroup) {
    if (!group.valid) {
      return BaseForm.markAllAsDirty(group);
    }

    this.saved.emit(this.form);
  }
}
