import { PhotoAgency } from '../../models/photography-agency.model';
import * as PhotographyAgencyActionTypes from './photography-agency.actions';

export const photographyAgencyInitialState: PhotoAgency[] = null;

export function photographyAgencyReducer(state = photographyAgencyInitialState, action: any): PhotoAgency[] {
  switch (action.type) {
    case PhotographyAgencyActionTypes.GetAllPhotographyAgenciesComplete.type: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
