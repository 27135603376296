import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef, OnDestroy, EmbeddedViewRef, OnInit,
} from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

export type ScreenSize = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
/**
 * This is a structural directive that displays/hides elements based on a given feature flag.
 * i.e. - <button *lcFeatureFlag="show-button-feature"></button>
 */
@Directive({
  selector: '[lcScreenSize]',
})
export class ScreenSizeDirective implements OnInit, OnChanges, OnDestroy {
  private _currentTemplate: TemplateRef<any>;
  private _currentRef: EmbeddedViewRef<any>;
  private _currentSize: ScreenSize;
  private _subscription: Subscription;

  @Input('lcScreenSize') device: ScreenSize | ScreenSize[] | string | string[];
  @Input() lcScreenSizeElse: TemplateRef<any>;

  private size$: Observable<ScreenSize>;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private mediaObserver: BreakpointObserver) {
    const isSmall$ = this.checkAlias$(Breakpoints.Small);
    const isMedium$ = this.checkAlias$(Breakpoints.Medium);
    const isLarge$ = this.checkAlias$(Breakpoints.Large);
    this.size$ = combineLatest([isSmall$, isMedium$, isLarge$]).pipe(
      map(([isSmall, isMedium]) => {
        let size: ScreenSize;

        if (isSmall) {
          size = 'small';
        } else if (isMedium) {
          size = 'medium';
        } else {
          size = 'large';
        }

        return size;
      }),
    );
  }

  ngOnInit() {
    this._subscription = this.size$
      .subscribe((size) => {
        this._currentSize = size;
        this.onChanges();
      });
  }

  ngOnChanges(_changes: SimpleChanges) {
    this.onChanges();
  }

  ngOnDestroy() {
    this._subscription?.unsubscribe();
  }

  private checkAlias$(alias: string | string[]) {
    return this.mediaObserver.observe(alias).pipe(
      map((state) => state.matches),
    );
  }

  private onChanges() {
    const device = this.device instanceof Array ? this.device : [this.device];
    this.setVisibility(device?.includes(this._currentSize));
  }

  private setVisibility(canView: boolean) {
    if (canView) {
      return this.show(this.templateRef);
    } if (this.lcScreenSizeElse) {
      return this.show(this.lcScreenSizeElse);
    }
    return this.clear();
  }

  private show(template: TemplateRef<any>) {
    // If it is already diplayed, dont show it
    if (this._currentTemplate === template) { return; }

    // Otherwise, clear the container and update the view
    this.clear();
    this._currentRef = this.viewContainer.createEmbeddedView(template);
    this._currentTemplate = template;
  }

  private clear() {
    if (!this._currentRef) { return; }
    this.viewContainer.clear();
    this._currentRef = null;
    this._currentTemplate = undefined;
  }
}
