import { Deserializable } from './deserializable.model';
import { UIConstants } from '../constants/UI.constants';
import { TeamProfile } from './team-profile.model';

export enum TeamPhotoOptions {
  teamOnly = 'team-only',
  twoMembers = 'two-members',
  teamWithLead = 'team-with-lead',
  none = 'none',
}

export enum TeamNameOptions {
  teamOnly = 'team-only',
  teamWithLead = 'team-with-lead',
  teamWithTwoMembers = 'team-with-two-members',
  twoMembers = 'two-members',
  none = 'none',
}

export enum TeamPhoneOptions {
  teamOnly = 'team-only',
  teamWithLead = 'team-with-lead',
  teamWithTwoMembers = 'team-with-two-members',
  twoMembers = 'two-members',
  office = 'office',
  none = 'none',
}

export enum TeamEmailOptions {
  teamOnly = 'team-only',
  teamWithLead = 'team-with-lead',
  twoMembers = 'two-members',
  none = 'none',
}

export enum TeamWebsiteOptions {
  teamOnly = 'team-only',
  teamWithLead = 'team-with-lead',
  twoMembers = 'two-members',
  none = 'none',
}

export enum TeamLicenseOptions {
  leadOnly = 'lead-only',
  twoMembers = 'two-members',
  teamWithLead = 'team-with-lead',
  none = 'none',
}

export enum TeamLogoOptions {
  teamOnly = 'team-only',
  lead = 'lead-only',
  twoMembers = 'two-members',
  none = 'none',
}

/**
 * Holds the display value for a provided enum value. Can be used with other enums if needed
 */
export class OptionDisplay<TEnum> {
  public disabled?: boolean;
  constructor(public value: TEnum, public display: string) {
  }
}

const teamPhotoDisplayOptions = [
  new OptionDisplay(TeamPhotoOptions.teamOnly, UIConstants.TEAM_ONLY),
  new OptionDisplay(TeamPhotoOptions.teamWithLead, UIConstants.TEAM_WITH_LEAD),
  new OptionDisplay(TeamPhotoOptions.twoMembers, UIConstants.TWO_MEMBERS),
  new OptionDisplay(TeamPhotoOptions.none, UIConstants.NONE),
];

const teamNameDisplayOptions = [
  new OptionDisplay(TeamNameOptions.teamOnly, UIConstants.TEAM_ONLY),
  new OptionDisplay(TeamNameOptions.teamWithLead, UIConstants.TEAM_WITH_LEAD),
  new OptionDisplay(TeamNameOptions.teamWithTwoMembers, UIConstants.TEAM_WITH_TWO_MEMBERS),
  new OptionDisplay(TeamNameOptions.twoMembers, UIConstants.TWO_MEMBERS),
  new OptionDisplay(TeamNameOptions.none, UIConstants.NONE),
];

const teamPhoneDisplayOptions = [
  new OptionDisplay(TeamPhoneOptions.teamOnly, UIConstants.TEAM_ONLY),
  new OptionDisplay(TeamPhoneOptions.teamWithLead, UIConstants.TEAM_WITH_LEAD),
  new OptionDisplay(TeamPhoneOptions.teamWithTwoMembers, UIConstants.TEAM_WITH_TWO_MEMBERS),
  new OptionDisplay(TeamPhoneOptions.twoMembers, UIConstants.TWO_MEMBERS),
  new OptionDisplay(TeamPhoneOptions.office, UIConstants.OFFICE),
  new OptionDisplay(TeamPhoneOptions.none, UIConstants.NONE),
];

const teamEmailDisplayOptions = [
  new OptionDisplay(TeamEmailOptions.teamOnly, UIConstants.TEAM_ONLY),
  new OptionDisplay(TeamEmailOptions.teamWithLead, UIConstants.TEAM_WITH_LEAD),
  new OptionDisplay(TeamEmailOptions.twoMembers, UIConstants.TWO_MEMBERS),
  new OptionDisplay(TeamEmailOptions.none, UIConstants.NONE),
];

const teamWebsiteDisplayOptions = [
  new OptionDisplay(TeamWebsiteOptions.teamOnly, UIConstants.TEAM_ONLY),
  new OptionDisplay(TeamWebsiteOptions.teamWithLead, UIConstants.TEAM_WITH_LEAD),
  new OptionDisplay(TeamWebsiteOptions.twoMembers, UIConstants.TWO_MEMBERS),
  new OptionDisplay(TeamWebsiteOptions.none, UIConstants.NONE),
];

const teamLicenseDisplayOptions = [
  new OptionDisplay(TeamLicenseOptions.leadOnly, UIConstants.LEAD_ONLY),
  new OptionDisplay(TeamLicenseOptions.teamWithLead, UIConstants.TEAM_WITH_LEAD),
  new OptionDisplay(TeamLicenseOptions.twoMembers, UIConstants.TWO_MEMBERS),
  new OptionDisplay(TeamLicenseOptions.none, UIConstants.NONE),
];

const teamLogoDisplayOptions = [
  new OptionDisplay(TeamLogoOptions.teamOnly, UIConstants.TEAM_ONLY),
  new OptionDisplay(TeamLogoOptions.lead, UIConstants.LEAD_ONLY),
  new OptionDisplay(TeamLogoOptions.twoMembers, UIConstants.TWO_MEMBERS),
  new OptionDisplay(TeamLogoOptions.none, UIConstants.NONE),
];

export class TeamContactPreferences implements Deserializable<TeamContactPreferences> {
  constructor(value?: any) {
    this.deserialize(value || {});
  }

  static getTeamNameDisplayOptions(): OptionDisplay<TeamNameOptions>[] {
    return teamNameDisplayOptions;
  }

  static getTeamPhoneDisplayOptions(): OptionDisplay<TeamPhoneOptions>[] {
    return teamPhoneDisplayOptions;
  }

  static getTeamEmailDisplayOptions(): OptionDisplay<TeamEmailOptions>[] {
    return teamEmailDisplayOptions;
  }

  static getTeamWebsiteDisplayOptions(): OptionDisplay<TeamWebsiteOptions>[] {
    return teamWebsiteDisplayOptions;
  }

  static getTeamLicenseDisplayOptions(): OptionDisplay<TeamLicenseOptions>[] {
    return teamLicenseDisplayOptions;
  }

  static getTeamLogoDisplayOptions(): OptionDisplay<TeamLogoOptions>[] {
    return teamLogoDisplayOptions;
  }

  static getTeamPhotoDisplayOptions(teamProfile?: TeamProfile): OptionDisplay<TeamPhotoOptions>[] {
    if (teamProfile) {
      return this.refineTeamPhotoDisplayOptions(teamProfile);
    }

    return teamPhotoDisplayOptions;
  }

  // Using the TeamProfile parameter refine the TeamPhotoDisplayOptions
  private static refineTeamPhotoDisplayOptions(teamProfile?: TeamProfile) {
    // if there is no teamProfile photo then we need to disable the team photo options
    if (!teamProfile.teamPhoto) {
      teamPhotoDisplayOptions.forEach((option) => {
        if (option.value === TeamPhotoOptions.teamOnly || option.value === TeamPhotoOptions.teamWithLead) {
          option.disabled = true;
        }
      });
      return;
    }

    if (teamProfile.teamPhoto) {
      const { metaData } = teamProfile.teamPhoto;
      const aspectRatio = Number.parseFloat(metaData && metaData.ratio ? metaData.ratio : null);
      // Disable team-with-lead option if the aspect ratio of the team photo is 2x1
      if (aspectRatio === 2) {
        teamPhotoDisplayOptions.forEach((option) => {
          if (option.value === TeamPhotoOptions.teamWithLead) {
            option.disabled = true;
          }
        });
      }
    }

    return teamPhotoDisplayOptions;
  }

  deserialize(input: any): TeamContactPreferences {
    Object.assign(this, input);
    return this;
  }
}
