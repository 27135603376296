import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UploadResultsDialogComponent, UploadResultsDialogData } from './upload-results-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class UploadResultsDialogService {
  constructor(private modal: MatDialog) { }

  openResults(failedMessages: string[], successfulMessages?: string[], isMultiple?: boolean): Promise<any> {
    const dialogRef = this.modal.open(UploadResultsDialogComponent, {
      data: new UploadResultsDialogData(failedMessages, successfulMessages, isMultiple),
      width: '500px',
      maxWidth: '90vw',
      maxHeight: '90vh',
    });
    const component: UploadResultsDialogComponent = dialogRef.componentInstance;
    component.close.subscribe(() => dialogRef.close(), (error) => { throw new Error(error); });

    return dialogRef.afterClosed().toPromise();
  }
}
