import { createAction, props } from '@ngrx/store';
import { User } from '../../models/user.model';
import { Profile } from '../../models/profile.model';

/**
 * Create ease of consumption wrapper actions
 * Eg.
 *
 * new LoadLoggedInUser();
 */
export const LoadLoggedInUser = createAction('[User] Load Logged In', props<{ user:User }>());

export const LoadUser = createAction('[User] Load User', props<{ payload:User }>());

export const UserProfileUpdated = createAction('[User] Profile Updated', props<{ payload:Profile }>());

export const LOGGEDINUSER = 'loggedInUser';
export const SELECTEDUSER = 'selectedUser';
