import { Deserializable } from './deserializable.model';

export interface QRCode {
  _id: string,
  data?: any,
  hits: number,
  redirectUrl: string,
  resourceUrl: string
}

export class MarketingOrderQRCodes implements Deserializable<MarketingOrderQRCodes> {
  totalHits?: number;
  qrCodes: QRCode[];

  constructor(input?: any) {
    this.deserialize(input);
  }

  deserialize(input?: any): MarketingOrderQRCodes {
    if (!input) { return this; }
    Object.assign(this, input);
    return this;
  }
}
