<mat-list-item class="py-2 bg-lightest">
  <div class="w-100 fx-row y-center fx-gap-sm" [ngClass]="isExpanded ? 'x-space-between' : 'x-center'">
    <mat-icon *ngIf="!hideIcon"
              matTooltip="{{ address?.streetAddress1 }}{{address?.streetAddress2 ? ' - ' + address?.streetAddress2 : ''}}, {{ address?.city }}, {{ address?.state }} {{ address?.postalCode }}"
              matTooltipPosition="right" [matTooltipDisabled]="isExpanded">place</mat-icon>

    <div *ngIf="isExpanded" class="fx-1-1-0">
      <address class="m-0 mat-body-2 fx-column x-center">
        <span class="mat-body-2 address-line line-height-0 text-center">{{ address?.streetAddress1 }}{{address?.streetAddress2 ? ' - ' +
          address?.streetAddress2 : ''}}</span>
        <span class="mat-caption line-height-0 text-center">{{ address?.city }}, {{ address?.state }} {{ address?.postalCode }}</span>
      </address>
    </div>

    <button *ngIf="isExpanded && showClipboard" mat-icon-button class="xs clipboard-icon align-self-start" ngxClipboard
            cbContent="{{address?.streetAddress1 }}, {{address?.streetAddress2 ? address?.streetAddress2 + ',' : ''}} {{address?.city }}, {{address?.state }} {{address?.postalCode}}"
            (cbOnSuccess)="copied($event)" matTooltip="Copy to clipboard">
      <mat-icon>content_copy</mat-icon>
    </button>

    <a *ngIf="editLink" mat-icon-button id="address-edit" [routerLink]="editLink" [replaceUrl]="replaceUrl" class="xs">
      <mat-icon>edit</mat-icon>
    </a>
  </div>
</mat-list-item>
