import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

/**
 * When the Close Dialog Resolve executes before a route change, it will make sure all
 * material and bootstrap dialogs are closed.
 */
@Injectable({
  providedIn: 'root',
})
export class CloseDialogResolve {
  constructor(private matDialogs: MatDialog) {
  }

  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    this.matDialogs.closeAll();
    return of(null);
  }
}
