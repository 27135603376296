import { Deserializable } from './deserializable.model';
import { User } from './user.model';

export interface TableConfigFilterDates {
  range: 'Today' | 'This Week' | 'This Month' | 'Past 90 Days' | 'Last Calendar Year' | 'Custom';
  startDate?: string;
  endDate?: string;
}

export interface LCCDashboardFilters {
  search?: string;
  areas?: string[];
  status?: string[];
  dates?: TableConfigFilterDates;
  flag?: ('Flagged' | 'Unflagged')[];
  userFilterOption?: 'agent' | 'lcc';
  userIds?: string[];
  assignments?: ('ASSIGNED' | 'UNASSIGNED')[];
}

export class TableConfig<TFilterModel = any> implements Deserializable<TableConfig> {
  _id: string;
  tableId: string;
  name: string;
  filters?: TFilterModel;
  user: string | User;
  sharedWith: (string | User)[];

  constructor(config?: Partial<TableConfig>) {
    this.deserialize(config);
  }
  deserialize(config?: Partial<TableConfig>): TableConfig {
    Object.assign(this, config);
    return this;
  }
}
