import {
  FormControl, FormArray, FormGroup, Validators,
} from '@angular/forms';
import { BaseForm } from './base.form';
import { PhoneNumberForm } from './phone-number.form';
import { TeamContactPreferencesForm } from './team-contact-preferences.form';
import { TeamMemberForm } from './team-member.form';
import { urlValidator } from '../validators/url.validator';
import { TeamMember, SocialMedia } from '../models';
import { PhotoInformationForm } from './photo-information.form';
import { emailValidator } from '../validators';
import { LCFormArray } from './lc-form-array';
import { SocialMediaForm } from './social-media.form';

export class TeamProfileForm extends BaseForm {
  get teamLogoURL() { return this.get('teamLogoURL').value; }
  public get contactInfo(): LCFormArray<SocialMedia> { return this.get('contactInfo') as LCFormArray<SocialMedia>; }

  constructor(model?: any, public options?: { validateType: boolean }) {
    super({
      _id: new FormControl(),
      name: new FormControl(null, [Validators.required, Validators.maxLength(35)]),
      phone: new PhoneNumberForm(),
      email: new FormControl(null, [Validators.required, emailValidator()]),
      websiteURL: new FormControl(null, [Validators.required, urlValidator()]),
      ownerId: new FormControl(),
      teamImage: new FormControl(),
      teamPhoto: new PhotoInformationForm(model ? model.teamPhoto : null),
      teamLogoURL: new FormControl(),
      members: new FormArray([], [Validators.required, Validators.minLength(2)]),
      contactPreferences: new TeamContactPreferencesForm(),
      contactInfo: new LCFormArray<SocialMedia>(
        model?.socialMedia || [],
        (socialMedia) => new SocialMediaForm(socialMedia, { typeRequired: true }),
      ),
    });

    this._originalValue = model;

    if (model) {
      this.patchValue(model);
      const members = model.members as Array<TeamMember> || [];
      members.forEach((member) => (this.get('members') as FormArray).push(new TeamMemberForm(member)));
    }
    if (model && model.contactInfo) {
      const contactInfo = this.get('contactInfo') as FormArray;
      model.contactInfo.forEach((element) => {
        if (element.type === 'socialMedia') {
          contactInfo.push(new SocialMediaForm(element.socialMedia, { ...this.options, typeRequired: true }));
        }
      });
    }
  }
}
