import {
  FormControl, FormGroup, Validators, ValidatorFn, AbstractControl,
} from '@angular/forms';
import { NotificationContact, NotificationSettings, UserSettings } from '../models/user-settings.model';
import { emailValidator } from '../validators';
import { BaseForm } from './base.form';
import { LCFormArray } from './lc-form-array';

const uniqueValidator = function (existing: LCFormArray<NotificationContact>): ValidatorFn {
  let emailArray = [];
  if (existing && existing.value) {
    emailArray = existing.value.map((ev) => ev.emailAddress.toLowerCase());
  }
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }
    if (emailArray.indexOf(control.value.toLowerCase()) >= 0) {
      return { emailExists: true };
    }
    return null;
  };
};

export class NotificationContactForm extends BaseForm<NotificationContact> {
  constructor(readonly model?: NotificationContact, readonly existing?: LCFormArray<NotificationContact>) {
    super({
      isEnabled: new FormControl(model?.isEnabled !== false),
      emailAddress: new FormControl(model?.emailAddress, [Validators.required, emailValidator(), uniqueValidator(existing)]),
    });
    this.isNew = this.model == null;
  }
}

export class NotificationSettingsForm extends BaseForm<NotificationSettings> {
  /** New contacts will be added to the form only once they are saved, to keep the form valid while toggling */
  readonly newContacts = new LCFormArray([], (contact) => new NotificationContactForm(contact, this.contacts));

  public get contacts(): LCFormArray<NotificationContact> {
    return this.getControl('contacts') as LCFormArray<NotificationContact>;
  }

  constructor(readonly model?: NotificationSettings) {
    super({
      isEnabled: new FormControl(model?.isEnabled !== false),
      contacts: new LCFormArray(model?.contacts || [], (contact) => new NotificationContactForm(contact)),
    });

    this.isNew = this.model == null;
  }

  onSaveNewContact(newContact: NotificationContactForm) {
    this.newContacts.deleteControl(newContact);
    this.contacts.addForm(newContact);
  }

  onDeleteContact(contactToDel: NotificationContactForm) {
    this.contacts.deleteControl(contactToDel);
  }
}

export class UserSettingsForm extends BaseForm<UserSettings> {
  public get notifications(): FormGroup {
    return this.getControl('notifications') as FormGroup;
  }

  constructor(readonly model?: UserSettings) {
    super({
      _id: new FormControl(model?._id),
      user: new FormControl(model?.userId),
      notifications: new FormGroup({
        EMAIL: new NotificationSettingsForm((model?.notifications || {}).EMAIL),
      }),
      isDarkTheme: new FormControl(model?.isDarkTheme || false),
    });
  }
}
