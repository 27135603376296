import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DragNDropHelperService {
  constructor(@Inject(DOCUMENT) private document: Document) { }

  private dragInfo = new BehaviorSubject<boolean>(false);
  dragStatus = this.dragInfo.asObservable();

  updateDragStatus(isDragging: boolean) {
    if (isDragging) {
      this.document.body.classList.add('dragging');
    } else {
      this.document.body.classList.remove('dragging');
    }
  }
}
