import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';

import { DialogsModule } from '../../dialogs';

import { QRStatisticsTableComponent } from './qr-statistics-table.component';
import { QRStatisticsTableDialogComponent } from './dialog/qr-statistics-table-dialog.component';

const angularModules = [CommonModule, MatTableModule];
const lcModules = [DialogsModule];

const externalModules = [CommonModule, MatTableModule];
@NgModule({
  declarations: [QRStatisticsTableComponent, QRStatisticsTableDialogComponent],
  imports: [...angularModules, ...lcModules],
  exports: [QRStatisticsTableComponent, ...externalModules],
})
export class QRStatisticsTableModule { }
