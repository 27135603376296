<div class="mt-5">
  <div class="text-center">
    <div id="home-button" class="app-brand demo py-5">
      <div class="app-brand-logo demo">
        <img src="/assets/images/ListingConcierge.svg">
      </div>
    </div>

    <div *ngIf="!isCompliant"><h1>Web browser not supported</h1></div>
    <div *ngIf="isCompliant">
      <h1>Congratulations! Your web browser is supported.</h1>
      <a class="mb-4" routerLink="/"><h4>Click here to navigate to the LC home page</h4></a>
    </div>

    <div class="row my-3 mx-0">
      <div class="col-xl-4 col-lg-3 col-md-2 col-sm-1"></div>
      <h2 *ngIf="!isCompliant" class="col-xl-4 col-lg-6 col-md-8 col-sm-10 h5">The web browser you are using is not supported. Please download one of the following browsers in order to access Listing Concierge:</h2>
      <h2 *ngIf="isCompliant" class="col-xl-4 col-lg-6 col-md-8 col-sm-10 h5">The web browser you are using is supported by the Listing Concierge team. Below is a list of all the supported browsers for your operating system</h2>
      <div class="col-xl-4 col-lg-3 col-md-2 col-sm-1"></div>
    </div>

    <div class="fx-row x-center">

      <div *ngFor="let browser of supportedBrowsers"  class="mat-elevation-z2 m-2 fx-0-0 w-200">
        <a [href]="browser.url" target="_blank">
          <div class="p-md-3 p-sm-2">
            <img class="w-100" [src]="browser.logo">
            <p class="h3 mt-3">{{browser.displayName || browser.name}}</p>
            <p>Minimum version:<br/>{{browser.version}}</p>
          </div>
        </a>
      </div>

    </div>
    <div>
      <p class="h5 mt-5">Your current configuration:</p>
      <ul>
        <li>Operating system: {{os}}</li>
        <li>Operating system version: {{osVersion}}</li>
        <li>Web browser: {{browser}}</li>
        <li>Web browser version: {{browserVersion}}</li>
      </ul>
    </div>
  </div>
</div>
