<h4 mat-dialog-title id="bulk-assign-title">{{data.title}}</h4>
<hr/>
<p id="bulk-assign-subtitle">{{data.subTitle}}</p>

<!--Modal Body-->
<div class="bulk-assign-modal-body">
  <lc-agent-search idPrefix="coordinator-search" [class.ng-valid]="error" [formControl]="coordinatorSearch" [searchBy]="roleTypeForReassign" (selectedChanged)="onCoordinatorSelected($event)" [max]="1" label="Search by Coordinator:" placeholder="Search by coordinator name"></lc-agent-search>
  <mat-error id="bulk-assign-error" *ngIf="error" [innerHTML]="error"></mat-error>
</div>

<!--Modal Footer -->
<div mat-dialog-actions>
  <lc-form-buttons id="bulk-assign-buttons" class="w-100" (save)="onCommit()" (cancel)="onCancel()" ></lc-form-buttons>
</div>
