import {
  Directive, EventEmitter, HostListener, Output,
} from '@angular/core';

@Directive({
  selector: '[lcClick]',
})
export class DetectSelectionOnClickDirective {
  @Output() readonly lcClick = new EventEmitter();

  /**
   * Will emit the click event only if:
   * 1) The user did NOT click on text, AND
   * 2) there is no text selected (most likely via a mouse-drag)
   *
   * @param event
   */
  @HostListener('click', ['$event'])
  clickEvent(event: any) {
    if (event?.target?.lastChild?.nodeName !== '#text' && !this.selectionExists()) {
      this.lcClick.emit(event);
    }
  }

  /**
   * Return true if a range of text is selected.
   *
   * @private
   */
  private selectionExists() {
    const selection = window.getSelection();
    return selection?.type === 'Range';
  }
}
