import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { BaseForm } from './base.form';
import { ProductInstance } from '../models/product-instance.model';
import { LCFormArray } from './lc-form-array';
import { wholeNumberValidator } from '../validators';

/**
 * Form Control representing the product's additional quantity
 */
export class AdditionalQuantityFormControl extends FormControl {
  readonly additionalUnitCost: number;
  readonly totalAdditionalCost$: Observable<number>;
  readonly totalUnits$: Observable<number>;

  constructor(public product: ProductInstance) {
    super(product.additionalUnits, [Validators.min(0), wholeNumberValidator()]);

    // When the value changes, update the total additional cost
    this.additionalUnitCost = product.details.additionalUnitPrice || product.details.unitPrice;
    this.totalAdditionalCost$ = this.valueChanges.pipe(
      startWith(this.value),
      map(() => +this.value * this.additionalUnitCost),
    );
    this.totalUnits$ = this.valueChanges.pipe(
      startWith(this.value),
      map(() => +this.value + product.details.unitQuantity),
      tap((total) => {
        if (total < 25) {
          this.setErrors({ custom: 'Orders require at least 25 total units' });
        } else if (total > 9999) {
          this.setErrors({ custom: `Orders cannot exceed ${9999 - product.details.unitQuantity} total units` });
        } else {
          this.setErrors(null);
        }
      }),
    );
  }
}

export class ProductInstanceForm extends BaseForm<ProductInstance> {
  get customContactBlock() { return this.getControl('customContactBlock'); }
  get marketingCopyHeadline() { return this.getControl('marketingCopyHeadline') as LCFormArray<string>; }
  get marketingCopyBody() { return this.getControl('marketingCopyBody') as LCFormArray<string>; }
  get customContactConsent() { return this.getControl('customContactConsent'); }
  get hiddenTags() { return this.getControl('hiddenTags'); }
  constructor(readonly originalModel?: ProductInstance) {
    // Declare ALL the fields that are allowed to be patched or updated from the UI
    super({
      customContactBlock: new FormControl(originalModel?.customContactBlock),
      hiddenTags: new FormControl(originalModel?.hiddenTags),
      marketingCopyHeadline: new LCFormArray<string>(
        originalModel?.marketingCopyHeadline,
        (headline) => new FormControl(headline), // TODO: Implement max chars here
      ),
      marketingCopyBody: new LCFormArray<string>(
        originalModel?.marketingCopyBody,
        (body) => new FormControl(body), // TODO: Implement max chars here
      ),
      // TODO: A strongly typed form is preferred, but this will suffice for the need now
      photos: new FormControl(originalModel?.photos),
      customContactConsent: new FormControl(),
    });
  }
}
