import { Deserializable } from './deserializable.model';
import { Audit } from './audit.model';

export enum PrintJobStatus {
  SENT = 'SENT',
  READY = 'READY',
  CANCELLED = 'CANCELLED',
  ERRORED = 'ERRORED',
  COMPLETED = 'COMPLETED',
}

export class PrintJobState implements Deserializable<PrintJobState> {
  /** State of the print job at the time of this webhook */
  state: PrintJobStatus;

  /** Timestamp when printjob was cancelled */
  canceled_at: string;

  /** Timestamp when printjob was completed */
  completed_at: string;

  constructor(input?: any) {
    this.deserialize(input);
  }

  deserialize(input: any): PrintJobState {
    Object.assign(this, input);

    return this;
  }
}

export class PrintJobWebhookPayload implements Deserializable<PrintJobWebhookPayload> {
  /** shipping_carrier, if specified */
  shipping_carrier?: string;

  /** tracking_numbers, if provided */
  tracking_numbers: Array<string> = [];

  /** State of the print job at the time of this webhook */
  State?: PrintJobState;

  constructor(input?: any) {
    this.deserialize(input);
  }

  deserialize(input: any): PrintJobWebhookPayload {
    Object.assign(this, input);

    return this;
  }
}

export class PrintJobUpdates implements Deserializable<PrintJobUpdates> {
  /** Timestamp when webhook call was received */
  webhookReceived?: string;

  /** Status of this webhook: sent, ready, cancelled, errored, completed */
  webhookStatus: PrintJobStatus = PrintJobStatus.SENT;

  /** Product code this printJob pertains to */
  webhookPayload?: PrintJobWebhookPayload;

  constructor(input?: any) {
    this.deserialize(input);
  }

  deserialize(input: any): PrintJobUpdates {
    Object.assign(this, input);

    return this;
  }
}

export class PrintJobTracking implements Deserializable<PrintJobTracking> {
  carrier?: string;

  trackingNumber?: string;

  constructor(input?: any) {
    this.deserialize(input);
  }

  deserialize(input: any): PrintJobTracking {
    Object.assign(this, input);

    return this;
  }
}

export class PrintJob implements Deserializable<PrintJob> {
  /** User this printJob pertains to */
  userId?: string;

  /** Marketing order this printJob pertains to */
  marketingOrderId?: string;

  /** Product code this printJob pertains to */
  productCode?: string;

  /** vendorOrderId of the printJob */
  vendorOrderId: string;

  /** vendorItemId of the printJob */
  vendorItemId: string;

  /** Status of the printJob: sent, ready, cancelled, errored, completed */
  status: PrintJobStatus = PrintJobStatus.SENT;

  /** Product code(s) this printJob corresponds to */
  updates: Array<PrintJobUpdates> = [];

  canonicalPrintJob?: any;
  vendorPrintJob?: any;

  tracking?: PrintJobTracking;

  constructor(input?: any) {
    this.deserialize(input);
  }

  deserialize(input: any): PrintJob {
    Object.assign(this, input);

    return this;
  }
}
