import { Deserializable } from './deserializable.model';

export class ProductConversionSource implements Deserializable<ProductConversionSource> {
  readonly type: 'quantity' | 'duration';
  readonly value: number;

  constructor(input?: Partial<ProductConversionSource>) {
    this.deserialize(input);
  }

  deserialize(input: Partial<ProductConversionSource>): ProductConversionSource {
    const defaults: Partial<ProductConversionSource> = { type: 'quantity' };
    return Object.assign(this, defaults, input);
  }
}

export class ProductConversion implements Deserializable<ProductConversion> {
  from: ProductConversionSource;
  to: ProductConversionSource;

  constructor(input?: Partial<ProductConversion>) {
    this.deserialize(input);
  }

  deserialize(input: Partial<ProductConversion>): ProductConversion {
    Object.assign(this, input);
    this.to = this.to || new ProductConversionSource();
    this.from = this.from || new ProductConversionSource();
    return this;
  }
}

export class ProductReplacement implements Deserializable<ProductReplacement> {
  readonly type: 'product';
  readonly value: string;
  readonly description?: string;
  readonly conversion: ProductConversion;

  constructor(input?: Partial<ProductReplacement>) {
    this.deserialize(input);
  }

  deserialize(input: Partial<ProductReplacement>): ProductReplacement {
    const defaults: Partial<ProductReplacement> = { type: 'product' };
    Object.assign(this, defaults, input);
    return this;
  }
}
