<form [formGroup]="form">

  <mat-horizontal-stepper #stepper class="h-100 bg-transparent hide-header p-0 fx-1-1-0"
                          [linear]="true">


    <ng-container *ngFor="let group of form.groups; let first = first; let last = last;">

      <mat-step [label]="group.group.header" [stepControl]="group.formGroup">

        <div [formGroup]="group" *ngFor="let control of group.sortedControls;">
          <ng-container *ngIf="control.input as input" [ngSwitch]="input.inputType">

            <lc-multiselect *ngSwitchCase="['multi-select', 'select'].includes(input.inputType) ? input.inputType : ''"
                            [label]="input.label"
                            [formControl]="control" [options]="input.options"
                            [displayOptions]="{display: '', multiDisplay: '', displayMultiCount: true}"
                            [displayPath]="'label'" [hint]="input.hint"
                            [valuePath]="'value'"
                            [multiple]="input.inputType === 'multi-select'"></lc-multiselect>

            <lc-textbox *ngSwitchCase="'textbox'" [formControl]="control" [label]="input.label"
                        [placeholder]="input.placeholder" [hint]="input.hint"></lc-textbox>

            <lc-checkbox *ngSwitchCase="'checkbox'" [formControl]="control" [label]="input.label"
                         [hint]="input.hint"></lc-checkbox>

            <lc-textarea *ngSwitchCase="'textarea'" [formControl]="control" [label]="input.label"
                         [placeholder]="input.placeholder" [hint]="input.hint" [rows]="3"></lc-textarea>

            <lc-radio-group *ngSwitchCase="'radio-group'"
                            layout="column"
                            [formControl]="control" [options]="input.options" [label]="input.label"
                            class="hide-form-borders"></lc-radio-group>

            <span *ngSwitchDefault>
              Missing {{option.inputType}}
            </span>
          </ng-container>
        </div>

        <mat-divider class="w-100 position-relative mb-3"></mat-divider>

        <div class="form-button-container fx-row fx-gap-xs">
          <button *ngIf="form.groups.length > 1" class="fx-1-1-0" mat-stroked-button matStepperPrevious [disabled]="first">Back</button>
          <button *ngIf="!last" class="fx-1-1-0" mat-stroked-button color="primary" matStepperNext>Next</button>
          <button *ngIf="last" class="fx-1-1-0" mat-stroked-button color="primary" (click)="onDone(group)">Complete</button>
        </div>


      </mat-step>
    </ng-container>
  </mat-horizontal-stepper>

</form>