import { FormControl } from '@angular/forms';
import { ListingPhoto } from '@lc/core';
import { BaseForm } from './base.form';

export class PhotoForm extends BaseForm {
  readonly originalModel: ListingPhoto;
  constructor(photo?: ListingPhoto) {
    super({
      tags: new FormControl(photo?.tags || []),
      caption: new FormControl(photo?.caption || ''),
    });

    if (photo) {
      this.patchValue(photo);
      this.originalModel = photo;
    }
  }

  patchValue(value) {
    return super.patchValue(value);
  }
}
