import { Component, Inject, OnInit } from '@angular/core';
import {
  AppService, ContentfulService, ContentKeys, ContentValueKeys,
} from '@lc/core';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'lc-welcome-dialog',
  templateUrl: './welcome-ui-dialog.component.html',
  styleUrls: ['./welcome-ui-dialog.component.scss'],
})
export class WelcomeUiDialogComponent implements OnInit {
  private readonly _close = new Subject<any>();
  public readonly close = this._close.asObservable();
  welcomeDialogImage$: Observable<any>;

  constructor(public contentfulService: ContentfulService) { }

  ngOnInit(): void {
    let key = null;

    if (AppService.isDCApp) {
      key = ContentValueKeys.DC;
    } else if (AppService.isAgentApp) {
      key = ContentValueKeys.LC;
    }

    this.welcomeDialogImage$ = this.contentfulService.getContent(ContentKeys.WELCOME_DIALOG_IMAGE, key, '../../../assets/images/welcome.png');
  }

  onCancel(): void {
    this._close.next(true);
  }
}
