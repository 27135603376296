import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClipboardService } from 'ngx-clipboard';
import { ApiService } from './api.service';
import { ListingPhoto } from '../models';
import { PhotoDownloadService } from './photo-download.service';
import { ProductGenerationService } from './product-generation.service';
import { ToasterService } from './toaster.service';
import { ProductInfoService } from './product-info.service';

interface SummaryResult {
  summary: {
    extractive?: string[]
    abstractive?: string[]
  }
}

@Injectable({
  providedIn: 'root',
})
export class TextSummaryService {
  constructor(
    private apiService: ApiService,
    private clipboardService: ClipboardService,
    private toasterService: ToasterService,
    private photoDownloadService: PhotoDownloadService,
    private productInfoService: ProductInfoService,
    private productGenerationService: ProductGenerationService,
  ) { }

  summarizeText(text: string, maxChars: number): Observable<SummaryResult> {
    const url = 'text-summary';
    return this.apiService.post(url, { text, maxChars });
  }

  onCopyLink(branded: boolean, viewModel: any, preferredSource?: string) {
    const product = viewModel?.product;
    if (!product) { return; }
    let lastPublishedUrl = '';

    if (product.isWebsiteProduct()) {
      lastPublishedUrl = product?.publishedUris[0].uri;
      if (!branded && lastPublishedUrl) {
        // Unbranded. We need to change the url to mlsindex.html.
        lastPublishedUrl = lastPublishedUrl.replace('/index.html', '/mlsindex.html');
      }
    } else {
      const copyLinks = product?.publishedUris.filter((video) => {
        let type: string;

        if (branded) {
          type = this.productInfoService.isYoutubeProduct(product) ? 'YouTubeAdvertising' : 'brandedvideo';
        } else {
          type = 'unbrandedvideo';
        }

        return video.uriType === type;
      });

      if (preferredSource) {
        const sourceIndex = copyLinks.findIndex((video) => video.uri.indexOf(preferredSource) !== -1);
        lastPublishedUrl = copyLinks[sourceIndex]?.uri;
      }

      if (!lastPublishedUrl) {
        // If we havent found a published URL that matches the preferred source,
        // just use the last one
        const lastIndex = copyLinks.length - 1;
        lastPublishedUrl = copyLinks[lastIndex]?.uri;
      }
    }

    if (lastPublishedUrl) {
      this.clipboardService.copyFromContent(lastPublishedUrl);
      this.toasterService.showInfo('Url is saved to clipboard');
    } else {
      this.toasterService.showInfo('Failed to copy URL.');
    }
  }

  onDownload(imageResolution: string, viewModel: any): void {
    if (!(viewModel?.canDownload && viewModel?.marketingOrder)) { return; }
    this.productGenerationService.download(viewModel?.marketingOrder, viewModel?.product, imageResolution);
  }

  /** Downloads the branded or unbranded digital products */
  onDownloadDigital(branded: boolean, viewModel: any): void {
    if (!(viewModel?.canDownload && viewModel?.product)) { return; }

    const downloadableLinks = viewModel?.product.publishedUris.filter((video) => {
      let type: string;

      if (branded) {
        type = this.productInfoService.isYoutubeProduct(viewModel?.product) ? 'YouTubeAdvertising' : 'brandedvideo';
      } else {
        type = 'unbrandedvideo';
      }

      return video.uriType === type
        && video.uri.indexOf('amazonaws') >= 0;
    });

    const lastPublishedUrl = downloadableLinks[downloadableLinks.length - 1]?.uri;
    if (lastPublishedUrl) {
      this.downloadUsingCorsProxy(lastPublishedUrl);
    }
  }

  downloadUsingCorsProxy(url: string): void {
    if (url) {
      // const corsProxy = 'https://cors-anywhere.herokuapp.com/';
      this.photoDownloadService.downloadUsingCorsProxy(
        new ListingPhoto({ uri: url }),
        (err: Error) => {
          console.error(`Error downloading ${url}: ${err.message}`);
          this.toasterService.showInfo('There was a problem downloading the file. Please try again');
        },
      );
    }
  }
}
