import { Deserializable } from './deserializable.model';
import { Address } from './address.model';
import { PhoneNumber } from './phone-number.model';
import { Metro } from './metro.model';

export class Office implements Deserializable<Office> {
  _id: string;
  officeCode: string;
  address: Address;
  phoneNumber: PhoneNumber;
  officeSourceId: string;
  marketingArea: string;
  company: any;
  metro: Metro;

  constructor(input?: any) {
    this.deserialize(input || {});
  }

  deserialize(input: any): Office {
    Object.assign(this, input);
    return this;
  }
}
