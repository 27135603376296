import { Injectable } from '@angular/core';
import { HttpBackend } from '@angular/common/http';
import { AppService } from './app.service';

export function initializeApp(startupService: StartupService) {
  return (): Promise<any> => startupService.initialize().then(() => console.log('INITIALIZED!'));
}

@Injectable({
  providedIn: 'root',
})
export class StartupService {
  constructor(private backend: HttpBackend) {
  }

  initialize(): Promise<void> {
    return AppService.initialize(this.backend);
  }
}
