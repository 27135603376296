import { Action, createAction, props } from '@ngrx/store';
import { Notification } from '../../models/notification.model';
import { NotificationForm } from '../../forms/notification.form';

// export enum NotificationActionTypes {
//   LoadNotifications = '[Notifications] Load',
//   NotificationsUpdated = '[Notifications] Updated',

//   CreateNotification = '[Notifications] Create',
//   CreateNotificationComplete = '[Notifications] Create Complete',
//   CreateNotificationFailed = '[Notifications] Create Failed',

//   UpdateNotification = '[Notifications] Update',
//   UpdateNotificationComplete= '[Notifications] Update Complete',
//   UpdateNotificationFailed= '[Notifications] Update Failed',
// }

export const LoadNotifications = createAction('[Notifications] Load', props<{ otherIds:string[] }>());

export const CreateNotification = createAction('[Notifications] Create', props<{ form:NotificationForm }>());

export const CreateNotificationComplete = createAction('[Notifications] Create Complete', props<{ model:Notification }>());

export const CreateNotificationFailed = createAction('[Notifications] Create Failed', props<{ form:NotificationForm }>());

export const UpdateNotification = createAction('[Notifications] Update', props<{ form:NotificationForm }>());

export const UpdateNotificationComplete = createAction('[Notifications] Update Complete', props<{ model:Notification }>());

export const UpdateNotificationFailed = createAction('Notifications] Update Failed', props<{ form:NotificationForm }>());

export const NotificationsUpdated = createAction('[Notifications] Updated', props<{ notifications: Notification[] }>());

// export class LoadNotifications implements Action {
//   readonly type = NotificationActionTypes.LoadNotifications;
//   constructor(public otherIds?: string[]) {}
// }
// export class CreateNotification implements Action {
//   readonly type = NotificationActionTypes.CreateNotification;
//   constructor(public form: NotificationForm) {}
// }

// export class CreateNotificationComplete implements Action {
//   readonly type = NotificationActionTypes.CreateNotificationComplete;
//   constructor(public model: Notification) {}
// }

// export class CreateNotificationFailed implements Action {
//   readonly type = NotificationActionTypes.CreateNotification;
//   constructor(public form: NotificationForm) {}
// }

// export class UpdateNotification implements Action {
//   readonly type = NotificationActionTypes.UpdateNotification;
//   constructor(public form: NotificationForm) {}
// }

// export class UpdateNotificationComplete implements Action {
//   readonly type = NotificationActionTypes.UpdateNotificationComplete;
//   constructor(public model: Notification) {}
// }

// export class UpdateNotificationFailed implements Action {
//   readonly type = NotificationActionTypes.UpdateNotificationFailed;
//   constructor(public form: NotificationForm) {}
// }

// export class NotificationsUpdated implements Action {
//   readonly type = NotificationActionTypes.NotificationsUpdated;
//   constructor(public notifications: Notification[]) {}
// }

export const NOTIFICATIONS = 'notifications';

// export type NotificationActions = LoadNotifications | CreateNotification | CreateNotificationFailed | NotificationsUpdated;
