import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@lc/core';
import { AvatarModule } from '../avatar/avatar.module';
import { InputsModule } from '../inputs/inputs.module';

// Internal Components
import { AgentSearchComponent } from './components/agent-search/agent-search.component';

const angularModules = [CommonModule];
const lcModules = [CoreModule, InputsModule, AvatarModule];
const exportComponents = [AgentSearchComponent];

@NgModule({
  declarations: [...exportComponents],
  imports: [
    ...angularModules,
    ...lcModules,
  ],
  exports: [...exportComponents],
})
export class AgentSearchModule { }
