// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

// Export Components
import { SpinnerComponent } from './spinner.component';

const angularModules = [
  CommonModule, MatProgressSpinnerModule,
];
const exportComponents = [SpinnerComponent];

@NgModule({
  declarations: [...exportComponents],
  exports: [...exportComponents, MatProgressSpinnerModule],
  imports: [
    ...angularModules,
  ],
})
export class SpinnerModule { }
