// tslint:disable: rxjs-no-sharereplay
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import * as snakeCase from 'lodash.snakecase';
import { PresignService } from './presign.service';
import { PrinterApiService } from './api.service';
import {
  PrintOrder, PrintOrderCancelResult, VendorOrderStatus, VendorServiceOrder, MarketingOrder, ProductInstance,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class PrintOrderService {
  private marketingOrderPrintOrdersCache: { [marketingOrderId: string]: Observable<PrintOrder[]> } = {};
  private readonly marketingOrderPrintOrderDetailsCache: { [marketingOrderId: string]: Observable<PrintOrder[]> } = {};

  constructor(
    private apiService: PrinterApiService,
    private presignService: PresignService,
  ) {
  }

  getPrintOrders(marketingOrderId: string): Observable<PrintOrder[]> {
    if (!this.marketingOrderPrintOrdersCache[marketingOrderId]) {
      const url = `printer/jobs/${marketingOrderId}`;
      this.marketingOrderPrintOrdersCache[marketingOrderId] = this.apiService.get(url).pipe(
        shareReplay(1),
      );
    }

    return this.marketingOrderPrintOrdersCache[marketingOrderId];
  }

  clearPrintOrderCache() {
    this.marketingOrderPrintOrdersCache = {};
  }

  getPrintOrderDetails(marketingOrderId: string): Observable<PrintOrder[]> {
    /* if(!this.marketingOrderPrintOrderDetailsCache[marketingOrderId]) { */
    const url = `printer/jobs/${marketingOrderId}?includeVendorDetails=true`;
    const vendorOrder = this.apiService.get(url).pipe(
      shareReplay(1),
    );
      /* } */
    return vendorOrder;
  }

  /**
   * Retrieves Print Job Details for a specific product of the marketing order
   * @param marketingOrderId The marketingOrderId to lookup printJobs for
   * @param productCode The specific product code we are interested in
   */
  getProductPrintOrderDetails$(marketingOrderId: string, productCode: string): Observable<PrintOrder> {
    return this.getPrintOrderDetails(marketingOrderId).pipe(
      map((printJobs) => (printJobs || []).find((printJob) => printJob.productCode === productCode && printJob.status !== VendorOrderStatus.CANCELLED)),
    );
  }

  async cancelPrintJob(vendorOrderId: string): Promise<PrintOrderCancelResult> {
    const url = `printer/cancel/${vendorOrderId}`;
    return await this.apiService.put(url).pipe(
      map((data) => data),
    ).toPromise();
  }

  public async downloadContacts(contactListUrl: string, order: MarketingOrder, product: ProductInstance): Promise<void> {
    const presignedUrl = await this.presignService.contactListPresignedUrl([contactListUrl]).pipe(map((presigned) => presigned[0]?.presignedUrl)).toPromise();
    const file = await this.apiService.getJsonFile(presignedUrl).toPromise();
    await this.initiateDownload(file, order, product);
  }

  private async initiateDownload(jsonContent: Array<any>, order: MarketingOrder, product: ProductInstance): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        const title = snakeCase(product.title.toLowerCase());
        const filename = `distribution_list_${title}_${snakeCase(order.listing.address.streetAddress1)}.xlsx`.toLowerCase();
        const ws = XLSX.utils.aoa_to_sheet(jsonContent);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, filename);
        resolve();
      } catch (error) {
        console.error(error);
        reject();
      }
    });
  }
}
