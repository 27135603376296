import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// misc components
import { CharacterCountComponent } from './components/character-count/character-count.component';

const exportComponents = [CharacterCountComponent];

@NgModule({
  declarations: [
    ...exportComponents,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ...exportComponents,
  ],
})
export class MiscModule { }
