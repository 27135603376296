export class TransitionDetails {
  name?: string;
  isForced?: boolean = false;
}
export class StateTransition {
  transitionedTo: string;
  transitionedFrom?: string;
  transitionedAt: Date;
  transitionedBy?: string;
  transitionedByFullName?: string;
  transition?: TransitionDetails;

  constructor(input?: any) {
    Object.assign(this, input);
  }
}
