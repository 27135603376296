import { createAction, props } from '@ngrx/store';
import { OrderState } from '../../models/listing.model';
import { MarketingOrder } from '../../models/marketing-order.model';
import { ProductDescription } from '../../models/product-description.model';
import { Media } from '../../models/media.model';
import { ProductInstance } from '../../models/product-instance.model';

export const UpdateProductMediaComplete = createAction('[Order] Update product media complete');

export const LoadOrder = createAction('[Order] Load', props<{ payload: MarketingOrder }>());

export const GetOrder = createAction('[Order] Get', props<{ payload: MarketingOrder, useCached?: boolean }>());

export const GetOrderComplete = createAction('[Order] Get Complete', props<{ payload: MarketingOrder }>());

export const AddOrder = createAction('[Order] Add', props<{ payload: MarketingOrder }>());

export const AddOrderComplete = createAction('[Order] Add Order Complete', props<{ payload: MarketingOrder }>());

export const UpdateOrder = createAction('[Order] Update', props<{ payload: MarketingOrder }>());

export const UpdateCurrentProductState = createAction('[Order] Update Current Product State', props<{ orderId: string, product: ProductInstance }>());

export const UpdateCurrentOrderState = createAction('[Order] Update Current Order State', props<{ payload: MarketingOrder, patch?: boolean }>());

export const UpdateOrderComplete = createAction('[Order] Update Order Complete', props<{ payload: MarketingOrder, initiatingAction?: any }>());

export const UpdateOrderFailed = createAction(
  '[Order] Update Order Failed',
  props<{ payload: MarketingOrder }>(),
);

export const UpdateOrderProductDescription = createAction(
  '[Order] Update Product Description',
  props<{ payload: ProductDescription }>(),
);

export const DeleteOrder = createAction(
  '[Order] Delete',
  props<{ payload: MarketingOrder }>(),
);

export const DeleteOrderComplete = createAction(
  '[Order] Delete Order Complete',
);

export const InitializeOrder = createAction(
  '[Order] Initialize',
);

export const UpdateOrderState = createAction(
  '[Order] Update State',
  props<{ marketingOrder: MarketingOrder, payload: OrderState }>(),
);

export const UpdateProductMedia = createAction(
  '[Order] Update product media',
  props<{ payload: Media, productCode: string }>(),
);

export const BulkAssignOrders = createAction(
  '[Order] Bulk Assignment',
  props<{ orderIds: string[], coordinatorId: string }>(),
);

export const BulkAssignOrdersComplete = createAction(
  '[Order] Bulk Assignment Complete',
);

export const SELECTEDORDER = 'selectedOrder';
