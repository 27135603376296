import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lc-area-menu-item',
  templateUrl: './area-menu-item.component.html',
  styleUrls: ['./area-menu-item.component.scss'],
})
export class AreaMenuItemComponent implements OnInit {
  @Input() areaName: string;
  @Input() isExpanded: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }
}
