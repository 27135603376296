import { Deserializable } from './deserializable.model';
import { ConciergeObligationTypes } from './concierge-obligation.types';
import { ProductOption } from './product-option.model';
import { DurationUnits } from './duration-units.enum';
import { LayoutDetail } from './layout-detail.model';
import { OutputFileFormatTypes } from './output-file-format.types';

interface CanBeReplacedBy {
  type: 'product',
  description: string,
  value: string,
  conversion: {
    from: {
      type: 'quantity' | 'duration',
      value: number
    },
    to: {
      type: 'quantity' | 'duration',
      value: number
    }
  }
}

export enum Impediement {
  mlsId = 'mlsid',
  videoUrl = 'videourl',
}

export class ShippedByModel implements Deserializable<ShippedByModel> {
  readonly shippingCarrier: string;
  readonly deliveryOptions: string[];

  constructor(model?: Partial<ShippedByModel>) {
    this.deserialize(model);
  }

  deserialize(model?: Partial<ShippedByModel>): ShippedByModel {
    Object.assign(this, model);
    return this;
  }
}
export class ProductRequirements implements Deserializable<ProductRequirements> {
  readonly isMarketingCopyRequired?: boolean;
  readonly isMlsIdRequired?: boolean;
  readonly isVideoUrlRequired?: boolean;
  readonly requiredPhotos?: number;

  constructor(model?: Partial<ProductRequirements>) {
    this.deserialize(model);
  }

  deserialize(model?: Partial<ProductRequirements>): ProductRequirements {
    const defaults: Partial<ProductRequirements> = {
      isMarketingCopyRequired: true,
      isMlsIdRequired: false,
      isVideoUrlRequired: false,
      requiredPhotos: 5,
    };
    Object.assign(this, defaults, model);
    return this;
  }
}

export class ProductDetails implements Deserializable<ProductDetails> {
  conciergeObligation: ConciergeObligationTypes;
  audience: string;
  optional: boolean;
  isBundled: boolean;
  durationOfSLA: number;
  unitQuantity: number;
  unitPrice: number;
  additionalUnitPrice: number;
  price: number;
  bundledPrice: number;
  maxOptionsSelectable: number;
  options: ProductOption[];
  isVendorPrinted: boolean;
  isShareable: boolean;
  deferredDelivery: boolean;
  mailDeliverable: boolean;
  mailReturnAddress: boolean;
  deliveredWithinEnvelope: boolean;
  outputFileFormat?: OutputFileFormatTypes;
  shippingCarrier: string;
  shippingDeliveryOption: string;
  duration: number;
  durationUnits: DurationUnits;
  maxPhotos: number;
  bodyLayouts: LayoutDetail[];

  shippedBy?: ShippedByModel;
  mailedBy?: ShippedByModel;

  canBeReplacedBy: CanBeReplacedBy[];
  canBeImpededBy: Impediement[];
  isReplaced: boolean;
  isReplacementOnly: boolean;
  replacedBy: string;
  replaces: string;
  customization: 'full' | 'semi' | 'none';
  wfCategoryId?: string;
  wfQueueTopicId?: string;
  schemaVersion?: number;
  requirements?: ProductRequirements;

  isAvailable: boolean;

  constructor(input?: any) {
    this.deserialize(input);
  }

  deserialize(input?: any): ProductDetails {
    if (!input) { return this; }
    Object.assign(this, input);

    if (this.options) {
      this.options = this.options.map((option) => new ProductOption(option));
    }
    if (this.bodyLayouts) {
      this.bodyLayouts = this.bodyLayouts.map((layout) => new LayoutDetail(layout));
    }

    return this;
  }

  get durationDescription() {
    if (!this.duration) {
      return null;
    }
    let desc = `${this.duration}`;
    if (this.durationUnits) {
      desc = `${desc} ${this.durationUnits}`;
    }
    return desc;
  }
}
