<div class="fx-column fx-gap-sm">
  <div class="img-container crop-container" [class.round-cropper]="settings?.isRound">
    <img id="image" *ngIf="photo" [lcPhotoCropper]="photo" [settings]="settings" [dimensions]="dimensions" (zoomChange)="zoom = $event" (loaded)="onLoaded()" crossOrigin="anonymous">
  </div>

  <div *ngIf="settings?.customAspectRatios?.length" class="fx-row x-center">
    <mat-button-toggle-group #group="matButtonToggleGroup" [(ngModel)]="settings.aspectRatio" (change)="updateAspectRatio()">
      <mat-button-toggle *ngFor="let aspect of settings.customAspectRatios | keyvalue" [value]="aspect.value" [aria-label]="aspect + ' ratio'">
        {{aspectRatios[aspect.value]}}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="fx-row fx-gap-sm y-center" *ngIf="isLoaded">
    <button mat-icon-button (click)="onZoomOut()"><mat-icon>photo_size_select_large</mat-icon></button>
    <mat-slider color="primary" class="fx-1-1-0" [min]="0.1" [max]="maxZoom" step=".01" [(ngModel)]="zoom" (change)="onZoomChanged()"></mat-slider>
    <button mat-icon-button (click)="onZoomIn()"><mat-icon>photo_size_select_actual</mat-icon></button>
  </div>
</div>
