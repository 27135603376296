import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseDialogModule } from '../base/base.module';

import { PdfViewerDialog } from './pdf-viewer.dialog';
import { PdfViewerDirective } from './pdf-viewer.directive';

const angularModules = [CommonModule];
const lcModules = [BaseDialogModule];
const externalComponents = [PdfViewerDialog, PdfViewerDirective];
const internalComponents = [];

@NgModule({
  declarations: [
    ...internalComponents,
    ...externalComponents,
  ],
  exports: [
    ...externalComponents,
  ],
  imports: [
    ...angularModules,
    ...lcModules,
  ],
})
export class PdfViewerModule { }
