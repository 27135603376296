<mat-form-field [id]="idPrefix + '-form-field'" appearance="outline"  [class.ng-valid]="errors === ''" [class.mat-form-field-readonly]="readonly">

    <!-- Label -->
    <mat-label [id]="idPrefix + '-label'">
      <template *ngIf="labelTemplate; else defaultLabel" [ngTemplateOutlet]="labelTemplate"></template>
      <ng-template #defaultLabel>
        {{label}}
      </ng-template>
    </mat-label>
  
    <!-- Input -->
    <input matInput [placeholder]="placeholderText" [matAutocomplete]="auto" [formControl]="formControl">
    
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)='updateSelection($event.option.value)' panelWidth="auto">
        <ng-container *ngIf="autoCompleteOptions && autoCompleteOptions.length > 0">
            <mat-option *ngFor="let data of autoCompleteOptions" [value]="data" [title]="data.text">
            <span>{{ data.text }}</span>
            </mat-option>
        </ng-container>
    </mat-autocomplete>
    <!-- Hint-->
    <mat-hint [id]="idPrefix + '-hint'" class="w-100">
      <template *ngIf="hintTemplate; else defaultHint" [ngTemplateOutlet]="hintTemplate"></template>
      <ng-template #defaultHint>
        {{hint}}
      </ng-template>
    </mat-hint>
  
    <!-- Error -->
    <mat-error [id]="idPrefix + '-error'" *ngIf="formControl && formControl.invalid" [innerHTML]="errors"></mat-error>
  
    <!-- Suffix -->
    <div matSuffix>
      <template *ngIf="suffixTemplate; else defaultSuffix" [ngTemplateOutlet]="suffixTemplate" ></template>
      <ng-template #defaultSuffix>
        <span *ngIf="suffix">{{suffix}}</span>
      </ng-template>
    </div>
  </mat-form-field>
  
  