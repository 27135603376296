import { Deserializable } from './deserializable.model';

/**
 * The TaxDetails model stores all the information regarding the tax calculation from stripe
 */
export class TaxDetails implements Deserializable<TaxDetails> {
  transactionId: string;
  transactionDate: Date;
  transactionReferenceId: string;
  amount: number;
  salesTax: number;
  transactionType: 'COLLECT' | 'ADJUST' | 'REFUND';

  constructor(input?: any) {
    if (input) {
      this.deserialize(input);
    }
  }

  deserialize(input: any): TaxDetails {
    return Object.assign(this, input);
  }
}
