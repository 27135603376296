import { Deserializable } from './deserializable.model';

export type InheritTypes = 'system' | 'order' | 'product';
export type DesignStatus = 'not-started' | 'started' | 'completed';
export type InputType = 'select' | 'multi-select' | 'text' | 'textarea' | 'radio' | 'textbox';

export class DesignOption implements Deserializable<DesignOption> {
  /** The value associated to this design option */
  value: any;

  /** The label to display with this design option */
  label: string;

  /** The description related to this design option */
  description?: string;

  /** Whether this design option is required to be selected */
  isRequired?: boolean;

  /** Additional data that can be customized or extended as needed */
  data?: {
    /** Assets to be used to display this field */
    imageUrl?: string;

    /** Additional Properties not common to fields */
    [key: string]: any;
  };

  constructor(value?: Partial<DesignOption>) {
    this.deserialize(value || {});
  }

  deserialize(value: Partial<DesignOption>): DesignOption {
    Object.assign(this, value);
    return this;
  }
}

export class Grouping implements Deserializable<Grouping> {
  /** The id of this group (used to build the url) */
  id: string;

  /** The display title of this group */
  title: string;

  constructor(value?: Partial<Grouping>) {
    this.deserialize(value || {});
  }

  deserialize(value: Partial<Grouping>): Grouping {
    Object.assign(this, value);
    this.id = this.id || (this.title || '').toLowerCase().replace(' ', '-');
    return this;
  }
}

export class DesignDetailRequirements implements Deserializable<DesignDetailRequirements> {
  /** The minimum length this design detail value requires */
  minLength?: number;

  /** The maximum length this design detail value requires */
  maxLength?: number;

  constructor(value?: Partial<DesignDetailRequirements>) {
    this.deserialize(value || {});
  }

  deserialize(value: Partial<DesignDetailRequirements>): DesignDetailRequirements {
    Object.assign(this, value);
    return this;
  }
}

/**
 * The Realized Product Design
 */
export class DesignDetails implements Deserializable<DesignDetails> {
  /** The unique code for this product design */
  code: string;

  /** The label to display with this design choice */
  label: string;

  /** The type of element to use to capture the value form the user */
  inputType: InputType;

  /** Whether this option is required */
  isRequired: boolean;

  /** The minimum required options to be selected (for multi-select) */
  minOptions?: number;

  /** The maximum required options that can be selected (for multi-select) */
  maxOptions?: number;

  /** Whether this design detail has any default value  */
  defaultValue?: any;

  /** The available options for this product */
  designOptions?: DesignOption[];

  grouping?: Grouping;

  requirements?: DesignDetailRequirements;

  /** Additional data that can be customized or extended as needed */
  data?: {
    uiInputVariant?: string;
    /** Additional Properties not common to fields */
    [key: string]: any;
  };

  /**
   * The value that was selected for this design:
   * textbox: string
   * select: any
   * checkbox: boolean
   * multiselect: any[]
   */
  value?: any;

  isShared?: boolean;

  constructor(value?: Partial<DesignDetails>) {
    this.deserialize(value);
  }

  deserialize(input: Partial<DesignDetails>): DesignDetails {
    Object.assign(this, input);
    this.designOptions = (input?.designOptions || []).map((option) => new DesignOption(option));
    this.grouping = input.grouping ? new Grouping(input.grouping) : undefined;
    this.requirements = new DesignDetailRequirements(input?.requirements);
    return this;
  }
}
