import {
  Input,
  Output,
  Component,
  ViewChild,
  forwardRef,
  EventEmitter,
  AfterViewInit,
  ContentChild,
  TemplateRef,
} from '@angular/core';
import { FormService, StringFormControl } from '@lc/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { InputField } from '../input-field';
import { getNativeElement } from '../../../../../products/src/lib/shared/components/util/util';

@Component({
  selector: 'lc-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
  ],
})
export class TextareaComponent extends InputField implements AfterViewInit {
  @ViewChild('textAreaForm') textAreaForm;

  @Input() rows: number = 2;

  @Input() label: string;

  @Input() placeholder = '';

  @Input() readonly = false;

  @Input() hint: string;

  @Input() suffix: string;

  @Input() isAdvertiserProduct: boolean;

  @Input() disabled: boolean;

  @Output() readonly blur = new EventEmitter<void>();
  @ContentChild('suffix') suffixTemplate: TemplateRef<any>;

  formService: FormService;

  constructor(
    sanitizer: DomSanitizer,
    formService: FormService,
  ) {
    super(sanitizer);
    this.formService = formService;
  }

  ngAfterViewInit() {
    if (this.isAdvertiserProduct) {
      getNativeElement(this.textAreaForm?._control?._elementRef, 'TextAreaComponent').click();
      this.formControl.statusChanges.subscribe((status) => {
        if (status === 'INVALID') {
          this.formService.disableFormElements(this.textAreaForm._control.id);
        } else {
          this.formService.enableAllForms();
        }
      }, (error) => {
        throw new Error(`Error from form service in textarea editor: ${error}`);
      });
    }
  }

  public onBlur() {
    if (this.formControl && this.formControl instanceof StringFormControl) {
      this.formControl.onLostFocus();
    }

    this.blur.emit(this.formControl.value);
    this.executeOnTouched();
  }
}
