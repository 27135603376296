<mat-form-field appearance="outline" floatLabel="always" [hideRequiredMarker]="!label">

  <!-- Label -->
  <mat-label [id]="idPrefix + '-label'">{{label}}</mat-label>

  <mat-radio-group class="lc-radio-group" [ngClass]="layout === 'row' ? 'fx-row fx-gap-md' : 'fx-column fx-gap-xs'">
    <mat-radio-button *ngFor="let option of options" [value]="option.value" (change)="onSelect(option)" [checked]="option.isSelected">

      <!-- Label Section-->
      <div class="option-label fx-row y-center fx-gap-xs">
        <div class="overflow-ellipsis label fx-1-1-0">{{option.label}}</div>
        <mat-icon *ngIf="option.description" class="material-icons-outlined text-faded description-icon" [matTooltip]="option.description" matTooltipPosition="after">info</mat-icon>
      </div>

    </mat-radio-button>
    <!-- Hidden matInput plugs into formControl -->
    <input matInput class="d-none" [formControl]="formControl" [errorStateMatcher]="matcher">
  </mat-radio-group>
  <mat-error *ngIf="formControl && formControl.invalid" [innerHTML]="errors" class="error-message"></mat-error>
</mat-form-field>
