import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  catchError, filter, map, switchMap, withLatestFrom, tap, mergeMap,
} from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import {
  UpdateTeamComplete, UpdateTeam, GetTeam, SELECTEDTEAM, GetTeamComplete, UpdateTeamContactPreferences,
} from './team.actions';
import * as TeamActionTypes from './team.actions';
import { ErrorData, GlobalErrorHandler } from '../../errors';
import { NotificationEvent, NotificationEventService } from '../../notifications';
import { TeamProfile, MarketingOrder } from '../../models';
import { TeamProfileService } from '../../services/team-profile.service';
import { BaseEffects } from '../base.effects';
import { GetOrder } from '../order/order.actions';

@Injectable()
export class TeamEffects extends BaseEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private teamService: TeamProfileService,
    eventService: NotificationEventService,
    errorHandler: GlobalErrorHandler,
  ) {
    super(errorHandler, eventService);
  }

  getTeam: Observable<TeamProfile> = createEffect(() => this.actions$.pipe(
    // When the GetTeam action is executed, retrieve the action and combine it with the current SELECTEDTEAM in store
    ofType(TeamActionTypes.GetTeam),
    withLatestFrom(this.store.pipe(select(SELECTEDTEAM), map((team: TeamProfile) => team))),
    switchMap(([action, team]) => {
      // If the requested team ID is the same as the team already in store, just return it
      if (team && action.team && team._id === action.team._id) {
        return of(team);
      }

      // Otherwise, if the teamId has been provided and is not in store, retrieve it from the API
      if (action.team && action.team._id) {
        return this.teamService.getTeamProfile(action.team._id).pipe(
          catchError((err) => this.processCatchError(TeamActionTypes.GetTeam, action.team, err)),
        );
      }

      // If no teamId is provided, get the first team that the current agent is assigned to
      return this.teamService.getAgentProfiles().pipe(
        map((teamProfiles) => (teamProfiles.length > 0 ? teamProfiles[0] : null)),
        catchError((err) => this.processCatchError(TeamActionTypes.GetTeam, action.team, err)),
      );
    }),
    filter((order) => !(order instanceof ErrorData)),
    map((order: TeamProfile) => {
      const event = new NotificationEvent('[Team] Get Team Profile Complete', '[Team] Get Team Profile Complete', order);
      this.eventService.getEventEmitter().emit(event);
      this.store.dispatch(GetTeamComplete({ team: order }));
      return order;
    }),
  ), { dispatch: false });

  updateProfile: Observable<TeamProfile> = createEffect(() => this.actions$.pipe(
    ofType(TeamActionTypes.UpdateTeam),
    mergeMap((team) => {
      // When updating a team, if _id is specified, we need to do a put. Otherwise, do a post
      const apiMethod = team.payload._id
        ? this.teamService.put(team.payload)
        : this.teamService.post(team.payload);

      // Execute put/post and handle any errors
      return apiMethod.pipe(
        tap((_) => {
          if (team.orderId) {
            // If the orderID is passed through as an action, we need to call to dispatch an
            // action to GetOrder so that the UI will update with the proper team information.
            // NOTE: This dispatch is using the cached version of the MarketingOrder.
            // Pass false as the last parameter if you want it to get the latest from the API
            this.store.dispatch(GetOrder({ payload: new MarketingOrder({ _id: team.orderId }) }));
          }
        }),
        catchError((err) => this.processCatchError(TeamActionTypes.UpdateTeam, { payload: team.payload }, err)),
      );
    }),
    filter((profile) => !(profile instanceof ErrorData)),
    map((profile: TeamProfile) => this.updateComplete(profile)),
  ), { dispatch: false });

  updateTeamContactPreferences: Observable<TeamProfile> = createEffect(() => this.actions$.pipe(
    ofType(TeamActionTypes.UpdateTeamContactPreferences),
    mergeMap((action) => {
      // Call to udpate contact prefernces
      return this.teamService.updateContactPreferences(action.payload._id, action.preferences).pipe(
        catchError((err) => this.processCatchError(TeamActionTypes.UpdateTeam, { team: action.payload, preference: action.preferences }, err)),
      );
    }),
    filter((profile) => !(profile instanceof ErrorData)),
    map((profile: TeamProfile) => this.updateComplete(profile)),
  ), { dispatch: false });

  private updateComplete(profile: TeamProfile): TeamProfile {
    this.store.dispatch(UpdateTeamComplete({ payload: profile }));
    const event = new NotificationEvent('[Team] Update Team Profile Complete', 'Team updated');
    this.eventService.getEventEmitter().emit(event);
    return profile;
  }
}
