import { ListingPhoto } from '@lc/core';
import { LoadingEvent } from '@lc/ui';
import { DragItem } from '../layout/drag-n-drop/drag-n-drop.component';

/**
 * The Photo View Model is used by this component to handle the coordination of events related to a specific photo
 * Each photo in the grid has many actions we need to keep track of. In order to do so, we need to directly correlate the
 * photo with isSelected and isEditNumber. This also will make it easier to maintain rather than having separate lookup
 * objects to maintain these values.
 */
export class PhotoViewModel implements DragItem {
  isSelected: boolean;
  isEditNumber: boolean;
  canFavorite: boolean;
  canReorder: boolean;
  canDownload: boolean;
  canEdit: boolean;
  canDelete: boolean;
  canSelect: boolean;
  canHide: boolean;
  inUseIndex?: number;
  showOrder: boolean;
  showFavorite: boolean;
  useSmallIcons: boolean;
  iconPlacement: 'default' | 'center';
  showSelected: boolean;
  pageDisplay: string;
  disablePortrait?: boolean;
  tooltip?: string;
  disabled?: boolean;
  isRequired: boolean;
  index: number;

  loading?: LoadingEvent;

  constructor(public photo: ListingPhoto) {
  }

  isDefault() {
    if (!(this.photo && this.photo.uri)) {
      return true;
    }
    return this.photo.uri.indexOf('welcome-house.png') >= 0;
  }
}
