import * as kebabCase from 'lodash.kebabcase';
import { Deserializable } from './deserializable.model';
import { Listing, OrderState } from './listing.model';
import { PackageInstance, ProductTitles } from './package-instance.model';
import { VIPOrder } from './vip-order.model';
import { AddressInformation } from './address-information.model';
import { Media } from './media.model';
import { ListingPhoto } from './listing-photo.model';
import { PhotoOrder } from './photo-order.model';
import { ProductInstance } from './product-instance.model';
import { StateTransition } from './status-history';
import { Audit } from './audit.model';
import { Pricing } from './pricing.model';
import { MarketingOrderStatusType } from './marketing-order-status.type';
import { Task } from './task.model';
import { LoanOfficer } from './loan-officer.model';
import { ProductCode } from './product.code';
import { Ai } from './ai-model';

export enum OrderType {
  MARKETING = 'LC',
  DESIGN = 'DC',
}

export type BrandCode = 'CBA' | 'CBR' | 'ERA' | 'BHG';

export class MarketingOrder implements Deserializable<MarketingOrder> {
  _id: string;
  __t: OrderType;
  brandCode?: BrandCode;
  agentId: string;
  agentProfileId: string;
  companyName: string;
  coListAgentProfileId: string;
  coListAgentId: string;
  teamId: string;
  coordinatorId: string;
  listing: Listing;
  submittedDate: Date;
  media: Media;
  selectedPackage: PackageInstance;
  availablePackages: PackageInstance[];
  shippingInformation: AddressInformation;
  billingInformation: AddressInformation;
  marketingCopyHeadline: string[] = [];
  marketingCopyBody: string[] = [];
  bodyCharLimit: number;
  ai?: Ai;
  photoOrder: PhotoOrder;
  status: MarketingOrderStatusType = MarketingOrderStatusType.OPEN;
  orderState: OrderState;
  selfService: boolean;
  summary = false;
  audit: Audit;
  photos: ListingPhoto[] = [];
  isArchived: boolean;
  isFlagged: boolean;
  isVIPOrder: boolean;
  vipOrderReason: VIPOrder;
  tasks?: Task[];
  loanOfficer: LoanOfficer;
  readonly copyCount: number;
  readonly photoCount: number;

  statusHistory: StateTransition[];

  stripeTokenId: string;

  pricing: Pricing;
  area?: string;
  storeOrderId?: string;

  constructor(input?: any) {
    if (input) {
      this.deserialize(input);
    }
  }

  // TODO - when using new MarketingOrder(aMarketingOrder) not all elements are cloned. media.multimedia is one example
  deserialize(input: any): MarketingOrder {
    Object.assign(this, input);

    if (this.pricing) {
      this.pricing = new Pricing(this.pricing);
    }

    if (this.selectedPackage) {
      this.selectedPackage = new PackageInstance(this.selectedPackage);
    }
    if (this.availablePackages) {
      // if raw json data, ensure this gets converted to array of PackageInstances
      this.setAvailablePackages(this.availablePackages);
    }
    if (this.listing) {
      this.listing = new Listing(this.listing);
    }
    if (this.media) {
      this.media = new Media(this.media);
    }
    this.photos = (this.photos || []).map((photo) => new ListingPhoto(photo));
    this.tasks = (this.tasks || []).map((task) => new Task(task));
    return this;
  }

  /**
   * This returns the photos for the marketing order
   */
  public getPhotos(): ListingPhoto[] {
    return this.photos;
  }

  /**
   * This returns the correct photos at a product instance level
   * @param productCode defines which product in which to get the photos
   */
  public getPhotosForProduct(productCode: string): any {
    if (!productCode) {
      return;
    }

    const product = this.selectedPackage?.products.find((aProduct) => aProduct.code === productCode);
    return product.photos;
  }

  /**
   * Sets photos list on listing or a product instance
   * @param photos
   * @param productCode
   */
  public setPhotos(photos: any, productCode?: string): void {
    if (!productCode) {
      this.photos = photos;
    } else {
      const product = this.selectedPackage?.products.find((aProduct) => aProduct.code === productCode);

      if (!product.photos || product.photos.toString().length === 0) {
        product.photos = photos;
      } else {
        // photos are stored on a product as an object that looks like a map: { photoType: ListingPhoto[] }.
        // One might think that product.photos = photos is the fastest way to get this job done.  However,
        // we don't want inadvertently overwrite an existing product.photos map entry that doesn't
        // exist in the map passed in to the function so we're looping and setting instead.
        for (const key in photos) {
          if (Object.prototype.hasOwnProperty.call(photos, key)) {
            product.photos[key] = photos[key];
          }
        }
      }
    }
  }

  public setAvailablePackages(data: PackageInstance[]): void {
    this.availablePackages = data.map((pkg) => new PackageInstance(pkg));
  }

  public getPhotographyProduct(): ProductInstance {
    return this.getProductByTitle(ProductTitles.photograhy);
  }

  public getBrochureProduct(): ProductInstance {
    return this.getProductByTitle(ProductTitles.brochure);
  }

  /**
   * Returns a specific product by packageCode from a given marketing order
   * @param productCode The product code to retrieve
   */
  getProduct(productCode: string): ProductInstance {
    // Get all of the products from the selected package
    const products = this.getAllProducts();
    const product = products.find((prod) => prod.code === productCode);
    return product;
  }

  /**
   * Returns a specific product by package title from a given marketing order
   * @param productTitle The product title to retrieve
   */
  getProductByTitle(productTitle: string): ProductInstance {
    if (!productTitle) return;

    // RegEx pattern for upper and lower case letters only
    const pattern = /[a-zA-Z]+/g;

    // Remove all non-alpha characters from search string
    const alphaProductTitle = productTitle.match(pattern)?.join('');
    if (!alphaProductTitle) return;

    // Get all of the products from the selected package
    const products = this.getAllProducts();
    const product = products
      .filter((prod) => prod.title?.length > 0) // remove any products with no titles (since we're matching on title)
      .find((prod) => {
        const alphaTitle = prod.title.match(pattern)?.join('');
        return alphaTitle?.toLowerCase() === alphaProductTitle?.toLowerCase();
      });

    return product;
  }

  getProductIndex(product: ProductInstance | ProductCode) {
    const code = product instanceof ProductInstance ? product?.code : product;
    return (this.selectedPackage?.products || []).findIndex((prod) => prod.code === code);
  }

  /**
   * Returns all products from a given marketing order
   */
  getAllProducts(): ProductInstance[] {
    // Get all of the products from the selected package
    const packageProducts = this.selectedPackage?.products || [];
    return packageProducts || [];
  }

  /**
   * Returns all available products that are not opted out
   */
  getAvailableProducts(): ProductInstance[] {
    const allProducts = this.getAllProducts();
    const availableProducts = allProducts.filter((pr) => pr.isAvailable && !pr.optedOut && pr.details?.duration !== 0);
    return availableProducts;
  }

  getTemplateDesignsRouterLink(product: ProductInstance) {
    return `/manage-order/${this._id}/products-services/${kebabCase(product.title)}`;
  }

  getImpediments(product: ProductInstance, impediments: any) {
    const impediment = impediments.filter((e) => e.productCode === product.code);
    if (impediment.length > 0) {
      return {
        isImpedimentBy: true,
        activetext: impediment[0].activeText,
      };
    }
    return {
      isImpedimentBy: false,
      activetext: '',
    };
  }

  hasShippableProducts(): boolean {
    const productsArray = this.getAllProducts();
    return productsArray?.some((product) => product.isAvailable && !product.details?.isReplaced && !product.optedOut && product.details?.shippedBy);
  }

  /**
   * Resets all the templates selected across all the products on a marketing order
   */
  resetAllProductTemplates() {
    const allProducts = this.getAllProducts();
    allProducts.forEach((product) => {
      delete product.selectedTemplate;
    });
  }

  clearPackageSpecificData() {
    if (this.selectedPackage) {
      // Relocate the selected package in the snapshot of available packages and restore it
      const freshPackage = this.availablePackages?.find((availPackage) => availPackage.code === this.selectedPackage.code);
      if (freshPackage) {
        this.selectedPackage = freshPackage;
      } else {
        console.warn('Could not relocate the selectedPackage in the available packages');
      }
    }
  }
}
