<lc-card>
  <ng-template #header>
    <lc-header header="Version History">
      <ng-template #suffix>
        <button mat-icon-button (click)="onClose()">
          <mat-icon>close</mat-icon>
        </button>
      </ng-template>
    </lc-header>
  </ng-template>
  <ng-template #content>
    <div class="fx-row" *ngIf="(fieldVersions$|async) as fieldVersions">
      <div class="version-table-container clean-scroll-bar fx-1-1"
           *ngIf="fieldVersions?.length">
        <table id="field-version-table" mat-table [dataSource]="fieldVersions"
               class="mat-elevation-z8 w-100 version-table">

          <!--- Note that these columns can be defined in any order.
                            The actual rendered columns are set as a property on the row definition" -->

          <!-- modifiedTime Column -->
          <ng-container matColumnDef="modifiedTime">
            <th mat-header-cell *matHeaderCellDef> Date/Time </th>
            <td id="field-version-date" style="width: 23%" mat-cell *matCellDef="let element">
              {{element.modifiedTime * 1000|date:'MM-dd-yyyy h:mm a'}}
            </td>
          </ng-container>

          <!-- Key Column -->
          <ng-container matColumnDef="userFullName">
            <th mat-header-cell *matHeaderCellDef> Who </th>
            <td id="field-version-who" style="width: 23%" class="pr-2" mat-cell
                *matCellDef="let element">{{element.userFullName}}
            </td>
          </ng-container>
          <!-- Key Column -->
          <ng-container matColumnDef="key">
            <th mat-header-cell *matHeaderCellDef> Field </th>
            <td id="field-version-key" style="width: 23%" class="pr-2" mat-cell
                *matCellDef="let element">{{element.key}}
            </td>
          </ng-container>

          <!-- value Column -->
          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef> Value </th>
            <td id="field-version-value" style="width: 40%" class="pr-2" mat-cell
                *matCellDef="let element">
              <div *ngIf="element.value">
                <div class="field-version"
                     *ngIf="((element.value).toString() === ('assets/images/welcome-house.png'))">
                  This Photo was removed</div>
                <div class="field-version" [innerHTML]="(element.value)"
                     *ngIf="!(((element.value).toString().includes('https://listing-concierge')) || ((element.value).toString().includes('https://media'))) && !((element.value).toString() === ('assets/images/welcome-house.png'))">
                </div>
                <a href="{{element.value}}" target="_blank"
                   *ngIf="(((element.value).toString().includes('https://listing-concierge')) || ((element.value).toString().includes('https://media')))">
                  This Photo was used</a>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr id="field-version-row" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </ng-template>
</lc-card>