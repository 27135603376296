import { MarketingOrder } from '../../models';
import * as OrderActionTypes from './order.actions';

export const initialState:MarketingOrder = null;

export function orderReducer(state = initialState, action): MarketingOrder {
  switch (action.type) {
    case OrderActionTypes.InitializeOrder.type: {
      return null;
    }
    // set state to loaded order
    case OrderActionTypes.LoadOrder.type:
    case OrderActionTypes.AddOrderComplete.type:
    case OrderActionTypes.GetOrderComplete.type:
    case OrderActionTypes.UpdateOrderComplete.type: {
      return new MarketingOrder(action.payload);
    }
    default:
      return state;
  }
  // return createOrderReducer(state, action)
}

export const cloneOrder = (order) => {
  return order ? new MarketingOrder(JSON.parse(JSON.stringify(order))) : null;
};
