import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PhonePipe } from './phone.pipe';
import { MinuteSecondsPipe } from './minutes.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { CssSafePipe } from './css-safe.pipe';
import { AddressPipe } from './address.pipe';
import { ServiceTypeDisplayPipe } from './service-type-display.pipe';
import { StatusDisplayPipe } from './status-display.pipe';
import { IsBrandPipe } from './brand.pipe';

const angularModules = [CommonModule];
const internalComponents = [];
const externalComponents = [
  PhonePipe, MinuteSecondsPipe,
  ServiceTypeDisplayPipe, StatusDisplayPipe,
  SafeHtmlPipe, CssSafePipe, IsBrandPipe,
];
const externalModules = [
  AddressPipe,
];

@NgModule({
  declarations: [
    ...internalComponents,
    ...externalComponents,
  ],
  exports: [
    ...externalComponents,
    ...externalModules,
  ],
  imports: [
    ...angularModules,
    ...externalModules,
  ],
})
export class PipesModule { }
