import { Injectable } from '@angular/core';
import { ApiService, ListingPhoto, Ai, MarketingOrder, UpdateCurrentOrderState } from '@lc/core';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class OrderPhotoService {
  constructor(
    private apiService: ApiService,
    private store: Store<any>,
  ) { }

  async generateTagsAndCaptions(orderId: string): Promise<{ photos: ListingPhoto[], ai: Ai }> {
    const route = `/orders/${orderId}/photos/generateTagsAndCaptions`;

    try {
      return await this.apiService.create(route, null, { version: 'v2' });
    } catch (error) {
      throw error?.error?.error || Error('An error occurred generating photo captions and key features');
    }
  }

  async patchOrderPhotos(orderId: string, partialPhotos: Partial<ListingPhoto>[]): Promise<MarketingOrder> {
    const route = `/orders/${orderId}/photos`;
    try {
      const updatedOrder = await this.apiService.patch(route, { partialPhotos }, { version: 'v2' });
      this.store.dispatch(UpdateCurrentOrderState({ payload: updatedOrder }));
      return updatedOrder;
    } catch (error) {
      throw error?.error?.error || Error('An error occurred patching updating the photo');
    }
  }
}
