<ng-container *ngIf="form">
  <!-- Address and Area search container-->
  <div lcClass.lt-md="fx-column" lcClass.gt-sm="fx-row fx-gap-sm">
    <!-- Address Search Filter-->
    <lc-textbox
      *ngIf="!isDCApp"
      class="fx-1-1-0"
      label="Search by Address"
      placeholder="Search by Address"
      [formControl]="form.searchFormControl"
      idPrefix="search"
    ></lc-textbox>

    <!-- Area Filter-->
    <lc-multiselect
      class="fx-1-1-0"
      label="Area"
      idPrefix="area"
      (openedChange)="onAreaClosed($event)"
      [displayOptions]="areaDisplayOptions"
      [options]="areas"
      [(ngModel)]="form.selectedAreas"
    >
      <ng-template #optionTpl let-item="item">
        <div><span class="status-text option-title">{{item}}</span></div>
      </ng-template>
    </lc-multiselect>

    <lc-multiselect
      class="fx-1-1-0"
      idPrefix="status"
      label="Status"
      (openedChange)="onStatusClosed()"
      [displayOptions]="statusDisplayOptions"
      [options]="form.statusOptions"
      [(ngModel)]="form.selectedStatuses"
      displayPath="label"
    >
      <ng-template #optionTpl let-item="item">
        <div>
          <span class="ui-icon ui-icon-status-bullet" [style]="{'background-color': item.color}"></span>
          <span class="status-text option-title">{{item.label}}</span></div>
      </ng-template>
    </lc-multiselect>

    <!-- Date Filter-->
    <lc-multiselect
      #dateSelect
      class="fx-1-1-0"
      label="Status Change Date"
      idPrefix="date"
      dropdownClass="date-filter"
      multiple="false"
      idPath="text"
      displayPath="display"
      [displayOptions]="dateDisplayOptions"
      [options]="form.dateOptions"
      [(ngModel)]="form.selectedDateOption"
      [deselectAllEnabled]="false"
    >

      <ng-template #optionTpl let-item="item">
        <div>
          <span class="date-option-text option-title">{{item.text}}</span>
        </div>

        <lc-datepicker
          #range
          *ngIf="item.text === 'Custom'"
          [enableRangeSelection]="true"
          (dateChange)="item.startDate = $event.start; item.endDate = $event.end; dateSelect.updateDisplay(); updateMaxDate($event.start, $event.end);"
          [maxDate]="maxDate"
          [defaultStartDate]="item.startDate"
          [defaultEndDate]="item.endDate"
        >
        </lc-datepicker>
      </ng-template>

    </lc-multiselect>


    <lc-multiselect
      *ngIf="form.showFlagged"
      class="fx-1-1-0"
      idPrefix="flagged"
      label="Flag"
      [formControl]="form.flaggedControl"
      (openedChange)="onFlagClosed()"
      [displayOptions]="flagDisplayOptions"
      [options]="form.flagOptions"
    ></lc-multiselect>
  </div>

  <div lcClass.lt-md="fx-column" lcClass.gt-sm="fx-row fx-gap-sm">
    <div class="fx-row fx-gap-sm fx-1-1-0">
      <lc-dropdown
        *ngIf="form.userFilters === 'both'"
        class="user fx-1-1-0"
        label="Search by User"
        [options]="form.userFilterOptions"
        displayPath="viewValue"
        valuePath="value"
        [(ngModel)]="form.selectedUserFilterOption"
      ></lc-dropdown>

      <lc-agent-search
        *ngIf="form.selectedUserFilterOption === 'agent'"
        class="fx-1-1-0"
        label="Agent"
        placeholder="Search by Agent name"
        [formControl]="form.agentSearch"
        [selectedIds]="form.selectedAgentIds"
        [searchBy]= "form.selectedUserFilterOption"
        (selectedChanged)="onAgentsSelected($event)"
      ></lc-agent-search>

      <lc-agent-search
        *ngIf="form.selectedUserFilterOption === 'lcc'"
        class="fx-1-1-0"
        label="Coordinator"
        placeholder="Search by Coordinator name"
        [formControl]="form.coordinatorSearch"
        [selectedIds]="form.selectedCoordinatedIds"
        (selectedChanged)="onCoordinatorsSelected($event)"
        [searchBy]= "form.selectedUserFilterOption"
      ></lc-agent-search>
    </div>

    <lc-multiselect
      *ngIf="form.showAssignment"
      class="fx-1-1-0"
      idPrefix="assignment"
      label="Assigned to"
      (openedChange)="onAssignmentsClosed()"
      [displayOptions]="assignmentDisplayOptions"
      [options]="form.assignmentOptions"
      [formControl]="form.assignmentsFormControl"
    ></lc-multiselect>
  </div>
</ng-container>
