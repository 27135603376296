import { formatDate } from '@angular/common';

export class DateOption {
  private _startDate: Date;
  private _endDate: Date;

  /** Property get accessor: Returns the start date */
  public get startDate(): Date { return this._startDate; }

  /** Property set accessor: Sets the start date to the start of the day */
  public set startDate(value: Date) {
    if (value) {
      // Start date must always start at 0:0:0
      value.setHours(0, 0, 0, 0);
    }
    this._startDate = value;
    this.setDisplay();
  }

  /** Property get accessor: Returns the end date */
  public get endDate(): Date { return this._endDate; }

  /** Property set accessor: Sets the end date to a tick before midnight */
  public set endDate(value: Date) {
    if (value) {
      // End date must always be a tick before midnight
      value.setHours(23, 59, 59, 999);
    }
    this._endDate = value;
    this.setDisplay();
  }

  public display: string;

  constructor(public readonly text: 'Today' | 'This Week' | 'This Month' | 'Past 90 Days' | 'Last Calendar Year' | 'Custom', private customDefault = 90) {
    this.setDates();
    this.setDisplay();
  }

  private setDisplay() {
    let display: string = this.text;
    if (this.text === 'Custom' && this.startDate && this.endDate) {
      display = `${formatDate(this.startDate, 'shortDate', 'en-US')} - ${formatDate(this.endDate, 'shortDate', 'en-US')}`;
    }
    this.display = display;
  }

  /**
   * Set the default dates based on which option is being displayed.
   */
  private setDates() {
    if (this.text === 'Today') {
      this.startDate = new Date();
      this.endDate = new Date();
    } else if (this.text === 'This Week') {
      this.startDate = this.getMonday();
      this.endDate = this.getSunday();
    } else if (this.text === 'This Month') {
      const today = new Date();
      this.startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      this.endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    } else if (this.text === 'Past 90 Days') {
      const today = new Date();
      this.startDate = new Date(today.setDate(today.getDate() - 90));
      this.endDate = new Date();
    } else if (this.text === 'Last Calendar Year') {
      const today = new Date();
      this.startDate = new Date(today.getFullYear() - 1, 0, 1);
      this.endDate = new Date(today.getFullYear() - 1, 11, 31);
    } else if (this.text === 'Custom') {
      const today = new Date();
      this.startDate = new Date(today.setDate(today.getDate() - this.customDefault));
      this.endDate = new Date();
    }
  }

  /**
   * Returns the Monday of the current week.
   */
  private getMonday() {
    const d = new Date();
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1);
    d.setDate(diff);
    return d;
  }

  /**
   * Returns the Sunday of the current week.
   */
  private getSunday() {
    const d = new Date();
    const day = d.getDay();
    const diff = d.getDate() - day + 7;
    d.setDate(diff);
    return d;
  }
}
