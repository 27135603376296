import { AbstractControl, FormControl } from '@angular/forms';
import { BaseForm } from './base.form';

export class MarketingCopyForm extends BaseForm {
  get headline(): AbstractControl { return this.get('headline'); }
  get body(): AbstractControl { return this.get('body'); }

  constructor(public readonly marketingOrder?: any) {
    super({
      headline: new FormControl(null),
      body: new FormControl(null),
    });
  }
}
