import { Deserializable } from './deserializable.model';

/*
 * Social Media type used for dropdown selects and labeling
 */
export enum MediaType {
  facebook = 'facebook',
  twitter = 'twitter',
  instagram = 'instagram',
  linkedIn = 'linkedIn',
  tikok = 'tiktok',
}

/**
 * The friendly UI display and values for the Request Types
 */

export const SocialMediaTypeDisplays: { display: string, value: MediaType }[] = [
  { display: 'Facebook', value: MediaType.facebook },
  { display: 'Twitter', value: MediaType.twitter },
  { display: 'Instagram', value: MediaType.instagram },
  { display: 'LinkedIn', value: MediaType.linkedIn },
  { display: 'TikTok', value: MediaType.tikok },
];

export class SocialMedia implements Deserializable<SocialMedia> {
  type: string;
  link: string;

  constructor(input?: any) {
    this.deserialize(input || {});
  }

  deserialize(input: any): SocialMedia {
    Object.assign(this, input);
    return this;
  }
}
