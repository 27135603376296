import { Deserializable } from './deserializable.model';

export class LoanOfficer implements Deserializable<LoanOfficer> {
  largeURL: string;
  smallURL: string;

  constructor(input?: any) {
    this.deserialize(input || {});
  }

  deserialize(input: any): LoanOfficer {
    Object.assign(this, input);
    return this;
  }
}
