import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription, map } from 'rxjs';
import { ScreenSize } from './screen-size.directive';

/**
 * Used to map the screen size to the appropriate input properties. Matching input property
 * values will be added as classes to the host element.
 */
const ALIASES: { [S in ScreenSize]: string[] } = {
  xsmall: ['lcClassXs', 'lcClassLtSm', 'lcClassLtMd', 'lcClassLtLg', 'lcClassLtXl'],
  small: ['lcClassSm', 'lcClassGtXs', 'lcClassLtMd', 'lcClassLtLg', 'lcClassLtXl'],
  medium: ['lcClassMd', 'lcClassGtXs', 'lcClassLtLg', 'lcClassLtXl', 'lcClassGtSm'],
  large: ['lcClassLg', 'lcClassGtXs', 'lcClassGtSm', 'lcClassGtMd', 'lcClassLtXl'],
  xlarge: ['lcClassXl', 'lcClassGtXs', 'lcClassGtSm', 'lcClassGtMd', 'lcClassGtLg'],
};

@Directive({
  selector: `
    [lcClass], [lcClass.xs], [lcClass.sm], [lcClass.md], [lcClass.lg], [lcClass.xl],
    [lcClass.lt-sm], [lcClass.lt-md], [lcClass.lt-lg], [lcClass.lt-xl],
    [lcClass.gt-xs], [lcClass.gt-sm], [lcClass.gt-md], [lcClass.gt-lg]
  `,
})
export class ResponsiveClassDirective implements OnInit, OnChanges, OnDestroy {
  @Input() lcClass: string;
  @Input('lcClass.xs') lcClassXs: string;
  @Input('lcClass.sm') lcClassSm: string;
  @Input('lcClass.md') lcClassMd: string;
  @Input('lcClass.lg') lcClassLg: string;
  @Input('lcClass.xl') lcClassXl: string;
  @Input('lcClass.lt-sm') lcClassLtSm: string;
  @Input('lcClass.lt-md') lcClassLtMd: string;
  @Input('lcClass.lt-lg') lcClassLtLg: string;
  @Input('lcClass.lt-xl') lcClassLtXl: string;
  @Input('lcClass.gt-xs') lcClassGtXs: string;
  @Input('lcClass.gt-sm') lcClassGtSm: string;
  @Input('lcClass.gt-md') lcClassGtMd: string;
  @Input('lcClass.gt-lg') lcClassGtLg: string;

  private currentSize: ScreenSize;
  private sub: Subscription;
  private size$: Observable<ScreenSize>;

  constructor(
    private hostElement: ElementRef,
    private renderer: Renderer2,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.size$ = this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      '(min-width: 1280px) and (max-width: 1599.99px)',
      '(min-width: 1600px)',
    ]).pipe(map((state) => {
      let size: ScreenSize;

      if (state.breakpoints[Breakpoints.XSmall]) {
        size = 'xsmall';
      } else if (state.breakpoints[Breakpoints.Small]) {
        size = 'small';
      } else if (state.breakpoints[Breakpoints.Medium]) {
        size = 'medium';
      } else if (state.breakpoints['(min-width: 1280px) and (max-width: 1599.99px)']) {
        size = 'large';
      } else {
        size = 'xlarge';
      }

      return size;
    }));
  }

  ngOnInit(): void {
    this.sub = this.size$.subscribe((size) => {
      this.currentSize = size;
      this.onChanges();
    });
  }

  ngOnChanges(): void {
    this.onChanges();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  onChanges() {
    if (this.currentSize) {
      // First, completely remove all classes that may have been dynamically added
      Object.keys(ALIASES).forEach((alias) => {
        ALIASES[alias].forEach((ref: string) => {
          const val = this[ref];

          if (val) {
            const classes = val.trim().split(' ');

            classes.forEach((klass: string) => {
              this.renderer.removeClass(this.hostElement.nativeElement, klass);
            });
          }
        });
      });

      // Then, add the appropriate classes for the current size
      ALIASES[this.currentSize].forEach((ref: string) => {
        const val = this[ref];

        if (val) {
          const classes = val.trim().split(' ');

          classes.forEach((klass: string) => {
            this.renderer.addClass(this.hostElement.nativeElement, klass);
          });
        }
      });
    }
  }
}
