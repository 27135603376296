import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  catchError, filter, switchMap, tap, mergeMap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ProfileService } from '../../services/profile.service';
import { Profile } from '../../models/profile.model';
import {
  UpdateProfileComplete,
  GetProfileComplete,
} from './profile.actions';
import * as ProfileActionTypes from './profile.actions';
import { ErrorData, GlobalErrorHandler } from '../../errors';
import { NotificationEvent, NotificationEventService } from '../../notifications';
import { UserProfileUpdated } from '../user/user.actions';
import { BaseEffects } from '../base.effects';
import { GetOrder } from '../order/order.actions';
import { MarketingOrder } from '../../models/marketing-order.model';

@Injectable()
export class ProfileEffects extends BaseEffects {
  constructor(
    private actions$: Actions,
    private profileService: ProfileService,
    private store: Store<any>,
    eventService: NotificationEventService,
    errorHandler: GlobalErrorHandler,
  ) {
    super(errorHandler, eventService);
  }

  getProfile: Observable<Profile> = createEffect(() => this.actions$.pipe(
    ofType(ProfileActionTypes.GetProfile),
    switchMap((action) => {
      return this.profileService.getProfile(action.payload).pipe(
        catchError((err) => this.processCatchError(ProfileActionTypes.GetProfile, { payload: action.payload }, err)),
      );
    }),
    filter((profile) => !(profile instanceof ErrorData)),
    tap((profile: Profile) => {
      this.store.dispatch(GetProfileComplete({ payload: profile }));
      const event = new NotificationEvent('[Profile] Get Complete', 'Profile loaded');
      this.eventService.getEventEmitter().emit(event);
    }),
  ), { dispatch: false });

  updateProfile: Observable<Profile> = createEffect(() => this.actions$.pipe(
    ofType(ProfileActionTypes.UpdateProfile),
    mergeMap((action) => {
      return this.profileService.updateProfilePartial(action.payload, action.fields).pipe(
        tap((profile) => {
          if (action.orderId) {
            // If the orderID is passed through as an action, we need to call to dispatch an
            // action to GetOrder so that the UI will update with the proper team information.
            // NOTE: This dispatch is using the cached version of the MarketingOrder.
            // Pass false as the last parameter if you want it to get the latest from the API
            this.store.dispatch(GetOrder({ payload: new MarketingOrder({ _id: action.orderId }) }));
          }
          if (action.isCurrentProfile) {
            this.store.dispatch(UserProfileUpdated({ payload: profile }));
          }
        }),
        catchError((err) => this.processCatchError(ProfileActionTypes.UpdateProfile, { payload: action.payload, fields: action.fields }, err)),
      );
    }),
    filter((profile) => !(profile instanceof ErrorData)),
    tap((profile: Profile) => {
      this.updateLoggedInUserProfile(profile);
      this.updateComplete(profile);
    }),
  ), { dispatch: false });

  updateLoggedInUserProfile(profile: Profile): void {
    this.store.dispatch(UserProfileUpdated({ payload: profile }));
  }

  updateComplete(profile: Profile) {
    this.store.dispatch(UpdateProfileComplete({ payload: profile }));
    const event = new NotificationEvent('[Profile] Update Complete', 'Profile updated');
    this.eventService.getEventEmitter().emit(event);
  }
}
