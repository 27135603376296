import { AgentTypes } from './agent.type';
import { ListingUi } from './listing-ui.model';
import { PropertyStatus, PropertyType } from './property.type';
import { QrCodeStatus } from './qr-code-display.types';
import { FlowStateData } from './flow-state.model';
import { LandTenure } from './land-tenure.enum';
import { LotSizeUnitOfMeasure } from './lot-size-unit-of-measure.enum';

export interface OrderState {
  id: string;
  state: FlowStateData;
}

export class Listing extends ListingUi {
  listDate:Date;
  closedDate:Date;
  archived: Boolean;
  agentType: AgentTypes;
  landTenure?: LandTenure;
  buyerBrokerageCommission: string;
  qrCodeStatus: string = QrCodeStatus.NO_DISPLAY;
  qrCodeRedirectUrl?: string;

  public static createListing(): Listing {
    return new Listing();
  }

  /**
   * @param listing Listing to copy (optional)
   */
  constructor(listing?: Partial<Listing>) {
    super(listing);
    if (listing) {
      this.deserialize(listing);
    }
  }

  public clearAgentInfo() {
    this.agentType = AgentTypes.singleAgent;
  }

  public clearPropertyInfo() {
    // HACK: This should really be a child entity (i.e. Listing.PropertyInfo)
    // we should really just be doing Listing.PropertInfo = new PropertyInfo();
    this.propertyType = PropertyType.SINGLE_FAMILY;
    this.status = PropertyStatus.ACTIVE;
    this.listPrice = null;
    this.displayAsPriceOnRequest = false;
    this.bedrooms = 0;
    this.bathrooms = 0;
    this.sqFt = 0;
    this.lotSize = 0;
    this.lotSizeUnitOfMeasure = LotSizeUnitOfMeasure.sqft;
    this.listDate = null;
    this.closedDate = null;
    this.qrCodeStatus = QrCodeStatus.NO_DISPLAY;
    this.qrCodeRedirectUrl = null;
    this.features = [];
  }

  formatAddress() {
    const { address } = this;
    if (!address) { return null; }
    return `${address.streetAddress1}${address?.streetAddress2 ? ` - ${address.streetAddress2}` : ''}, ${address.city}, ${address.state} ${address.postalCode}`;
  }

  deserialize(input: any): Listing {
    Object.assign(this, input);
    if (input.listDate) {
      this.listDate = new Date(input.listDate);
    }
    if (input.closedDate) {
      this.closedDate = new Date(input.closedDate);
    }
    return this;
  }
}
