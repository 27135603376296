import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, take } from 'rxjs/operators';

import {
  Address,
  MarketingOrder,
  OutputMimeType,
  ProductInstance,
} from '../models';

import { WebsiteGenerationService } from './website-generation.service';
import { PdfGenerationService } from './pdf-generation.service';
import { GlobalErrorHandler } from '../errors/global-error-handler';
import { ErrorData } from '../errors/error-data';
import { VideoGenerationService } from './video-generation.service';
import { ToasterService } from './toaster.service';

@Injectable()
export class ProductGenerationService {
  public DOWNLOADABLE_URL_MATCH = 'amazonaws';
  private mimeTypeGenerators = {};

  constructor(
    websiteGenerationService: WebsiteGenerationService,
    pdfGenerationService: PdfGenerationService,
    videoGenerationService: VideoGenerationService,
    protected readonly errorHandler: GlobalErrorHandler,
    private toasterService: ToasterService,
  ) {
    this.mimeTypeGenerators[websiteGenerationService.mimeType] = websiteGenerationService;
    this.mimeTypeGenerators[pdfGenerationService.mimeType] = pdfGenerationService;

    this.mimeTypeGenerators[OutputMimeType.jpg] = pdfGenerationService;
    this.mimeTypeGenerators[OutputMimeType.json] = videoGenerationService;
  }

  isMimeTypeDownloadable(mimeType: string): boolean {
    return this.mimeTypeGenerators[mimeType].downloadable;
  }

  isProductUrlDownloadable(product: ProductInstance): boolean {
    for (let i = 0; product.publishedUris && i < product.publishedUris.length; i++) {
      const url = product.publishedUris[i].uri;
      if (product.isVideoService() && url.indexOf(this.DOWNLOADABLE_URL_MATCH) >= 0) {
        return true;
      }
    }
    return false;
  }

  isDownloadable(product: ProductInstance): boolean {
    const { mimeType } = product.selectedTemplate;
    return this.isMimeTypeDownloadable(mimeType) || this.isProductUrlDownloadable(product);
  }

  download(order: MarketingOrder, product: ProductInstance, imageResolution?: string): Observable<any> {
    const { mimeType } = product.selectedTemplate;
    // Download flag used for image optimization and
    // using low res images if selected by user.
    const isDownload = true;

    return this.isDownloadable(product)
      ? this.mimeTypeGenerators[mimeType].download(order, product, imageResolution, isDownload)
      : of(null);
  }

  downloadById(
    marketingOrderId: string,
    listingAddress: Address,
    product: ProductInstance | { title: string, code: string },
    mimeType: string,
  ) {
    if (this.isMimeTypeDownloadable(mimeType)) {
      const generator = this.mimeTypeGenerators[mimeType];
      if (generator && generator.downloadById) {
        this.toasterService.showInfo('Download initiated');
        generator.downloadById(marketingOrderId, listingAddress, product);
      }
    }
  }

  generate(
    order: MarketingOrder,
    product: ProductInstance,
    generateData?: { consent?: boolean, preview?: boolean },
  ): Promise<any> {
    const { mimeType } = product.selectedTemplate;
    return this.mimeTypeGenerators[mimeType]
      .generate(order, product, generateData)
      .pipe(
        catchError((err) => this.processCatchError('PRODUCT_GENERATION', order, err)),
        take(1),
      )
      .toPromise();
  }

  unpublish(order: MarketingOrder, product: ProductInstance): Promise<any> {
    const { mimeType } = product.selectedTemplate;
    return this.mimeTypeGenerators[mimeType].unpublish(order).pipe(
      catchError((err) => this.processCatchError('PRODUCT_GENERATION', order, err)),
    ).toPromise();
  }

  processCatchError(type, payload, err): Observable<ErrorData> {
    const errorPayload = new ErrorData(
      type,
      {
        original: payload,
        error: err,
      },
    );
    this.errorHandler.handleError(errorPayload);
    return of(errorPayload);
  }
}
