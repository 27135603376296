import { FormControl, FormControlOptions, ValidatorFn } from '@angular/forms';
import { numberValidator } from '../../validators';

export class NumberFormControl extends FormControl {
  constructor(formState?: any, validatorOrOpts?: ValidatorFn | ValidatorFn[] | FormControlOptions) {
    const validators = validatorOrOpts instanceof Array ? validatorOrOpts : [];
    if (validators.indexOf(numberValidator) < 0) {
      validators.push(numberValidator);
    }
    super(formState, numberValidator);
  }

  setValue(value: any, options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
    emitModelToViewChange?: boolean;
    emitViewToModelChange?: boolean;
  }): void {
    const isNumber = !isNaN(value);
    if (isNumber) {
      value = Number(value);
    } else if (value === '-') {
      value = -0;
    }
    return super.setValue(value, options);
  }
}
