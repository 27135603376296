import {
  Component,
  forwardRef,
  Input,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter,
  TemplateRef, ContentChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, isObservable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { InputField } from '../input-field';

export class MultiSelectDisplayOptions {
  /**
   *
   * @param display Display text for options i.e. - Paper
   * @param multiDisplay Used for pluralization of selected options i.e. - Papers (2)
   * @param displayMultiCount Used to distinguish if should display the count for multi selections i.e. - (2)
   */
  constructor(public display: string, public multiDisplay?: string, public displayMultiCount: boolean = true) {
  }
}

@Component({
  selector: 'lc-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiselectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MultiselectComponent),
      multi: true,
    },
  ],
})
export class MultiselectComponent extends InputField implements OnChanges {
  @Input()
    label: string;

  @Input()
    hint: string;

  @Input()
    displayOptions: MultiSelectDisplayOptions;

  @Input()
    options: any | Observable<any[]>;

  @Input()
    displayPath: string = '';

  @Input()
    valuePath: string;

  /**
   * If false the user can select only a single option.
   */
  @Input()
    multiple: boolean = true;

  /**
   * If true then the X button on the right will be enabled
   * when there is a selection, otherwise it is hidden and the user must
   * manually de-select individual options.
   */
  @Input()
    deselectAllEnabled: boolean = true;

  @Input()
    dropdownClass: string;

  @Input()
    idPath: string;

  @ContentChild('optionTpl')
    optionTemplate: TemplateRef<any>;

  @ContentChild('display') displayTemplate: TemplateRef<any>;
  @ContentChild('displayDateRange') displayDateRangeTemplate: TemplateRef<any>;

  @Output()
  readonly selectionChanged = new EventEmitter<any[]>();

  @Output()
  readonly openedChange = new EventEmitter<boolean>();

  public display: string;
  public isSelected: boolean;
  private allOptions: any = [];
  public options$: any | Observable<any[]>;

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      if (isObservable(this.options)) {
        this.options$ = this.options.pipe(
          tap((options) => this.allOptions = options),
          tap(() => this.updateDisplay()),
        );
      } else {
        this.options$ = of(this.options);
        this.allOptions = this.options;
      }
    }
    if (changes.multiple) {
      this.multiple = changes.multiple.currentValue === true || changes.multiple.currentValue === 'true';
    }
    this.updateDisplay();
  }

  clear(event) {
    event.stopPropagation();

    const value = this.multiple ? [] : null;
    this.value = value;
    if (this.formControl) {
      this.formControl.setValue(value);
    }
    this.onSelectionChanged([]);
    this.openedChange.emit(false);
  }

  onSelectionChanged(selections: any[]) {
    const value = this.getSelection();
    this.selectionChanged.emit(value);
  }

  onOpenedChange(opened: boolean) {
    this.openedChange.emit(opened);
  }

  updateDisplay() {
    this.display = this.getDisplay();
    const selection = this.getSelection();
    this.isSelected = (selection || []).length > 0;
  }

  protected executeOnChanged() {
    // Intercept when the value changes to update the display
    this.updateDisplay();
    super.executeOnChanged();
  }

  private getSelection(): any[] {
    if (!this.value) return [];

    const selectionValues = this.multiple ? this.value : [this.value];

    return this.valuePath
      ? selectionValues
        .map((value: any) => this.options.find((option: {}) => option[this.valuePath] === value))
        .filter(Boolean)
      : selectionValues;
  }

  private getDisplay() {
    if (!this.displayOptions.displayMultiCount) {
      // If not displaying multicounts, just return the display
      return this.displayOptions.display;
    }

    const selected = this.getSelection() || [];
    const selectedCount = selected.length;

    if (selectedCount === 0) {
      return this.displayOptions.display;
    } if (selectedCount === 1) {
      return this.displayPath ? selected[0][this.displayPath] : selected[0];
    } if (selectedCount === this.allOptions?.length) {
      return `All ${this.displayOptions.multiDisplay}`;
    }
    return this.displayOptions.multiDisplay
      ? `${this.displayOptions.multiDisplay} (${selectedCount})`
      : this.displayOptions.display;
  }

  public getOptionId(option) {
    const path = this.idPath || this.displayPath;
    return `option-${((path ? option[path] : option) || '')
      .replace(/\./g, '')
      .replace(/ /g, '-')
      .toLowerCase()}`;
  }

  public getOptionAriaId() {
    let optionIds = 'disabled-option';
    this.options.forEach((optt) => {
      const path = this.idPath || this.displayPath;
      const optText = `option-${((path ? optt[path] : optt) || '')
        .replace(/\./g, '')
        .replace(/ /g, '-')
        .toLowerCase()}`;
      optionIds = `${optionIds} ${optText}`;
    });
    return optionIds;
  }
}
