
<div class="fx-column" [formGroup]="formGroup">
  <!-- Optional Name and Company if exists -->
  <lc-textbox *ngIf="formGroup.get('fullName')" label="Full Name" placeholder="" [formControl]="formGroup.get('fullName')"></lc-textbox>
  <lc-textbox *ngIf="formGroup.get('company')" label="Company (Optional)" placeholder="" [formControl]="formGroup.get('company')"></lc-textbox>
  <!-- Address -->
  <lc-autocomplete placeholderText="Number and Street" [autoCompleteOptions]="addressOptions$ | async" (optionValue)="updateAddressFields($event)" [isAutocomplete]="true"  label="Address 1" [formControl]="getControl('streetAddress1')" placeholder="Number and Street"></lc-autocomplete>

  <!-- Street Address2 and City Row -->
  <lc-textbox label="Address 2 (Optional)" placeholder="Address line 2" [formControl]="getControl('streetAddress2')"></lc-textbox>
  <lc-textbox label="City" placeholder="City" [formControl]="getControl('city')"></lc-textbox>

  <!-- State and Zip Code row (column if layout is xs)-->
  <div lcClass.gt-sm="fx-row fx-gap-sm" lcClass.lt-md="fx-column">
    <lc-dropdown lcClass.gt-sm="fx-1-1-0" class="state" label="State" placeholder="State" [options]="getStates()" displayPath="abbreviation" valuePath="abbreviation" [formControl]="getControl('state')" emptyOptionDisplay="Select State"></lc-dropdown>
    <lc-textbox lcClass.gt-sm="fx-1-1-0" label="Zip Code" placeholder="Zip Code" [formControl]="getControl('postalCode')"></lc-textbox>
  </div>

  <ng-container *ngIf="formGroup.get('phoneNumber') || formGroup.get('email')">
    <div lcClass.gt-sm="fx-row fx-gap-md" lcClass.lt-md="fx-column">
      <ng-container *ngIf="formGroup.get('phoneNumber') as phoneNumberForm" [formGroup]="phoneNumberForm">
        <lc-phone-number class="fx-1-1" idPrefix="phone" label="Phone" [formControl]="phoneNumberForm.get('number')"></lc-phone-number>
      </ng-container>
      <lc-textbox  *ngIf="formGroup.get('email') as emailControl"  class="fx-1-1" idPrefix="email" label="Email (Optional)" [formControl]="emailControl"></lc-textbox>
    </div>
  </ng-container>
</div>
