import { Component } from '@angular/core';

@Component({
  selector: 'lc-terms-of-use-page',
  templateUrl: './terms-of-use-page.component.html',
  styleUrls: ['./terms-of-use-page.component.scss'],
})
export class TermsOfUsePageComponent {
  constructor() { }
}
