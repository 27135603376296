import * as KeyActionTypes from './key.actions';

export const initialState: any = {};

export function keysReducer(state = initialState, action: any): any {
  switch (action.type) {
    // set state to loaded user
    case KeyActionTypes.ClearKeys.type: {
      return {};
    }
    case KeyActionTypes.AddKeys.type: {
      return { ...state, ...action.payload };
    }
    case KeyActionTypes.RemoveKeys.type: {
      action.payload.forEach((member) => delete state[member]);
      return { ...state };
    }
    default: {
      return state;
    }
  }
}
