import { Validators } from '@angular/forms';
import { noWhitespaceValidator } from '../validators';
import { AddressForm } from './address.form';
import { BaseForm } from './base.form';
import { StringFormControl } from './form-controls';
import { DeliveryAddress } from '../models';

export class DeliveryAddressForm extends BaseForm<DeliveryAddress> {
  get fullName(): string { return this.get('fullName').value; }
  get company(): string { return this.get('company').value; }

  readonly address: AddressForm;

  constructor(value?: any, options?: { trimPostalCode: boolean }) {
    super({
      fullName: new StringFormControl(value?.fullName || null, [Validators.required, noWhitespaceValidator()]),
      company: new StringFormControl(value?.company || null),
      address: new AddressForm(value?.address || null, options ?? null),
    });

    this.address = this.get('address') as AddressForm;
    this.reset(value);
  }
}
