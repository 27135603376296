import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { TeamProfile } from '../../models/team-profile.model';
import { GetTeam } from './team.actions';

/**
 * The team resolve is used to simply request the store to get the pipe plumbed with the Team
 */
@Injectable({
  providedIn: 'root',
})
export class TeamResolve {
  constructor(private store: Store<any>) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const { teamId } = route.params;
    let teamProfile: TeamProfile;
    if (teamId) {
      teamProfile = new TeamProfile();
      teamProfile._id = teamId;
    }
    this.store.dispatch(GetTeam({ team: teamProfile }));
    // we are no using resolve injection of objects so just return null
    return of(null);
  }
}
