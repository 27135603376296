import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[lcHovered]',
  exportAs: 'hovered',
  standalone: true,
})
export class HoveredDirective {
  private _isHovered: boolean;
  get isHovered(): boolean { return this._isHovered; }

  @HostListener('mouseenter', ['$event'])
  onEnter(e: MouseEvent) {
    this._isHovered = true;
  }

  @HostListener('mouseleave', ['$event'])
  onLeave(e: MouseEvent) {
    this._isHovered = false;
  }
}
