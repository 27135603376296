<lc-base-dialog [title]="data.title" [message]="data.message" [primaryAction]="data.acceptAction" [otherActions]="data.readonly ? [] : [data.cancelAction]" (actionClicked)="onActionClicked($event)">

  <ng-template #content>
    <form [formGroup]="reasonForm" id="reason-form">
      <!-- CSS class is for test selector only.  No UI implications. -->
      <p class="reason-message mat-body-1">{{data.message}}</p>

      <!-- if the modal contains editable fields -->
      <ng-container *ngIf="!data.readonly">
        <ng-container *ngIf="data.acceptAction.text === 'Refund'">
          <lc-dropdown #orderDropdown [formControl]="reasonForm.getControl('storeOrder')"
                       (selectionChanged)="onOrderSelected(orderDropdown.selected?.model.id)" [options]="orderOptions"
                       label="Order" idPrefix="store-order" class="store-order" valuePath="id" displayPath="display"
                       placeholder="Please select an order to refund">
          </lc-dropdown>
          <lc-multiselect
            #dateSelect
            *ngIf="lineItemOptions?.length"
            [formControl]="reasonForm.getControl('lineItems')"
            class="fx-1-1-0"
            label="Line Items"
            [multiple]="true"
            [options]="lineItemOptions"
            [displayOptions]="lineItemOptionsDisplay"
            displayPath="display"
            valuePath="id"
            [deselectAllEnabled]="true"
            (selectionChanged)="onLineItemSelected($event)"
          ></lc-multiselect>
        </ng-container>
        <lc-dropdown [formControl]="reasonForm.getControl('reason')" placeholder="Please select a reason from the list" idPrefix="reason" class="reason" [options]="data.reasons" label="Reason" (selectionChanged)="changeReason()"></lc-dropdown>

        <div *ngIf="!hideNotes">
          <lc-textarea [formControl]="reasonForm.getControl('notes')" idPrefix="reason-note" label="Details" placeholder="Please provide more details..."></lc-textarea>
        </div>

        <ng-container *ngIf="data.acceptAction.text === 'Refund'">
          <lc-textbox [formControl]="reasonForm.getControl('amount')" idPrefix="reason-amount" label="Refund" placeholder="$0" [inputMask]="refundMask" [maskDropSpecialCharacters]="false"></lc-textbox>
          <div>The maximum refund can be up to {{ maxRefundAmount | currency }}</div>
        </ng-container>
      </ng-container>

      <!-- if the modal is readonly -->
      <ng-container *ngIf="data.readonly">
        <div *ngIf="reasonForm.getControl('reason').value" class="mb-3">
          <div class="font-weight-bolder">REASON</div>
          <!-- CSS class is for test selector only.  No UI implications. -->
          <div class="reject-reason-text">{{reasonForm.getControl('reason').value}}</div>
        </div>
        <div *ngIf="reasonForm.getControl('notes').value">
          <div class="font-weight-bolder">DETAILS</div>
          <!-- CSS class is for test selector only.  No UI implications. -->
          <div class="reject-notes-text">{{reasonForm.getControl('notes').value}}</div>
        </div>
      </ng-container>
    </form>
  </ng-template>
</lc-base-dialog>
