import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../models/address.model';

@Pipe({
  name: 'address',
  standalone: true,
})
export class AddressPipe implements PipeTransform {
  transform(address: Address, newLine?: boolean) {
    const line1 = [address?.streetAddress1, address?.streetAddress2].filter((str) => !!str?.trim()).join(', ');
    const line2 = [address?.city, address?.state, address?.postalCode].filter((str) => str?.trim()).join(', ');

    return [line1, line2].filter((line) => !!line).join(newLine ? '<br>' : ', ');
  }
}
