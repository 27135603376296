<div class="h-100 py-0 fx-column fx-gap-md">

  <!-- Title -->
  <div class="fx-row y-center" [ngClass]="(header || title || subheader) ? 'x-space-between' : 'x-end'">
    <!-- If we passed in a template for the header, use that. Otherwise, use default-->
    <ng-container *ngIf="!titleTemplate; else titleTemplate">
      <div *ngIf="title || header || subheader" class="fx-column">
        <h1 mat-dialog-title class="m-0 dialog-title line-height-0 ">{{ header || title}}</h1>
        <span *ngIf="subheader" class="mat-subheading-2 mat-hint m-0 line-height-0 card-subheader">{{subheader}}</span>
      </div>
    </ng-container>

    <button *ngIf="!removeClose" mat-icon-button class="m-0 close-button fx-0-0-auto" (click)="onCloseClicked()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!-- Content -->
  <div class="banner-text prompt-text" *ngIf="!contentTemplate; else contentTemplate" mat-dialog-content [innerHTML]="message">
  </div>

  <!-- Actions -->
  <ng-container *ngIf="primaryAction || otherActions?.length || actionsTemplate">
    <div mat-dialog-actions *ngIf="!actionsTemplate; else actionsTemplate" >
      <div class="w-100 mb-4 fx-row-wrap x-end y-center fx-gap-sm">
        <a class="cancel-btn" [id]="toButtonId(action.text)" *ngFor="let action of otherActions" mat-button (click)="onActionClicked(action)">{{action.text}}</a>
        <button [id]="toButtonId(primaryAction.text)" *ngIf="primaryAction" type="button" mat-raised-button color="primary" (click)="onActionClicked(primaryAction)">{{primaryAction.text}}</button>
      </div>
    </div>
  </ng-container>
</div>
