import { Validators } from '@angular/forms';
import { BaseForm } from './base.form';
import { Note } from '../models/note.model';
import { StringFormControl } from './form-controls/string-form-control';

export class NoteForm extends BaseForm<Note> {
  constructor(note?: Note, options?: { isRequired?: boolean }) {
    super({
      // Notes Text. Allow Emojis and other special characters
      text: new StringFormControl(null, options?.isRequired === false ? [] : [Validators.required], { sanitizeOptions: { allowedCharacters: null, removeEmojis: false } }),
    });

    if (note) {
      this.patchValue(note);
    }
  }
}
