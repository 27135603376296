import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  catchError, filter, map, switchMap, withLatestFrom, tap, mergeMap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { MarketingOrderService } from '../../services/marketing-order.service';

import {
  AddOrderComplete,
  GetOrderComplete,
  UpdateOrderComplete,
  SELECTEDORDER,
  DeleteOrderComplete,
  UpdateOrderFailed,
  BulkAssignOrdersComplete,
} from './order.actions';
import * as OrderActionTypes from './order.actions';
import { ErrorData } from '../../errors/error-data';
import { NotificationEvent, NotificationEventService } from '../../notifications';
import { MarketingOrder } from '../../models';
import { GlobalErrorHandler } from '../../errors/global-error-handler';
import { BaseEffects } from '../base.effects';

/**
 * The order effects are post state change actions that occur
 */
@Injectable()
export class OrderEffects extends BaseEffects {
  constructor(
    private actions$: Actions,
    private marketingOrderService: MarketingOrderService,
    private store: Store<any>,
    eventService: NotificationEventService,
    errorHandler: GlobalErrorHandler,
  ) {
    super(errorHandler, eventService);
  }

  /**
   * Intercept errors so if it's a simple card decline from Stripe
   * we don't log the error to DataDog
   */
  processCatchError(type, payload, err): Observable<ErrorData> {
    return super.processCatchError(type, payload, err, true);
  }

  /**
   * Take action on AddOrder actions
   *  1. Update the order
   *  2. If error, dispatch to error handler
   *  3. filter out chained error observables
   *  4. Send out success notification and return observable of order
   *
   */

  addOrder: Observable<MarketingOrder> = createEffect(() => this.actions$.pipe(
    ofType(OrderActionTypes.AddOrder),
    mergeMap((action) => {
      return this.marketingOrderService.saveOrder(action.payload).pipe(
        catchError((err) => this.processCatchError(OrderActionTypes.AddOrder, action.payload, err)),
      );
    }),
    filter((order) => !(order instanceof ErrorData)),
    map((order:MarketingOrder) => {
      const event = new NotificationEvent(OrderActionTypes.AddOrderComplete.type, OrderActionTypes.AddOrderComplete.type, order);
      this.eventService.getEventEmitter().emit(event);
      this.store.dispatch(AddOrderComplete({ payload: order }));
      return order;
    }),
  ), { dispatch: false });

  /**
   * Take action on GetOrder actions
   *  1. Get the order
   *  2. If error, dispatch to error handler
   *  3. filter out chained error observables
   *  4. Send out success notification and return observable of order
   *
   */

  getOrder: Observable<MarketingOrder> = createEffect(() => this.actions$.pipe(
    ofType(OrderActionTypes.GetOrder),
    withLatestFrom(this.store.select(SELECTEDORDER)),
    switchMap(([action, order]) => {
      if (order && order._id === action.payload._id && action.useCached) {
        return of(order);
      }
      return this.marketingOrderService.getOrder(action.payload._id).pipe(
        catchError((err) => this.processCatchError(OrderActionTypes.GetOrder, action.payload, err)),
      );
    }),
    filter((order) => !(order instanceof ErrorData)),
    map((order:MarketingOrder) => {
      const event = new NotificationEvent(OrderActionTypes.GetOrderComplete.type, OrderActionTypes.GetOrderComplete.type, order);
      this.eventService.getEventEmitter().emit(event);
      this.store.dispatch(GetOrderComplete({ payload: order }));
      return order;
    }),
  ), { dispatch: false });

  /**
   * Take action on UpdateOrder actions
   *  1. Update the order
   *  2. If error, dispatch to error handler
   *  3. filter out chained error observables
   *  4. Send out success notification and return observable of order
   *
   */

  updateOrder: Observable<MarketingOrder> = createEffect(() => this.actions$.pipe(
    ofType(OrderActionTypes.UpdateOrder),
    mergeMap((action) => {
      return this.marketingOrderService.saveOrder(action.payload).pipe(
        catchError((err) => {
          this.store.dispatch(UpdateOrderFailed({ payload: action.payload }));
          return this.processCatchError(OrderActionTypes.UpdateOrderFailed, { payload: action.payload }, err);
        }),
      );
    }),
    filter((order) => !(order instanceof ErrorData)),
    map((order:MarketingOrder) => {
      const event = new NotificationEvent(OrderActionTypes.UpdateOrderComplete.type, OrderActionTypes.UpdateOrderComplete.type);
      this.eventService.getEventEmitter().emit(event);
      this.store.dispatch(UpdateOrderComplete({ payload: order }));
      return order;
    }),
  ), { dispatch: false });

  /**
   * This effect will update the current order in state if the order that was updated
   * matches the current order
   */

  updateCurrentOrderState: Observable<MarketingOrder> = createEffect(() => this.actions$.pipe(
    ofType(OrderActionTypes.UpdateCurrentOrderState),
    withLatestFrom(this.store.select(SELECTEDORDER).pipe(
      map((order: MarketingOrder) => order),
    )),
    map(([action, order]) => {
      if (action?.payload._id === order?._id) {
        let updatedOrder = action.payload;
        if (action.patch) {
          // If we are doing a patch, update the existing order in memory
          order.deserialize(action.payload);
          updatedOrder = order;
        }

        this.store.dispatch(UpdateOrderComplete({ payload: updatedOrder }));
        return action.payload;
      }
      return order;
    }),
  ), { dispatch: false });

  updateProductState: Observable<MarketingOrder> = createEffect(() => this.actions$.pipe(
    ofType(OrderActionTypes.UpdateCurrentProductState),
    withLatestFrom(this.store.select(SELECTEDORDER).pipe(
      map((order: MarketingOrder) => order),
    )),
    map(([action, order]) => {
      if (action?.orderId === order?._id) {
        const product = order.getProduct(action.product?.code);
        if (product) {
          product.deserialize(action.product);
          this.store.dispatch(UpdateOrderComplete({ payload: order }));
        } else {
          console.warn('Could not locate product');
        }
      }
      return order;
    }),
  ), { dispatch: false });

  /**
   * Take action on DeleteOrder actions
   *
   *  1. Delete the MarketingOrder
   *  2. If error, dispatch to error handler
   *  3. filter out chained error observables
   *  4. Dispatch delete complete state
   *
   */

  deleteOrder: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(OrderActionTypes.DeleteOrder),
    mergeMap((action) => {
      return this.marketingOrderService.deleteOrder(action.payload).pipe(
        catchError((err) => this.processCatchError(OrderActionTypes.DeleteOrder, action.payload, err)),
      );
    }),
    filter((order) => !(order instanceof ErrorData)),
    map(() => { this.store.dispatch(DeleteOrderComplete()); }),
  ), { dispatch: false });

  updateOrderProductDescription: Observable<MarketingOrder> = createEffect(() => this.actions$.pipe(
    ofType(OrderActionTypes.UpdateOrderProductDescription),
    mergeMap((action) => this.marketingOrderService.saveOrderProductDescription(action.payload).pipe(
      catchError((err) => this.processCatchError(OrderActionTypes.UpdateOrderProductDescription, action.payload, err)),
    )),
    filter((order) => !(order instanceof ErrorData)),
    map((order:MarketingOrder) => {
      this.store.dispatch(UpdateOrderComplete({ payload: order }));

      const event = new NotificationEvent(OrderActionTypes.UpdateOrder.type, OrderActionTypes.UpdateOrder.type);
      this.eventService.getEventEmitter().emit(event);
      return order;
    }),
  ), { dispatch: false });

  /**
   * This will update the media selected on a product instance.
   */

  updateProductMedia: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(OrderActionTypes.UpdateProductMedia),
    withLatestFrom(this.store.select(SELECTEDORDER)),
    mergeMap(([action, order]) => {
      return this.marketingOrderService.updateMedia(order._id, action.productCode, action.payload).pipe(
        catchError((err) => this.processCatchError(OrderActionTypes.UpdateProductMedia, action.payload, err)),
      );
    }),
    filter((orderState) => !(orderState instanceof ErrorData)),
    map((order:MarketingOrder) => {
      const event = new NotificationEvent(OrderActionTypes.UpdateProductMediaComplete.type, OrderActionTypes.UpdateProductMediaComplete.type);
      this.eventService.getEventEmitter().emit(event);
      this.store.dispatch(UpdateOrderComplete({ payload: order }));
      return order;
    }),
  ), { dispatch: false });

  /**
   * Take action on BulkAssignOrders actions
   *  1. Update the orders assignment
   *  2. If error, dispatch to error handler
   *  3. filter out chained error observables
   *  4. Send out success notification and return observable of order
   *
   */

  bulkAssignOrders: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(OrderActionTypes.BulkAssignOrders),
    switchMap(({ orderIds, coordinatorId }) => {
      return this.marketingOrderService.bulkAssignOrders(orderIds, coordinatorId).pipe(
        catchError((err) => this.processCatchError(OrderActionTypes.BulkAssignOrders, { orderIds, coordinatorId }, err)),
      );
    }),
    filter((order) => !(order instanceof ErrorData)),
    map((res:any) => {
      this.store.dispatch(BulkAssignOrdersComplete());

      const event = new NotificationEvent(OrderActionTypes.BulkAssignOrdersComplete.type);
      this.eventService.getEventEmitter().emit(event);
      return res;
    }),
  ), { dispatch: false });
}

/**
 * The order state effects are post state change actions that occur.
 */
@Injectable()
export class OrderStateEffects extends BaseEffects {
  constructor(
    private actions$: Actions,
    private marketingOrderService: MarketingOrderService,
    eventService: NotificationEventService,
    errorHandler: GlobalErrorHandler,
  ) {
    super(errorHandler, eventService);
  }

  /**
   * Take action on UpdateOrderState actions
   *
   *  1. Update the listing
   *  2. If error, dispatch to error handler
   *  3. filter out chained error observables
   *  4. Send out success notification and return observable of listing
   *
   */

  updateOrderState: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(OrderActionTypes.UpdateOrderState),
    mergeMap((action) => {
      return this.marketingOrderService.updateOrderState(action.marketingOrder._id, action.payload).pipe(
        catchError((err) => this.processCatchError(OrderActionTypes.UpdateOrderState, action.payload, err)),
      );
    }),
    filter((orderState) => !(orderState instanceof ErrorData)),
    map((result:any) => {
      return result;
    }),
  ), { dispatch: false });
}
