import { Deserializable } from './deserializable.model';

export enum OptionType {
  paperSize = 'paper-size',
  paperType = 'paper-type',
}
export class Option implements Deserializable<Option> {
  title: string;
  description: string;
  type: OptionType;
  code: string;
  order: number;
  selected: boolean;
  assets: any;

  deserialize(input: any): Option {
    Object.assign(this, input);
    return this;
  }
}
