import { FormControl, Validators } from '@angular/forms';
import { distinctUntilChanged, map, pairwise, startWith, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseForm } from './base.form';
import { emptyDecimalPointValidator, wholeNumberValidator } from '../validators/whole-number.validator';
import { Listing } from '../models/listing.model';
import { LotSizeUnitOfMeasure } from '../models/lot-size-unit-of-measure.enum';
import { CustomValidator } from '../validators';
import { emptyStringValidator } from '../validators/empty-string.validator';
import { urlValidator } from '../validators/url.validator';
import { AddressForm } from './address.form';
import { ListingPhoto } from '../models';

export class ListingForm extends BaseForm {
  readonly originalModel: Listing;
  readonly addressForm: AddressForm;
  readonly showLandTenure$: Observable<boolean>;
  readonly features$: Observable<any>;
  listingFeatures: string[];
  tags: string[];
  photos: ListingPhoto[];

  public get bedrooms(): number { return this.get('bedrooms').value; }
  public get bathrooms(): number { return this.get('bathrooms').value; }

  constructor(listing?: Listing, listingPhotos?: ListingPhoto[]) {
    super({
      address: new AddressForm(listing?.address),
      propertyType: new FormControl(null, [Validators.required]),
      status: new FormControl(null, [Validators.required]),
      listPrice: new FormControl(null, [wholeNumberValidator(), Validators.max(999999999), emptyStringValidator('listPrice')]),
      features: new FormControl([]),
      displayAsPriceOnRequest: new FormControl(false),
      bedrooms: new FormControl(null, [wholeNumberValidator(), Validators.max(99), emptyStringValidator('bedrooms')]),
      bedroomsLabel: new FormControl(),
      bathrooms: new FormControl(null, [emptyDecimalPointValidator(), Validators.max(99), emptyStringValidator('bathrooms')]),
      bathroomsLabel: new FormControl(),
      sqFt: new FormControl(null, [emptyDecimalPointValidator(), emptyStringValidator('sqFt')]),
      lotSize: new FormControl(null, [emptyDecimalPointValidator(), emptyStringValidator('lotSize')]),
      lotSizeUnitOfMeasure: new FormControl(listing?.lotSizeUnitOfMeasure || LotSizeUnitOfMeasure.sqft),
      listDate: new FormControl(null),
      closedDate: new FormControl(null),
      mlsId: new FormControl(null, [Validators.pattern('^[A-Za-z0-9 -]+$'), CustomValidator.forbiddenWordsValidator]),
      globalLuxuryListing: new FormControl(null),
      coldwellBankerHomesUrl: new FormControl(null, [urlValidator({ enforceHttps: true })]),
      landTenure: new FormControl(listing?.landTenure),
      buyerBrokerageCommission: new FormControl(null),
      qrCodeStatus: new FormControl(null),
      qrCodeRedirectUrl: new FormControl(null, [emptyStringValidator('qrCodeRedirectUrl'), urlValidator()]),
      priceLabel: new FormControl(),
      agentType: new FormControl(),
    });

    this.addressForm = this.get('address') as AddressForm;
    if (listing) {
      this.patchValue(listing);
      this.originalModel = listing;

      if (listingPhotos) {
        this.listingFeatures = listing?.features || [];
        this.tags = listingPhotos?.flatMap((photo) => photo.tags || []) ?? [];
        this.photos = listingPhotos;

        const uniqueFeatures = new Set([...this.listingFeatures, ...this.tags]);
        this.get('features')?.setValue([...uniqueFeatures]);
      }
    }

    if (!listing?.lotSizeUnitOfMeasure) {
      this.patchValue({ lotSizeUnitOfMeasure: LotSizeUnitOfMeasure.sqft });
    }

    this.showLandTenure$ = this.addressForm.get('state').valueChanges.pipe(
      map(() => this.addressForm.isHawaii),
      startWith(this.addressForm.isHawaii),
      tap((isHawaii) => this.get('landTenure').setValue(isHawaii ? this.originalModel?.landTenure : null)),
    );

    this.features$ = this?.get('features')?.valueChanges.pipe(
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
      pairwise(),
      map(([previous, current]) => this.onFeaturesChanged(previous, current)),
      tap(({ features, photos }) => {
        if (features) this.listingFeatures = features;
        if (photos) this.photos = photos;
      }),
    );
  }

  patchValue(value: any) {
    return super.patchValue(value);
  }

  onFeaturesChanged(
    previous: string[],
    current: string[],
  ): { features?: string[], photos?: ListingPhoto[] } {
    const { added, deleted } = findDifference(previous, current);

    if (added) {
      this.listingFeatures.push(added);
      return { features: this.listingFeatures };
    }

    if (deleted) {
      this.photos.forEach((photo) => { photo.tags = photo.tags?.filter((tag) => tag !== deleted) || []; });
      this.listingFeatures = this.listingFeatures.filter((feature) => feature !== deleted);
    }

    return { features: this.listingFeatures, photos: this.photos };
  }
}

const findDifference = (previous: string[], current: string[]): { added: string, deleted: string } => {
  const frequencyMapPrevious = previous.reduce((acc, item) => {
    acc[item] = (acc[item] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  const frequencyMapCurrent = current.reduce((acc, item) => {
    acc[item] = (acc[item] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  let added: string = null;
  let deleted: string = null;

  Object.keys(frequencyMapPrevious).forEach((item) => {
    if (frequencyMapPrevious[item] > (frequencyMapCurrent[item] || 0)) {
      deleted = item;
    }
  });

  Object.keys(frequencyMapCurrent).forEach((item) => {
    if (frequencyMapCurrent[item] > (frequencyMapPrevious[item] || 0)) {
      added = item;
    }
  });

  return { added, deleted };
};
