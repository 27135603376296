<mat-list-item class="py-2 bg-lightest" [matTooltip]="'Package: ' + (package?.title ?? 'None')" matTooltipPosition="right" [matTooltipDisabled]="isExpanded">
  <div class="w-100 fx-row y-center fx-gap-sm" [class.x-center]="!isExpanded">

    <img *ngIf="package" [lcBadge]="package" [isSmall]="true" [isVip]="isVIP">
    <mat-icon *ngIf="!package" class="size-34">hide_source</mat-icon>

    <div *ngIf="isExpanded" class="fx-1-1-0">
      <div class="package-name mat-body-2 text-bold text-uppercase line-height-0">{{package?.title ?? 'None'}}</div>
      <div class="package-label mat-caption line-height-0">Package</div>
    </div>

    <a *ngIf="isExpanded && editLink" id="edit-package" mat-icon-button [routerLink]="editLink" [replaceUrl]="replaceUrl" class="xs">
      <mat-icon>edit</mat-icon>
    </a>
  </div>
</mat-list-item>
