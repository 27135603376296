<lc-base-dialog [title]="data?.header || 'Select a User'" [subheader]="data?.subheader" (actionClicked)="onActionClicked($event)">
  <ng-template #content>
    <lc-card-select [formControl]="value" [options]="options" class="hide-form-borders" [size]="150">
      <ng-template #image let-option="option">
        <mat-icon class="xl material-icons-outlined">{{option?.value === 'current-user' ? 'person' : 'supervisor_account'}}</mat-icon>
      </ng-template>
    </lc-card-select>
  </ng-template>
  <ng-template #actions>
    <div class="w-100 fx-row x-end">
      <button mat-raised-button color="primary" class="continue" (click)="onContinue()">Continue</button>
    </div>
  </ng-template>
</lc-base-dialog>
