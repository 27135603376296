import { AbstractControl, ValidatorFn } from '@angular/forms';

export const arrayLengthValidator = (minLength: number): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }

    const value = control.value instanceof Array ? control.value : control.value;
    return value.length >= minLength ? null : { custom: `Must have at least ${minLength} values` };
  };
};
