export enum UIConstants {
  YES = 'Yes',
  NO = 'No',
  CANCEL = 'Cancel',
  CONFIRM = 'Confirm',
  REJECT_PROOF = 'Reject Proof',
  REQUEST_REVISION = 'Request Revision',
  OK = 'OK',
  REFUND = 'Refund',
  SIGN_OUT = 'Sign Out',
  COPY_PASSWORD = 'Copy Password',

  TEAM_ONLY = 'Team',
  LEAD_ONLY = 'Primary Agent',
  TEAM_WITH_LEAD = 'Team with Primary',
  TWO_MEMBERS = 'First Two Members',
  TEAM_WITH_TWO_MEMBERS = 'Team with First Two Members',
  OFFICE = 'Office',
  NONE = 'None',
}
