import { FormControl } from '@angular/forms';
import { BaseForm } from './base.form';
import { STATES_BY_ABBREVIATION, LoanOfficer } from '../models';

export class LoanOfficerForm extends BaseForm {
  readonly states = STATES_BY_ABBREVIATION;
  readonly originalModel: LoanOfficer;

  constructor(loanOfficer: any) {
    super({
      largeURL: new FormControl(),
      smallURL: new FormControl(),
    });

    if (loanOfficer) {
      this.patchValue(loanOfficer);
      this.originalModel = loanOfficer;
    }
  }
}
