<div class="editor" id="editor-control-value"
     [class.disabled]="disabled"
     [ngClass]="!setBorder ? '' : isHTMLOverflow ? 'danger-border' : ''"
     (lcClickOutside)="onClickedOutside($event)"
>
  <quill-editor
    id="quill-editor-value"
    class="w-100"
    #myEditor
    [formats]="quillFormats"
    [format]="quillFormat"
    [styles]="{'height': minimumHeight}"
    [(ngModel)]="value"
    [bounds]="'self'"
    (onSelectionChanged)="onBlur($event)"
    (onContentChanged)="onTextChange($event)"
    (onEditorCreated)="setFocus($event)"
    [class.invalid]="errors"
    [disabled]="disabled"
    [modules]="modules">
    <div quill-editor-toolbar>
      <div class="editable-toolbar">
        <div  class="editable-toolbar-inner" [ngClass]="{ 'hide-toolbar': !quillToolbarEnabled }">
          <div class="fx-row y-center">
            <div class="toolbar-buttons fx-1-0">
              <div class="ql-formats quill-editor-options">
                <div *ngIf="!isHeader">
                  <button class="ql-bold"></button>
                  <button class="ql-italic"></button>
                  <button class="ql-underline"></button>

                  <button class="ql-list" value="ordered" type="button"></button>
                  <button class="ql-list" value="bullet" type="button"></button>

                  <select class="ql-align">
                    <option value="left"></option>
                    <option value="center"></option>
                    <option value="right"></option>
                  </select>

                  <button class="ql-link"></button>
                  <button class="summarize-button" *ngIf="statistics?.maximum && !isAgentApp"
                          (click)="onSummarizeClicked()"
                          [disabled]="hasSummarized">
                    <mat-icon class="summarize">auto_awesome</mat-icon>
                  </button>
                  <button *ngIf="(aiFeatureIsEnabled$ | async) && showUndo" style="font-size: x-small; line-height: 10px; min-width: 30px;"
                          mat-button color="primary" type="button" (click)="onUndo()" [disabled]="undoDisabled">
                    Undo
                  </button>
                </div>
              </div>

            </div>
            <div *ngIf="statistics?.maximum">
              <div class="fx-row">
                <div class="approx-text" style="align-content: center; padding-right: 8px;" [ngStyle]="{'color': statistics.percentage >= maxPercentage ? '#de342b' : '#000'}">Approx {{statistics.maximum}} chars </div>
                <div class="mr-1 fx-0-0-auto">
                  <mat-progress-spinner mode="determinate" [value]="statistics.percentage" class="spinner-right"
                                        [color]="statistics.percentage >= maxPercentage ? 'warn': 'primary'" [diameter]="spinnerDiameter"></mat-progress-spinner>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </quill-editor>
</div>
