import { Deserializable } from './deserializable.model';
import { Audit } from './audit.model';

export enum MultiMediaType {
  VIDEO = 'VIDEO',
  WALK_THROUGH = 'WALK_THROUGH',
  OTHER = 'OTHER',
}

export enum MultiMediaSubType {
  VIMEO = 'VIMEO',
  YOUTUBE = 'YOUTUBE',
  MATTERPORT = 'MATTERPORT',
}

export class MultiMediaItem implements Deserializable<MultiMediaItem> {
  _id: string;
  title: string;
  type: MultiMediaType;
  subtype: MultiMediaSubType;
  uri: string;
  // createdById is the photographerId
  createdById: string;
  audit: Audit;

  constructor(input?: any) {
    this.deserialize(input || {});
  }

  deserialize(input: any): MultiMediaItem {
    Object.assign(this, input);
    // only create audit object if it is passed
    if (input.audit) {
      this.audit = new Audit(input.audit);
    }
    return this;
  }
}

export class MultiMediaItemRequest extends MultiMediaItem {
  photoOrderId: string;
  orderId: string;

  constructor(input?: any) {
    super();
    this.deserialize(input || {});
  }

  deserialize(input: any): MultiMediaItem {
    Object.assign(this, input);
    if (input.audit) {
      this.audit = new Audit(input.audit);
    }
    return this;
  }
}
