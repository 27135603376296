import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PdfPreviewDialogComponent, PdfPreviewDialogData } from './pdf-preview.dialog.component';

@Injectable({
  providedIn: 'root',
})
export class PdfPreviewDialogService {
  constructor(private readonly dialog: MatDialog) { }

  /**
   * Opens a PdfPreview dialog and displays the pdf from the pdfUrl
   * @param pdfUrl The url to the PDF
   */
  open(pdfUrl: string) {
    const dialogRef = this.dialog.open<PdfPreviewDialogComponent, PdfPreviewDialogData>(PdfPreviewDialogComponent, {
      data: new PdfPreviewDialogData(pdfUrl),
      height: '90vh',
      width: '1000px',
      maxWidth: '90vw',
    });
    return dialogRef.afterClosed().toPromise();
  }
}
