import { Injectable } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams, HttpResponse,
} from '@angular/common/http';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import {
  catchError, map, shareReplay, tap,
} from 'rxjs/operators';
import { AppService } from './app.service';
import { HeadersEnum } from '../models/headers.enum';

type ModelCreator<T = any> = new (...args: any[]) => T;

/** Additional Options that can be used when making the request */
class ApiOptions<TResponse = any> {
  /** Signifies the ErrorInterceptor to not log errors if one is returned (i.e. - Coupon not found) */
  supressLogErrors?: boolean;

  model?: ModelCreator<TResponse>;

  params?: {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  };

  version?: 'v1' | 'v2';
}

/**
 * Abstract implementation to communicate with the API
 */
abstract class BaseApiService {
  constructor(readonly baseUrl: string, protected readonly http: HttpClient) {
  }

  query$<TModel = any>(path: string, options?: ApiOptions<TModel>): Observable<TModel[]> {
    const { url, params, headers } = this.buildRequest(path, options?.params, options);
    return this.http.get<TModel[]>(url, { params, headers }).pipe(
      map((results) => this.toModels<TModel>(results, options)),
      catchError(this.formatErrors),
      shareReplay(1),
    );
  }

  find$<TModel = any>(path: string, options?: ApiOptions<TModel>): Observable<TModel> {
    const { url, params, headers } = this.buildRequest(path, options?.params, options);
    return this.http.get<TModel>(url, { params, headers }).pipe(
      map((result) => this.toModel<TModel>(result, options)),
      catchError(this.formatErrors),
      shareReplay(1),
    );
  }

  create<TResponse, TRequest = TResponse>(route: string, body: TRequest, options?: ApiOptions<TResponse>): Promise<TResponse> {
    const { url, params } = this.buildRequest(route, options?.params, options);
    return firstValueFrom(this.http.post<TResponse>(url, body, { params }).pipe(
      map((result) => this.toModel<TResponse>(result, options)),
      catchError(this.formatErrors),
      shareReplay(1),
    ));
  }

  update<TResponse, TRequest = TResponse>(route: string, body: TRequest, options?: ApiOptions<TResponse>): Promise<TResponse> {
    const { url, params } = this.buildRequest(route, options?.params, options);
    return firstValueFrom(this.http.put<TResponse>(url, body, { params }).pipe(
      map((result) => this.toModel<TResponse>(result, options)),
      catchError(this.formatErrors),
      shareReplay(1),
    ));
  }

  /**
   * @deprecated in favor of query$(...)
   */
  get<TModel = any>(path: string, parameters: any = {}, options?: ApiOptions<TModel>): Observable<TModel> {
    const { url, params, headers } = this.buildRequest(path, this.buildParams(parameters || {}), options);
    return this.http.get<TModel>(url, { params, headers }).pipe(
      catchError(this.formatErrors),
    );
  }

  /**
   * @deprecated in favor of update
   */
  put<TModel = any>(path: string, body: Object = {}, options?: ApiOptions<TModel>): Observable<TModel> {
    const { url, headers } = this.buildRequest(path, {}, options);
    return this.http.put<TModel>(url, body, { headers })
      .pipe(catchError(this.formatErrors));
  }

  /**
   * @deprecated in favor of create
   */
  post<TModel = any>(path: string, body: Object = {}, options?: ApiOptions<TModel>): Observable<TModel> {
    const { url, headers } = this.buildRequest(path, {}, options);
    return this.http.post<TModel>(url, body, { headers })
      .pipe(catchError(this.formatErrors));
  }

  patch<TModel = any>(path: string, body: Object = {}, options?: ApiOptions<TModel>): Promise<TModel> {
    const { url, headers, params } = this.buildRequest(path, {}, options);
    return firstValueFrom(this.http.patch<TModel>(url, body, { headers, params })
      .pipe(
        catchError(this.formatErrors),
        shareReplay(1),
      ));
  }

  /**
   * @deprecated in favor of .patch(...)
   */
  patch$<TModel = any>(path: string, body: Object = {}, options?: ApiOptions): Observable<TModel> {
    const { url, headers } = this.buildRequest(path, {}, options);
    return this.http.patch<TModel>(url, body, { headers })
      .pipe(catchError(this.formatErrors));
  }

  delete<TModel = any>(path: string, parameters: any = {}, options?: ApiOptions<TModel>): Observable<TModel> {
    const { url, headers, params } = this.buildRequest(path, parameters, options);
    return this.http.delete<TModel>(url, { params, headers })
      .pipe(catchError(this.formatErrors));
  }

  getBlob$(route: string, parameters: any = {}, options?: ApiOptions) {
    const { url, headers, params } = this.buildRequest(route, parameters, options);
    return this.http.get<Blob>(url, { responseType: 'blob' as 'json', params, headers }).pipe(
      tap((blob: any) => console.log(blob.type)),
    );
  }

  getBlobFromS3URL$(imageUrl: string, options?: ApiOptions, parameters: any = {}): Observable<HttpResponse<Blob>> {
    const { headers, params } = this.buildRequest(imageUrl, parameters, options);
    return this.http.get(imageUrl, {
      observe: 'response', responseType: 'blob', params, headers,
    })
      .pipe(map((response) => response));
  }

  /**
   * Queries the API and returns api response (different than the other GET in that it returns the entire response, not just the body)
   * @param path The path to query
   * @param params The parameters to use
   */
  getResponse<TModel = any>(path: string, parameters: any = {}, options?: ApiOptions<TModel>): Observable<HttpResponse<TModel>> {
    const { url, headers, params } = this.buildRequest(path, parameters, options);
    return this.http.get<TModel>(url, { params, observe: 'response', headers })
      .pipe(catchError(this.formatErrors));
  }

  downloadFile(file: Blob, fileName: string) {
    const element:any = document.createElement('a', {});
    document.body.appendChild(element);
    element.style = 'display: none';

    const url = window.URL.createObjectURL(file);
    element.href = url;
    element.download = fileName;
    element.click();
    window.URL.revokeObjectURL(url);
  }

  /**
 * Combines multiple param objects into a array of objects and returns the non-nullable properties
 */
  buildParams(paramObjects: any | any[]) {
    const paramArray = paramObjects instanceof Array ? paramObjects : [paramObjects || {}];

    // Combine all parameters and only return the values that are defined (i.e. - not null)
    let allParams = Object.assign({}, ...paramArray);
    // @ts-ignore
    allParams = Object.entries(allParams)
      .reduce((combined, [name, value]) => {
        if (value) {
          if (typeof value !== 'string') {
            combined[name] = JSON.stringify(value);
          } else {
            combined[name] = value;
          }
        }
        return combined;
      }, {});
    return allParams;
  }

  protected toModel<TModel>(result: TModel, options?: ApiOptions<TModel>) {
    return options?.model ? new options.model(result) : result;
  }

  protected toModels<TModel>(results: TModel[], options?: ApiOptions<TModel>) {
    return options?.model ? results.map((result) => new options.model(result)) : results;
  }

  protected buildRequest(route: string, parameters: any = {}, options?: ApiOptions) {
    let baseUrl = route.startsWith('http') ? '' : this.baseUrl;
    baseUrl = options?.version === 'v2' ? baseUrl.replace('v1', 'v2') : baseUrl;

    const url = `${baseUrl}${route}`;
    const headers = this.buildHeaders(options);
    const nonNullParams = Object.keys(parameters).reduce((p, key) => {
      const value = parameters[key];
      if (value != null) { p[key] = value; }
      return p;
    }, {});
    const flattened = this.buildHttpParams(nonNullParams);
    const params = new HttpParams({ fromObject: flattened });
    return { url, headers, params };
  }

  protected formatErrors(error: any) {
    return throwError(error);
  }

  protected buildHeaders(options: ApiOptions): HttpHeaders | { [header: string]: string | string[] } {
    if (options?.supressLogErrors) {
      const headers: any = {};
      headers[HeadersEnum.SuppressLogErrors] = 'true';
      return headers;
    }
  }

  private buildHttpParams(data: any, params: any = {}, currentPath: string = '') {
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (value instanceof Date) {
        params[`${currentPath}${key}`] = value.toString();
      } else if (value instanceof Object) {
        this.buildHttpParams(value, params, `${currentPath}${key}.`);
      } else {
        params[`${currentPath}${key}`] = value;
      }
    });
    return params;
  }
}

@Injectable()
export class PhotoApiService extends BaseApiService {
  constructor(http: HttpClient) {
    super(AppService.get('photosBaseURL'), http);
  }
}

@Injectable()
export class NotifyApiService extends BaseApiService {
  constructor(http: HttpClient) {
    super(AppService.get('notifyBaseURL'), http);
  }
}

@Injectable()
export class PrinterApiService extends BaseApiService {
  constructor(http: HttpClient) {
    super(AppService.get('printerBaseURL'), http);
  }

  getJsonFile(imageUrl: string): Observable<any> {
    return this.http.get(imageUrl, { observe: 'response', responseType: 'json' })
      .pipe(map((response) => response.body));
  }
}

@Injectable()
export class PdfRenderApiService extends BaseApiService {
  constructor(http: HttpClient) {
    super(AppService.get('pdfRenderBaseURL'), http);
  }
}

@Injectable()
export class ApiService extends BaseApiService {
  constructor(http: HttpClient) {
    super(AppService.get('persistBaseURL'), http);
  }
}
